import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { ShortUrl } from "../types/persisted";
import { CollectionName } from "../utils/collections/shared";
import { shortUrlConverter } from "../utils/collections/short-urls";
import { firestore } from "../utils/firebase";

export const useShortUrlOnce = (shortUrlId: string) => {
  const [shortUrl, loading, error] = useDocumentDataOnce<ShortUrl>(
    doc(firestore, CollectionName.SHORT_URLS, shortUrlId).withConverter(
      shortUrlConverter,
    ),
  );

  return { shortUrl, loading, error };
};
