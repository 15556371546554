import { User } from "firebase/auth";
import { addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { ScheduleFormType } from "../components/ScheduleForm/types";
import { Job } from "../types/persisted";
import { createJobDeploymentRequestCollectionRef } from "./collections/deployment-requests";
import { fetchProject } from "./collections/projects";
import { CollectionName, createDocRefPath } from "./collections/shared";
import { isWorkspaceGenerated } from "./project-utils";
import { sleep } from "./ts-utils";
import { createPerformedByObj } from "./user-utils";

// Fake that scheduling takes a bit longer to give a less flickering UI
const scheduleJobAndWait = async (params: {
  projectId: string;
  job: Job;
  user: User;
  cronExpression: string;
  scheduleForm: ScheduleFormType;
}) => {
  await addDoc(
    createJobDeploymentRequestCollectionRef({
      projectId: params.projectId,
      jobId: params.job.id,
    }),
    {
      createdBy: createPerformedByObj({ user: params.user }),
      cronTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      cronExpression: params.cronExpression,
      scheduleForm: params.scheduleForm,
      deploymentRef: null,
    },
  );

  await sleep(1_000);
};

export const scheduleJob = async (params: {
  projectId: string;
  job: Job;
  user: User;
  cronExpression: string;
  scheduleForm: ScheduleFormType;
}) => {
  const project = await fetchProject({
    refPath: createDocRefPath([CollectionName.PROJECTS, params.projectId]),
  });

  if (isWorkspaceGenerated(project)) {
    await toast.promise(scheduleJobAndWait(params), {
      pending: "Scheduling job",
      success: "Scheduling job",
      error: "Scheduling job failed",
    });
  } else {
    toast.error("Please wait for workspace to be generated.");
  }
};
