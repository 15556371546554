import { collection, orderBy, query } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import {
  createJobsCollectionRefPath,
  jobConverter,
} from "../utils/collections/jobs";
import { firestore } from "../utils/firebase";

export const useJobsSubscription = ({
  projectId,
  shouldFetch,
}: {
  projectId?: string;
  shouldFetch: boolean;
}) => {
  const jobsReceived = useStore((state) => state.jobsReceived);

  const [jobs, _loading, error] = useCollectionData(
    shouldFetch && projectId
      ? query(
          collection(firestore, createJobsCollectionRefPath({ projectId })),
          orderBy("createdAt", "asc"),
        ).withConverter(jobConverter)
      : null,
  );

  const filteredJobs = useMemo(
    () =>
      jobs?.filter(
        (it) => !(it.markedForDeletionAt || it.markedForDeletionBy),
      ) ?? [],
    [jobs],
  );

  useEffect(() => {
    jobsReceived({ items: filteredJobs });
  }, [filteredJobs]);

  if (error) {
    logService.warn(error.message);
  }
};
