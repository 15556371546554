import { IPosition } from "monaco-editor";
import { NavigateFunction } from "react-router-dom";
import { ChatGptMessage } from "../../services/chatgpt-api";
import {
  App,
  Component,
  Datafile,
  Dataframe,
  Job,
  Module,
  MultipageAppPage,
} from "../../types/persisted";
import { routeUtils } from "../../utils/route-utils";
import { useStore } from "../store";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

const actionType = createActionTypeLogger("component");

export interface ComponentState {
  name: string;
  lastOpenedAt: Date;
  isOpen: boolean;
  id: string;
  slug: string;
  refPath: string;
  path: string;
  type: "page" | "dataframe" | "datafile" | "job" | "module" | "app";
  mode?: "build" | "monitor";
  lastKnownCursorPosition?: IPosition;
  chatHistory: ChatGptMessage[];
  chatUpdatedAt: number | null;
}

export interface ComponentSlice {
  componentState: {
    [componentId: string]: ComponentState;
  };
  chatMessageChunkAdded: (params: {
    componentId: string;
    messageId: string;
    content: string;
  }) => void;
  chatMessageAdded: (params: {
    componentId: string;
    message: ChatGptMessage;
  }) => void;
  cursorPositionInEditorChanged: (params: {
    componentId: string;
    position: IPosition;
  }) => void;
  componentRenamed: (params: {
    componentId: string;
    newName: string;
    newSlug: string;
    newPath: string;
  }) => void;
  componentDeleted: (params: {
    componentId: string;
    navigate?: NavigateFunction;
  }) => void;
  componentOpened: (params: {
    component: Component;
    mode?: ComponentState["mode"];
  }) => void;
}

const initialComponentState = {} satisfies ComponentSlice["componentState"];

const isMultipageAppPage = (
  component: Component,
): component is MultipageAppPage =>
  component.id.startsWith("mp-") || component.id.toLocaleLowerCase() === "home";

const isDataframe = (component: Component): component is Dataframe =>
  "numberOfRows" in component;

const isDatafile = (component: Component): component is Datafile =>
  "contentType" in component && !isDataframe(component);

const isApp = (component: Component): component is App =>
  component.id.startsWith("st-");

const isJob = (component: Component): component is Job =>
  component.id.startsWith("jb-");

const isModule = (component: Component): component is Module =>
  component.id.startsWith("mo-");

const componentType = (component: Component): ComponentState["type"] => {
  if (isMultipageAppPage(component)) {
    return "page";
  }

  if (isDataframe(component)) {
    return "dataframe";
  }

  if (isDatafile(component)) {
    return "datafile";
  }

  if (isApp(component)) {
    return "app";
  }

  if (isJob(component)) {
    return "job";
  }

  if (isModule(component)) {
    return "module";
  }

  throw new Error(`Unknown component type: ${JSON.stringify(component)}`);
};

const componentSlug = (component: Component): string => {
  if (isDatafile(component)) {
    return component.slug ?? component.generatedSlug;
  }

  if (isDataframe(component)) {
    return component.slug ?? component.generatedSlug;
  }

  return component.slug;
};

const toComponentState = (params: {
  component: Component;
  mode?: ComponentState["mode"];
}): ComponentState => {
  return {
    name: params.component.name ?? params.component.id,
    lastOpenedAt: new Date(),
    isOpen: true,
    id: params.component.id,
    refPath: params.component.refPath,
    slug: componentSlug(params.component),
    path: window.location.pathname,
    type: componentType(params.component),
    mode: params.mode,
    chatHistory: [],
    chatUpdatedAt: null,
  };
};

export const useOpenComponent = (): ComponentState | null =>
  useStore(
    (state) =>
      Object.values(state.componentState).find((it) => it.isOpen) ?? null,
  );

export const useComponentState = (componentId: string): ComponentState | null =>
  useStore((state) => state.componentState[componentId] ?? null);

const findOpenComponent = (
  componentState: ComponentSlice["componentState"],
): ComponentState | null =>
  Object.values(componentState).find((it) => it.isOpen) ?? null;

export const useOpenComponents = (): ComponentState[] =>
  useStore((state) =>
    Object.values(state.componentState).sort((a, b) =>
      a.lastOpenedAt > b.lastOpenedAt ? -1 : 1,
    ),
  );

export const useComponentDeleted = () =>
  useStore((state) => state.componentDeleted);

export const useComponentRenamed = () =>
  useStore((state) => state.componentRenamed);

// https://github.com/pmndrs/zustand#persist-middleware Store workspace settings in firestore?
// https://docs.pmnd.rs/zustand/guides/typescript -> Search StateCreator
export const componentStore: StoreSlice<ComponentSlice> = (set, get) => ({
  componentState: initialComponentState,
  componentOpened: (params) => {
    set(
      (draft) => {
        const { component, mode } = params;

        Object.keys(draft.componentState).forEach((it) => {
          draft.componentState[it].isOpen = false;
        });

        if (component.id in draft.componentState) {
          draft.componentState[component.id].lastOpenedAt = new Date();
          draft.componentState[component.id].mode = mode;
        } else {
          draft.componentState[component.id] = toComponentState(params);
        }
      },
      false,
      actionType("component-opened"),
    );
  },
  componentDeleted: (params) => {
    const { componentState, project: projectState } = get();
    const openComponent = findOpenComponent(componentState);

    set(
      (draft) => {
        if (params.componentId in draft.componentState) {
          // rome-ignore lint/performance/noDelete: <explanation>
          delete draft.componentState[params.componentId];
        }
      },
      false,
      actionType("component-deleted"),
    );

    if (openComponent?.id === params.componentId && projectState.projectId) {
      // Navigate to project home page if you delete the component you're currently viewing
      params.navigate?.(
        routeUtils.path({
          to: "project",
          params: { projectId: projectState.projectId },
        }),
        {
          replace: true,
        },
      );
    }
  },
  // TODO: Handle renaming the component you are currently viewing
  componentRenamed: (params) => {
    set(
      (draft) => {
        if (params.componentId in draft.componentState) {
          draft.componentState[params.componentId].name = params.newName;
          draft.componentState[params.componentId].slug = params.newSlug;
          draft.componentState[params.componentId].path = params.newPath;
        }
      },
      false,
      actionType("component-renamed"),
    );
  },
  cursorPositionInEditorChanged: (params) => {
    set(
      (draft) => {
        if (params.componentId in draft.componentState) {
          draft.componentState[params.componentId].lastKnownCursorPosition =
            params.position;
        }
      },
      false,
      actionType("cursor-position-in-editor-changed"),
    );
  },
  chatMessageAdded: (params) => {
    set(
      (draft) => {
        if (params.componentId in draft.componentState) {
          draft.componentState[params.componentId].chatHistory.push(
            params.message,
          );
          draft.componentState[params.componentId].chatUpdatedAt = Date.now();
        }
      },
      false,
      actionType("chat-message-added"),
    );
  },
  chatMessageChunkAdded: (params) => {
    set(
      (draft) => {
        if (params.componentId in draft.componentState) {
          const indexOfMessage = get().componentState[
            params.componentId
          ].chatHistory.findIndex((it) => it.id === params.messageId);

          if (indexOfMessage !== -1) {
            draft.componentState[params.componentId].chatHistory[
              indexOfMessage
            ].content += params.content;
          } else {
            draft.componentState[params.componentId].chatHistory.push({
              hidden: false,
              role: "assistant",
              id: params.messageId,
              content: params.content,
            });
          }

          draft.componentState[params.componentId].chatUpdatedAt = Date.now();
        }
      },
      false,
      actionType("chat-message-chunk-added", {
        messageId: params.messageId,
        content: params.content,
      }),
    );
  },
});
