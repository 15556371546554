import { styled } from "../../../stitches.config";

export const Page = styled("div", {
  display: "flex",
  flexFlow: "column",
  padding: "$2",
  variants: {
    padding: {
      default: {
        padding: "$2",
      },
      none: {
        padding: 0,
      },
    },
    width: {
      full: {
        width: "100%",
      },
      "one-half": {
        width: "50%",
      },
      "one-third": {
        width: "33%",
      },
      "one-fourth": {
        width: "25%",
      },
    },
    height: {
      full: {
        height: "calc(100vh - $$topBarHeight)",
        overflow: "auto",
      },
    },
    align: {
      center: {
        margin: "0 auto",
      },
    },
  },
  defaultVariants: {
    width: "full",
    padding: "default",
  },
});

export const FullSizePage = styled("div", {
  height: "calc(100vh - $$topBarHeight)",
  width: "100vw",
});
