import { collection, getCountFromServer, query } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";
import { useProjectDeployment } from "./useProjectDeployment";

export const useIsPublishedMultipageApp = ({
  multipageAppRef,
}: {
  multipageAppRef?: string;
}) => {
  const [isPublishedLegacy, setIsPublishedLegacy] = useState(false);

  useEffect(() => {
    if (multipageAppRef) {
      getCountFromServer(
        query(
          collection(firestore, multipageAppRef, CollectionName.DEPLOYMENTS),
        ),
      ).then((it) => {
        setIsPublishedLegacy(it.data().count > 0);
      });
    }
  }, [multipageAppRef]);

  const { projectDeployment } = useProjectDeployment();

  const isPublished = useMemo(
    () =>
      projectDeployment ? projectDeployment.multipageApps.length > 0 : false,
    [projectDeployment],
  );

  return { isPublished: isPublishedLegacy || isPublished };
};
