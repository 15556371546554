import { useMemo } from "react";
import { ProjectGroup } from "../../components/ProjectGroup/ProjectGroup";
import { useQuery } from "../../hooks/useQuery";
import {
  useHomeState,
  useMemberProjects,
  useOwnProjects,
} from "../../store/slices/home-slice";
import { Flex } from "../../ui/Layout/Flex";
import { Page } from "../../ui/Page";
import { H2 } from "../../ui/TypographyV2/TypographyV2";
import { A_TOUR_OF_DATABUTTON_TEMPLATE_ID } from "../../utils/constants";
import { NewDataAppButton } from "./components/NewDataAppButton";
import { CTACard, OnboardingGuide } from "./components/OnboardingGuide";
import { SharedWithMe } from "./components/SharedWithMe";

const HomePage = () => {
  const ownProjects = useOwnProjects();
  const memberProjects = useMemberProjects();
  const homeState = useHomeState();
  const { appsSharedWithUser } = homeState;
  const { queryParams } = useQuery();
  const isNewDataAppModalOpen = queryParams.get("new-data-app");

  const aTourOfDatabuttonProject = useMemo(
    () =>
      ownProjects.find(
        (it) =>
          it.project.createdFromProjectTemplateId ===
          A_TOUR_OF_DATABUTTON_TEMPLATE_ID,
      ),
    [ownProjects],
  );

  const shouldRenderOnboardingGuide = useMemo(() => {
    if (appsSharedWithUser.length > 0 || memberProjects.length > 0) {
      return false;
    }

    if (ownProjects.length === 0) {
      return true;
    }

    return ownProjects.length === 1 && !!aTourOfDatabuttonProject;
  }, [
    ownProjects,
    appsSharedWithUser,
    memberProjects,
    aTourOfDatabuttonProject,
  ]);

  if (!homeState.isInitialized) {
    return null;
  }

  if (shouldRenderOnboardingGuide) {
    return (
      <OnboardingGuide
        aTourOfDatabuttonProjectId={aTourOfDatabuttonProject?.project.id}
        isModalOpen={isNewDataAppModalOpen === "true"}
      />
    );
  }

  return (
    <Page>
      <Flex direction="vertical" gap="8">
        <Flex direction="vertical" gap="2">
          <Flex gap="2">
            <H2>My data apps</H2>

            <NewDataAppButton isModalOpen={isNewDataAppModalOpen === "true"} />
          </Flex>

          {ownProjects.length > 0 ? (
            <ProjectGroup projects={ownProjects} />
          ) : (
            <CTACard css={{ width: "600px" }}>
              You don't have any data apps yet. Create a new data app to get
              started
            </CTACard>
          )}
        </Flex>

        <SharedWithMe />
      </Flex>
    </Page>
  );
};

export default HomePage;
