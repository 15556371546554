import * as Sentry from "@sentry/react";

export const fixJson = <T>(value: string): T[] => {
  try {
    if (value.includes("}{")) {
      return JSON.parse(`[${value.replace(/\}\{/g, "},{")}]`) as T[];
    } else {
      return [JSON.parse(value) as T];
    }
  } catch (err) {
    Sentry.captureException(err, {
      extra: { value },
    });
    return [];
  }
};
