import { LoginPageWrapper } from "./pages/LoginPage/components/LoginPageWrapper";
import { Navbar } from "./pages/LoginPage/components/Navbar";
import { PageContent } from "./pages/LoginPage/components/PageContent";

export const LoginPageFallback = () => (
  <LoginPageWrapper>
    <PageContent>
      <Navbar />
    </PageContent>
  </LoginPageWrapper>
);
