import { faUpFromDottedLine } from "@fortawesome/pro-regular-svg-icons";
import { User } from "firebase/auth";
import { useCallback, useState } from "react";
import { App } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { publishView } from "../../utils/app-utils";

interface Props {
  projectId: string;
  app: App;
  user: User;
}

export const PublishViewButton = ({ projectId, app, user }: Props) => {
  const [isPublishing, setIsPublishing] = useState(false);

  const onPublishClick = useCallback(async () => {
    setIsPublishing(true);

    await publishView({
      projectId,
      app,
      user,
    });

    setIsPublishing(false);
  }, [projectId, app.id]);

  return (
    <Button intent="secondary" disabled={isPublishing} onClick={onPublishClick}>
      <StyledFontAwesomeIcon icon={faUpFromDottedLine} />
      Publish view
    </Button>
  );
};
