import { ProjectSummary } from "../../store/slices/home-slice";
import { notEmpty } from "../../utils/ts-utils";

export const extractInitials = (projectName: string): string => {
  if (projectName.includes(" ")) {
    const parts = projectName.split(" ");
    return `${parts[0][0]}${parts[1][0]}`.toLocaleUpperCase();
  }

  if (projectName.includes("-")) {
    const parts = projectName.split("-");
    return `${parts[0][0]}${parts[1][0]}`.toLocaleUpperCase();
  }

  if (projectName.length > 2) {
    return projectName.slice(0, 2).toLocaleUpperCase();
  }

  return ":)";
};

const pluralize = (params: {
  singular: string;
  plural: string;
  count?: number;
}): string | null => {
  if (params.count === undefined || params.count === 0) {
    return null;
  }

  return params.count >= 1
    ? `${params.count} ${params.plural}`
    : `${params.count} ${params.singular}`;
};

export const generateComponentsText = ({
  projectSummary,
}: {
  projectSummary: ProjectSummary;
}): string => {
  const { stats } = projectSummary;
  const numberOfDatafiles =
    (stats?.datafileCount ?? 0) + (stats?.dataframeCount ?? 0);
  const parts = [
    pluralize({
      singular: "PAGE",
      plural: "PAGES",
      count: stats?.pageCount,
    }),
    pluralize({
      singular: "VIEW",
      plural: "VIEWS",
      count: stats?.appCount,
    }),
    pluralize({
      singular: "JOB",
      plural: "JOBS",
      count: stats?.jobCount,
    }),
    pluralize({
      singular: "LIBRARY",
      plural: "LIBRARIES",
      count: stats?.jobCount,
    }),
    pluralize({
      singular: "DATAFILE",
      plural: "DATAFILES",
      count: numberOfDatafiles,
    }),
  ];

  return parts.filter(notEmpty).join(", ").trim();
};
