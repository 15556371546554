import { styled } from "../../../stitches.config";
import { ProjectSummary } from "../../store/slices/home-slice";
import { Box } from "../../ui/Box";
import { ProjectCard } from "../ProjectCard/ProjectCard";

const Grid = styled(Box, {
  display: "grid",
  gap: "$2",

  variants: {
    columns: {
      "1": {
        gridTemplateColumns: "1fr",
      },
      "2": {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      "3": {
        gridTemplateColumns: "repeat(3, 1fr)",
      },
      "4": {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
      "5": {
        gridTemplateColumns: "repeat(5, 1fr)",
      },
      "6": {
        gridTemplateColumns: "repeat(6, 1fr)",
      },
    },
  },
});

interface Props {
  projects: ProjectSummary[];
}

export const ProjectGroup = ({ projects }: Props) => (
  <Box>
    <Grid
      columns={{
        "@mobile": "1",
        "@tablet": "2",
        "@laptop": "3",
        "@desktop": "4",
        "@largeDesktop": "5",
        "@extraLargeDesktop": "6",
      }}
    >
      {projects.map((it) => (
        <ProjectCard key={it.project.id} projectSummary={it} />
      ))}
    </Grid>
  </Box>
);
