import { ProjectEvent } from "../../types/persisted";

const isRelatedToPublishingView = (relatedToRef?: string): boolean =>
  !!relatedToRef && /apps\/st-([\da-z]*)\/deployments\/ad-/i.test(relatedToRef);

const isRelatedToPublishingPage = (relatedToRef?: string): boolean =>
  !!relatedToRef &&
  /\/multipage-apps\/([\da-z]*)\/deployments\/mad-/i.test(relatedToRef);

const isRelatedToSchedulingJob = (relatedToRef?: string): boolean =>
  !!relatedToRef && /jobs\/jb-([\da-z]*)\/deployments\/jd-/i.test(relatedToRef);

const derivePublishingViewTitle = (
  event: Omit<ProjectEvent, "derivedTitle">,
): { derivedTitle: string; shortTitle: string } | null => {
  const { relatedTo, triggeredByUser } = event.meta;
  const viewName = relatedTo?.title ?? "view";
  const triggeredByUserName = triggeredByUser?.name;

  if (event.status === "PENDING" || event.status === "IN_PROGRESS") {
    return { derivedTitle: event.title, shortTitle: `Publishing ${viewName}` };
  }

  if (event.status === "FAILURE") {
    return {
      derivedTitle: `Publishing ${viewName} failed.`,
      shortTitle: "Publishing failed.",
    };
  }

  if (event.status === "SUCCESS") {
    return {
      derivedTitle: `${viewName} was published${
        triggeredByUserName ? ` by ${triggeredByUserName}` : ""
      }`,
      shortTitle: "View published!",
    };
  }

  return null;
};

const derivePublishingPagesTitle = (
  event: Omit<ProjectEvent, "derivedTitle">,
): { derivedTitle: string; shortTitle: string } | null => {
  const { triggeredByUser } = event.meta;
  const triggeredByUserName = triggeredByUser?.name;

  if (event.status === "PENDING" || event.status === "IN_PROGRESS") {
    return { derivedTitle: event.title, shortTitle: "Publishing pages" };
  }

  if (event.status === "FAILURE") {
    return {
      derivedTitle: "Publishing pages failed.",
      shortTitle: "Publishing failed.",
    };
  }

  if (event.status === "SUCCESS") {
    return {
      derivedTitle: `Pages were published${
        triggeredByUserName ? ` by ${triggeredByUserName}` : ""
      }`,
      shortTitle: "Pages published!",
    };
  }

  return null;
};

const deriveSchedulingJobTitle = (
  event: Omit<ProjectEvent, "derivedTitle">,
): { derivedTitle: string; shortTitle: string } | null => {
  const { relatedTo, triggeredByUser } = event.meta;
  const jobName = relatedTo?.title ?? "job";
  const triggeredByUserName = triggeredByUser?.name;

  if (event.status === "PENDING" || event.status === "IN_PROGRESS") {
    return { derivedTitle: event.title, shortTitle: `Scheduling ${jobName}` };
  }

  if (event.status === "FAILURE") {
    return {
      derivedTitle: `Scheduling ${jobName} failed`,
      shortTitle: "Scheduling failed",
    };
  }

  if (event.status === "SUCCESS") {
    return {
      derivedTitle: `${jobName} was scheduled successfully${
        triggeredByUserName ? ` by ${triggeredByUserName}` : ""
      }`,
      shortTitle: "Job scheduled!",
    };
  }

  return null;
};

/**
 * Will update event title for supported events. If this returns null the event will display "title" and "description"
 */
export const createTitleForEvent = (
  event: Omit<ProjectEvent, "derivedTitle">,
): { derivedTitle: string; shortTitle: string } | null => {
  if (isRelatedToPublishingView(event.meta.relatedTo?.ref)) {
    return derivePublishingViewTitle(event);
  }

  if (isRelatedToSchedulingJob(event.meta.relatedTo?.ref)) {
    return deriveSchedulingJobTitle(event);
  }

  if (isRelatedToPublishingPage(event.meta.relatedTo?.ref)) {
    return derivePublishingPagesTitle(event);
  }

  return null;
};
