import { styled } from "../../../../stitches.config";

export const DatabutlerWrapper = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const DatabutlerDialog = styled("div", {
  display: "flex",
  flexFlow: "column",
  gap: "$3",
  position: "absolute",
  bottom: "$$statusBarHeight",
  border: "1px solid $mainBorder",
  backgroundColor: "#fdfdfd",
  maxHeight: "60vh",
  width: "100%",
  zIndex: 1,
  padding: "$2",
  justifyContent: "space-between",
});

export const DatabutlerMessageHistory = styled("div", {
  display: "flex",
  flexFlow: "column",
  gap: "$1",
  maxHeight: "90%",
  overflowY: "auto",
  paddingRight: "$1",

  scrollbarWidth: "none",
  "-ms-overflow-style": "none",

  "&::-webkit-scrollbar": {
    display: "none",
  },
});
