import { useCallback } from "react";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useStore } from "../../../store/store";
import { ADJUSTED_WORKSPACE } from "../../../utils/analytics-constants";
import { Button } from "../../Button";
import { ButtonGroup } from "../../ButtonGroup/ButtonGroup";
import { ColumnsIcon, EditIcon, EyeIcon, RowsIcon } from "../../Icon";
import { SplitPaneLayout } from "../utils";

interface Props {
  layout: SplitPaneLayout;
  onLayoutClicked: (layout: SplitPaneLayout) => void;
}

export const SplitPaneButtons = ({ layout, onLayoutClicked }: Props) => {
  const identifier = useWorkspaceContext();
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  const handleLayoutClick = useCallback(
    (layout: SplitPaneLayout) => () => {
      pulseTriggered({
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          layout,
          workspaceSetting: "layout",
        },
      });
      onLayoutClicked(layout);
    },
    [identifier],
  );

  return (
    <ButtonGroup>
      <Button
        onClick={handleLayoutClick(SplitPaneLayout.VERTICAL_100_0)}
        content="icon"
        intent={layout === SplitPaneLayout.VERTICAL_100_0 ? "primary" : "pink"}
      >
        <EditIcon />
      </Button>
      <Button
        onClick={handleLayoutClick(SplitPaneLayout.VERTICAL_50_50)}
        content="icon"
        intent={layout === SplitPaneLayout.VERTICAL_50_50 ? "primary" : "pink"}
      >
        <ColumnsIcon />
      </Button>
      <Button
        onClick={handleLayoutClick(SplitPaneLayout.HORIZONTAL_50_50)}
        content="icon"
        intent={
          layout === SplitPaneLayout.HORIZONTAL_50_50 ? "primary" : "pink"
        }
      >
        <RowsIcon />
      </Button>
      <Button
        onClick={handleLayoutClick(SplitPaneLayout.VERTICAL_0_100)}
        content="icon"
        intent={layout === SplitPaneLayout.VERTICAL_0_100 ? "primary" : "pink"}
      >
        <EyeIcon />
      </Button>
    </ButtonGroup>
  );
};
