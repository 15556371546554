import { useEffect } from "react";
import { toast } from "react-toastify";
import { usePrevious } from "../../hooks/usePrevious";
import { useProjectEvent } from "../../hooks/useProjectEvent";

interface Props {
  refPath: string;
}

export const EventSuccessListener = ({ refPath }: Props) => {
  const { event } = useProjectEvent({ refPath });
  const prevEvent = usePrevious(event);

  useEffect(() => {
    const isTransitioning =
      !!prevEvent?.status &&
      !!event?.status &&
      prevEvent.status !== event.status;

    if (isTransitioning && event?.status === "SUCCESS") {
      toast(event.shortTitle ?? event.title);
    }

    if (isTransitioning && event?.status === "FAILURE") {
      toast(event.shortTitle ?? event.title);
    }
  }, [prevEvent?.status, event?.status]);

  return null;
};
