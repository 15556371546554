import { Timestamp } from "firebase/firestore";
import { logService } from "../services/log-service";
import { Job, Schedule } from "../types/persisted";
import { fetchSchedule, updateSchedule } from "../utils/collections/schedules";

/**
 * Pauses schedule for a job if there is any
 */
export const pauseSchedule = async (params: {
  job: Job;
}): Promise<void> => {
  logService.info(`Pausing schedule for job: ${params.job.refPath}`);

  const schedule = await fetchSchedule({
    jobRefPath: params.job.refPath,
  });

  if (schedule) {
    const pauseScheduleRequest = {
      state: "PAUSED",
      updatedAt: Timestamp.now(),
    } satisfies Pick<Schedule, "state" | "updatedAt">;

    await updateSchedule({
      refPath: schedule.refPath,
      request: pauseScheduleRequest,
    });
  } else {
    logService.info("Job has no schedules. Skipping");
  }
};

/**
 * Starts schedule for a job if there is any
 */
export const startSchedule = async (params: {
  job: Job;
}): Promise<void> => {
  logService.info(`Starting schedule for job: ${params.job.refPath}`);

  const schedule = await fetchSchedule({
    jobRefPath: params.job.refPath,
  });

  if (schedule) {
    const startScheduleRequest = {
      state: "ACTIVE",
      updatedAt: Timestamp.now(),
    } satisfies Pick<Schedule, "state" | "updatedAt">;

    await updateSchedule({
      refPath: schedule.refPath,
      request: startScheduleRequest,
    });
  } else {
    logService.info("Job has no schedules. Skipping");
  }
};
