import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InlineTable } from "../../components/InlineTable/InlineTable";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useProjectPageContext } from "../../components/ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../../components/UserGuard/UserGuard";
import { useDataStorageGroups } from "../../hooks/useDataStorageGroups";
import { useTitle } from "../../hooks/useTitle";
import { useStore } from "../../store/store";
import { VISITED_A_DATAFRAME } from "../../utils/analytics-constants";

export const DataframeView = () => {
  const params = useParams<{ projectId: string; dataframeSlug: string }>();
  const { project } = useProjectPageContext();
  const { dataframes } = useDataStorageGroups({ projectId: project.id });
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const componentOpened = useStore((state) => state.componentOpened);

  const dataframe = useMemo(
    () =>
      dataframes.find(
        (it) =>
          it.slug === params.dataframeSlug ||
          it.generatedSlug === params.dataframeSlug,
      ),
    [dataframes, params.dataframeSlug],
  );

  useEffect(() => {
    if (dataframe) {
      componentOpened({ component: dataframe });
    }
  }, [dataframe]);

  useTitle(dataframe?.name ?? dataframe?.id);
  const { user } = useUserGuardContext();

  useEffect(() => {
    if (dataframe) {
      pulseTriggered({
        eventName: VISITED_A_DATAFRAME,
        user,
      });
    }
  }, [dataframe]);

  if (!dataframe) {
    return <LoadingScreen message="Loading dataframe..." />;
  }

  return <InlineTable projectId={project.id} dataframe={dataframe} />;
};
