import {
  faArrowUpRightFromSquare,
  faBrowser,
  faCheck,
  faMoon,
  faTableColumns,
  faTableRows,
} from "@fortawesome/pro-regular-svg-icons";
import { styled } from "../../../stitches.config";
import { useStore } from "../../store/store";
import { Icon, StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import {
  isOnOffFeatureEnabled,
  OnOffFeatureFlag,
} from "../../utils/feature-flag-utils";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";

const MenuItem = styled("div", {
  display: "grid",
  gridTemplateColumns: "max-content auto 20px",
  alignItems: "center",
  gap: "$1",
  cursor: "pointer",
});

interface Props {
  iframeSrc?: string;
  componentType: "app" | "job" | "module" | "page";
  onLayoutAdjusted: () => void;
}

export const WorkspaceMenu = ({
  iframeSrc,
  componentType,
  onLayoutAdjusted,
}: Props) => {
  const identifier = useWorkspaceContext();
  const workspace = useStore((state) => state.workspace);
  const darkModeToggled = useStore((state) => state.darkModeToggled);
  const layoutAdjusted = useStore((state) => state.layoutAdjusted);
  const multiplayerCursorToggled = useStore(
    (state) => state.multiplayerCursorToggled,
  );

  const handleOpenInTabClick = () => {
    if (!iframeSrc) {
      console.error("No preview url provided");
    }
    window.open(iframeSrc, "_blank");
  };

  return (
    <Flex
      direction="vertical"
      gap="2"
      css={{
        width: "200px",
        padding: "$1 $2",
        fontSize: "$1",
      }}
    >
      {componentType !== "module" && (
        <>
          <MenuItem
            role="button"
            onClick={() => {
              layoutAdjusted(identifier, {
                direction: "vertical",
                preset: "vertical-50-50",
              });
              onLayoutAdjusted();
            }}
          >
            <StyledFontAwesomeIcon icon={faTableColumns} />
            Side by side view
            {workspace.layout.direction === "vertical" &&
              workspace.layout.preset !== "vertical-100-0" && (
                <StyledFontAwesomeIcon icon={faCheck} />
              )}
          </MenuItem>
          <MenuItem
            role="button"
            onClick={() => {
              layoutAdjusted(identifier, {
                direction: "horizontal",
                preset: "horizontal-50-50",
              });
              onLayoutAdjusted();
            }}
          >
            <StyledFontAwesomeIcon icon={faTableRows} />
            Top bottom view
            {workspace.layout.direction === "horizontal" && (
              <StyledFontAwesomeIcon icon={faCheck} />
            )}
          </MenuItem>

          <MenuItem
            role="button"
            onClick={() => {
              layoutAdjusted(identifier, {
                direction: "vertical",
                preset: "vertical-100-0",
              });
            }}
          >
            <StyledFontAwesomeIcon icon={faBrowser} />
            Code only
            {workspace.layout.preset === "vertical-100-0" && (
              <StyledFontAwesomeIcon icon={faCheck} />
            )}
          </MenuItem>
        </>
      )}

      {componentType === "app" && (
        <MenuItem
          role="button"
          css={{ marginTop: "$2" }}
          onClick={handleOpenInTabClick}
        >
          <StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} />
          Open preview in tab
        </MenuItem>
      )}

      <MenuItem
        css={componentType !== "module" ? { marginTop: "$2" } : {}}
        role="button"
        onClick={() => {
          darkModeToggled(identifier);
        }}
      >
        <StyledFontAwesomeIcon icon={faMoon} />
        Dark mode
        <Icon
          src={
            workspace.darkMode ? "/toggle-active.svg" : "/toggle-inactive.svg"
          }
          alt="Button to toggle dark mode"
          height="10px"
        />
      </MenuItem>

      {isOnOffFeatureEnabled(OnOffFeatureFlag.SHOW_CURSORS) && (
        <MenuItem
          role="button"
          onClick={() => {
            multiplayerCursorToggled(identifier);
          }}
        >
          <Icon src="/cursor.svg" alt="Multiplayer cursor" height="10px" />
          Multiplayer cursors
          <Icon
            src={
              workspace.multiplayerCursor
                ? "/toggle-active.svg"
                : "/toggle-inactive.svg"
            }
            alt="Toggle status of multiplayer cursor"
            height="10px"
          />
        </MenuItem>
      )}
    </Flex>
  );
};
