import { ComponentProps, lazy, Suspense } from "react";

const DatabuttonEditor = lazy(() => import("./DatabuttonEditor"));

type Props = ComponentProps<typeof DatabuttonEditor>;

export const DatabuttonEditorLazy = (props: Props) => (
  <Suspense fallback={<>...</>}>
    <DatabuttonEditor {...props} />
  </Suspense>
);
