import { User } from "firebase/auth";
import { codeBlockSync } from "../services/code-block-sync";
import { logService } from "../services/log-service";
import { CodeBlock, CodeBlockVersion } from "../types/persisted";
import { takeControlOverCodeBlock } from "../utils/code-block-utils";
import {
  appendNewCodeBlockVersion,
  fetchLatestCodeBlockVersion,
} from "../utils/collections/code-block-versions";
import {
  createCodeBlockRefPath,
  fetchCodeBlock,
} from "../utils/collections/code-blocks";

type FetchLatestCodeByRefParams = { codeBlockRef: string };

type FetchLatestCodeByIdsParams = { projectId: string; componentId: string };

/**
 * Fetches latest code block and code block version
 */
export const fetchLatestCode = async (
  params: FetchLatestCodeByIdsParams | FetchLatestCodeByRefParams,
): Promise<{
  codeBlock: CodeBlock;
  version: CodeBlockVersion;
} | null> => {
  if ("codeBlockRef" in params) {
    const [codeBlock, version] = await Promise.all([
      fetchCodeBlock({ refPath: params.codeBlockRef }),
      fetchLatestCodeBlockVersion({ refPath: params.codeBlockRef }),
    ]);

    if (!codeBlock) {
      return null;
    }

    return { codeBlock, version };
  }

  const codeBlock = await fetchCodeBlock({
    projectId: params.projectId,
    componentId: params.componentId,
  });

  if (!codeBlock) {
    return null;
  }

  const version = await fetchLatestCodeBlockVersion({
    projectId: params.projectId,
    codeBlockId: codeBlock.id,
  });

  return { codeBlock, version };
};

/**
 * Saves code
 *
 * This will:
 * 1. Append a new code block version to the history
 * 2. Take control over the code block
 * 3. Sync changes to devx
 */
export const saveCode = async (params: {
  projectId: string;
  codeBlockRef: string;
  user: User;
  code: string;
  isDevxReady: boolean;
}): Promise<CodeBlockVersion> => {
  const latestCode = await fetchLatestCode({
    codeBlockRef: params.codeBlockRef,
  });

  if (latestCode) {
    const [newCodeBlockVersion] = await Promise.all([
      appendNewCodeBlockVersion({
        codeBlockRef: params.codeBlockRef,
        user: params.user,
        newCode: params.code,
        previousCodeBlockVersionRef: latestCode.version.refPath,
      }),
      takeControlOverCodeBlock({
        refPath: params.codeBlockRef,
        user: params.user,
      }),
    ]);

    await codeBlockSync.createOrUpdate({
      projectId: params.projectId,
      codeBlock: latestCode.codeBlock,
      codeBlockVersion: newCodeBlockVersion,
      user: params.user,
      isDevxReady: params.isDevxReady,
    });

    return newCodeBlockVersion;
  } else {
    throw new Error(
      `Could not find code version to append: ${params.codeBlockRef}`,
    );
  }
};

/**
 * Restore a version. Note: This will not restore the name of that version. Only the code.
 *
 * This will:
 * 1. Append a new code block version to the history, but previous version will
 *    be set to the code block you restored from
 * 2. Sync changes to devx
 */
export const restoreVersion = async (params: {
  projectId: string;
  codeBlockId: string;
  version: CodeBlockVersion;
  user: User;
  isDevxReady: boolean;
}): Promise<CodeBlockVersion> => {
  logService.info(`Restoring code block to version: ${params.version.refPath}`);
  const codeBlockRef = createCodeBlockRefPath({
    projectId: params.projectId,
    codeBlockId: params.codeBlockId,
  });

  const codeBlock = await fetchCodeBlock({
    refPath: codeBlockRef,
  });

  if (codeBlock) {
    const newCodeBlockVersion = await appendNewCodeBlockVersion({
      codeBlockRef,
      newCode: params.version.code,
      previousCodeBlockVersionRef: params.version.refPath,
      user: params.user,
    });

    await codeBlockSync.createOrUpdate({
      projectId: params.projectId,
      codeBlock,
      codeBlockVersion: newCodeBlockVersion,
      user: params.user,
      isDevxReady: params.isDevxReady,
    });

    return newCodeBlockVersion;
  } else {
    throw new Error(
      `Could not find code block to restore to: ${params.codeBlockId}`,
    );
  }
};
