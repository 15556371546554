import { User } from "firebase/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useStore } from "../../store/store";
import { App } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { Divider } from "../../ui/Divider/Divider";
import { Form } from "../../ui/Form";
import { Icon } from "../../ui/Icon";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import { Flex } from "../../ui/Layout/Flex";
import { Grid } from "../../ui/Layout/Grid";
import { Spacer } from "../../ui/Spacer";
import { Text } from "../../ui/Text";
import { Tooltip } from "../../ui/Tooltip";
import { MADE_VIEW_ACCESSIBLE_BY_LINK } from "../../utils/analytics-constants";
import { publishView } from "../../utils/app-utils";
import { togglePubliclyAccessibleByLink } from "../../utils/collections/apps";
import { copyTextToClipboard } from "../../utils/user-utils";
import { isValidEmailAddress } from "../../utils/validation-utils";
import { IconButton } from "../IconButton";
import { ListViewers } from "./ListViewers";
import { addViewerToView } from "./utils";

interface Props {
  projectId: string;
  app: App;
  user: User;
  isPublished: boolean;
}

interface FormProps {
  recipient: string;
}

export const ShareViewForm = ({ app, projectId, user, isPublished }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();

  const handlePublishView = async () => {
    setIsLoading(true);

    await publishView({
      projectId,
      app,
      user,
    });

    setIsLoading(false);
  };

  const handleCopyLink = async () => {
    if (!isPublished) {
      await handlePublishView();
    }

    copyTextToClipboard(sharingLink);
    toast("Link copied to clipboard");
  };

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    if (!isPublished) {
      await handlePublishView();
    }

    await addViewerToView({
      projectId,
      appId: app.id,
      recipient: data.recipient,
      user,
    });

    toast("Invitation sent");
  };

  const toggleSharingLink = async () => {
    await togglePubliclyAccessibleByLink({
      projectId,
      app,
      user,
    });

    pulseTriggered({
      user,
      properties: {
        projectId,
        appId: app.id,
      },
      eventName: MADE_VIEW_ACCESSIBLE_BY_LINK,
    });
  };

  const sharingLink = `${window.location.origin}${app.shortUrl}`;

  return (
    <>
      <Flex direction="vertical">
        <Text weight="thin">Show them what you made!</Text>
        {!isPublished && (
          <Text weight="thin">
            In order to share your view, you have to publish it.
          </Text>
        )}
      </Flex>

      <Spacer height="small" />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        css={{ display: "flex", gap: "$1", flexFlow: "column" }}
      >
        <Grid
          css={{ gridTemplateColumns: "auto max-content", columnGap: "$1" }}
        >
          <Label>
            <Text size="2">Share via email</Text>
            <Tooltip
              text={errors.recipient?.message ?? ""}
              placement="bottom"
              visible={!!errors.recipient}
            >
              <Input
                type="email"
                disabled={isLoading}
                {...register("recipient", {
                  required: "Please enter a valid email",
                  validate: {
                    isValidEmailCheck: (value) => {
                      const valid = isValidEmailAddress(value);
                      return valid || "Invalid email address";
                    },
                  },
                })}
              />
            </Tooltip>
          </Label>

          <Button
            type="submit"
            disabled={isLoading}
            css={{ alignSelf: "flex-end" }}
          >
            {isPublished ? "Send invitation" : "Publish and send invitation"}
          </Button>
        </Grid>
      </Form>

      <Spacer height="small" />

      <ListViewers projectId={projectId} appId={app.id} />

      <Divider marginY="4" />

      <Flex direction="vertical" gap="2">
        <Text size="2">Share with open link</Text>

        <Flex direction="vertical">
          <Text weight="thin">
            Anyone who has access to this link can see your view.
          </Text>

          <Text weight="thin">
            You can always deactivate the link using the toggle below.
          </Text>
        </Flex>

        <Grid
          css={{
            gridTemplateColumns: "auto max-content",
            columnGap: "$1",
          }}
        >
          <Input
            id="sharing-link"
            disabled={true}
            type="text"
            value={sharingLink}
          />

          <Button
            css={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
            onClick={handleCopyLink}
            disabled={isLoading || !app.isPublic}
          >
            {isPublished ? "Copy link" : "Publish and copy link"}
          </Button>
        </Grid>

        <Flex css={{ marginBottom: "$1" }} gap="1">
          <Flex gap="1">
            <Text weight="thin">Enable open link</Text>
            <IconButton onClick={toggleSharingLink}>
              <Icon
                src={
                  app.isPublic ? "/toggle-active.svg" : "/toggle-inactive.svg"
                }
                alt="Button to toggle open link"
                height="10px"
              />
            </IconButton>
          </Flex>
          <Text weight="thin">
            {app.isPublic
              ? "Anyone with access to this link can see this view."
              : "Your link is currently deactivated and can not be opened."}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
