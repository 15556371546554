import {
  ComponentSnapshot,
  ComponentSnapshotBase,
} from "@databutton/firebase-types";
import { useMemo } from "react";
import { useAppConfigSnapshot } from "../../../hooks/useAppConfigSnapshot";
import { useProjectDeployment } from "../../../hooks/useProjectDeployment";
import { useProjectPageContext } from "../../ProjectWrapper/ProjectWrapper";

interface CodeInProd {
  hash: number;
  codeBlockVersionId?: string;
}

const toCodeInProd = (
  component?: ComponentSnapshotBase,
): CodeInProd | undefined =>
  component
    ? {
        hash: component.codeBlock.version.hash,
        codeBlockVersionId: component.codeBlock.version.id,
      }
    : undefined;

const toCodeInProdFromAppConfig = (
  component?: ComponentSnapshot,
): CodeInProd | undefined =>
  component
    ? {
        hash: component.codeHash,
        codeBlockVersionId: undefined,
      }
    : undefined;

export const useCodeInProd = (params: {
  componentType: "app" | "page" | "job";
  componentId: string;
}) => {
  const { project } = useProjectPageContext();
  const { projectDeployment } = useProjectDeployment();
  const { appConfigSnapshot } = useAppConfigSnapshot({ projectId: project.id });

  const codeInProd = useMemo((): CodeInProd | undefined => {
    if (projectDeployment && params.componentType === "page") {
      const component = projectDeployment?.multipageApps[0]?.pages.find(
        (it) => it.component.id === params.componentId,
      );

      return toCodeInProd(component);
    } else if (projectDeployment && params.componentType === "app") {
      const component = projectDeployment?.views.find(
        (it) => it.component.id === params.componentId,
      );

      return toCodeInProd(component);
    } else if (projectDeployment && params.componentType === "job") {
      const component = projectDeployment?.jobs.find(
        (it) => it.component.id === params.componentId,
      );

      return toCodeInProd(component);
    } else if (appConfigSnapshot && params.componentType === "page") {
      const component = appConfigSnapshot.multipageApps[0]?.pages.find(
        (it) => it.id === params.componentId,
      );

      return toCodeInProdFromAppConfig(component);
    } else if (appConfigSnapshot && params.componentType === "app") {
      const component = appConfigSnapshot.views.find(
        (it) => it.id === params.componentId,
      );

      return toCodeInProdFromAppConfig(component);
    } else if (appConfigSnapshot && params.componentType === "job") {
      const component = appConfigSnapshot.jobs.find(
        (it) => it.id === params.componentId,
      );

      return toCodeInProdFromAppConfig(component);
    }

    return undefined;
  }, [projectDeployment, appConfigSnapshot, params.componentId]);

  return codeInProd;
};
