import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { createRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLogs } from "../../hooks/useLogs";
import { JobRun } from "../../types/persisted";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";
import { Tooltip } from "../../ui/Tooltip";
import { getDevRunRef } from "../../utils/collections/logs";
import { copyTextToClipboard } from "../../utils/user-utils";
import { IconButton } from "../IconButton";

interface Props {
  projectId: string;
  jobId: string;
  run: JobRun;
}

export const DevRunLog = ({ projectId, jobId, run }: Props) => {
  const { logs } = useLogs({
    componentRef: getDevRunRef({ projectId, jobId, runId: run.id }),
  });
  const [width, setWidth] = useState<string | null>(null);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current && width === null) {
      const parentWidth = ref.current.parentElement?.offsetWidth;
      setWidth(parentWidth ? `${parentWidth}px` : null);
    }
  }, [ref, width]);

  const output =
    logs !== undefined && logs.length > 0
      ? logs.map((it) => it.msg).join("")
      : null;

  return (
    <Div css={{ position: "relative" }}>
      {output && (
        <Tooltip text="Copy logs to clipboard">
          <IconButton
            css={{
              position: "absolute",
              top: 0,
              right: "$2",
              backgroundColor: "$white",
            }}
            onClick={() => {
              copyTextToClipboard(output).then(() => {
                toast("Copied to clipboard");
              });
            }}
          >
            <StyledFontAwesomeIcon icon={faCopy} />
          </IconButton>
        </Tooltip>
      )}

      <Div
        ref={ref}
        css={{
          padding: "$1",
          display: "flex",
          flexDirection: "column-reverse",
          width: width ?? "100%",
          overflow: "auto",
          minHeight: "30px",
          maxHeight: "400px",
          whiteSpace: "pre",
          fontFamily: "monospace",
        }}
      >
        {output}
        {(run.exitcode === -9 || run.exitcode === -15) && "Aborted"}
      </Div>
    </Div>
  );
};
