import { User } from "firebase/auth";
import { addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { App } from "../types/persisted";
import { createAppDeploymentRequestCollectionRef } from "./collections/deployment-requests";
import { sleep } from "./ts-utils";
import { createPerformedByObj } from "./user-utils";

// Fake that publishing takes a bit longer to give a less flickering UI
const publishViewAndWait = async (params: {
  projectId: string;
  app: App;
  user: User;
}) => {
  await addDoc(
    createAppDeploymentRequestCollectionRef({
      projectId: params.projectId,
      appId: params.app.id,
    }),
    {
      createdBy: createPerformedByObj({ user: params.user }),
      deploymentRef: null,
    },
  );

  await sleep(1_000);
};

export const publishView = async (params: {
  projectId: string;
  app: App;
  user: User;
}) => {
  await toast.promise(publishViewAndWait(params), {
    pending: "Publishing view",
    success: "Publishing view",
    error: "Publishing view failed",
  });
};
