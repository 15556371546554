import { styled } from "../../../stitches.config";

export const Group = styled("div", {
  display: "flex",

  variants: {
    direction: {
      horizontal: {
        alignItems: "center",
      },
      vertical: {
        flexFlow: "column",
      },
    },
    gap: {
      "1": {
        gap: "$1",
      },
      "2": {
        gap: "$2",
      },
      "3": {
        gap: "$3",
      },
      "4": {
        gap: "$4",
      },
    },
  },

  defaultVariants: {
    direction: "horizontal",
  },
});
