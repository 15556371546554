import { PerformedBy } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import {
  CodeBlock,
  CodeBlockVersion,
  MultipageAppPage,
  WithoutId,
} from "../../types/persisted";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
  createDocRefPath,
} from "./shared";
import { createCodeBlockDocument } from "./code-blocks";
import {
  codeBlockVersionConverter,
  createCodeBlockVersionDocument,
} from "./code-block-versions";
import { createPerformedByObj } from "../user-utils";
import { doc, getDoc, runTransaction, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { logService } from "../../services/log-service";
import { createPageSlug } from "../slug-utils";

export const pageConverter = createConverter<MultipageAppPage>();

export const getPageCollectionKey = (params: { projectId: string }) =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.MULTIPAGE_APPS,
    "main",
    CollectionName.PAGES,
  ]);

export const updatePage = async (params: {
  refPath: string;
  payload: Partial<MultipageAppPage>;
}): Promise<void> => {
  logService.debug(`Updating page: ${params.refPath}`);
  await updateDoc(doc(firestore, params.refPath), params.payload);
};

const createNewPageRequest = (params: {
  overrideId?: string;
  name: string;
  slug: string;
  performedBy: PerformedBy;
  codeBlockRef: string;
  sequence: number;
}): { id: string; request: WithoutId<MultipageAppPage> } => {
  const pageId =
    params.overrideId ??
    createDatabuttonId(DatabuttonIdPrefix.MULTIPAGE_APP_PAGE);

  const request = {
    createdBy: params.performedBy,
    name: params.name,
    markedForDeletionBy: null,
    hidden: true,
    slug: params.slug,
    codeBlockRef: params.codeBlockRef,
    sequence: params.sequence,
  } satisfies WithoutId<MultipageAppPage>;

  return { id: pageId, request };
};

export const createPageInFirebase = async (params: {
  name: string;
  projectId: string;
  user: User;
  sequence: number;
  code?: string;
}): Promise<{
  page: MultipageAppPage;
  codeBlock: CodeBlock;
  codeBlockVersion: CodeBlockVersion;
}> => {
  const projectRefPath = createDocRefPath([
    CollectionName.PROJECTS,
    params.projectId,
  ]);

  const pageId = createDatabuttonId(DatabuttonIdPrefix.MULTIPAGE_APP_PAGE);
  const pageRefPath = createDocRefPath([
    projectRefPath,
    CollectionName.MULTIPAGE_APPS,
    "main",
    CollectionName.PAGES,
    pageId,
  ]);

  const slug = createPageSlug(params.name);
  const performedBy = createPerformedByObj({ user: params.user });

  const { id: newCodeBlockId, doc: newCodeBlock } = createCodeBlockDocument({
    componentId: pageId,
    type: "page",
  });

  const { id: newCodeBlockVersionId, doc: newCodeBlockVersion } =
    createCodeBlockVersionDocument({
      user: params.user,
      name: slug,
      code: params.code ?? "import streamlit as st",
    });

  const codeBlockRefPath = createDocRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.CODE_BLOCKS,
    newCodeBlockId,
  ]);

  const { request: newPage } = createNewPageRequest({
    overrideId: pageId,
    name: params.name,
    slug: slug,
    performedBy,
    codeBlockRef: codeBlockRefPath,
    sequence: params.sequence,
  });

  const codeBlockVersionRef = doc(
    firestore,
    createDocRefPath([
      codeBlockRefPath,
      CollectionName.VERSIONS,
      newCodeBlockVersionId,
    ]),
  );

  await runTransaction(firestore, async (transaction) => {
    transaction
      .set(doc(firestore, pageRefPath), newPage)
      .set(doc(firestore, codeBlockRefPath), newCodeBlock)
      .set(codeBlockVersionRef, newCodeBlockVersion);
  });

  const persistedCodeBlockVersionDoc = await getDoc(
    codeBlockVersionRef.withConverter(codeBlockVersionConverter),
  );

  const persistedCodeBlockVersion = persistedCodeBlockVersionDoc.data();

  if (!persistedCodeBlockVersion) {
    throw new Error(
      `Unable to fetch newely created code block version: ${codeBlockVersionRef.path}`,
    );
  }

  return {
    page: { ...newPage, id: pageId, refPath: pageRefPath },
    codeBlock: {
      ...newCodeBlock,
      id: newCodeBlockId,
      refPath: codeBlockRefPath,
    },
    codeBlockVersion: persistedCodeBlockVersion,
  };
};
