import { User } from "firebase/auth";
import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useStore } from "../store/store";
import { Project } from "../types/persisted";
import { projectConverter } from "../utils/collections/projects";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useProjects = (user: User | null) => {
  const projectActions = useStore((state) => state.projectActions);

  const [ownerList] = useCollectionData<Project>(
    user
      ? query(
          collection(firestore, CollectionName.PROJECTS),
          where("owner", "==", user.uid),
          where("markedForDeletionAt", "==", null),
        ).withConverter(projectConverter)
      : null,
  );

  const [memberList] = useCollectionData<Project>(
    user
      ? query(
          collection(firestore, CollectionName.PROJECTS),
          where("members", "array-contains", user.uid),
          where("markedForDeletionAt", "==", null),
        ).withConverter(projectConverter)
      : null,
  );

  useEffect(() => {
    if (ownerList) {
      projectActions.projectsReceived({ projects: ownerList, relation: "own" });
    }
  }, [ownerList]);

  useEffect(() => {
    if (memberList) {
      projectActions.projectsReceived({
        projects: memberList,
        relation: "member",
      });
    }
  }, [memberList]);
};
