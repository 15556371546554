import { ProjectEvent as ProjectEventDoc } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import {
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  setDoc,
} from "firebase/firestore";
import { createTitleForEvent } from "../../hooks/useProjectEvents/utils";
import { ProjectEvent } from "../../types/persisted";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import { firestore } from "../firebase";
import { createPerformedByObj } from "../user-utils";
import { CollectionName, createCollectionRefPath } from "./shared";

export const projectEventConverter: FirestoreDataConverter<ProjectEvent> = {
  toFirestore: (it) => it as DocumentData,
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);

    const event = {
      id: snapshot.id,
      refPath: snapshot.ref.path,
      ...data,
    } as Omit<ProjectEvent, "derivedTitle">;

    return {
      ...event,
      ...createTitleForEvent(event),
    } as ProjectEvent;
  },
};

export const getProjectEventsCollectionKey = (params: {
  projectId: string;
}): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.EVENTS,
  ]);

export const createProjectEventDocRef = (params: {
  refPath: string;
}): DocumentReference<ProjectEvent> =>
  doc(firestore, params.refPath).withConverter(projectEventConverter);

const createProjectEventRequest = (params: {
  user: User;
  status: "PENDING" | "FAILURE" | "IN_PROGRESS" | "SUCCESS";
  title: string;
  shortUrl?: string;
  relatedTo?: { ref: string; title: string };
}): {
  projectEvent: ProjectEventDoc;
  id: string;
} => {
  const projectEventId = createDatabuttonId(DatabuttonIdPrefix.PROJECT_EVENT);

  const performedBy = createPerformedByObj({ user: params.user });

  return {
    id: projectEventId,
    projectEvent: {
      status: params.status,
      title: params.title,
      description: null,
      shortUrl: params.shortUrl ?? null,
      createdBy: performedBy,
      lastUpdatedBy: performedBy,
      meta: {
        relatedTo: params.relatedTo ?? null,
        triggeredByUser: {
          id: params.user.uid,
          name: params.user.displayName ?? "Unknown",
        },
      },
    },
  };
};

export const createAddSecretProjectEvent = (params: {
  user: User;
}): { projectEvent: ProjectEventDoc; id: string } =>
  createProjectEventRequest({
    user: params.user,
    title: params.user.displayName
      ? `${params.user.displayName} added a new secret.`
      : "Added a new secret.",
    status: "SUCCESS",
  });

export const createUpdateSecretProjectEvent = (params: {
  user: User;
  secretName: string;
}): { projectEvent: ProjectEventDoc; id: string } =>
  createProjectEventRequest({
    user: params.user,
    title: params.user.displayName
      ? `The secret ${params.secretName} was updated by ${params.user.displayName}.`
      : `The secret ${params.secretName} was updated.`,
    status: "SUCCESS",
  });

export const addProjectEvent = async (params: {
  eventId: string;
  projectId: string;
  event: ProjectEventDoc;
}) => {
  await setDoc(
    doc(
      firestore,
      getProjectEventsCollectionKey({ projectId: params.projectId }),
      params.eventId,
    ),
    params.event,
  );
};
