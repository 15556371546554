import {
  MigrateViewsRequest,
  MigrateViewsResponse,
} from "@databutton/firebase-types";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { httpsCallable } from "firebase/functions";
import { Fragment, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useApps } from "../../store/slices/project-slice";
import { App } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { Grid } from "../../ui/Layout/Grid";
import { Bold, Txt } from "../../ui/TypographyV2/TypographyV2";
import { functions } from "../../utils/firebase";
import { restartWorkspace } from "../../utils/workspace-utils";
import { useProjectPageContext } from "../ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../UserGuard/UserGuard";

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

const migrateViewsCallable = httpsCallable<
  MigrateViewsRequest,
  MigrateViewsResponse
>(functions, "migrateViews");

export const ConvertViewsDialog = ({ onCancel, onSuccess }: Props) => {
  const [isPosting, setIsPosting] = useState(false);
  const { project } = useProjectPageContext();
  const apps = useApps();
  const { user } = useUserGuardContext();

  const handleSubmit = async () => {
    setIsPosting(true);

    await toast.promise(
      migrateViewsCallable({
        projectId: project.id,
      }),
      {
        pending: "Converting views into pages",
        error:
          "Could not convert views into pages. Please contact a Databutler.",
        success:
          "Success! Restarting your workspace which should take just a minute",
      },
    );

    await restartWorkspace({
      user,
      projectId: project.id,
    });

    setIsPosting(false);
    onSuccess();
  };

  const pages = useMemo((): { homePage: App; nextPages: App[] } | null => {
    if (apps.length === 0) {
      return null;
    }

    return {
      homePage: apps[0],
      nextPages: apps.slice(1),
    };
  }, [apps]);

  return pages ? (
    <Flex direction="vertical" gap="3">
      <Txt>
        Do you want to convert all views in <Bold>{project.name}</Bold> into
        pages?
      </Txt>

      <Flex direction="vertical" gap="2">
        <Txt>
          After converting, your multipage data app will look like this:
        </Txt>

        <Grid
          css={{
            gridTemplateColumns: "120px auto",
            rowGap: "4px",
          }}
        >
          <Flex>
            <Bold>Home</Bold>
          </Flex>
          <Flex>{pages.homePage.name}</Flex>

          {pages.nextPages.map((it, index) => (
            <Fragment key={it.id}>
              <Flex>
                <Bold>Page #{index + 2}</Bold>
              </Flex>
              <Flex>{it.name}</Flex>
            </Fragment>
          ))}
        </Grid>
      </Flex>

      <Txt>
        As default, all pages will be set to <Bold>hidden</Bold> so you will
        need to unhide pages you want to publish. In addition,{" "}
        <Bold>all sharing privileges will be reset</Bold> so you will need to
        re-share to those you want to continue having access.
      </Txt>

      <Txt>
        Links to your old views will be redirected to your home page and version
        history for all pages will be kept.
      </Txt>

      <Txt css={{ color: "$redSolid" }}>
        <Bold>
          <StyledFontAwesomeIcon
            icon={faExclamationTriangle}
            css={{ marginRight: "$1" }}
          />
          Warning: Please review above before converting as this process will
          convert all of your views into pages and replace your current pages.
        </Bold>
      </Txt>

      <Flex gap="1" css={{ alignSelf: "flex-end" }}>
        <Button
          type="button"
          intent="secondary"
          onClick={onCancel}
          disabled={isPosting}
        >
          Cancel
        </Button>
        <Button type="button" disabled={isPosting} onClick={handleSubmit}>
          Yes, convert my views
        </Button>
      </Flex>
    </Flex>
  ) : null;
};
