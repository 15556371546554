import * as Sentry from "@sentry/react";
import { notEmpty } from "../../utils/ts-utils";
import {
  RepeatingFrequency,
  RepeatingWeeklySchedule,
  SpecificTimeWeeklySchedule,
  TimeSelectType,
  Weekday,
  WeeklyScheduleInput,
} from "./types";

const convertWeekdaysToCronPart = (params: WeeklyScheduleInput): string =>
  params.weekdays.length === 7
    ? "*"
    : params.weekdays
        .map((it) => it.split("-")[1])
        .sort()
        .join(",");

const convertCronToWeekdays = (schedule: string): { weekdays: Weekday[] } => {
  if (schedule === "*") {
    return {
      weekdays: Object.values(Weekday),
    };
  }

  return {
    weekdays: schedule
      .split(",")
      .map((it) => {
        const weekday = Object.values(Weekday).find((w) =>
          w.endsWith(`-${it}`),
        );

        return weekday;
      })
      .filter(notEmpty),
  };
};

const REPEATING_TO_CRON_MAP: {
  [key in RepeatingFrequency]: string;
} = {
  [RepeatingFrequency.EVERY_MINUTE]: "* {hourRange}",
  [RepeatingFrequency.EVERY_5_MINUTES]: "*/5 {hourRange}",
  [RepeatingFrequency.EVERY_10_MINUTES]: "*/10 {hourRange}",
  [RepeatingFrequency.EVERY_15_MINUTES]: "*/15 {hourRange}",
  [RepeatingFrequency.EVERY_30_MINUTES]: "*/30 {hourRange}",
  [RepeatingFrequency.EVERY_HOUR]: "0 {hourRange}",
  [RepeatingFrequency.EVERY_2_HOURS]: "0 {hourRange}/2",
  [RepeatingFrequency.EVERY_3_HOURS]: "0 {hourRange}/3",
  [RepeatingFrequency.EVERY_6_HOURS]: "0 {hourRange}/6",
  [RepeatingFrequency.EVERY_12_HOURS]: "0 {hourRange}/12",
};

const convertTimeToCronPart = (params: WeeklyScheduleInput): string => {
  if (params.timeSelectType === TimeSelectType.SPECIFIC_TIME) {
    const [hours, minutes] = params.at.split(":");

    return `${minutes.replace(/^0/, "")} ${hours.replace(/^0/, "")}`;
  }

  if (params.timeSelectType === TimeSelectType.REPEATING) {
    const expression = REPEATING_TO_CRON_MAP[params.repeatingFrequency];
    if (expression && params.repeatingInterval) {
      const { fromHour, toHour } = params.repeatingInterval;

      // Feels natural to write to hour 24, while 23 is actually the max value
      const toHourAdjusted = toHour === 24 ? 23 : toHour;

      if (fromHour.toString() === "0" && toHourAdjusted.toString() === "23") {
        return expression.replace("{hourRange}", "*");
      }

      return expression.replace("{hourRange}", `${fromHour}-${toHourAdjusted}`);
    }
    if (expression) {
      return expression.replace("{hourRange}", "*");
    }

    throw new Error(
      `Could not find matching cron expression for ${params.repeatingFrequency}`,
    );
  }

  return "* *";
};

const getRepeatingFrequencyFromString = (params: {
  timePart: string;
  hourPart: string;
  cronExpression: string;
}): RepeatingFrequency => {
  const asTemplate = `${params.timePart} ${params.hourPart}`.replace(
    /\d{1,2}-\d{1,2}/,
    "{hourRange}",
  );

  const frequency = Object.entries(REPEATING_TO_CRON_MAP).find(
    ([, value]) => value === asTemplate,
  );
  if (frequency) {
    return frequency[0] as RepeatingFrequency;
  }

  throw new Error(
    `Unable to determine repeating frequency from cron expression: "${params.cronExpression}". Consider adding as test case.`,
  );
};

const convertCronToSpecificTime = (
  cronExpression: string,
): Omit<SpecificTimeWeeklySchedule, "weekdays"> => {
  const [timePart, hourPart] = cronExpression.split(" ");

  const at = `${hourPart.padStart(2, "0")}:${timePart.padStart(2, "0")}`;
  const isValidTimestamp = /^\d{2}:\d{2}$/.test(at);

  if (!isValidTimestamp) {
    throw new Error(
      `Unable to create timestamp from cron expression: "${cronExpression}". Consider adding test case.`,
    );
  }

  return {
    timeSelectType: TimeSelectType.SPECIFIC_TIME,
    at,
  };
};

const convertCronToRepeatingTime = (
  cronExpression: string,
): Omit<RepeatingWeeklySchedule, "weekdays"> => {
  const [timePart, hourPart] = cronExpression.split(" ");
  const fixedHourPart = hourPart.startsWith("*")
    ? hourPart.replace(/^\*/, "0-23")
    : hourPart;
  const [fromHour, toHour] = fixedHourPart.replace(/\/\d*$/, "").split("-");
  const repeatingFrequency = getRepeatingFrequencyFromString({
    timePart,
    hourPart: fixedHourPart,
    cronExpression,
  });

  const isValidFromHour = /^\d{1,2}$/.test(fromHour);
  const isValidToHour = /^\d{1,2}$/.test(toHour);

  if (!(isValidFromHour && isValidToHour)) {
    throw new Error(
      `Unable to create repeating interval from cron expression: "${cronExpression}". Consider adding test case.`,
    );
  }

  return {
    timeSelectType: TimeSelectType.REPEATING,
    repeatingInterval: {
      fromHour: Number.parseInt(fromHour, 10),
      toHour: Number.parseInt(toHour, 10),
    },
    repeatingFrequency,
  };
};

// Convert weekly schedule form to cron expression
export const convertWeeklyScheduleToCron = (
  params: WeeklyScheduleInput,
): string =>
  [
    convertTimeToCronPart(params),
    "*",
    "*",
    convertWeekdaysToCronPart(params),
  ].join(" ");

// Convert cron expression to weekly schedule form
// Will capture exception and return null if unable to convert
export const convertCronToWeeklySchedule = (
  cronExpression: string,
): WeeklyScheduleInput | null => {
  try {
    // If expression starts with two numbers such as '12 11 * * *'
    const timeSelectType = /^(?:\d{1,2} ){2}/.test(cronExpression)
      ? TimeSelectType.SPECIFIC_TIME
      : TimeSelectType.REPEATING;

    const timePartParams =
      timeSelectType === TimeSelectType.REPEATING
        ? convertCronToRepeatingTime(cronExpression)
        : convertCronToSpecificTime(cronExpression);

    const weekdayPart = cronExpression.split(" ")[4];
    const weekdayPartParams = convertCronToWeekdays(weekdayPart);

    return {
      ...timePartParams,
      ...weekdayPartParams,
    };
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};
