import { ComponentProps } from "react";
import { styled } from "../../../../stitches.config";

const PlainLink = styled("a", {
  color: "inherit",
  width: "fit-content",
  whiteSpace: "nowrap",

  "&:hover": {
    textDecoration: "underline",
  },

  variants: {
    color: {
      blue: {
        color: "#3A21D1",
      },
    },
  },
});

export const LoginA = ({
  children,
  ...rest
}: ComponentProps<typeof PlainLink>) => (
  <PlainLink {...rest} rel="noopener">
    {children}
  </PlainLink>
);
