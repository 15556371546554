import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const reconstructUrl = (query: URLSearchParams): string => {
  const { protocol, host, pathname } = window.location;

  const newPathname = pathname.startsWith("/") ? pathname : `/${pathname}`;

  return `${protocol}://${host}${newPathname}?${query.toString()}`;
};

export function useQuery() {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const removeQueryParam = (key: string) => {
    queryParams.delete(key);

    return reconstructUrl(queryParams);
  };

  const setQueryParam = (key: string, value: string) => {
    queryParams.set(key, value);

    return reconstructUrl(queryParams);
  };

  return { queryParams, setQueryParam, removeQueryParam, search };
}
