import { Icon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";

interface Props {
  message?: string;
}

/**
 * View that uses 100% of the width and height of the parent element
 * and centers a message to the user
 */
export const ErrorView = ({
  message = "We could not find the page you are looking for",
}: Props) => (
  <Div
    css={{
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Div
      css={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        gap: "$6",
      }}
    >
      <Icon css={{ width: "12rem" }} src="/sad-face.svg" alt="Sad face" />
      {message}
    </Div>
  </Div>
);
