import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import * as Sentry from "@sentry/react";
import { FormEventHandler, useMemo, useState } from "react";
import { useDataframes } from "../../../hooks/useDataframes";
import { chatgptApi, ChatGptMessage } from "../../../services/chatgpt-api";
import { createCodeBlockSelector } from "../../../store/slices/code-slice";
import { useComponentState } from "../../../store/slices/component-slice";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import { Form } from "../../../ui/Form";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Input } from "../../../ui/Input";
import { Flex } from "../../../ui/Layout/Flex";
import { SENT_MESSAGE_TO_DATABUTLER_AI } from "../../../utils/analytics-constants";
import { notEmpty } from "../../../utils/ts-utils";
import { useWorkspaceContext } from "../../BuildComponentWrapper/BuildComponentWrapper";
import { useProjectPageContext } from "../../ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../../UserGuard/UserGuard";

interface Props {
  componentId: string;
}

const ESTIMATED_CHARS_PER_TOKEN = 4;

/**
 * Just a really simple implementation for now to avoid using too many tokens.
 * Will basically count length of context window and divide by 4 following this rule of thumb
 *
 * 1 token ~= 4 chars in English
 *
 * https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them
 */
const estimateTokensForMessage = (
  message: ChatGptMessage,
): { message: ChatGptMessage; tokens: number } => {
  return {
    message,
    tokens: message.content.length / ESTIMATED_CHARS_PER_TOKEN,
  };
};

// Max number of tokens in a chat is 4090. We need to leave some room for the response,
// because if ChatGPT hits the limit, it will cut off the response
const MAX_TOKENS_IN_REQUEST = 2500;

/**
 * Try to fit in as much context as possible, while also leaving room for the newest message and the opening message.
 *
 * Will build it up in reverse order to make sure that we get the following:
 *
 * 1. The newest message
 * 2. As much context as we can fit
 * 3. The opening message
 * (and then reverse it before returning)
 */
const computeContextWindow = (params: {
  openingMessage: ChatGptMessage;
  history: ChatGptMessage[];
  newMessage: ChatGptMessage;
}): ChatGptMessage[] => {
  const openingMessageWithTokens = estimateTokensForMessage(
    params.openingMessage,
  );
  const historyWithTokens = params.history.map(estimateTokensForMessage);
  const newMessageWithTokens = estimateTokensForMessage(params.newMessage);

  const contextWindow = [
    newMessageWithTokens.message,
  ] satisfies ChatGptMessage[];
  let tokensUsed = newMessageWithTokens.tokens;

  historyWithTokens.reverse().forEach((it) => {
    if (tokensUsed < MAX_TOKENS_IN_REQUEST - openingMessageWithTokens.tokens) {
      contextWindow.push(it.message);
      tokensUsed += it.tokens;
    }
  });

  contextWindow.push(openingMessageWithTokens.message);
  tokensUsed += openingMessageWithTokens.tokens;

  console.log({
    numberOfMessages: contextWindow.length,
    tokensUsed,
    contextWindow,
  });

  return contextWindow.reverse();
};

export const MessageForm = ({ componentId }: Props) => {
  const { user } = useUserGuardContext();
  const { project } = useProjectPageContext();
  const { dataframes } = useDataframes({ projectId: project.id });
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const [isPosting, setIsPosting] = useState(false);
  const [message, setMessage] = useState("");
  const componentState = useComponentState(componentId);
  const chatMessageAdded = useStore((state) => state.chatMessageAdded);
  const chatMessageChunkAdded = useStore(
    (state) => state.chatMessageChunkAdded,
  );
  const { codeBlockId } = useWorkspaceContext();
  const codeBlockState = useStore(createCodeBlockSelector(codeBlockId));

  const openingMessage = useMemo((): ChatGptMessage => {
    return {
      role: "user",
      content: [
        "I want answers with code that I can paste into a code editor and only short explanations.",
        "I am writing code in Databutton, an online workspace where you can create full stack data apps using Python. Apps are written using streamlit, whereas jobs and libraries/modules are written in pure Python.",
        "The databutton library should be imported as `import databutton as db` and has storage functionality to put and get dataframes, json and binary files. Never check if a key exists, instead pass an argument called default to provide a default value. Example usage is db.storage.dataframes.get('my-dataframe', default=None).",
        "Apps generally does not store data, only when requested. instead it uses @st.cache_data (which is the new version of @st.cache) around the function that fetches the data. Jobs very often store data in databutton.",

        dataframes.length === 0
          ? "There are no dataframes in the databutton storage"
          : `Here are the keys of all available dataframes in the databutton storage: [${dataframes
              .map((df) => df.id)
              .join(", ")}].`,
        "If the data requested does not exist in databutton, write code to fetch it from the internet. Use @st.cache_data around the function to cache the data. Select a reasonable ttl.",
        codeBlockState
          ? `This is my current Python code ${codeBlockState.code}.\n`
          : null,
        ["app", "page"].includes(componentState?.type ?? "")
          ? "I am currently writing a streamlit app."
          : null,
        componentState?.type === "job" ? "I am currently writing a job." : null,
        componentState?.type === "module"
          ? "I am currently writing a library."
          : null,
      ]
        .filter(notEmpty)
        .join("\n"),
      hidden: true,
    };
  }, [componentId, codeBlockState, dataframes, componentState]);

  const sendMessage: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!isPosting) {
      setIsPosting(true);

      pulseTriggered({
        eventName: SENT_MESSAGE_TO_DATABUTLER_AI,
      });

      const userMessage = {
        role: "user",
        content: message,
        hidden: false,
      } satisfies ChatGptMessage;

      chatMessageAdded({
        componentId,
        message: userMessage,
      });

      // Try to keep only a few messages to avoid using too many tokens
      const historyToInclude = componentState
        ? computeContextWindow({
            openingMessage,
            history: componentState.chatHistory,
            newMessage: userMessage,
          })
        : [];

      try {
        const iterator = chatgptApi.post({
          messages: historyToInclude,
          user,
          projectId: project.id,
        });

        while (true) {
          const chunk = await iterator.next();

          if (chunk.value) {
            chatMessageChunkAdded({
              componentId,
              content: chunk.value.content,
              messageId: chunk.value.id,
            });
          }

          if (chunk.done) {
            break;
          }
        }
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setMessage("");
        setIsPosting(false);
      }
    }
  };

  return (
    <Form onSubmit={sendMessage}>
      <Flex gap="1" css={{ width: "100%" }}>
        <Input
          css={{
            flexGrow: 1,
            border: "none",
            backgroundColor: "#fdfdfd",
            "&:focus,&:focus-visible": {
              border: "none",
              outline: "none",
            },
          }}
          placeholder="Write a prompt, e.g. improve my code"
          disabled={isPosting}
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
          autoFocus
        />
        <Flex
          css={{
            width: "fit-content",
          }}
        >
          <Button type="submit" disabled={isPosting || !message}>
            {isPosting ? (
              <>
                <StyledFontAwesomeIcon icon={faSpinnerThird} spin={true} />
                Generating response
              </>
            ) : (
              "Send"
            )}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
