import { collection, doc, limit, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import { MultipageAppDeployment } from "../types/persisted";
import {
  getMultipageAppKey,
  multipageAppConverter,
} from "../utils/collections/multipage-apps";
import { CollectionName, createConverter } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

const multipageAppDeploymentConverter =
  createConverter<MultipageAppDeployment>();

export const useMultipageAppSubscription = (params: { projectId: string }) => {
  const multipageAppReceived = useStore((state) => state.multipageAppReceived);

  const [multipageApp, _loading, error] = useDocumentData(
    doc(
      firestore,
      getMultipageAppKey({ projectId: params.projectId }),
    ).withConverter(multipageAppConverter),
  );

  useEffect(() => {
    multipageAppReceived({ multipageApp });
  }, [multipageApp]);

  if (error) {
    logService.warn(`Failed fetching multipage app: ${error.message}`);
  }
};

export const useLatestMultipageAppDeployment = (params: {
  projectId: string;
}) => {
  const [deployments, _loading, error] = useCollectionData(
    query(
      collection(
        firestore,
        getMultipageAppKey({ projectId: params.projectId }),
        CollectionName.DEPLOYMENTS,
      ).withConverter(multipageAppDeploymentConverter),
      orderBy("createdBy.timestamp", "desc"),
      limit(1),
    ),
  );

  if (error) {
    logService.warn(
      `Failed fetching latest multipage app deployment: ${error.message}`,
    );
  }

  return {
    latestMultipageAppDeployment:
      deployments?.length === 1 ? deployments[0] : null,
  };
};
