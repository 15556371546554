import cronstrue from "cronstrue";
import { collection, limit, orderBy, query } from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import {
  getScheduleCollectionKey,
  scheduleConverter,
} from "../utils/collections/schedules";
import { firestore } from "../utils/firebase";

export const useSchedule = (projectId: string, jobId?: string) => {
  const [values, loading, error] = useCollectionData(
    jobId
      ? query(
          collection(
            firestore,
            getScheduleCollectionKey({
              projectId,
              jobId,
            }),
          ),
          orderBy("createdAt", "desc"),
          limit(1),
        ).withConverter(scheduleConverter)
      : null,
  );

  if (error) {
    logService.warn(error.message);
  }

  const schedule = useMemo(
    () => (values && values.length > 0 ? values[0] : null),
    [values],
  );

  const verboseSchedule = useMemo(
    () =>
      schedule?.cronExpressionUtc
        ? cronstrue.toString(schedule.cronExpressionUtc)
        : "",
    [schedule?.cronExpressionUtc],
  );

  return { schedule, loading, verboseSchedule };
};
