import { ReactNode, useMemo } from "react";
import { styled } from "../../../stitches.config";
import { createInControlSelector } from "../../store/slices/code-slice";
import { useStore } from "../../store/store";
import {
  determineOrientation,
  SplitPaneLayout,
  SplitPaneOrientation,
} from "../../ui/SplitPane/utils";
import { MemoizedAssistant } from "../Assistant/Assistant";
import { NotInControlWarning } from "../BuildLayout/NotInControlWarning";

interface Props {
  codeBlockId: string;
  layout?: SplitPaneLayout;
  children: ReactNode;
}

const StatusBar = styled("div", {
  height: "$$statusBarHeight",
  borderTop: "1px solid $mainBorder",
  display: "flex",
  alignItems: "center",
});

const Container = styled("div", {
  $$statusBarHeight: "30px",

  position: "relative",
  height: "100%",
  display: "grid",
  gridTemplateRows: "auto $$statusBarHeight",
});

export const EditorWrapper = ({ codeBlockId, layout, children }: Props) => {
  const inControl = useStore(createInControlSelector(codeBlockId));
  const isProjectNavigatorClosed = useStore(
    (state) => state.appState.isProjectNavigatorClosed,
  );

  const statusBarCss = useMemo(
    () => ({
      // Make room for sidebar expander button
      paddingLeft:
        isProjectNavigatorClosed &&
        layout &&
        determineOrientation(layout) === SplitPaneOrientation.VERTICAL
          ? "48px"
          : 0,
    }),
    [isProjectNavigatorClosed, layout],
  );

  return (
    <Container>
      {children}

      {!inControl && <NotInControlWarning />}

      <StatusBar css={statusBarCss}>
        <MemoizedAssistant />
      </StatusBar>
    </Container>
  );
};
