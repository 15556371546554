import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useImmer } from "use-immer";
import { Button } from "../../ui/Button";
import { Checkbox } from "../../ui/Checkbox/Checkbox";
import { Form } from "../../ui/Form";
import { Input } from "../../ui/Input";
import { Flex } from "../../ui/Layout/Flex";
import { Select } from "../../ui/Select/Select";
import { Txt } from "../../ui/TypographyV3/TypographyV3";
import { ListUpcomingRuns } from "./ListUpcomingRuns";
import {
  RepeatingFrequency,
  RepeatingWeeklySchedule,
  ScheduleFormType,
  SpecificTimeWeeklySchedule,
  TimeSelectType,
  Weekday,
  WeeklyScheduleInput,
} from "./types";
import { validateHour, validateTimestring } from "./utils";
import {
  convertCronToWeeklySchedule,
  convertWeeklyScheduleToCron,
} from "./weekly-utils";

interface Props {
  hasExistingSchedule: boolean;
  onSubmit: (schedule: string, scheduleFormType: ScheduleFormType) => void;
  disabled: boolean;
  initialSchedule?: string;
}

interface FormErrors {
  [fieldName: string]: string;
}

interface FormProps {
  form: WeeklyScheduleInput;
  errors: FormErrors;
}

const isValidForm = ({ form, errors }: FormProps): boolean =>
  form.weekdays.length > 0 && Object.keys(errors).length === 0;

const DEFAULT_SCHEDULE: WeeklyScheduleInput = {
  weekdays: [
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
  ],
  timeSelectType: TimeSelectType.SPECIFIC_TIME,
  at: "09:00",
};

export const WeeklyScheduleForm = ({
  hasExistingSchedule,
  onSubmit,
  disabled,
  initialSchedule,
}: Props) => {
  const [schedule, setSchedule] = useState<string | null>(
    initialSchedule ?? null,
  );

  const initialWeeklySchedule = initialSchedule
    ? convertCronToWeeklySchedule(initialSchedule)
    : null;

  const [state, setState] = useImmer<FormProps>({
    form: initialWeeklySchedule ?? DEFAULT_SCHEDULE,
    errors: {},
  });

  useEffect(() => {
    setSchedule(
      isValidForm(state) ? convertWeeklyScheduleToCron(state.form) : null,
    );
  }, [state]);

  const handleWeekdayChange = (name: string, checked: boolean) => {
    setState((draft) => {
      const nextWeekdays = checked
        ? [...state.form.weekdays, name as Weekday]
        : state.form.weekdays.filter((it) => it !== name);

      draft.form.weekdays = nextWeekdays;

      if (nextWeekdays.length === 0) {
        draft.errors.weekdays = "Select at least one day";
      } else {
        // rome-ignore lint/performance/noDelete: <explanation>
        delete draft.errors.weekdays;
      }
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (isValidForm(state) && schedule && !disabled) {
      onSubmit(schedule, ScheduleFormType.WEEKLY);
    }
  };

  const handleAtChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, name } = e.target;

    setState((draft) => {
      if (draft.form.timeSelectType === TimeSelectType.SPECIFIC_TIME) {
        draft.form.at = value;

        const errorMessage = validateTimestring(value);
        if (errorMessage) {
          draft.errors[name] = errorMessage;
        } else {
          // rome-ignore lint/performance/noDelete: <explanation>
          delete draft.errors[name];
        }
      }
    });
  };

  const handleRepeatingIntervalChange: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setState((draft) => {
      if (draft.form.timeSelectType === TimeSelectType.REPEATING) {
        const { value, min, max, name } = e.target;
        const typedName = name as "fromHour" | "toHour";

        if (value && /^\d*$/.test(value)) {
          const valueAsNumber = Number.parseInt(value, 10);

          draft.form.repeatingInterval[typedName] = valueAsNumber;

          const errorMessage = validateHour(valueAsNumber, min, max);
          if (errorMessage) {
            draft.errors.repeatingInterval = errorMessage;
          } else {
            // rome-ignore lint/performance/noDelete: <explanation>
            delete draft.errors.repeatingInterval;
          }
        } else {
          draft.form.repeatingInterval[typedName] = value;
          draft.errors.repeatingInterval = "Provide interval in hours";
        }
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Flex gap="3" direction="vertical">
        <Flex direction="vertical" gap="1">
          <Txt weight="semiBold">On</Txt>
          <Flex gap="2">
            {Object.entries(Weekday).map(([, value]) => (
              <Checkbox
                key={value}
                name={value}
                label={value[0].toUpperCase()}
                checked={state.form.weekdays.includes(value)}
                onChange={handleWeekdayChange}
              />
            ))}
          </Flex>
        </Flex>

        <Flex gap="1">
          <Select<TimeSelectType>
            value={state.form.timeSelectType}
            onChange={(it) => {
              setState((draft) => {
                if (it === TimeSelectType.REPEATING) {
                  const newForm: RepeatingWeeklySchedule = {
                    timeSelectType: TimeSelectType.REPEATING,
                    weekdays: state.form.weekdays,
                    repeatingFrequency: RepeatingFrequency.EVERY_HOUR,
                    repeatingInterval: { fromHour: 0, toHour: 23 },
                  };

                  draft.form = newForm;
                } else if (it === TimeSelectType.SPECIFIC_TIME) {
                  const newForm: SpecificTimeWeeklySchedule = {
                    timeSelectType: TimeSelectType.SPECIFIC_TIME,
                    weekdays: state.form.weekdays,
                    at: "09:00",
                  };
                  draft.form = newForm;
                }
              });
            }}
            options={Object.values(TimeSelectType)}
            getKey={(it) => it}
            getDisplayValue={(it) => it}
          />

          {state.form.timeSelectType === TimeSelectType.SPECIFIC_TIME ? (
            <Input
              type="text"
              value={state.form.at}
              name="at"
              state={state.errors.at ? "invalid" : "valid"}
              onChange={handleAtChange}
              css={{ width: "58px" }}
            />
          ) : (
            <>
              <Select<RepeatingFrequency>
                value={state.form.repeatingFrequency}
                onChange={(it) => {
                  setState((draft) => {
                    if (
                      draft.form.timeSelectType === TimeSelectType.REPEATING
                    ) {
                      draft.form.repeatingFrequency = it;
                    }
                  });
                }}
                options={Object.values(RepeatingFrequency)}
                getKey={(it) => it}
                getDisplayValue={(it) => it}
              />
              <Txt size="12">between</Txt>
              <Input
                type="text"
                // https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
                inputMode="numeric"
                pattern="[0-9]*"
                min={0}
                max={state.form.repeatingInterval.toHour}
                value={state.form.repeatingInterval.fromHour}
                name="fromHour"
                state={state.errors.repeatingInterval ? "invalid" : "valid"}
                onChange={handleRepeatingIntervalChange}
                css={{ width: "40px" }}
              />

              <Txt size="12">and</Txt>

              <Input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                min={state.form.repeatingInterval.fromHour}
                max={24}
                value={state.form.repeatingInterval.toHour}
                name="toHour"
                state={state.errors.repeatingInterval ? "invalid" : "valid"}
                onChange={handleRepeatingIntervalChange}
                css={{ width: "40px" }}
              />

              <Txt size="12">hours</Txt>
            </>
          )}
        </Flex>

        {Object.entries(state.errors).map(([key, value]) => (
          <Txt key={key}>{value}</Txt>
        ))}

        <ListUpcomingRuns schedule={schedule} />

        <Flex
          gap="1"
          css={{
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" disabled={disabled || !isValidForm(state)}>
            {hasExistingSchedule ? "Update Schedule" : "Create Schedule"}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
