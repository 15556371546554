import { styled } from "../../../../stitches.config";

interface Props {
  label: string;
  name: string;
  value: string;
  onChange: (name: string) => void;
  checked: (name: string) => boolean;
}

const RadioButton = styled("input", {
  display: "none",
});

const RadioButtonLabel = styled("label", {
  cursor: "pointer",
  fontSize: "14px",

  variants: {
    checked: {
      yes: {
        textDecoration: "underline",
        color: "$purpleDark",
      },
      no: {
        color: "$fontSecondary",
      },
    },
  },
});

export const FilterRadioButton = ({
  label,
  name,
  value,
  onChange,
  checked,
}: Props) => {
  const id = `filter-radio-${value}`;

  return (
    <RadioButtonLabel htmlFor={id} checked={checked(value) ? "yes" : "no"}>
      {label}
      <RadioButton
        type="radio"
        name={name}
        value={value}
        id={id}
        onChange={(e) => onChange(e.target.value)}
      />
    </RadioButtonLabel>
  );
};
