import { styled } from "../../../stitches.config";

/**
 * @deprecated Use Button
 */
export const IconButton = styled("button", {
  cursor: "pointer",
  color: "$primaryDark",
  border: "none",
  display: "flex",
  margin: 0,
  padding: "$1",

  fontSize: "$3",
  fontWeight: "900",
  borderRadius: "4px",

  "&:hover": {
    backgroundColor: "$hoverGray",
  },

  "&:active": {
    backgroundColor: "$gray5",
  },

  "&:disabled": {
    cursor: "not-allowed",
  },

  variants: {
    background: {
      transparent: {
        background: "transparent",
      },
      gray: {
        background: "$gray6BoardBackground",
      },
    },
  },

  defaultVariants: {
    background: "transparent",
  },
});
