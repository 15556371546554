import { ReactNode, useContext } from "react";
import { NotFoundPage } from "../../components/ErrorPage/ErrorPage";
import { UserGuardContext } from "../../components/UserGuard/UserGuard";
import { isAdmin } from "../../utils/permission-utils";

interface Props {
  children: ReactNode;
}

export const AdminGuard = ({ children }: Props) => {
  const { profile } = useContext(UserGuardContext);

  if (!isAdmin({ profile })) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};
