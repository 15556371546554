import { styled } from "../../../../stitches.config";

export const BaseCard = styled("div", {
  display: "flex",
  border: "1px solid $mainBorder",
  borderRadius: "4px",
  boxShadow: "$mediumElevation",
  transition: "box-shadow 300ms ease-out",

  "&:hover": {
    boxShadow: "$largeElevation",
  },
});
