import { faRobotAstromech } from "@fortawesome/pro-solid-svg-icons";
import { User } from "firebase/auth";
import { styled } from "../../../stitches.config";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";

interface Props {
  user: User | null;
  onClick?: () => void;
}

const ImageButton = styled("input", {
  $$size: "24px",

  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",

  "&:active": {
    boxShadow: "none",
    outline: "none",
  },
});

const PlaceholderImage = styled("button", {
  $$size: "24px",

  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",
  border: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "$primaryPurple",
  color: "$white",
  cursor: "pointer",

  "&:active": {
    boxShadow: "none",
    outline: "none",
  },
});

export const ProfilePhoto = ({ user, onClick }: Props) => {
  if (user?.photoURL) {
    return (
      <ImageButton
        type="image"
        src={user.photoURL}
        alt="Profile"
        onClick={onClick}
      />
    );
  }

  return (
    <PlaceholderImage onClick={onClick} type="button">
      {user?.displayName && user.displayName.length > 0 ? (
        <Div
          css={{
            textTransform: "uppercase",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          {user.displayName[0]}
        </Div>
      ) : (
        <StyledFontAwesomeIcon
          icon={faRobotAstromech}
          css={{ fontSize: "16px" }}
        />
      )}
    </PlaceholderImage>
  );
};
