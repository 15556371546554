import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useStore } from "../../../../store/store";
import { Button } from "../../../../ui/Button";
import { BaseCard } from "../../../../ui/Card/BaseCard";
import { StyledFontAwesomeIcon } from "../../../../ui/Icon";
import { Txt } from "../../../../ui/TypographyV2/TypographyV2";
import { SCHEDULE_CARD_HEIGHT, SCHEDULE_CARD_WIDTH } from "../JobCard";

export const NewJobCard = () => {
  const newComponentToggled = useStore((state) => state.newComponentToggled);

  return (
    <Button
      intent="plain"
      type="button"
      padding="none"
      css={{ height: "fit-content" }}
      onClick={() => {
        newComponentToggled({ open: true, tags: ["job"] });
      }}
    >
      <BaseCard
        css={{
          height: SCHEDULE_CARD_HEIGHT,
          width: SCHEDULE_CARD_WIDTH,
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          gap: "$2",
          alignItems: "center",
          backgroundColor: "$purpleBright",
          borderColor: "$purpleBright",
          color: "$purpleDark",
        }}
      >
        <StyledFontAwesomeIcon icon={faPlus} size="2x" />
        <Txt size="14" color="inherit">
          New Job
        </Txt>
      </BaseCard>
    </Button>
  );
};
