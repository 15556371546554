import { styled } from "../../../../stitches.config";
import { Icon } from "../../../ui/Icon";
import { A } from "../../../ui/Typography";

const StyledIcon = styled(Icon, {
  $$size: "207px",
  width: "$$size",

  variants: {
    device: {
      mobile: {
        top: "$2",
        right: "$3",
      },
      rest: {
        top: "$3",
        left: "$3",
      },
    },
  },
});

export const LogoLink = () => (
  <A href="https://databutton.com">
    <StyledIcon
      // width="300"
      src="/logo-new.svg"
      alt="Databutton logo"
      device={{
        "@initial": "mobile",
        "@bp1": "rest",
      }}
    />
  </A>
);
