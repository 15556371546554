import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../utils/firebase";
import { uniqueByKey } from "../utils/ts-utils";

export type ProjectMember =
  | {
      userId?: string;
      displayName: string;
      email: string;
      role: "member" | "owner";
    }
  | {
      email: string;
      invitationId: string;
      role: "pending";
    };

const listProjectMembers = httpsCallable<
  { projectId: string },
  ProjectMember[]
>(functions, "listProjectMembers");

// TODO: Add error handling (see accept invitation)
export const useProjectMembers = ({
  projectId,
  shouldFetch = true,
}: {
  projectId: string;
  shouldFetch: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(fale);
  const [members, setMembers] = useState<ProjectMember[] | null>(null);

  useEffect(() => {
    setLoading(true);
    if (shouldFetch) {
      listProjectMembers({ projectId }).then((response) => {
        setMembers(response.data);
        setLoading(false);
      });
    }
  }, [projectId, shouldFetch]);

  const refetch = () => {
    setLoading(true);
    listProjectMembers({ projectId }).then((response) => {
      setMembers(response.data);
      setLoading(false);
    });
  };

  const addMember = (member: ProjectMember) => {
    if (!members?.some((it) => it.email === member.email)) {
      setMembers((prev) =>
        prev ? uniqueByKey([...prev, member], "email") : [member],
      );
    }
  };

  return {
    loading,
    // error,
    members,
    refetch,
    addMember,
  };
};
