import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import { Icon } from "../../../ui/Icon";
import { Text } from "../../../ui/Text";

export const HotReloadingButton = () => {
  const hotReloadingToggled = useStore((state) => state.hotReloadingToggled);
  const workspace = useStore((state) => state.workspace);
  const identifier = useWorkspaceContext();

  return (
    <Button
      intent="plain"
      onClick={() => {
        hotReloadingToggled(identifier);
      }}
    >
      <Text>Hot reloading</Text>
      <Icon
        src={
          workspace.hotReloading ? "/toggle-active.svg" : "/toggle-inactive.svg"
        }
        alt="Toggle status of hot reloading"
        height="10px"
      />
    </Button>
  );
};
