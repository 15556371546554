import { memo } from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { SparklineChartProps } from "../../../store/slices/project-slice";

interface Props {
  data: SparklineChartProps;
}

export const Sparkline = memo(
  ({ data }: Props) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data.items}>
          <Line
            type="monotone"
            dataKey="count"
            isAnimationActive={false}
            stroke="#9747FF"
            dot={false}
            strokeWidth="2px"
          />
        </LineChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => prev.data.key === next.data.key,
);
