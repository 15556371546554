import {
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { styled } from "../../../stitches.config";
import { JobRun } from "../../types/persisted";
import { Div } from "../../ui/Layout";
import { Text } from "../../ui/Text";
import { convertMillisToReadable } from "../../utils/time-utils";
import { ZonedTimestamp } from "../ZonedTimestamp/ZonedTimestamp";
import { DevRunLog } from "./DevRunLog";
import { ExpandRowCell } from "./ExpandRowCell";

interface Props {
  projectId: string;
  jobId: string;
  runs: JobRun[];
}

const columnHelper = createColumnHelper<JobRun>();

const Table = styled("table", { width: "100%", borderCollapse: "collapse" });

const Tbody = styled("tbody", { width: "100%" });

const Th = styled("th", {
  textTransform: "uppercase",
  color: "$gray4",
  fontWeight: 600,
  fontSize: "10px",
  padding: "$1",
  variants: {
    align: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
    },
  },
  defaultVariants: {
    align: "left",
  },
});

const Thead = styled("thead", {
  position: "sticky",
  top: 0,
  margin: 0,
  backgroundColor: "$gray7CardBackground",
  borderRadius: "4px",
});

const Td = styled("td", {
  padding: "$1",
});

const Tr = styled("tr", {
  position: "relative",
  borderBottom: "1px solid $gray6BoardBackground",
  variants: {
    expanded: {
      yes: {
        backgroundColor: "$activeBlue",
      },
      no: {},
    },
  },
});

const TableText = styled("span", {
  fontSize: "$1",
  color: "$gray4",
});

const columns = [
  columnHelper.accessor("startTime", {
    cell: (info) => (
      <Div
        css={{
          display: "flex",
          gap: "$4",
          alignItems: "center",
          width: "fit-content",
        }}
      >
        <TableText>
          <ZonedTimestamp timestamp={info.getValue()} format="YYYY-MM-DD" />
        </TableText>
        <Text margin="none" weight="big">
          <ZonedTimestamp timestamp={info.getValue()} format="HH:mm:ss" />
        </Text>
      </Div>
    ),
    header: "Start time",
  }),
  columnHelper.accessor("duration", {
    header: "Duration",
    cell: (info) => {
      const duration = info.getValue();
      return duration ? (
        <TableText>{convertMillisToReadable(duration)}</TableText>
      ) : (
        ""
      );
    },
  }),
  columnHelper.accessor((row) => row.jobId, {
    header: "",
    id: "expand-job",
    cell: (info) => <ExpandRowCell info={info} />,
  }),
];

export const DevRunsTable = ({ runs, projectId, jobId }: Props) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable<JobRun>({
    autoResetPageIndex: false,
    data: runs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Th>
            ))}
          </tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <Tr expanded={row.getIsExpanded() ? "yes" : "no"}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>

            {row.getIsExpanded() && (
              <Tr>
                <Td colSpan={columns.length}>
                  <DevRunLog
                    projectId={projectId}
                    jobId={jobId}
                    run={row.original}
                  />
                </Td>
              </Tr>
            )}
          </Fragment>
        ))}
      </Tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </Table>
  );
};
