import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { styled } from "../../../stitches.config";
import { ProjectSummary } from "../../store/slices/home-slice";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Row } from "../../ui/Layout";
import { Flex } from "../../ui/Layout/Flex";
import { Tooltip } from "../../ui/Tooltip";
import { H4 } from "../../ui/TypographyV2/TypographyV2";
import { routeUtils } from "../../utils/route-utils";
import { ProjectCardMeta } from "./components/ProjectCardMeta";
import { ProjectCardPopover } from "./components/ProjectCardPopover";
import { extractInitials } from "./utils";

const ProjectLink = styled(Link, {
  $$borderRadius: "4px",

  cursor: "pointer",
  width: "100%",
  minWidth: "320px",
  borderRadius: "$$borderRadius",
  backgroundColor: "$white",
  boxShadow: "$mediumElevation",
  transition: "box-shadow 300ms ease-out",

  "&:hover": {
    boxShadow: "$largeElevation",
  },

  "&:active": {
    outline: "4px solid $focusOutline",
    backgroundColor: "$gray7CardBackground",
  },
});

const Cover = styled("div", {
  $$borderRadius: "4px",

  height: "100px",
  borderRadius: "$$borderRadius $$borderRadius 0 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  variants: {
    color: {
      green: {
        backgroundColor: "green",
      },
    },
  },

  defaultVariants: {
    color: "green",
  },
});

const Content = styled("div", {
  $$borderRadius: "4px",

  padding: "$2 $1 $1",
  borderRadius: "0 0 $$borderRadius $$borderRadius",
  backgroundColor: "inherit",
  display: "flex",
  flexFlow: "column",
});

const Initials = styled("div", {
  $$size: "54px",

  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontWeight: "$semiBold",
  fontSize: "$4",
  letterSpacing: "1px",
  color: "#fff",
  textTransform: "uppercase",

  backgroundColor: "rgba(255, 255, 255, 0.2)",
});

interface Props {
  projectSummary: ProjectSummary;
}

export const ProjectCard = memo(
  ({ projectSummary }: Props) => {
    const projectInitials = useMemo(
      () => extractInitials(projectSummary.project.name.trim()),
      [projectSummary],
    );

    const projectUrl = useMemo(() => {
      return routeUtils.path({
        to: "project",
        params: {
          projectId: projectSummary.project.id,
        },
      });
    }, [projectSummary.project.id]);

    return (
      <ProjectLink to={projectUrl}>
        <Cover
          css={{
            backgroundColor: projectSummary.project.projectCardColor,
          }}
        >
          <Initials>{projectInitials}</Initials>
        </Cover>

        <Content>
          <H4 css={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {projectSummary.project.name}
          </H4>

          <Row css={{ justifyContent: "space-between", alignItems: "center" }}>
            <ProjectCardMeta projectId={projectSummary.id} />

            <Flex gap="1">
              {projectSummary.project.members.length > 0 && (
                <Tooltip
                  text={`${
                    projectSummary.project.members.length + 1
                  } collaborators`}
                >
                  <StyledFontAwesomeIcon
                    icon={faUsers}
                    css={{ color: "$primaryDark" }}
                  />
                </Tooltip>
              )}

              <ProjectCardPopover project={projectSummary.project} />
            </Flex>
          </Row>
        </Content>
      </ProjectLink>
    );
  },
  (prev, next) => prev.projectSummary.id === next.projectSummary.id,
);
