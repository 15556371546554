import { useState } from "react";
import { styled } from "../../../stitches.config";
import { LoadingScreen } from "../LoadingScreen";

interface Props {
  src: string;
  instanceTag?: string;
  title: string;
  loadingMessage: string;
}

const Iframe = styled("iframe", {
  height: "calc(100% - 4px)",
  border: 0,
  width: "100%",
});

const Container = styled("div", {
  $$iframeHeight: "100%",

  position: "relative",
  height: "$$iframeHeight",
  border: 0,
  width: "100%",
});

const LoadingOverlay = styled("div", {
  backgroundColor: "#212121",
  position: "absolute",
  inset: 0,
});

export const IFrameWrapper = ({
  src,
  title,
  loadingMessage,
  instanceTag,
}: Props) => {
  const [state, setState] = useState<null | "LOADING" | "READY">();

  return (
    <Container>
      <Iframe
        key={instanceTag}
        src={src}
        allow="camera; microphone;"
        title={title}
        onLoad={() => {
          setState("READY");
        }}
      />

      {state === "LOADING" && (
        <LoadingOverlay>
          <LoadingScreen message={loadingMessage} fontColor="white" />
        </LoadingOverlay>
      )}
    </Container>
  );
};
