import { useContext } from "react";
import { styled } from "../../../../stitches.config";
import { UserGuardContext } from "../../../components/UserGuard/UserGuard";
import { useScreenshot } from "../../../hooks/useAppScreenshot";
import { useAppVisits } from "../../../hooks/useAppVisits";
import { useIsPublishedApp } from "../../../hooks/useIsPublishedApp";
import { App } from "../../../types/persisted";
import { BaseCard } from "../../../ui/Card/BaseCard";
import { Div } from "../../../ui/Layout";
import { Flex } from "../../../ui/Layout/Flex";
import { NavigateLink } from "../../../ui/NavigateLink";
import { Text } from "../../../ui/Text";
import { convertToImageSrc } from "../../../utils/collections/screenshots";
import {
  CollectionName,
  createCollectionRefPath,
} from "../../../utils/collections/shared";
import { routeUtils } from "../../../utils/route-utils";
import { ShareViewModalButton } from "../../BuildAppPage/ShareViewModalButton";
import { ComponentName } from "./ComponentName";

interface Props {
  projectId: string;
  app: App;
}

const VIEW_CARD_HEIGHT = "180px";
const VIEW_CARD_WIDTH = "220px";

const Card = styled(BaseCard, {
  height: VIEW_CARD_HEIGHT,
  width: VIEW_CARD_WIDTH,
  color: "$primaryDark",
});

const Image = styled("img", {
  width: "100%",
  overflow: "hidden",
});

export const ViewCard = ({ projectId, app }: Props) => {
  const { stats } = useAppVisits({
    projectId,
    appId: app.id,
  });
  const { isPublished } = useIsPublishedApp({ projectId, appId: app.id });
  const { screenshot: appScreenshot } = useScreenshot({
    screenshotCollectionRef: createCollectionRefPath([
      app.refPath,
      CollectionName.SCREENSHOTS,
    ]),
    isPublished,
  });
  const { user } = useContext(UserGuardContext);

  return (
    <NavigateLink
      to={routeUtils.path({
        to: "app",
        params: {
          projectId,
          appSlug: app.slug,
          mode: isPublished ? "monitor" : "build",
        },
      })}
    >
      <Card>
        <Flex direction="vertical" css={{ width: "100%" }}>
          <Div
            css={{
              height: "100px",
              width: "100%",
              overflow: "hidden",
              borderBottom: "1px solid $mainBorder",
            }}
          >
            {appScreenshot && (
              <Image
                alt="d"
                src={convertToImageSrc(appScreenshot.base64Image)}
              />
            )}
          </Div>

          <Flex direction="vertical" css={{ padding: "0 $1 $1" }}>
            <ComponentName>{app.name}</ComponentName>
            <Flex css={{ justifyContent: "space-between" }}>
              {isPublished ? (
                <Text
                  weight="thin"
                  css={{
                    visibility:
                      stats && stats.numberOfVisits > 0 ? "visible" : "hidden",
                  }}
                >
                  {stats?.numberOfVisits ?? 0} visits
                </Text>
              ) : (
                <Text weight="thin">Not published</Text>
              )}

              <ShareViewModalButton
                projectId={projectId}
                app={app}
                user={user}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </NavigateLink>
  );
};
