import { DeleteDataframe } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import {
  doc,
  DocumentReference,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { SetNonNullable } from "type-fest";
import urlSlug from "url-slug";
import { logService } from "../../services/log-service";
import { Dataframe } from "../../types/persisted";
import { firestore } from "../firebase";
import { createPerformedByObj } from "../user-utils";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

export const dataframeConverter = createConverter<Dataframe>({
  generateSlugFn: (id) => urlSlug(id),
});

export const getDataframesCollectionRefPath = (params: {
  projectId: string;
}): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.DATAFRAMES,
  ]);

const createDataframeDocRef = (params: {
  refPath: string;
}): DocumentReference<Dataframe> =>
  doc(firestore, params.refPath).withConverter(dataframeConverter);

const updateDataframe = async (params: {
  refPath: string;
  payload: Partial<Dataframe>;
}) => {
  logService.debug(`Updating dataframe: ${params.refPath}`);
  await updateDoc(
    createDataframeDocRef({ refPath: params.refPath }),
    params.payload,
  );
};

/**
 * Mark a dataframe for deletion
 */
export const deleteDataframe = async (params: {
  dataframe: Dataframe;
  user: User;
}) => {
  const payload: DeleteDataframe = {
    markedForDeletionAt: Timestamp.now(),
    markedForDeletionBy: createPerformedByObj({ user: params.user }),
  };

  await updateDataframe({
    refPath: params.dataframe.refPath,
    payload,
  });
};

/**
 * Rename a dataframe and generate new slug
 */
export const renameDataframe = async (params: {
  dataframe: Dataframe;
  user: User;
  name: {
    from: string;
    to: string;
  };
}): Promise<{ newSlug: string }> => {
  const newSlug = urlSlug(params.name.to);

  const payload: SetNonNullable<Required<Pick<Dataframe, "name" | "slug">>> = {
    name: params.name.to,
    slug: newSlug,
  };

  await updateDataframe({
    refPath: params.dataframe.refPath,
    payload,
  });

  return { newSlug };
};
