import { styled } from "../../../stitches.config";

const ButtonBase = styled("button", {
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "$1",
  fontSize: "$1",
  fontFamily: "$sans",
  fontWeight: "600",
  height: "30px",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
});

export const Button = styled(ButtonBase, {
  $$borderWidth: "1px",

  width: "fit-content",
  padding: "$1 $2",
  borderRadius: "4px",

  "&:disabled": {
    cursor: "not-allowed",
    color: "$gray5",
    backgroundColor: "$gray6",
    border: "$$borderWidth solid $gray6",

    "&:hover": {
      cursor: "not-allowed",
      color: "$gray5",
      backgroundColor: "$gray6",
      borderColor: "$gray6",
    },
  },

  variants: {
    content: {
      icon: {
        padding: "$1 12px",
      },
    },
    padding: {
      none: {
        padding: 0,
      },
    },
    intent: {
      primary: {
        backgroundColor: "$darkPurpleSolid",
        color: "$white",
        border: "$$borderWidth solid $darkPurpleSolid",

        "&:hover": {
          backgroundColor: "$primaryPurple",
          border: "$$borderWidth solid $primaryPurple",
        },
      },
      secondary: {
        backgroundColor: "$backgroundMain",
        color: "$gray2",
        border: "$$borderWidth solid $mainBorder",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
      "secondary-alternate": {
        backgroundColor: "$primaryDark",
        color: "$white",
        border: "$$borderWidth solid $white",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
      danger: {
        background: "$redSolid",
        border: "$$borderWidth solid transparent",
        color: "$white",

        "&:hover": {
          background: "$redHover",
        },
      },
      pink: {
        backgroundColor: "#f8f3ff",
        color: "#9747ff",
        border: "$$borderWidth solid #f2ebfc",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
      success: {
        backgroundColor: "#D4FFD9",
        color: "#197D23",
        border: "$$borderWidth solid #D4FFD9",

        "&:hover": {
          color: "$white",
          backgroundColor: "#197D23",
          border: "$$borderWidth solid #197D23",
        },
      },
      plain: {
        border: "none",
        background: "none",
      },
    },
    size: {
      normal: {
        height: "30px",
        width: "fit-content",
        justifyContent: "center",
        gap: "$1",
      },
      large: {
        fontSize: "14px",
        fontWeight: 500,
        height: "55px",
        width: "250px",
        justifyContent: "flex-start",
        gap: "$2",
      },
    },
  },

  defaultVariants: {
    intent: "primary",
    size: "normal",
  },
});

export const TopBarButton = styled(ButtonBase, {
  $$borderWidth: "1px",

  borderRadius: "8px",
  padding: "$1 $2",

  variants: {
    content: {
      icon: {
        padding: "$1 12px",
      },
    },
    intent: {
      primary: {
        backgroundColor: "$darkPurpleSolid",
        color: "$white",
        border: "$$borderWidth solid $darkPurpleSolid",

        "&:hover": {
          backgroundColor: "$primaryPurple",
          border: "$$borderWidth solid $primaryPurple",
        },
      },
      secondary: {
        backgroundColor: "$backgroundMain",
        color: "$gray2",
        border: "$$borderWidth solid $mainBorder",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
    },
  },
});
