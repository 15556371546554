import dayjs from "dayjs";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  SparklineChartProps,
  SparklineData,
} from "../../store/slices/project-slice";
import { CollectionName } from "../../utils/collections/shared";
import { appVisitConverter } from "../../utils/collections/visits";
import { MULTIPAGE_APP_ID } from "../../utils/constants";
import { firestore } from "../../utils/firebase";
import { distinct, notEmpty } from "../../utils/ts-utils";

const toDateString = (date: Date): string => date.toISOString().split("T")[0];

const getInitialSparklineData = (
  referenceDateForNewVisits: Date,
): SparklineChartProps["items"] => {
  const toDate = new Date();
  const result: SparklineChartProps["items"] = [];

  let timestamp = referenceDateForNewVisits;
  while (timestamp < toDate) {
    result.push({
      timestamp: toDateString(timestamp),
      count: 0,
    });
    timestamp = dayjs(timestamp).add(1, "day").toDate();
  }

  return result;
};

export const getVisitorSparklineData = async (params: {
  projectId: string;
}): Promise<SparklineData | null> => {
  const referenceDateForNewVisits = dayjs().subtract(7, "days").toDate();
  const referenceTimeInMsForActiveUsers = dayjs()
    .subtract(10, "minutes")
    .valueOf();

  const visitorDocs = await getDocs(
    query(
      collection(
        firestore,
        CollectionName.PROJECTS,
        params.projectId,
        CollectionName.MULTIPAGE_APPS,
        MULTIPAGE_APP_ID,
        CollectionName.VISITS,
      ),
      where("visitedBy.timestamp", ">=", referenceDateForNewVisits),
    ).withConverter(appVisitConverter),
  );

  const newVisits = visitorDocs.size;
  const visitors = visitorDocs.docs.map((it) => it.data());

  const activeVisitors = visitors
    .filter(
      (it) =>
        it.visitedBy &&
        it.visitedBy.timestamp.toMillis() > referenceTimeInMsForActiveUsers,
    )
    .map((it) => it.visitedBy?.id)
    .filter(notEmpty)
    .filter(distinct).length;

  const sparklineChartItems = visitors
    .reduce((prev: { timestamp: string; count: number }[], it) => {
      if (!it.visitedBy) {
        return prev;
      }

      // Timestamp to use on the X-axis. Adjust this if you want to adjust the granularity of the sparkline chart.
      const timestamp = it.visitedBy.timestamp
        .toDate()
        .toISOString()
        .split("T")[0];
      const existingEntry = prev.find((it) => it.timestamp === timestamp);

      if (existingEntry) {
        return [
          ...prev.filter((it) => it.timestamp !== timestamp),
          {
            timestamp: timestamp,
            count: existingEntry.count + 1,
          },
        ];
      } else {
        return [
          ...prev,
          {
            timestamp: timestamp,
            count: 1,
          },
        ];
      }
    }, getInitialSparklineData(referenceDateForNewVisits))
    .sort((a, b) => a.timestamp.localeCompare(b.timestamp));

  const result = {
    newVisits,
    activeVisitors,
    sparklineChart: {
      items: sparklineChartItems,
      key: sparklineChartItems.map((it) => it.count).join(""),
    },
    calculatedAt: new Date().toISOString(),
  } satisfies SparklineData;

  return result;
};
