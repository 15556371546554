import ky from "ky";
import { useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { logService } from "../../services/log-service";
import { Assets } from "../../store/slices/app-slice";
import { useStore } from "../../store/store";
import { isLocal } from "../../utils/feature-flag-utils";
import { notEmpty } from "../../utils/ts-utils";

const REGEX = /(src|href)="(?<asset>\/assets\/[a-z]*\.[\da-z]*\.(js|css))/gm;

const extractAssets = (content: string): Assets | null => {
  const match = [...content.matchAll(REGEX)]
    .map((m) => (m.groups && "asset" in m.groups ? m.groups.asset : null))
    .filter(notEmpty);

  const indexJs = match.find(
    (it) => it.startsWith("/assets/index.") && it.endsWith(".js"),
  );

  if (indexJs) {
    return {
      fetchedAt: new Date(),
      indexJs,
      paths: match,
    };
  }

  return null;
};

const fetchAssets = async (): Promise<Assets | null> => {
  const response = await ky.get(
    isLocal() ? "http://localhost:3000" : window.location.origin,
    {
      cache: "no-cache",
    },
  );
  const html = await response.text();

  if (html) {
    return extractAssets(html);
  } else {
    return null;
  }
};

export const VersionPoller = () => {
  const assetsLoaded = useStore((state) => state.assetsLoaded);
  const assets = useStore((state) => state.assets);

  const prevAssets = usePrevious<Assets | null>(assets);

  useEffect(() => {
    const isNewVersion =
      prevAssets && assets && prevAssets.indexJs !== assets.indexJs;

    if (isNewVersion) {
      logService.debug("New version out. Fetching assets and caching them");
      assets.paths.forEach((it) => {
        fetch(it).then(() => {
          logService.debug(`Fetched asset: ${it}`);
        });
      });
    }
  }, [prevAssets, assets]);

  useEffect(() => {
    fetchAssets().then(assetsLoaded);

    const interval = setInterval(() => {
      fetchAssets().then(assetsLoaded);
    }, 15_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
};
