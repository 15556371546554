import { useParams } from "react-router-dom";
import { Heading } from "../../ui/Heading";
import { Text } from "../../ui/Text";

export const ProjectNotFound = () => {
  const params = useParams<{ projectId: string }>();
  return (
    <>
      <Heading size="large">
        You don&apos;t have access to this data app
      </Heading>
      <Text>
        The data app {params.projectId} is either not public, or you have the
        wrong link.
      </Text>
    </>
  );
};
