import { styled } from "../../../../stitches.config";

const Wrapper = styled("div", {
  position: "relative",
});

export const Avatar = styled("img", {
  $$size: "20px",

  position: "absolute",
  top: "calc(-1 * calc($$size / 2))",
  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",

  variants: {
    position: {
      inline: {
        position: "relative",
        top: "unset",
      },
    },
  },
});

interface Props {
  avatars: { type: "letter" | "image"; value: string }[];
}

const LetterAvatar = styled("div", {
  $$size: "20px",

  fontSize: "12px",
  backgroundColor: "$primaryPurple",
  display: "flex",
  alignItems: "center",
  fontWeight: "400",
  justifyContent: "center",
  color: "$white",
  position: "absolute",
  top: "calc(-1 * calc($$size / 2))",
  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",
});

export const Avatars = ({ avatars }: Props) => (
  <Wrapper>
    {avatars.map((it, index) =>
      it.type === "image" ? (
        <Avatar
          key={it.value}
          src={it.value}
          css={{ right: `${index * 10}px` }}
        />
      ) : (
        <LetterAvatar
          key={`${it.value}-${index}`}
          css={{
            right: `${index * 10}px`,
          }}
        >
          {it.value}
        </LetterAvatar>
      ),
    )}
  </Wrapper>
);
