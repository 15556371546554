import { SetOptional } from "type-fest";
import { pulse, PulseParams } from "../../utils/analytics-constants";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

interface AppState {
  isProjectNavigatorClosed: boolean;
}

export interface Assets {
  fetchedAt: Date;
  indexJs: string;
  paths: string[];
}

export interface AppSlice {
  pulseTriggered: (params: SetOptional<PulseParams, "user">) => void;
  projectNavigatorToggled: (params?: { open: boolean }) => void;
  assetsLoaded: (assets: Assets | null) => void;
  appState: AppState;
  assets: Assets | null;
}

const actionType = createActionTypeLogger("app");

export const appStore: StoreSlice<AppSlice> = (set, get) => ({
  appState: {
    isProjectNavigatorClosed: false,
  },
  assets: null,
  pulseTriggered: async (params) => {
    const user = get().auth.user;
    if (user) {
      await pulse({
        ...params,
        user,
      });
    }
  },
  assetsLoaded: (value) => {
    set(
      (draft) => {
        draft.assets = value;
      },
      false,
      actionType("assets-loaded"),
    );
  },
  projectNavigatorToggled: (params) => {
    set(
      (draft) => {
        if (params && "open" in params) {
          draft.appState.isProjectNavigatorClosed = !params.open;
        } else {
          draft.appState.isProjectNavigatorClosed =
            !get().appState.isProjectNavigatorClosed;
        }
      },
      false,
      actionType("project-navigator-toggled"),
    );
  },
});
