import { DeleteProject } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { CollectionName } from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";
import { createPerformedByObj } from "../../utils/user-utils";

export const deleteProject = async (params: {
  user: User;
  projectId: string;
}) => {
  const request = {
    markedForDeletionAt: Timestamp.now(),
    markedForDeletionBy: createPerformedByObj({ user: params.user }),
  } satisfies DeleteProject;

  await updateDoc(
    doc(firestore, CollectionName.PROJECTS, params.projectId),
    request,
  );
};
