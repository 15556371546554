import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { CellContext } from "@tanstack/react-table";
import { useEffect } from "react";
import { JobRun } from "../../types/persisted";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";
import { IconButton } from "../IconButton";
import { JobRunStatusIcon } from "../JobRunStatusIcon/JobRunStatusIcon";

interface Props {
  info: CellContext<JobRun, string>;
}

export const ExpandRowCell = ({ info }: Props) => {
  useEffect(() => {
    if (!(info.row.original.endTime || info.row.getIsExpanded())) {
      info.row.toggleExpanded();
    }
  }, [info]);

  return (
    <Div
      css={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <JobRunStatusIcon run={info.row.original} />

      <IconButton
        onClick={() => {
          info.row.toggleExpanded();
        }}
      >
        {info.row.getIsExpanded() ? (
          <StyledFontAwesomeIcon icon={faChevronUp} />
        ) : (
          <StyledFontAwesomeIcon icon={faChevronDown} />
        )}
      </IconButton>
    </Div>
  );
};
