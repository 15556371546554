import { ComponentProps } from "react";
import { styled } from "../../../stitches.config";

export const H1 = styled("h1", {
  margin: 0,
  fontSize: "28px",
  fontWeight: "bold",
  color: "$primaryDark",
});

export const H2 = styled("h2", {
  margin: 0,
  fontSize: "24px",
  fontWeight: "bold",
  color: "$primaryDark",
});

export const H3 = styled("h3", {
  margin: 0,
  fontSize: "18px",
  fontWeight: "bold",
  color: "$primaryDark",
});

export const H4 = styled("h4", {
  margin: 0,
  fontSize: "16px",
  fontWeight: "bold",
  color: "$primaryDark",
});

export const Txt = styled("p", {
  margin: 0,

  variants: {
    size: {
      "10": { fontSize: "10px" },
      "12": { fontSize: "12px" },
      "14": { fontSize: "14px" },
      "16": { fontSize: "16px" },
      inherit: {
        fontSize: "inherit",
      },
    },
    weight: {
      regular: {
        fontWeight: 400,
      },
      "semi-bold": {
        fontWeight: 600,
      },
      bold: {
        fontWeight: 700,
      },
      inherit: {
        fontWeight: "inherit",
      },
    },
    color: {
      white: {
        color: "$white",
      },
      dark: {
        color: "$primaryDark",
      },
      gray: {
        color: "$gray4",
      },
      inherit: {
        color: "inherit",
      },
    },
    inherit: {
      yes: {},
    },
  },

  defaultVariants: {
    size: "16",
    weight: "regular",
    color: "dark",
  },
});

const StyledA = styled("a", {
  fontSize: "inherit",
  fontWeight: "inherit",

  variants: {
    color: {
      purple: {
        color: "$primaryPurple",
      },
      blue: {
        color: "$linkBlue",
      },
    },
  },

  defaultVariants: {
    color: "blue",
  },
});

export const Bold = styled("strong", {
  fontSize: "inherit",
  color: "inherit",
  fontWeight: "$interSemiBold600",
});

// Set rel="noopener" to all links
export const A = ({ children, ...rest }: ComponentProps<typeof StyledA>) => (
  <StyledA {...rest} rel="noopener">
    {children}
  </StyledA>
);
