import { Icon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";

export const LoadingView = () => (
  <Div
    css={{
      backgroundColor: "$splashBackground",
      padding: "3.1em 3.7em",
      position: "fixed",
      inset: 0,
    }}
  >
    <Icon src="/logo-new.svg" alt="Databutton logo" />
  </Div>
);
