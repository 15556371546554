import { collection, limit, orderBy, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { JobRun } from "../../types/persisted";
import { createConverter } from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";

const buildRunsConverter = createConverter<JobRun>();

export const useDevRuns = (params: {
  projectId: string;
  jobId: string;
  codeBlockId: string;
  limit?: number;
}) => {
  const collectionKey = `/projects/${params.projectId}/jobs/${params.jobId}/devruns`;

  const [values, loading] = useCollectionData<JobRun>(
    query(
      collection(firestore, collectionKey),
      orderBy("startTime", "desc"),
      limit(params.limit || 10),
    ).withConverter(buildRunsConverter),
  );

  const inProgress = values?.filter((it) => !it.endTime) ?? [];

  return { values, loading, inProgress };
};
