import { useMemo, useState } from "react";
import { Schedule } from "../../types/persisted";
import { Flex } from "../../ui/Layout/Flex";
import { Select } from "../../ui/Select/Select";
import { H2, Txt } from "../../ui/TypographyV3/TypographyV3";
import { getBrowserTimezone } from "../ZonedTimestamp/utils";
import { CustomScheduleForm } from "./CustomScheduleForm";
import { ScheduleFormType } from "./types";
import { convertCronToWeeklySchedule } from "./weekly-utils";
import { WeeklyScheduleForm } from "./WeeklyScheduleForm";

interface Props {
  onSubmit: (schedule: string, scheduleForm: ScheduleFormType) => void;
  disabled: boolean;
  existingSchedule: Schedule | null;
}

const determineFrequency = (params: {
  initialSchedule: Schedule | null;
  canExistingScheduleBeRenderedAsWeeklySchedule: boolean;
}): ScheduleFormType => {
  if (!params.initialSchedule) {
    return ScheduleFormType.WEEKLY;
  }

  return params.initialSchedule.scheduleForm === undefined &&
    params.canExistingScheduleBeRenderedAsWeeklySchedule
    ? ScheduleFormType.WEEKLY
    : ScheduleFormType.CUSTOM;
};

const isCustomSchedule = (cronExpression: string): boolean =>
  /\d-\d$/.test(cronExpression);

export const ScheduleForm = ({
  onSubmit,
  disabled,
  existingSchedule,
}: Props) => {
  const hasExistingSchedule = !!existingSchedule;
  const canExistingScheduleBeRenderedAsWeeklySchedule = useMemo(() => {
    if (existingSchedule && existingSchedule.scheduleForm === undefined) {
      const cronExpression =
        existingSchedule.cronExpression ?? existingSchedule.cronExpressionUtc;

      if (isCustomSchedule(cronExpression)) {
        return false;
      }

      const schedule = convertCronToWeeklySchedule(cronExpression);
      return schedule !== null;
    }

    return false;
  }, [existingSchedule]);

  const [frequency, setFrequency] = useState<ScheduleFormType>(
    determineFrequency({
      initialSchedule: existingSchedule,
      canExistingScheduleBeRenderedAsWeeklySchedule,
    }),
  );

  const renderSubForm = () => {
    if (
      frequency === ScheduleFormType.WEEKLY ||
      canExistingScheduleBeRenderedAsWeeklySchedule
    ) {
      return (
        <WeeklyScheduleForm
          hasExistingSchedule={hasExistingSchedule}
          onSubmit={onSubmit}
          disabled={disabled}
          initialSchedule={
            existingSchedule?.cronExpression ??
            existingSchedule?.cronExpressionUtc
          }
        />
      );
    }

    if (frequency === ScheduleFormType.CUSTOM) {
      return (
        <CustomScheduleForm
          hasExistingSchedule={hasExistingSchedule}
          onSubmit={onSubmit}
          disabled={disabled}
          initialSchedule={existingSchedule?.cronExpression}
        />
      );
    }

    return null;
  };

  return (
    <Flex direction="vertical" gap="2">
      <H2
        css={{
          width: "100%",
          marginBottom: "$2",
        }}
      >
        {existingSchedule ? "Change schedule" : "Create schedule"}
      </H2>

      <Flex direction="vertical" gap="1">
        <Txt weight="semiBold">Run</Txt>

        <Select<ScheduleFormType>
          value={frequency}
          onChange={setFrequency}
          options={Object.values(ScheduleFormType)}
          getKey={(it) => it}
          getDisplayValue={(it) => it}
        />
      </Flex>

      {renderSubForm()}

      <Flex css={{ justifyContent: "flex-end" }}>
        <Txt color='secondary'>
          All times are displayed in {getBrowserTimezone()}
        </Txt>
      </Flex>
    </Flex>
  );
};
