import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { createCodeBlockSelector } from "../../store/slices/code-slice";
import { useStore } from "../../store/store";
import { CloudCheckIcon, StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { Tooltip } from "../../ui/Tooltip";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";
import { getVerboseTimestamp } from "../ZonedTimestamp/utils";

export const SaveStatus = () => {
  const { codeBlockId } = useWorkspaceContext();
  const codeBlockState = useStore(createCodeBlockSelector(codeBlockId));
  const prevCodeBlockState = usePrevious(codeBlockState);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (
      !!prevCodeBlockState?.codeBlockVersion &&
      prevCodeBlockState.codeBlockVersion.id !==
        codeBlockState?.codeBlockVersion?.id
    ) {
      setIsSaving(true);

      setTimeout(() => {
        setIsSaving(false);
      }, 1500);
    }
  }, [
    codeBlockState?.codeBlockVersion?.id,
    prevCodeBlockState?.codeBlockVersion?.id,
  ]);

  return (
    <Tooltip
      text={
        codeBlockState?.codeBlockVersion?.createdAtUtc && !isSaving
          ? `Last saved ${getVerboseTimestamp({
              showAsRelative: true,
              timestamp: codeBlockState.codeBlockVersion?.createdAtUtc,
            })}`
          : "Saving code..."
      }
    >
      <Flex
        gap="1"
        css={{
          fontSize: "$1",
          width: "18px",
          color: "$gray4",
        }}
      >
        {isSaving ? (
          <StyledFontAwesomeIcon icon={faSpinnerThird} spin={true} />
        ) : (
          <CloudCheckIcon />
        )}
      </Flex>
    </Tooltip>
  );
};
