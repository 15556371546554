import { memo, useMemo } from "react";
import { IFrameWrapper } from "../../../components/IFrameWrapper/IFrameWrapper";
import { LoadingScreen } from "../../../components/LoadingScreen";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { useQuery } from "../../../hooks/useQuery";
import { useIsDevxReady } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { MultipageAppPage } from "../../../types/persisted";
import { isHomePage } from "../../../utils/page-utils";
import { getProjectPath } from "../../../utils/project-utils";
import { randomAppTip } from "../../../utils/user-utils";

interface Props {
  page: MultipageAppPage;
}

const PagePreview = ({ page }: Props) => {
  const { project } = useProjectPageContext();
  const isDevxReady = useIsDevxReady();
  const instanceTag = useStore((state) => state.project.devxHealth.instanceTag);
  const { search } = useQuery();
  const loadingTip = useMemo(() => randomAppTip(), []);

  const iframeSrc = useMemo(
    () =>
      `${getProjectPath({
        projectId: project.id,
        route: isHomePage(page)
          ? "/dbtn/devx/pages"
          : `/dbtn/devx/pages/${page.slug}`,
      })}${search ?? ""}`,
    [project.id, search, page.slug],
  );

  return isDevxReady && instanceTag ? (
    <IFrameWrapper
      instanceTag={instanceTag}
      title="View preview"
      src={iframeSrc}
      loadingMessage="Fetching view preview..."
    />
  ) : (
    <LoadingScreen animation="random" message={loadingTip} />
  );
};

export const MemoizedPagePreview = memo(
  PagePreview,
  (prev, next) => prev.page.id === next.page.id,
);
