import { useMultipageApp } from "../../store/slices/project-slice";
import { Button } from "../../ui/Button";
import { Flex } from "../../ui/Layout/Flex";
import {
  CollectionName,
  createCollectionRefPath,
} from "../../utils/collections/shared";
import { viewerDoc } from "../../utils/collections/viewers";
import { useViewers } from "./useViewers";

export const ListViewers = () => {
  const multipageApp = useMultipageApp();
  const { viewers } = useViewers({
    collectionPath: multipageApp
      ? createCollectionRefPath([multipageApp.refPath, CollectionName.VIEWERS])
      : null,
  });

  return viewers.length > 0 ? (
    <Flex
      direction="vertical"
      gap="1"
      css={{ maxHeight: "130px", overflowY: "auto" }}
    >
      {viewers.map((it) => (
        <Flex key={it.id} css={{ justifyContent: "space-between" }}>
          {it.recipient}
          <Button
            intent="plain"
            type="button"
            onClick={() => {
              viewerDoc.revokeAccess({
                viewerRef: it.refPath,
              });
            }}
          >
            Remove
          </Button>
        </Flex>
      ))}
    </Flex>
  ) : null;
};
