import { User } from "firebase/auth";
import { useEffect } from "react";
import { useStore } from "../store/store";

export const useUser = (): {
  user: User | null;
  isInitialized: boolean;
  segments: string[];
  enabledFeatures: string[];
} => {
  const { user, isListening, isInitialized, enabledFeatures, segments } =
    useStore((state) => state.auth);
  const fetchUser = useStore((state) => state.fetchUser);

  useEffect(() => {
    if (!isListening) {
      fetchUser();
    }
  }, [isListening, fetchUser]);

  return {
    enabledFeatures,
    segments,
    user,
    isInitialized,
  };
};
