import { faArrowPointer } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { useOthers } from "../../context/liveblocks-context";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";

// TODO: Calculate X and Y as relative to screen size
const Cursor = ({ x, y, name }: { x: number; y: number; name: string }) => {
  const displayName = useMemo(
    () => (name.includes(" ") ? name.split(" ")[0] : name),
    [name],
  );

  return (
    <Flex
      direction="horizontal"
      gap="1"
      css={{
        position: "absolute",
        top: y,
        left: x,
        zIndex: 9999,
      }}
    >
      <StyledFontAwesomeIcon icon={faArrowPointer} />
      <span>{displayName}</span>
    </Flex>
  );
};

export const Others = () => {
  const others = useOthers();

  const othersInThisView = useMemo(
    () =>
      others.filter((it) => it.presence.pathname === window.location.pathname),
    [others],
  );

  return (
    <>
      {othersInThisView.map((it) =>
        it.presence.cursor ? (
          <Cursor
            key={it.connectionId}
            x={it.presence.cursor.x}
            y={it.presence.cursor.y}
            name={it.presence.name ?? "Unknown"}
          />
        ) : null,
      )}
    </>
  );
};
