import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { User } from "firebase/auth";
import ReactMarkdown from "react-markdown";
import { styled } from "../../../../stitches.config";
import { Avatar } from "../../../pages/ProjectPage/ProjectComponents/Avatars";
import { ChatGptMessage } from "../../../services/chatgpt-api";
import { Flex } from "../../../ui/Layout/Flex";
import { Txt } from "../../../ui/TypographyV3/TypographyV3";
import { Div } from "../../../ui/Layout";
import { Tooltip } from "../../../ui/Tooltip";
import { CopyCodeButton } from "./CopyCodeButton";
import { COPIED_CODE_FROM_DATABUTLER_AI } from "../../../utils/analytics-constants";
import { copyTextToClipboard } from "../../../utils/user-utils";
import { toast } from "react-toastify";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { useStore } from "../../../store/store";
import { memo } from "react";

const MessageContainer = styled("div", {
  backgroundColor: "#fdfdfd",
  padding: "$1",
  borderRadius: "4px",
  width: "100%",
  height: "fit-content",
  color: "$primaryDark",

  variants: {
    role: {
      user: {
        fontWeight: "700",
        fontSize: "14px",
      },
      assistant: { fontWeight: "500", fontSize: "14px" },
    },
  },
});

interface Props {
  user: User;
  message: ChatGptMessage;
}

const Message = ({ user, message }: Props) => {
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const role = message.role === "user" ? "user" : "assistant";

  if (role === "user") {
    return (
      <MessageContainer role="user">
        <Flex gap="1">
          <Avatar src={user.photoURL ?? ""} position="inline" />
          <Txt>{message.content}</Txt>
        </Flex>
      </MessageContainer>
    );
  }

  return (
    <MessageContainer role={role}>
      <ReactMarkdown
        children={message.content}
        components={{
          code({ node: _node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            const language = match ? match[1] : "python";

            return !inline ? (
              <Flex
                css={{
                  position: "relative",
                  borderColor: "$mainBorder",
                }}
              >
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  showLineNumbers
                  language={language}
                  {...props}
                  style={vs}
                  customStyle={{
                    width: "100%",
                    borderColor: "inherit",
                    margin: 0,
                  }}
                />
                <Div
                  css={{
                    position: "absolute",
                    top: "$1",
                    right: "$1",
                  }}
                >
                  <Tooltip text="Copy code to clipboard">
                    <CopyCodeButton
                      onClick={async () => {
                        pulseTriggered({
                          eventName: COPIED_CODE_FROM_DATABUTLER_AI,
                        });
                        await copyTextToClipboard(String(children));
                        toast.success("Code added to clipboard");
                      }}
                    >
                      <StyledFontAwesomeIcon icon={faCopy} />
                      Copy code
                    </CopyCodeButton>
                  </Tooltip>
                </Div>
              </Flex>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </MessageContainer>
  );
};

export const MemoizedMessage = memo(
  Message,
  (prev, next) =>
    prev.message.content === next.message.content &&
    prev.user.photoURL === next.user.photoURL,
);
