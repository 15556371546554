import { User } from "firebase/auth";
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { styled } from "../../../stitches.config";
import { ProjectMember } from "../../hooks/useProjectMembers";
import { ButtonDeprecated } from "../../ui/Button";
import { Row } from "../../ui/Layout";
import { Text } from "../../ui/Text";
import { CollectionName } from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";

interface Props {
  projectId: string;
  members: ProjectMember[] | null;
  refetch: () => void;
  resendInvitation: (params: {
    recipientEmail: string;
    projectId: string;
  }) => Promise<void>;
  user: User | null;
}

const ButtonGroup = styled("div", {
  display: "flex",
});

export const ProjectMemberList = ({
  members,
  refetch,
  projectId,
  resendInvitation,
  user,
}: Props) => {
  const removeMember = async (userId: string): Promise<void> => {
    updateDoc(doc(firestore, CollectionName.PROJECTS, projectId), {
      members: arrayRemove(userId),
    }).then(refetch);
  };

  const withdrawInvitation = async (recipientEmail: string): Promise<void> => {
    const docs = await getDocs(
      query(
        collection(firestore, CollectionName.PROJECT_INVITES),
        where("project.id", "==", projectId),
        where("to.email", "==", recipientEmail),
      ),
    );

    await Promise.all(
      docs.docs.map((it) =>
        updateDoc(doc(firestore, CollectionName.PROJECT_INVITES, it.id), {
          state: "withdrawn",
        }),
      ),
    );

    refetch();
  };

  if (members && members.length > 0) {
    return (
      <Row css={{ flexFlow: "column", gap: "$1" }}>
        {members.map((it, index) => {
          const userId = it.role !== "pending" && it.userId ? it.userId : null;

          return (
            <Row
              key={userId ?? index}
              css={{
                height: "38px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text css={{ display: "flex", gap: "$1" }} margin="none">
                {it.role === "pending" ? it.email : it.displayName || it.email}
                <Text color="gray" margin="none">
                  ({it.role})
                </Text>
              </Text>

              {it.role !== "owner" && userId && userId !== user?.uid && (
                <ButtonDeprecated
                  type="button"
                  onClick={() => {
                    removeMember(userId);
                  }}
                  intent="plain"
                >
                  Remove
                </ButtonDeprecated>
              )}

              {it.role === "pending" && (
                <ButtonGroup>
                  <ButtonDeprecated
                    type="button"
                    intent="plain"
                    onClick={() => {
                      resendInvitation({
                        recipientEmail: it.email,
                        projectId,
                      });
                    }}
                  >
                    Resend
                  </ButtonDeprecated>

                  <ButtonDeprecated
                    type="button"
                    onClick={() => {
                      withdrawInvitation(it.email);
                    }}
                    intent="plain"
                  >
                    Withdraw
                  </ButtonDeprecated>
                </ButtonGroup>
              )}
            </Row>
          );
        })}
      </Row>
    );
  }

  return null;
};
