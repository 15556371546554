import { collection, orderBy, query, where } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useStore } from "../store/store";
import { ProjectTemplate } from "../types/persisted";
import { projectTemplateConverter } from "../utils/collections/project-templates";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useProjectTemplates = ({
  filter = "active-only",
  shouldFetch = true,
}: {
  filter: "active-only" | "all";
  shouldFetch?: boolean;
}) => {
  const projectTemplatesReceived = useStore(
    (state) => state.projectTemplatesReceived,
  );
  const [values, loading] = useCollectionData<ProjectTemplate>(
    shouldFetch
      ? query(
          collection(firestore, CollectionName.PROJECT_TEMPLATES).withConverter(
            projectTemplateConverter,
          ),
          where("markedForDeletionBy", "==", null),
          orderBy("sequence", "asc"),
        )
      : null,
  );

  const projectTemplates = useMemo(
    () =>
      values?.filter(
        (it) => filter === "all" || (filter === "active-only" && it.active),
      ) ?? [],
    [values],
  );

  useEffect(() => {
    projectTemplatesReceived({ items: projectTemplates });
  }, [projectTemplates]);

  return {
    projectTemplates,
    loading,
  };
};
