import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import { ProjectSummary } from "../types/persisted";
import { projectConverter } from "../utils/collections/projects";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useProjectSubscription = (projectId: string | undefined) => {
  const projectLoaded = useStore((state) => state.projectLoaded);
  const [isInitialized, setIsInitialized] = useState(false);
  const projectUnloaded = useStore((state) => state.projectUnloaded);

  const [project, loading, error] = useDocumentData(
    projectId
      ? doc(firestore, CollectionName.PROJECTS, projectId).withConverter(
          projectConverter,
        )
      : null,
  );

  useEffect(() => {
    if (!isInitialized && project) {
      projectLoaded(project);
      setIsInitialized(true);
    }
  }, [project, isInitialized]);

  useEffect(() => {
    return projectUnloaded;
  }, []);

  const projectSummary = useMemo((): ProjectSummary | null => {
    return project
      ? {
          id: project.id,
          refPath: project.refPath,
          name: project.name,
          markedForDeletionAt: project.markedForDeletionAt,
          owner: project.owner,
          members: project.members,
          devxUrl: project.devxUrl,
          prodxUrl: project.prodxUrl,
          createdFromProjectTemplateId: project.createdFromProjectTemplateId,
          currentDeploymentId: project.currentDeploymentId,
        }
      : null;
  }, [project]);

  if (error) {
    logService.warn(error.message);
  }

  return {
    projectSummary,
    loading,
    error,
  };
};
