import { styled } from "../../../../stitches.config";

export const DatabutlerButton = styled("button", {
  color: "#9747FF",
  backgroundColor: "#F8F3FF",
  border: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "500",
  paddingX: "$2",
  gap: "$1",
  height: "30px",
  cursor: "pointer",

  variants: {
    state: {
      open: {
        backgroundColor: "#9747FF",
        color: "$white",
      },
      closed: {},
    },
  },

  defaultVariants: {
    state: "closed",
  },
});
