import { faQuestionCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { isValidCron } from "cron-validator";
import { FormEventHandler, useState } from "react";
import { useImmer } from "use-immer";
import { Button } from "../../ui/Button";
import { Form } from "../../ui/Form";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import { Flex } from "../../ui/Layout/Flex";
import { A } from "../../ui/Typography";
import { Txt } from "../../ui/TypographyV3/TypographyV3";
import { ListUpcomingRuns } from "./ListUpcomingRuns";
import { ScheduleFormType } from "./types";

interface Props {
  onSubmit: (schedule: string, scheduleForm: ScheduleFormType) => void;
  disabled: boolean;
  initialSchedule?: string;
  hasExistingSchedule: boolean;
}

export const CustomScheduleForm = ({
  onSubmit,
  disabled,
  initialSchedule,
  hasExistingSchedule,
}: Props) => {
  const [isHelpTextVisible, setIsHelpTextVisible] = useState(false);
  const [state, setState] = useImmer<{
    expression: string;
    errorMessage?: string;
  }>({
    expression: initialSchedule ?? "*/5 * * * *",
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!(state.errorMessage || disabled)) {
      onSubmit(state.expression.trim(), ScheduleFormType.CUSTOM);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Flex direction="vertical" gap="2">
        <Label>
          <Txt weight="semiBold">Cron expression</Txt>
          <Flex
            gap="2"
            css={{
              width: "200px",
            }}
          >
            <Input
              css={{ width: "100px" }}
              type="text"
              value={state.expression}
              state={state.errorMessage ? "invalid" : "valid"}
              onChange={(e) => {
                setState((draft) => {
                  draft.expression = e.target.value;

                  if (isValidCron(e.target.value)) {
                    // rome-ignore lint/performance/noDelete: <explanation>
                    delete draft.errorMessage;
                  } else {
                    draft.errorMessage = "Invalid cron expression";
                  }
                });
              }}
            />

            <StyledFontAwesomeIcon
              icon={faQuestionCircle}
              css={{ color: "$iconGray", fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setIsHelpTextVisible((prev) => !prev);
              }}
            />
          </Flex>
        </Label>

        {isHelpTextVisible && (
          <Flex
            direction="vertical"
            gap="1"
            css={{
              marginBottom: "$3",
              borderRadius: "4px",
              border: "1px solid $gray6BoardBackground",
              padding: "$1 $2 $2",
            }}
          >
            <Flex
              css={{
                justifyContent: "space-between",
                marginBottom: "$1",
              }}
            >
              <Txt weight="semiBold">What is this?</Txt>
              <Button
                content="icon"
                intent="plain"
                onClick={() => {
                  setIsHelpTextVisible(false);
                }}
              >
                <StyledFontAwesomeIcon icon={faXmark} />
              </Button>
            </Flex>

            <Txt>
              Using cron expressions cron you can customize your schedule to fit
              your need.
            </Txt>
            <Txt>
              Check out{" "}
              <A
                href="https://crontab.guru/#*/5_*_*_*_*"
                target="_blank"
                rel="noreferrer"
              >
                this guide
              </A>{" "}
              for a tips on how to create your own expression.
            </Txt>
          </Flex>
        )}

        {state.errorMessage && <Txt>{state.errorMessage}</Txt>}

        <ListUpcomingRuns schedule={state.expression.trim()} />

        <Button
          type="submit"
          disabled={disabled}
          css={{
            alignSelf: "flex-end",
          }}
        >
          {hasExistingSchedule ? "Update Schedule" : "Create Schedule"}
        </Button>
      </Flex>
    </Form>
  );
};
