import { ReactNode } from "react";
import { styled } from "../../../stitches.config";

const Wrapper = styled("div", {
  height: "50px",
});

interface Props {
  children: ReactNode;
}

export const SidecarNavbar = ({ children }: Props) => (
  <Wrapper>{children}</Wrapper>
);
