import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { motion } from "framer-motion";
import { ComponentProps, createRef, useEffect, useState } from "react";
import { styled } from "../../../../stitches.config";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";

const Btn = styled(motion.button, {
  $$borderSize: "2px",
  $$iconFontSize: "20px",

  width: "fit-content",
  borderRadius: "$2",
  display: "flex",
  alignItems: "center",
  padding: "$2",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: "500",

  backgroundColor: "$white",
  border: "$$borderSize solid #F8F3FF",
  color: "$primaryPurple",

  "&:hover": {
    border: "$$borderSize solid $primaryPurple",
    backgroundColor: "$primaryPurple",
    color: "$white",
  },

  "& > svg": {
    display: "none",
  },

  "&:hover > svg": {
    display: "initial",
  },
});

interface Props {
  onClick: ComponentProps<typeof Btn>["onClick"];
  text: string;
}

const AnimatedIcon = motion(StyledFontAwesomeIcon);

const AnimatedDiv = styled(motion.div);

export const NewComponentButton = ({ onClick, text }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [width, setWidth] = useState<number>(0);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      setWidth(width);
    }
  }, [ref]);

  return (
    <Btn
      type="button"
      onClick={onClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <AnimatedIcon
        icon={faPlus}
        animate={{ opacity: isHovered ? 1 : 0 }}
        css={{
          fontSize: "$$iconFontSize",
        }}
      />
      <AnimatedDiv
        css={{
          textAlign: "right",
          height: "$$iconFontSize",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        ref={ref}
        animate={{ width: isHovered ? `${width + 16}px` : `${width}px` }}
      >
        {text}
      </AnimatedDiv>
    </Btn>
  );
};
