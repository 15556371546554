export enum TimeSelectType {
  SPECIFIC_TIME = "At",
  REPEATING = "Every",
}

export enum RepeatingFrequency {
  EVERY_MINUTE = "minute",
  EVERY_5_MINUTES = "5 minute",
  EVERY_10_MINUTES = "10 minute",
  EVERY_15_MINUTES = "15 minute",
  EVERY_30_MINUTES = "30 minute",
  EVERY_HOUR = "hour",
  EVERY_2_HOURS = "2 hours",
  EVERY_3_HOURS = "3 hours",
  EVERY_6_HOURS = "6 hours",
  EVERY_12_HOURS = "12 hours",
}

// Representing weekdays in cron where the week starts on sunday (hence 0)
export enum Weekday {
  MONDAY = "monday-1",
  TUESDAY = "tuesday-2",
  WEDNESDAY = "wednesday-3",
  THURSDAY = "thursday-4",
  FRIDAY = "friday-5",
  SATURDAY = "saturday-6",
  SUNDAY = "sunday-0",
}

interface WeeklyScheduleBase {
  weekdays: Weekday[];
}

export interface RepeatingWeeklySchedule extends WeeklyScheduleBase {
  timeSelectType: TimeSelectType.REPEATING;
  repeatingFrequency: RepeatingFrequency;
  repeatingInterval: { fromHour: number | string; toHour: number | string };
}

export interface SpecificTimeWeeklySchedule extends WeeklyScheduleBase {
  timeSelectType: TimeSelectType.SPECIFIC_TIME;
  at: string;
}

export type WeeklyScheduleInput =
  | RepeatingWeeklySchedule
  | SpecificTimeWeeklySchedule;

export enum ScheduleFormType {
  // YEARLY = "Yearly",
  // MONTHLY = "Monthly",
  WEEKLY = "Weekly",
  CUSTOM = "Custom",
}
