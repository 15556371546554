import { ProjectEventsReadMap } from "@databutton/firebase-types";
import dayjs, { Dayjs } from "dayjs";
import {
  collection,
  limit,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { ProjectEvent } from "../../types/persisted";
import {
  getProjectEventsCollectionKey,
  projectEventConverter,
} from "../../utils/collections/project-events";
import { firestore } from "../../utils/firebase";
import { isAfter } from "../../utils/time-utils";

const isUpdatedWithin20Minutes = (params: {
  reference: Dayjs;
  event: ProjectEvent;
}): boolean =>
  Math.abs(
    params.reference.diff(params.event.createdBy.timestamp.toDate(), "minutes"),
  ) <= 20;

export const getReadProjectEventsAt = (params: {
  map?: ProjectEventsReadMap | null;
  projectId: string;
}): Timestamp | null => {
  if (!params.map) {
    return null;
  }

  const timestamp = params.map[params.projectId];

  return timestamp ?? null;
};

export const useProjectEvents = ({
  projectId,
  readProjectEventsAt,
  numberOfEvents = 5,
}: {
  projectId: string;
  readProjectEventsAt: Timestamp | null;
  numberOfEvents?: number;
}) => {
  const collectionKey = getProjectEventsCollectionKey({ projectId });

  const [events] = useCollectionData<ProjectEvent>(
    query(
      collection(firestore, collectionKey),
      orderBy("createdBy.timestamp", "desc"),
      limit(numberOfEvents),
    ).withConverter(projectEventConverter),
  );

  const unreadProjectEvents = useMemo(() => {
    if (events && events.length > 0 && readProjectEventsAt) {
      return events.filter((it) =>
        isAfter({
          timestamp: it.lastUpdatedBy.timestamp,
          reference: readProjectEventsAt,
        }),
      );
    }

    return events ?? [];
  }, [events, readProjectEventsAt]);

  const hasUnreadProjectEvents = useMemo(
    () => unreadProjectEvents.length > 0,
    [unreadProjectEvents],
  );

  const hasUnreadFailureEvent = useMemo(
    () => unreadProjectEvents.some((it) => it.status === "FAILURE"),
    [unreadProjectEvents],
  );

  /**
   * Events are shown as in progress if they are PENDING or IN_PROGRESS and the
   * event has been updated within the last 10 minutes
   */
  const inProgressEvents = useMemo(() => {
    if (events) {
      const now = dayjs();
      const filteredEvents = events
        .filter((it) => isUpdatedWithin20Minutes({ event: it, reference: now }))
        .filter((it) => it.status === "PENDING" || it.status === "IN_PROGRESS");

      return filteredEvents;
    }

    return [];
  }, [events]);

  const eventsLast20Minutes = useMemo(() => {
    if (events) {
      const now = dayjs();
      return events.filter((it) =>
        isUpdatedWithin20Minutes({ event: it, reference: now }),
      );
    }

    return [];
  }, [events]);

  const latestInProgressEvent = useMemo(
    () => (inProgressEvents.length > 0 ? inProgressEvents[0] : null),
    [inProgressEvents],
  );

  return {
    eventsLast20Minutes,
    events,
    hasUnreadProjectEvents,
    inProgressEvents,
    latestInProgressEvent,
    hasUnreadFailureEvent,
  };
};
