export const isLocal = () => /localhost:\d{4}/i.test(window.location.origin);

export const isPrLink = () =>
  /^https:\/\/databutton-[\d]*-databutton\.vercel\.app/i.test(
    window.location.origin,
  );

export enum OnOffFeatureFlag {
  SHOW_PROJECT_EVENTS = "SHOW_PROJECT_EVENTS",
  SHOW_CURSORS = "SHOW_CURSORS",
  LINE_DECORATORS = "LINE_DECORATORS",
}

export enum UserPropertyFeatureFlag {
  MULTIPAGE_APPS = "MULTIPAGE_APPS",
  PUBLISH_MULTIPAGE_APPS = "PUBLISH_MULTIPAGE_APPS",
  MIGRATE_VIEWS = "MIGRATE_VIEWS",
  STATUS_ICON = "status-icon",
  DEPRECATE_VIEWS = "deprecate-views",
  EDIT_DBTN_REQUIREMENTS = "edit-dbtn-requirements",
}

export enum FeatureFlag {
  MAGIC_WAND = "magic-wand",
}

const onOffFeatureFlags: { [key in OnOffFeatureFlag]: boolean } = {
  [OnOffFeatureFlag.SHOW_PROJECT_EVENTS]: true,
  [OnOffFeatureFlag.SHOW_CURSORS]: false,
  [OnOffFeatureFlag.LINE_DECORATORS]: false,
};

export const isOnOffFeatureEnabled = (feature: OnOffFeatureFlag): boolean =>
  onOffFeatureFlags[feature];
