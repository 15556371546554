import { memo, useCallback } from "react";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { DatabuttonEditorLazy } from "../../../components/DatabuttonEditor/DatabuttonEditorLazy";
import { EditorWrapper } from "../../../components/DatabuttonEditor/EditorWrapper";
import { useIsDevxReady } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { SplitPaneLayout } from "../../../ui/SplitPane/utils";

interface Props {
  layout: SplitPaneLayout;
}

const PageEditor = ({ layout }: Props) => {
  const isDevxReady = useIsDevxReady();
  const identifier = useWorkspaceContext();

  const updatePreviewTriggered = useStore(
    (state) => state.updatePreviewTriggered,
  );

  const updatePreviewTriggeredFn = useCallback(
    (code: string) => {
      updatePreviewTriggered({ identifier, code });
    },
    [identifier],
  );

  return (
    <EditorWrapper codeBlockId={identifier.codeBlockId} layout={layout}>
      <DatabuttonEditorLazy
        projectId={identifier.projectId}
        componentId={identifier.componentId}
        codeBlockId={identifier.codeBlockId}
        isDevxReady={isDevxReady}
        runFn={updatePreviewTriggeredFn}
      />
    </EditorWrapper>
  );
};

export const MemoizedPageEditor = memo(PageEditor);
