import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MemoizedBuildComponentWrapper } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useProjectPageContext } from "../../components/ProjectWrapper/ProjectWrapper";
import { useCodeBlockSubscription } from "../../hooks/useCodeBlock";
import { useTitle } from "../../hooks/useTitle";
import { useJobs } from "../../store/slices/project-slice";
import { WorkspaceIdentifier } from "../../store/slices/workspace-slice";
import { MemoizedBuildJobForm } from "./BuildJobForm";

export const BuildJobPage = () => {
  const { jobIdOrSlug } = useParams() as { jobIdOrSlug: string };
  const { project } = useProjectPageContext();
  const jobs = useJobs();

  const job = useMemo(
    () => jobs.find((it) => it.slug === jobIdOrSlug || it.id === jobIdOrSlug),
    [jobs, jobIdOrSlug],
  );

  useTitle(job?.name);

  const { codeBlock } = useCodeBlockSubscription(project.id, job?.id);

  const identifier = useMemo(
    (): WorkspaceIdentifier | null =>
      job && codeBlock
        ? {
            projectId: project.id,
            componentId: job.id,
            componentType: "job",
            codeBlockId: codeBlock.id,
          }
        : null,
    [project.id, job?.id, codeBlock?.id],
  );

  if (!(job && codeBlock && identifier)) {
    return null;
  }

  return (
    <MemoizedBuildComponentWrapper
      component={job}
      identifier={identifier}
      codeBlockId={codeBlock.id}
      createdFromProjectTemplateId={project.createdFromProjectTemplateId}
    >
      <MemoizedBuildJobForm job={job} codeBlockId={codeBlock.id} />
    </MemoizedBuildComponentWrapper>
  );
};
