import { styled } from "../../../../stitches.config";

export const CopyCodeButton = styled("button", {
  color: "#9747FF",
  border: "1px solid #F2EBFC",
  backgroundColor: "#F8F3FF",
  display: "flex",
  gap: "$1",
  padding: "calc($1/2) $1",
  fontWeight: 500,
  borderRadius: "2px",
  cursor: "pointer",
});
