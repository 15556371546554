import { Popover as HeadlessUiPopover } from "@headlessui/react";
import { ReactTag } from "@headlessui/react/dist/types";
import { ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { styled } from "../../../stitches.config";
import { Tooltip } from "../Tooltip";

const Panel = styled("div", {
  $$arrowSize: "7px",
  $$panelBorderSize: "1px",
  $$panelBorderColor: "#EAEAEA",
  $$panelBackgroundColor: "#FBFBFB",

  backgroundColor: "$$panelBackgroundColor",
  border: "$$panelBorderSize solid $$panelBorderColor",
  borderRadius: "4px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexFlow: "column",
  gap: "$1",
  minWidth: "120px",
  position: "relative",
  zIndex: 1,

  '&[data-popper-placement^="right"] > #arrow': {
    left: "calc($$arrowSize * -1)",
    top: "calc(100% / 2 - $arrowSize)",
  },

  '&[data-popper-placement^="left"] > #arrow': {
    right: "calc($$arrowSize * -1)",
    top: "calc(100% / 2 - $$arrowSize)",
    transform: "rotate(180deg)",
  },

  '&[data-popper-placement^="top"] > #arrow': {
    left: "calc(100% / 2 - $$arrowSize)",
    bottom: "calc(($$arrowSize * 1.5) * -1)",
    transform: "rotate(270deg)",
  },

  '&[data-popper-placement^="bottom"] > #arrow': {
    left: "calc(100% / 2 - $$arrowSize)",
    top: "calc(($$arrowSize * 1.5 + $$panelBorderSize) * -1)",
    transform: "rotate(90deg)",
  },
});

export const PopoverButton = styled("button", {
  background: "transparent",
  color: "$primaryDark",
  width: "100%",
  border: "none",
  padding: "$1",
  margin: 0,
  fontSize: "$2",
  textAlign: "left",

  "&:hover": {
    backgroundColor: "$lightGray",
    cursor: "pointer",
  },
});

export const PopoverLink = styled(Link, {
  fontSize: "$2",
  padding: "$1",
  display: "flex",
  margin: 0,
  color: "$primaryDark",

  "&:hover": {
    backgroundColor: "$lightGray",
    cursor: "pointer",
  },
});

interface Props {
  buttonAs: ReactTag;
  tooltipText?: string;
  panelContent: ReactNode;
  buttonContent: ReactNode;
}

const PopoverWrapper = styled("div", {
  position: "relative",
});

const PopoverArrow = styled("div", {
  position: "absolute",
  border: "$$arrowSize solid transparent",
  borderLeft: "none",
  borderRightColor: "$$panelBorderColor",

  "&::before": {
    content: "",
    position: "absolute",
    border: "$$arrowSize solid transparent",
    borderLeft: "none",
    borderRightColor: "$$panelBackgroundColor",
    top: "calc($$arrowSize * -1)",
    left: "$$panelBorderSize",
  },
});

export const Popover = ({
  panelContent,
  tooltipText,
  buttonContent,
  buttonAs,
}: Props) => {
  const [buttonRef, setButtonRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);

  const { styles, attributes } = usePopper(buttonRef, popperRef, {
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  return (
    <HeadlessUiPopover as={PopoverWrapper}>
      {tooltipText ? (
        <Tooltip text={tooltipText}>
          {/* rome-ignore lint/suspicious/noExplicitAny: <explanation> */}
          <HeadlessUiPopover.Button ref={setButtonRef as any} as={buttonAs}>
            {buttonContent}
          </HeadlessUiPopover.Button>
        </Tooltip>
      ) : (
        // rome-ignore lint/suspicious/noExplicitAny: <explanation>
        <HeadlessUiPopover.Button ref={setButtonRef as any} as={buttonAs}>
          {buttonContent}
        </HeadlessUiPopover.Button>
      )}

      {/* React Portal used to avoid other divs overshadowing the popover. Read more here: https://popper.js.org/react-popper/v2/react-portals/ */}
      {ReactDOM.createPortal(
        <HeadlessUiPopover.Panel
          as={Panel}
          id="panel"
          // rome-ignore lint/suspicious/noExplicitAny: <explanation>
          ref={setPopperRef as any}
          style={styles.popper}
          {...attributes.popper}
        >
          <PopoverArrow data-popper-arrow={true} id="arrow" />

          {panelContent}
        </HeadlessUiPopover.Panel>,
        document.querySelector("#popover") as HTMLElement,
      )}
    </HeadlessUiPopover>
  );
};
