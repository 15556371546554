import { collection, query } from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import {
  appVisitConverter,
  getAppVisitCollectionKey,
} from "../utils/collections/visits";
import { firestore } from "../utils/firebase";
import { getPastTimestamp, isAfter } from "../utils/time-utils";

export const useAppVisits = (params: { projectId: string; appId?: string }) => {
  const [values] = useCollectionDataOnce(
    params.appId
      ? query(
          collection(
            firestore,
            getAppVisitCollectionKey({
              projectId: params.projectId,
              appId: params.appId,
            }),
          ),
        ).withConverter(appVisitConverter)
      : null,
  );

  const stats = useMemo(() => {
    if (values) {
      const date24HoursAgo = getPastTimestamp({ hours: 24 });
      const date48HoursAgo = getPastTimestamp({ hours: 48 });

      const numberOfVisitsLast24Hours = values.filter(
        (it) =>
          it.visitedBy &&
          isAfter({
            timestamp: it.visitedBy.timestamp,
            reference: date24HoursAgo,
          }),
      ).length;

      const numberOfVisitsLast48Hours =
        values.filter(
          (it) =>
            it.visitedBy &&
            isAfter({
              timestamp: it.visitedBy.timestamp,
              reference: date48HoursAgo,
            }),
        ).length - numberOfVisitsLast24Hours;

      return {
        numberOfVisits: values.length,
        numberOfVisitsLast24Hours,
        numberOfVisitsLast48Hours,
      };
    }

    return null;
  }, [values]);

  return { stats };
};
