import { Command } from "cmdk";
import { useContext, useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import tinykeys from "tinykeys";
import { styled } from "../../../stitches.config";
import { useDataStorageGroups } from "../../hooks/useDataStorageGroups";
import { dbtnApi } from "../../services/dbtn-api";
import {
  ComponentState,
  useOpenComponents,
} from "../../store/slices/component-slice";
import {
  useApps,
  useJobs,
  useModules,
  usePages,
} from "../../store/slices/project-slice";
import { useStore } from "../../store/store";
import { Txt } from "../../ui/TypographyV2/TypographyV2";
import { OPENED_COMMAND_PALETTE } from "../../utils/analytics-constants";
import {
  createPath,
  createPathToApp,
  createPathToDatafile,
  createPathToDataframe,
  createPathToJob,
  createPathToModule,
  createPathToProject,
  createPathToProjectConfiguration,
  routeUtils,
} from "../../utils/route-utils";
import { notEmpty, uniqueByKey } from "../../utils/ts-utils";
import { isDatabuttonEmail } from "../../utils/user-utils";
import { restartWorkspace } from "../../utils/workspace-utils";
import { deleteProject } from "../DeleteProjectModal/utils";
import { useProjectPageContext } from "../ProjectWrapper/ProjectWrapper";
import { UserGuardContext } from "../UserGuard/UserGuard";

const CommandDialog = styled(Command.Dialog, {
  position: "fixed",
  width: "450px",

  borderRadius: "4px",
  padding: "$1",
  paddingBottom: "$2",
  boxShadow: "$largeElevation",
  backgroundColor: "$white",
  top: "20%",
  left: 0,
  right: 0,
  margin: "0 auto",
});

const CommandInput = styled(Command.Input, {
  width: "100%",
  padding: "$1",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid $mainBorder",
  marginBottom: "$1",
});

const CommandList = styled(Command.List, {
  width: "100%",
  borderRadius: "4px",
  maxHeight: "350px",
  overflowY: "auto",
});

const CommandEmpty = styled(Command.Empty, {
  padding: "$1",
});

const CommandGroup = styled(Command.Group, {
  fontSize: "12px",
  marginBottom: "$2",

  "& > [cmdk-group-heading]": {
    padding: "$1",
  },
});

const CommandItem = styled(Command.Item, {
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "max-content auto max-content",
  columnGap: "$2",
  padding: "$1 $2",
  borderRadius: "4px",

  "&:hover": {
    cursor: "pointer",
  },

  '&[aria-selected="true"]': {
    backgroundColor: "$gray6BoardBackground",
  },

  '&[aria-disabled="true"]': {},
});

interface CommandAction {
  value: string;
  title: string;
  onSelect: (value: string) => void;
  key: string;
}

const createRecentComponentAction = (params: {
  component: ComponentState;
  navigate: NavigateFunction;
}): CommandAction => ({
  value: `recent tabs ${params.component.name}`,
  title: params.component.name,
  key: params.component.id,
  onSelect: () => {
    params.navigate(params.component.path);
  },
});

export const ProjectCommandPalette = () => {
  const [open, setOpen] = useState(false);
  const { project } = useProjectPageContext();
  const modules = useModules();
  const pages = usePages();
  const jobs = useJobs();
  const apps = useApps();
  const dataStorageGroups = useDataStorageGroups({ projectId: project.id });
  const { user } = useContext(UserGuardContext);
  const navigate = useNavigate();
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const openComponents = useOpenComponents();
  const projectNavigatorToggled = useStore(
    (state) => state.projectNavigatorToggled,
  );

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      "$mod+k": (e) => {
        if (e.defaultPrevented) {
          return;
        }

        e.preventDefault();
        // Toggle the menu when ⌘K is pressed
        setOpen((prevState) => {
          if (prevState === false) {
            pulseTriggered({
              user,
              eventName: OPENED_COMMAND_PALETTE,
            });
          }

          return !prevState;
        });
      },
      "$mod+.": (e) => {
        if (e.defaultPrevented) {
          return;
        }

        e.preventDefault();

        projectNavigatorToggled();
      },
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const recentlyVisitedActions = useMemo(
    () =>
      uniqueByKey<CommandAction>(
        openComponents.reduce(
          (prev, it) => [
            ...prev,
            createRecentComponentAction({
              navigate,
              component: it,
            }),
          ],
          openComponents.length > 1
            ? [
                createRecentComponentAction({
                  navigate,
                  component: openComponents[1],
                }),
              ]
            : [],
        ),
        "key",
      ),
    [openComponents],
  );

  const adminActions: CommandAction[] = useMemo(
    () =>
      user.email && isDatabuttonEmail(user.email)
        ? [
            {
              title: "Open in Firebase",
              value: "admin open in firebase",
              key: "firebase",
              onSelect: () => {
                window.open(
                  `https://console.firebase.google.com/project/databutton/firestore/data/projects/${project.id}`,
                  "_blank",
                );
              },
            },
            {
              title: "Go to self service",
              value: "admin go to self service",
              key: "self-service",
              onSelect: () => {
                navigate("/self-service");
              },
            },
            {
              title: "Configurate feature flags",
              value: "admin configurate feature flags segments",
              key: "feature-flags",
              onSelect: () => {
                navigate("/admin/feature-flags");
              },
            },
            {
              title: "Delete project",
              value: "admin delete project",
              key: "delete-project",
              onSelect: async () => {
                await deleteProject({ user, projectId: project.id });
                toast.success("Project deleted");

                navigate("/");
              },
            },
            {
              title: "Restart workspace",
              value: "admin restart workspace",
              key: "restart-workspace",
              onSelect: async () => {
                await restartWorkspace({
                  projectId: project.id,
                  user,
                });
              },
            },
            {
              title: "List files",
              value: "admin list files",
              key: "list-files",
              onSelect: async () => {
                const response = await dbtnApi.get({
                  projectId: project.id,
                  user,
                  route: "/dbtn/devx/status/appfiles",
                });

                const body = await response.json();

                console.log(body);
              },
            },
          ]
        : [],
    [user, project],
  );

  const pageActions = useMemo(
    () =>
      pages.map<CommandAction>((it) => ({
        title: it.name,
        onSelect: () => {
          navigate(
            routeUtils.path({
              to: "page",
              params: {
                // mode: "build",
                pageIdOrSlug: it.slug,
                projectId: project.id,
              },
            }),
          );
        },
        key: it.id,
        value: `page ${it.name}`,
      })),
    [pages],
  );

  const viewActions = useMemo(
    () =>
      apps.map<CommandAction>((it) => ({
        title: it.name,
        onSelect: () => {
          navigate(
            createPath({
              to: createPathToApp({
                projectId: project.id,
                appSlug: it.slug,
                mode: "build",
              }),
            }),
          );
        },
        key: it.id,
        value: `view ${it.name}`,
      })),
    [apps],
  );

  const jobActions = useMemo(
    () =>
      jobs.map<CommandAction>((it) => ({
        title: it.name,
        key: it.id,
        value: `job ${it.name}`,
        onSelect: () => {
          navigate(
            createPath({
              to: createPathToJob({
                projectId: project.id,
                jobSlug: it.slug,
                mode: "build",
              }),
            }),
          );
        },
      })),
    [jobs],
  );

  const moduleActions = useMemo(
    () =>
      modules.map<CommandAction>((it) => ({
        title: it.name,
        key: it.id,
        value: `library ${it.name}`,
        onSelect: () => {
          navigate(
            createPath({
              to: createPathToModule({
                projectId: project.id,
                moduleIdOrSlug: it.slug,
              }),
            }),
          );
        },
      })),
    [modules],
  );

  const datafileActions = useMemo(
    () =>
      dataStorageGroups.allDatafiles.map<CommandAction>((it) => ({
        title: it.name ?? it.id,
        key: it.id,
        value: `data storage ${it.name} ${it.id}`,
        onSelect: () => {
          navigate(
            createPath({
              to: createPathToDatafile({
                projectId: project.id,
                slug: it.slug ?? it.generatedSlug ?? it.id,
                contentType: it.contentType,
              }),
            }),
          );
        },
      })),
    [dataStorageGroups],
  );

  const dataframeActions = useMemo(
    () =>
      dataStorageGroups.dataframes.map<CommandAction>((it) => ({
        title: it.name ?? it.id,
        key: it.id,
        value: `data storage ${it.name} ${it.id}`,
        onSelect: () => {
          navigate(
            createPath({
              to: createPathToDataframe({
                projectId: project.id,
                slug: it.slug ?? it.generatedSlug ?? it.id,
              }),
            }),
          );
        },
      })),
    [dataStorageGroups],
  );

  const dataStorageActions = useMemo(
    () =>
      datafileActions
        .concat(dataframeActions)
        .sort((a, b) => (a.title.localeCompare(b.title) ? -1 : 1)),
    [datafileActions, dataframeActions],
  );

  const projectActions = useMemo(
    () =>
      [
        {
          title: "Home",
          onSelect: () => {
            navigate(
              createPath({
                to: createPathToProject({ projectId: project.id }),
              }),
            );
          },
          value: "data app home",
          key: "home",
        },
        {
          title: "Configure",
          value: "data app configure",
          onSelect: () => {
            navigate(
              createPath({
                to: createPathToProjectConfiguration({
                  projectId: project.id,
                }),
              }),
            );
          },
          key: "configure",
        },
        {
          title: "Install package",
          value: "data app install package",
          key: "install-package",
          onSelect: () => {
            navigate(
              createPath({
                to: createPathToProjectConfiguration({
                  projectId: project.id,
                }),
                query: {
                  focus: "packages",
                },
              }),
            );
          },
        },
      ].filter(notEmpty) satisfies CommandAction[],
    [project],
  );

  const renderAction = (action: CommandAction) => (
    <CommandItem
      onSelect={(value) => {
        setOpen(false);
        action.onSelect(value);
      }}
      value={action.value}
      key={action.key}
    >
      <Txt>{action.title}</Txt>
    </CommandItem>
  );

  const handleBlur = () => {
    setOpen(false);
  };

  return (
    <CommandDialog
      open={open}
      onOpenChange={setOpen}
      label="Global Command Menu"
    >
      <CommandInput
        placeholder="Type a command or search..."
        onBlur={handleBlur}
      />
      <CommandList>
        <CommandEmpty>No commands found</CommandEmpty>

        {recentlyVisitedActions.length > 0 && (
          <CommandGroup heading="Recently visited">
            {recentlyVisitedActions.map(renderAction)}
          </CommandGroup>
        )}

        {pageActions.length > 0 && (
          <CommandGroup heading="Pages">
            {pageActions.map(renderAction)}
          </CommandGroup>
        )}

        {viewActions.length > 0 && (
          <CommandGroup heading="Views">
            {viewActions.map(renderAction)}
          </CommandGroup>
        )}

        {jobActions.length > 0 && (
          <CommandGroup heading="Jobs">
            {jobActions.map(renderAction)}
          </CommandGroup>
        )}

        {moduleActions.length > 0 && (
          <CommandGroup heading="Libraries">
            {moduleActions.map(renderAction)}
          </CommandGroup>
        )}

        {dataStorageActions.length > 0 && (
          <CommandGroup heading="Data storage">
            {dataStorageActions.map(renderAction)}
          </CommandGroup>
        )}

        {projectActions.length > 0 && (
          <CommandGroup heading="Data app">
            {projectActions.map(renderAction)}
          </CommandGroup>
        )}

        {adminActions.length > 0 && (
          <CommandGroup heading="Admin">
            {adminActions.map(renderAction)}
          </CommandGroup>
        )}
      </CommandList>
    </CommandDialog>
  );
};
