import { collection, limit, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import { AppConfigSnapshot } from "../types/persisted";
import { CollectionName, createConverter } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

const appConfigSnapshotConverter = createConverter<AppConfigSnapshot>();

export const useAppConfigSnapshot = (params: { projectId: string }) => {
  const [values, _loading, error] = useCollectionData<AppConfigSnapshot>(
    query(
      collection(
        firestore,
        CollectionName.PROJECTS,
        params.projectId,
        CollectionName.APP_CONFIG_SNAPSHOTS,
      ).withConverter(appConfigSnapshotConverter),
      orderBy("createdBy.timestamp", "desc"),
      limit(1),
    ),
  );
  const appConfigReceived = useStore((state) => state.appConfigReceived);

  if (error) {
    logService.warn(`Could not fetch app config snapshot: ${error.message}`);
  }

  useEffect(() => {
    if (values && values.length === 1) {
      appConfigReceived({
        appConfig: values[0],
      });
    }
  }, [values]);

  return { appConfigSnapshot: values && values.length > 0 ? values[0] : null };
};
