import { useDocumentData } from "react-firebase-hooks/firestore";
import { ProjectEvent } from "../types/persisted";
import { createProjectEventDocRef } from "../utils/collections/project-events";

export const useProjectEvent = (params: { refPath: string }) => {
  const [event] = useDocumentData<ProjectEvent>(
    createProjectEventDocRef({ refPath: params.refPath }),
  );

  return { event };
};
