import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Timestamp } from "firebase/firestore";

dayjs.extend(utc);

export type TimestampFormat =
  | "HH:mm on MMMM Do"
  | "YYYY-MM-DD"
  | "HH:mm:ss"
  | "DD.MM.YY HH:mm"
  | "YYYY-MM-DD HH:mm:ss"
  | "YYYY-MM-DD HH:mm";

interface BaseParams {
  format?: TimestampFormat;
  showAsRelative?: boolean;
  asUtc?: boolean;
}

const getDurationInHours = (params: {
  from: Timestamp;
  to: dayjs.Dayjs;
}): number =>
  Math.abs(dayjs(params.from.toDate()).diff(params.to.toDate(), "hours"));

/**
 * Setting 'showAsRelative' to true will show the timestamp as relative to current time if the duration
 * between the timestamp and the current time is less than 24 hours.
 */
export const getVerboseTimestamp = ({
  showAsRelative = false,
  timestamp,
  format = "YYYY-MM-DD HH:mm:ss",
  asUtc = false,
}: {
  timestamp: Timestamp;
} & BaseParams): string => {
  if (showAsRelative) {
    const now = dayjs();
    const durationInHours = getDurationInHours({
      from: timestamp,
      to: now,
    });

    if (durationInHours < 24) {
      return dayjs(timestamp.toDate()).fromNow();
    }
  }

  return asUtc
    ? dayjs(timestamp.toDate()).utc().format(format)
    : dayjs(timestamp.toDate()).format(format);
};

export const getBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
