import { useRef, useState } from "react";
import { Button } from "../../ui/Button";
import { Flex } from "../../ui/Layout/Flex";
import { H2 } from "../../ui/Typography";
import { Txt } from "../../ui/TypographyV2/TypographyV2";
import { ModalDialog } from "../ModalDialog";
import { ConvertViewsDialog } from "./ConvertViewsDialog";

export const ConvertViewsButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialFocusRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Flex
        gap="3"
        css={{
          padding: "$4 $3",
          width: "600px",
          borderRadius: "4px",
          backgroundColor: "$gray8BoardBackground",
          marginBottom: "$2",
        }}
      >
        <Flex direction="vertical">
          <H2>Convert views to pages</H2>

          <Txt>Do you want to convert all views to pages?</Txt>
        </Flex>

        <Button
          css={{ alignSelf: "flex-end" }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Convert views into pages
        </Button>

        <ModalDialog
          isOpen={isOpen}
          initialFocus={initialFocusRef}
          title="Convert views to pages"
          content={
            <ConvertViewsDialog onCancel={closeModal} onSuccess={closeModal} />
          }
          onClose={closeModal}
        />
      </Flex>
    </>
  );
};
