import { faSparkles, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { createRef, memo, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useComponentState } from "../../store/slices/component-slice";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";
import { useUserGuardContext } from "../UserGuard/UserGuard";
import { DatabutlerButton } from "./components/DatabutlerButton";
import { MemoizedMessage } from "./components/Message";
import { MessageForm } from "./components/MessageForm";
import {
  DatabutlerDialog,
  DatabutlerMessageHistory,
  DatabutlerWrapper,
} from "./components/Ui";

export const Assistant = () => {
  const { componentId } = useWorkspaceContext();
  const componentState = useComponentState(componentId);
  const [isOpen, setIsOpen] = useState(true);
  const messageHistoryRef = useRef<HTMLDivElement>(null);
  const { user } = useUserGuardContext();

  useEffect(() => {
    if (messageHistoryRef.current) {
      messageHistoryRef.current.scrollTop =
        messageHistoryRef.current.scrollHeight;
    }
  }, [componentState?.chatHistory]);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const databutlerRef = createRef<HTMLDivElement>();
  useOnClickOutside(databutlerRef, handleClickOutside);

  const toggleDatabutler = () => {
    setIsOpen((prev) => !prev);
  };

  const visibleChatHistory = useMemo(
    () => componentState?.chatHistory.filter((it) => !it.hidden) ?? [],
    [componentState?.chatUpdatedAt],
  );

  return (
    <DatabutlerWrapper ref={databutlerRef}>
      <DatabutlerButton
        type="button"
        onClick={toggleDatabutler}
        state={isOpen ? "open" : "closed"}
      >
        <StyledFontAwesomeIcon icon={faSparkles} />
        Databutler
        {isOpen && <StyledFontAwesomeIcon icon={faXmark} />}
      </DatabutlerButton>

      {isOpen && (
        <DatabutlerDialog>
          {visibleChatHistory.length > 0 && (
            <DatabutlerMessageHistory ref={messageHistoryRef}>
              {visibleChatHistory.map((it, index) => (
                <MemoizedMessage
                  key={`${it.role}-${index}`}
                  message={it}
                  user={user}
                />
              ))}
            </DatabutlerMessageHistory>
          )}

          <MessageForm componentId={componentId} />
        </DatabutlerDialog>
      )}
    </DatabutlerWrapper>
  );
};

export const MemoizedAssistant = memo(Assistant);
