import {
  doc,
  DocumentReference,
  getCountFromServer,
  query,
  updateDoc,
} from "firebase/firestore";
import { logService } from "../../services/log-service";
import { Job } from "../../types/persisted";
import { firestore } from "../firebase";
import { createDeploymentCollectionRef } from "./deployments";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

export const jobConverter = createConverter<Job>();

/** Create path to job collection */
export const createJobsCollectionRefPath = (params: {
  projectId: string;
}): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.JOBS,
  ]);

const createJobDocRef = (params: { refPath: string }): DocumentReference<Job> =>
  doc(firestore, params.refPath).withConverter(jobConverter);

export const updateJob = async (params: {
  refPath: string;
  payload: Partial<Job>;
}) => {
  await updateDoc(createJobDocRef({ refPath: params.refPath }), params.payload);
};

export const isJobScheduled = async (params: {
  job: Job;
}): Promise<boolean> => {
  logService.debug(`Checking if job is published: ${params.job.refPath}`);
  const result = await getCountFromServer(
    query(
      createDeploymentCollectionRef({
        componentRefPath: params.job.refPath,
      }),
    ),
  );

  return result.data().count > 0;
};
