import { styled } from "../../../stitches.config";

export const ButtonGroup = styled("div", {
  display: "flex",

  "& > button": {
    borderRadius: 0,
  },

  "& > :first-child": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderRight: "1px solid $mainBorder",

    "&:hover": {
      borderRight: "1px solid $mainBorder",
    },
  },

  "& > :last-child": {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderLeft: "1px solid $mainBorder",

    "&:hover": {
      borderLeft: "1px solid $mainBorder",
    },
  },
});
