import { styled } from "../../../stitches.config";

export const Label = styled("label", {
  display: "flex",
  flexFlow: "column",
  fontWeight: "$interRegular400",
  gap: "$1",
  fontSize: "$1",

  variants: {
    color: {
      light: {
        color: "$white",
      },
      dark: {
        color: "$primaryDark",
      },
    },
  },
});
