import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { AppOrMultipageApp } from "../components/AppGuard/AppGuard";
import { createConverter } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const appOrMultipageAppConverter = createConverter<AppOrMultipageApp>();

export const useAppOrMultipageAppByRef = (refPath?: string) => {
  const [app, loading, error] = useDocumentData<AppOrMultipageApp>(
    refPath
      ? doc(firestore, refPath).withConverter(appOrMultipageAppConverter)
      : null,
  );

  return { app, loading, error };
};
