import ky from "ky";
import { addRequestIdToSentry } from "../../services/dbtn-api";

/**
 * TODO: Move to auth-utils.ts
 */
export const postIdToken = async (idToken: string) => {
  await ky.post("https://cdn.databutton.com/_auth/login/success", {
    headers: {
      "content-type": "application/json",
    },
    credentials: "include",
    json: {
      id_token: idToken,
    },
    hooks: {
      beforeError: [addRequestIdToSentry],
    },
  });
};
