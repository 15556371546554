import { IFrameWrapper } from "../../components/IFrameWrapper/IFrameWrapper";
import { useProjectPageContext } from "../../components/ProjectWrapper/ProjectWrapper";
import { getProjectPath } from "../../utils/project-utils";

export const NotebookView = () => {
  const { project } = useProjectPageContext();

  const notebookEndpoint = getProjectPath({
    projectId: project.id,
    route: "/dbtn/devx/jupyter",
  });

  return (
    <IFrameWrapper
      title="Notebook"
      loadingMessage="Loading notebook..."
      src={notebookEndpoint}
    />
  );
};
