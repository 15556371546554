import { Button } from "../../ui/Button";
import { Flex } from "../../ui/Layout/Flex";
import { useViewers } from "./useViewers";
import { revokeAccessForViewer } from "./utils";

interface Props {
  projectId: string;
  appId: string;
}

export const ListViewers = ({ projectId, appId }: Props) => {
  const { viewers } = useViewers({
    projectId,
    appId,
  });

  return viewers.length > 0 ? (
    <Flex
      direction="vertical"
      gap="1"
      css={{ maxHeight: "130px", overflowY: "auto" }}
    >
      {viewers.map((it) => (
        <Flex key={it.id} css={{ justifyContent: "space-between" }}>
          {it.recipient}
          <Button
            intent="plain"
            type="button"
            onClick={() => {
              revokeAccessForViewer({
                projectId,
                appId,
                viewerId: it.id,
              });
            }}
          >
            Remove
          </Button>
        </Flex>
      ))}
    </Flex>
  ) : null;
};
