import { ComponentProps, lazy, Suspense } from "react";

const TableExplorer = lazy(() => import("./TableExplorer"));

type Props = ComponentProps<typeof TableExplorer>;

export const TableExplorerLazy = (props: Props) => (
  <Suspense fallback={<>...</>}>
    <TableExplorer {...props} />
  </Suspense>
);
