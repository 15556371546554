import { useUserGuardContext } from "../../../../components/UserGuard/UserGuard";
import { Job } from "../../../../types/persisted";
import { scheduleJob } from "../../../../utils/job-utils";
import { SimpleScheduleButton } from "../../EditJobForm/SimpleScheduleButton";

interface Props {
  projectId: string;
  job: Job;
}

export const NotScheduledActions = ({ projectId, job }: Props) => {
  const { user } = useUserGuardContext();

  return (
    <SimpleScheduleButton
      size="large"
      onSubmit={async (cronExpression, scheduleForm) => {
        await scheduleJob({
          projectId,
          job,
          cronExpression,
          user,
          scheduleForm,
        });
      }}
      existingSchedule={null}
    />
  );
};
