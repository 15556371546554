import { styled } from "../../../stitches.config";

export const Box = styled("div", {
  variants: {
    width: {
      full: {
        width: "100%",
      },
      large: {
        width: "800px",
      },
      medium: {
        width: "400px",
      },
      small: {
        width: "200px",
      },
    },
  },
});
