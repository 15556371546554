import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Viewer } from "../../types/persisted";
import { viewerConverter } from "../../utils/collections/viewers";
import { firestore } from "../../utils/firebase";

export const useViewers = (params: { collectionPath: string | null }) => {
  const [viewers] = useCollectionData<Viewer>(
    params.collectionPath
      ? query(
          collection(firestore, params.collectionPath),
          where("revoked", "==", false),
        ).withConverter(viewerConverter)
      : null,
  );

  return { viewers: viewers ?? [] };
};
