import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AppGuard } from "./components/AppGuard/AppGuard";
import { CodeDiff } from "./components/CodeDiff/CodeDiff";
import { ProjectCommandPalette } from "./components/ProjectCommandPalette/ProjectCommandPalette";
import { ProjectGuard } from "./components/ProjectGuard/ProjectGuard";
import { ProjectWrapper } from "./components/ProjectWrapper/ProjectWrapper";
import { TopBar } from "./components/TopBar";
import { UserGuard } from "./components/UserGuard/UserGuard";
import { LoginPageFallback } from "./GradientFallback";
import { useHomePageContent } from "./hooks/useHomePageContent";
import { AdminGuard } from "./pages/AdminPage/AdminGuard";
import { BuildAppPage } from "./pages/BuildAppPage/BuildAppPage";
import { BuildJobPage } from "./pages/BuildJobPage/BuildJobPage";
import { BuildPagePage } from "./pages/BuildPagePage/BuildPagePage";
import HomePage from "./pages/HomePage/HomePage";
import { DataframeView } from "./pages/ProjectPage/DataframeView";
import { LandingView } from "./pages/ProjectPage/LandingView/LandingView";
import { MonitorJobView } from "./pages/ProjectPage/MonitorJobPage/MonitorJobView";
import { NotebookView } from "./pages/ProjectPage/NotebookView";
import { ProjectEventsView } from "./pages/ProjectPage/ProjectEventsView/ProjectEventsView";
import { logService } from "./services/log-service";
import { useStore } from "./store/store";
import { SuspenseWrapper } from "./SuspenseWrapper";
import { Div } from "./ui/Layout";

const BuildModulePage = lazy(
  () => import("./pages/BuildModulePage/BuildModulePage"),
);
const MonitorViewPage = lazy(
  () => import("./features/monitor-view/MonitorViewPage"),
);
const ConfigureView = lazy(
  () => import("./pages/ProjectPage/ConfigureView/ConfigureView"),
);
const PackageInstallLogsView = lazy(
  () =>
    import("./pages/ProjectPage/PackageInstallLogsView/PackageInstallLogsView"),
);

const DatafileWrapper = lazy(
  () => import("./pages/ProjectPage/DatafileView/DatafileWrapper"),
);
const DatafileJsonView = lazy(
  () => import("./pages/ProjectPage/DatafileView/DatafileJsonView"),
);
const DatafileTxtView = lazy(
  () => import("./pages/ProjectPage/DatafileView/DatafileTxtView"),
);
const DatafileBinaryView = lazy(
  () => import("./pages/ProjectPage/DatafileView/DatafileBinaryView"),
);
const DatafileFallbackView = lazy(
  () => import("./pages/ProjectPage/DatafileView/DatafileFallbackView"),
);

const MagicLinkSentPage = lazy(
  () => import("./pages/LoginPage/MagicLinkSentPage"),
);
const SignUpPage = lazy(() => import("./pages/LoginPage/SignUpPage"));
const LoginAndSignUpPage = lazy(
  () => import("./pages/LoginPage/LoginAndSignUpPage"),
);
const MagicLinkContinuePage = lazy(
  () => import("./pages/LoginPage/MagicLinkContinuePage"),
);
const CompleteProfilePage = lazy(
  () => import("./pages/LoginPage/CompleteProfilePage"),
);
const WaitlistPage = lazy(() => import("./pages/LoginPage/WaitlistPage"));

const LogoutPage = lazy(() => import("./pages/LogoutPage/LogoutPage"));
const UiPage = lazy(() => import("./pages/UiPage/UiPage"));
const ExperimentingPage = lazy(
  () => import("./pages/ExperimentingPage/ExperimentingPage"),
);
const ProjectPage = lazy(() => import("./pages/ProjectPage/ProjectPage"));
const AdminPage = lazy(() => import("./pages/AdminPage/AdminPage"));
const ProfileEditPage = lazy(() => import("./pages/AdminPage/ProfileEditPage"));
const FeatureFlagPage = lazy(() => import("./pages/AdminPage/FeatureFlagPage"));
const FeatureFlagFormPage = lazy(
  () => import("./pages/AdminPage/FeatureFlagFormPage"),
);
const UserSegmentFormPage = lazy(
  () => import("./pages/AdminPage/UserSegmentFormPage"),
);
const AdminPackagesPage = lazy(
  () => import("./pages/AdminPage/AdminPackagesPage"),
);
const ProjectTemplatesPage = lazy(
  () => import("./pages/AdminPage/ProjectTemplatesPage"),
);
const PublishedViewPage = lazy(
  () => import("./pages/PublishedViewPage/PublishedViewPage"),
);
const RedirectPage = lazy(() => import("./pages/RedirectPage/RedirectPage"));

const App = () => {
  const user = useStore((state) => state.auth.user);

  useEffect(() => {
    const unsub = useStore.subscribe((state, prevState) => {
      if (prevState.project.projectId !== state.project.projectId) {
        logService.debug(`Project changed to: ${state.project.projectId}`);
      }
    });
    return unsub;
  }, []);

  useHomePageContent(user);

  return (
    <Div
      css={{
        $$topBarHeight: "45px",
      }}
    >
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    {/* Do not lazy load home page */}
                    <HomePage />
                  </UserGuard>
                </>
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <LoginAndSignUpPage />
              </Suspense>
            }
          />
          <Route
            path="/login/link-sent"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <MagicLinkSentPage />
              </Suspense>
            }
          />

          <Route
            path="/sign-up"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <SignUpPage />
              </Suspense>
            }
          />
          <Route
            path="/waitlist"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <WaitlistPage />
              </Suspense>
            }
          />
          <Route
            path="/sign-up/link-sent"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <MagicLinkSentPage />
              </Suspense>
            }
          />
          <Route
            path="/complete-sign-in"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <MagicLinkContinuePage />
              </Suspense>
            }
          />
          <Route
            path="/complete-profile"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <CompleteProfilePage />
              </Suspense>
            }
          />

          <Route
            path="/logout"
            element={
              <Suspense fallback={<LoginPageFallback />}>
                <LogoutPage />
              </Suspense>
            }
          />

          <Route
            path="/v/:shortUrlId/"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar showProjectEvents={true} />
                  <AppGuard>
                    <PublishedViewPage />
                  </AppGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/v/:shortUrlId/:pageSlug"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar showProjectEvents={true} />
                  <AppGuard>
                    <PublishedViewPage />
                  </AppGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/r/:shortUrlId"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <RedirectPage />
                </>
              </Suspense>
            }
          />

          <Route
            path="/projects/:projectId"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar shouldShowDocs={true} showProjectEvents={true} />
                  {/* Uses user guard within project guard */}
                  <ProjectGuard>
                    <ProjectWrapper>
                      <ProjectPage />
                      <ProjectCommandPalette />
                    </ProjectWrapper>
                  </ProjectGuard>
                </>
              </Suspense>
            }
          >
            {/* TODO: Add lazy loading of components */}
            <Route
              path="configure"
              element={
                <SuspenseWrapper>
                  <ConfigureView />
                </SuspenseWrapper>
              }
            />
            <Route
              path="install-logs/:requirementsId"
              element={
                <SuspenseWrapper>
                  <PackageInstallLogsView />
                </SuspenseWrapper>
              }
            />
            <Route path="apps/:appIdOrSlug/build" element={<BuildAppPage />} />
            <Route
              path="pages/:pageIdOrSlug/build"
              element={<BuildPagePage />}
            />
            <Route
              path="apps/:appIdOrSlug/monitor"
              element={<MonitorViewPage />}
            />
            <Route
              path="libraries/:moduleIdOrSlug/build"
              element={<BuildModulePage />}
            />
            <Route
              path="dataframes/:dataframeSlug"
              element={<DataframeView />}
            />
            <Route
              path="jobs/:jobIdOrSlug/monitor"
              element={<MonitorJobView />}
            />
            <Route path="jobs/:jobIdOrSlug/build" element={<BuildJobPage />} />
            <Route
              path="code-blocks/:codeBlockId/diff/:leftAndRight"
              element={<CodeDiff />}
            />
            <Route path="notebook" element={<NotebookView />} />
            <Route path="events" element={<ProjectEventsView />} />
            <Route
              path="datafiles/:datafileIdOrSlug"
              element={
                <SuspenseWrapper>
                  <DatafileWrapper />
                </SuspenseWrapper>
              }
            >
              <Route
                path="json"
                element={
                  <SuspenseWrapper>
                    <DatafileJsonView />
                  </SuspenseWrapper>
                }
              />
              <Route
                path="text"
                element={
                  <SuspenseWrapper>
                    <DatafileTxtView />
                  </SuspenseWrapper>
                }
              />
              <Route
                path="binary"
                element={
                  <SuspenseWrapper>
                    <DatafileBinaryView />
                  </SuspenseWrapper>
                }
              />
              <Route
                index={true}
                element={
                  <SuspenseWrapper>
                    <DatafileFallbackView />
                  </SuspenseWrapper>
                }
              />
            </Route>

            <Route index={true} element={<LandingView />} />
          </Route>

          <Route
            path="/ui"
            element={
              <Suspense fallback={<>...</>}>
                <UiPage />
              </Suspense>
            }
          />

          <Route
            path="/experimenting"
            element={
              <Suspense fallback={<>...</>}>
                <ExperimentingPage />
              </Suspense>
            }
          />

          <Route
            path="/admin/packages"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <AdminPackagesPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/templates"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <ProjectTemplatesPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/self-service"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <AdminPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/profiles/:profileId/edit"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <ProfileEditPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/feature-flags"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <FeatureFlagPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/feature-flags/create-or-update"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <FeatureFlagFormPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/feature-flags/create-or-update/:featureFlag"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <FeatureFlagFormPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/user-segments/create-or-update"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <UserSegmentFormPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />

          <Route
            path="/admin/user-segments/create-or-update/:userSegment"
            element={
              <Suspense fallback={<>...</>}>
                <>
                  <TopBar />
                  <UserGuard>
                    <AdminGuard>
                      <UserSegmentFormPage />
                    </AdminGuard>
                  </UserGuard>
                </>
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </Div>
  );
};

export default App;
