import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import {
  getModuleCollectionKey,
  moduleConverter,
} from "../utils/collections/modules";
import { firestore } from "../utils/firebase";

export const useModulesSubscription = ({
  projectId,
  shouldFetch,
}: { projectId: string; shouldFetch: boolean }) => {
  const modulesReceived = useStore((state) => state.modulesReceived);

  const [modules, _loading, error] = useCollectionData(
    shouldFetch
      ? query(
          collection(firestore, getModuleCollectionKey({ projectId })),
          where("markedForDeletionBy", "==", null),
        ).withConverter(moduleConverter)
      : null,
  );

  useEffect(() => {
    modulesReceived({ items: modules ?? [] });
  }, [modules]);

  if (error) {
    logService.warn(error.message);
  }
};
