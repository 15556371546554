import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useStore } from "../../store/store";
import { createAppCollectionRef } from "../../utils/collections/apps";
import { DEFAULT_MULTIPAGE_APP_KEY } from "../../utils/collections/multipage-apps";
import {
  CollectionName,
  createCollectionRefPath,
} from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";

const countApps = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      createAppCollectionRef({ projectId }),
      where("markedForDeletionAt", "==", null),
    ),
  );

  return response.data().count;
};

const countJobs = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      collection(
        firestore,
        createCollectionRefPath([
          CollectionName.PROJECTS,
          projectId,
          CollectionName.JOBS,
        ]),
      ),
      where("markedForDeletionAt", "==", null),
    ),
  );

  return response.data().count;
};

const countDataframes = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      collection(
        firestore,
        createCollectionRefPath([
          CollectionName.PROJECTS,
          projectId,
          CollectionName.DATAFRAMES,
        ]),
      ),
      where("markedForDeletionAt", "==", null),
    ),
  );

  return response.data().count;
};

const countPages = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      collection(
        firestore,
        createCollectionRefPath([
          CollectionName.PROJECTS,
          projectId,
          CollectionName.MULTIPAGE_APPS,
          DEFAULT_MULTIPAGE_APP_KEY,
          CollectionName.PAGES,
        ]),
      ),
      where("markedForDeletionBy", "==", null),
    ),
  );

  return response.data().count;
};

const countDatafiles = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      collection(
        firestore,
        createCollectionRefPath([
          CollectionName.PROJECTS,
          projectId,
          CollectionName.DATAFILES,
        ]),
      ),
      where("markedForDeletionAt", "==", null),
    ),
  );

  return response.data().count;
};

const countModules = async (projectId: string) => {
  const response = await getCountFromServer(
    query(
      collection(
        firestore,
        createCollectionRefPath([
          CollectionName.PROJECTS,
          projectId,
          CollectionName.MODULES,
        ]),
      ),
      where("markedForDeletionBy", "==", null),
    ),
  );

  return response.data().count;
};

export interface ProjectStats {
  appCount: number;
  jobCount: number;
  dataframeCount: number;
  datafileCount: number;
  pageCount: number;
  moduleCount: number;
}

export const useProjectStats = (projectId: string) => {
  const projectActions = useStore((state) => state.projectActions);

  useEffect(() => {
    Promise.all([
      countApps(projectId),
      countJobs(projectId),
      countDatafiles(projectId),
      countDataframes(projectId),
      countPages(projectId),
      countModules(projectId),
    ]).then(
      ([
        appCount,
        jobCount,
        datafileCount,
        dataframeCount,
        pageCount,
        moduleCount,
      ]) => {
        projectActions.statsChanged({
          projectId,
          stats: {
            appCount,
            jobCount,
            datafileCount,
            dataframeCount,
            pageCount,
            moduleCount,
          },
        });
      },
    );
  }, [projectId]);
};
