import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "../../../../stitches.config";
import { Project } from "../../../types/persisted";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Popover, PopoverButton, PopoverLink } from "../../../ui/Popover";
import {
  canShareProject,
  canEditProject,
  canDeleteProject,
} from "../../../utils/permission-utils";
import { RoutePath } from "../../../utils/route-utils";
import { DeleteProjectModal } from "../../DeleteProjectModal/DeleteProjectModal";
import { IconButton } from "../../IconButton";
import { InviteCollaboratorsModal } from "../../InviteCollaboratorsModal/InviteCollaboratorsModal";
import { useUserGuardContext } from "../../UserGuard/UserGuard";

interface Props {
  project: Project;
}

const PopoverIconButton = styled(IconButton, {
  defaultVariants: {
    intent: "plain",
  },
});

export const ProjectCardPopover = memo(
  ({ project }: Props) => {
    const { user } = useUserGuardContext();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();

    const showActions = useMemo(
      () =>
        canShareProject({ project, user }) ||
        canEditProject({ project, user }) ||
        canDeleteProject({ project, user }),
      [project, user],
    );

    const showShareButton = useMemo(
      () => canShareProject({ project, user }),
      [project, user],
    );

    const showEditButton = useMemo(
      () => canEditProject({ project, user }),
      [project, user],
    );

    const showDeleteButton = useMemo(
      () => canDeleteProject({ project, user }),
      [project, user],
    );

    const closeShareModal = () => {
      setIsShareModalOpen(false);
    };

    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
    };

    const handleDeleteSuccess = () => {
      navigate(RoutePath.HOME);
    };

    return showActions ? (
      <>
        <Popover
          buttonAs={PopoverIconButton}
          buttonContent={<StyledFontAwesomeIcon icon={faEllipsis} />}
          panelContent={
            <>
              {showShareButton && (
                <PopoverButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsShareModalOpen(true);
                  }}
                >
                  Invite collaborators
                </PopoverButton>
              )}

              {showEditButton && (
                <PopoverLink to={`/projects/${project.id}/configure`}>
                  Edit data app details
                </PopoverLink>
              )}

              {showDeleteButton && (
                <PopoverButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete data app
                </PopoverButton>
              )}
            </>
          }
        />

        <InviteCollaboratorsModal
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          project={project}
        />

        <DeleteProjectModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onSuccess={handleDeleteSuccess}
          project={project}
        />
      </>
    ) : null;
  },
  (prev, next) => prev.project.id === next.project.id,
);
