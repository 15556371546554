import { User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { CodeBlock, CodeBlockVersion } from "../types/persisted";
import {
  fetchCodeBlock,
  getCodeBlockCollectionKey,
  resolveComponentRefPath,
  updateCodeBlock,
} from "./collections/code-blocks";
import { createDocRefPath } from "./collections/shared";
import { firestore } from "./firebase";
import { howLongSinceInMinutes } from "./time-utils";
import { createPerformedByObj } from "./user-utils";

export const takeControlOverCodeBlock = async (
  params:
    | {
        projectId: string;
        codeBlockId: string;
        user: User;
      }
    | {
        refPath: string;
        user: User;
      },
): Promise<void> => {
  const takeControlRequest = {
    controlTakenBy: createPerformedByObj({ user: params.user }),
  } satisfies Pick<CodeBlock, "controlTakenBy">;

  await updateCodeBlock({
    refPath:
      "refPath" in params
        ? params.refPath
        : createDocRefPath([
            getCodeBlockCollectionKey(params.projectId),
            params.codeBlockId,
          ]),
    payload: takeControlRequest,
  });
};

export const isInControlOfCodeBlock = (params: {
  codeBlock: CodeBlock;
  user: User;
}): boolean => {
  if (params.user.uid === params.codeBlock.controlTakenBy?.id) {
    return true;
  }

  // Consider code block up for grabs if no changes have been made in 10 minutes
  if (
    params.codeBlock.controlTakenBy &&
    Math.abs(
      howLongSinceInMinutes(params.codeBlock.controlTakenBy.timestamp),
    ) >= 10
  ) {
    return true;
  }

  // Backwards compatibility with code blocks that never have been taken control over
  if (!params.codeBlock.controlTakenBy) {
    return true;
  }

  return false;
};

/**
 * Temporary until we have name on all latest code block versions.
 * This should only run if the latest code block version doesn't already have a name.
 * That should be max 1 run per code block (running migration)
 */
export const resolveCodeBlockVersionName = async (
  latestCodeBlockVersion: CodeBlockVersion,
): Promise<string> => {
  if (latestCodeBlockVersion.name) {
    return latestCodeBlockVersion.name;
  }

  const codeBlockRefPath =
    latestCodeBlockVersion.refPath.split("/versions/")[0];

  const codeBlock = await fetchCodeBlock({ refPath: codeBlockRefPath });

  if (!codeBlock) {
    throw new Error(`Could not find code block: ${codeBlockRefPath}`);
  }

  const componentRefPath = resolveComponentRefPath({ codeBlock });

  const componentDoc = await getDoc(doc(firestore, componentRefPath));

  const component = componentDoc.data() as { name: string; slug: string };

  if (!component) {
    throw new Error(`Could not find component: ${componentRefPath}`);
  }

  if (codeBlock.type === "module") {
    return component.name;
  }

  return component.slug;
};

export const getCodeBlockIdFromRefPath = (refPath: string): string => {
  const id = refPath.split("/").pop();
  if (id) {
    return id;
  }

  throw new Error(`Could not resolve code block id from ref path: ${refPath}`);
};
