import { useState } from "react";
import { ProjectTemplate } from "../../types/persisted";
import { Grid } from "../../ui/Layout/Grid";
import { ProjectTemplateButton } from "./components/ProjectTemplateButton";
import { createProject } from "./utils";

interface Props {
  projectTemplates: ProjectTemplate[];
  onSuccess: (params: { projectId: string; projectTemplateId: string }) => void;
}

export const NewProjectForm = ({ onSuccess, projectTemplates }: Props) => {
  const [isPublishing, setIsPublishing] = useState(false);

  const submit = async (template: ProjectTemplate) => {
    setIsPublishing(true);

    const { projectId, projectTemplateId } = await createProject({
      name: template.name,
      projectTemplateId: template.id,
    });

    setIsPublishing(false);

    onSuccess({
      projectId,
      projectTemplateId,
    });
  };

  return (
    <Grid
      css={{
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: "$2",
        rowGap: "$2",
        padding: "$1",
      }}
    >
      {projectTemplates.map((it) => (
        <ProjectTemplateButton
          illustrationSrc={it.illustrationSrc ?? null}
          disabled={isPublishing}
          key={it.id}
          template={it}
          onClick={submit}
        />
      ))}
    </Grid>
  );
};
