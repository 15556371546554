import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { Timestamp } from "firebase/firestore";

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(advancedFormat);

export const howLongSince = (timestamp: Timestamp): string =>
  dayjs().to(timestamp.toDate());

export const convertMillisToReadable = (
  millis: number,
  fractionDigits: number = 3,
): string => {
  const result = dayjs.duration(millis);

  const minutes = result.minutes();
  if (minutes < 1) {
    return `${result.asSeconds().toFixed(fractionDigits).toString()}s`;
  }

  if (minutes >= 1 && minutes < 60) {
    const rest = dayjs.duration(millis - minutes * 60 * 1000);
    return `${minutes}m ${rest.format("s")}s`;
  }

  return `${result.asMinutes().toString()}m`;
};

export const isAfter = (params: {
  timestamp: Timestamp;
  reference: Timestamp;
}): boolean =>
  dayjs(params.timestamp.toDate()).isAfter(params.reference.toDate());

export const howLongSinceInMinutes = (timestamp: Timestamp): number =>
  dayjs(timestamp.toDate()).diff(new Date(), "minutes");

export const getPastTimestamp = (params: {
  hours: number;
}): Timestamp =>
  Timestamp.fromDate(
    dayjs(new Date()).subtract(params.hours, "hours").toDate(),
  );
