import { RunLog } from "../../types/persisted";
import { CollectionName, createConverter } from "./shared";

export const runLogConverter = createConverter<RunLog>();

export const getRunRef = (params: {
  projectId: string;
  runId: string;
}): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.RUNS}/${params.runId}`;

export const getDevRunRef = (params: {
  projectId: string;
  jobId: string;
  runId: string;
}): string =>
  `/${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.JOBS}/${params.jobId}/${CollectionName.DEVRUNS}/${params.runId}`;
