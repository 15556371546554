import { collection, CollectionReference } from "firebase/firestore";
import {
  AppDeploymentRequest,
  JobDeploymentRequest,
  WithoutId,
} from "../../types/persisted";
import { firestore } from "../firebase";
import { AppRef } from "./apps";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

const appDeploymentRequestConverter = createConverter<AppDeploymentRequest>();

const jobDeploymentRequestConverter = createConverter<JobDeploymentRequest>();

export const createAppDeploymentRequestCollectionRef = (
  params: AppRef,
): CollectionReference<WithoutId<AppDeploymentRequest>> =>
  collection(
    firestore,
    "refPath" in params
      ? createCollectionRefPath([
          params.refPath,
          CollectionName.DEPLOYMENT_REQUESTS,
        ])
      : createCollectionRefPath([
          CollectionName.PROJECTS,
          params.projectId,
          CollectionName.APPS,
          params.appId,
          CollectionName.DEPLOYMENT_REQUESTS,
        ]),
  ).withConverter(appDeploymentRequestConverter);

export const createJobDeploymentRequestCollectionRef = (params: {
  projectId: string;
  jobId: string;
}): CollectionReference<WithoutId<JobDeploymentRequest>> =>
  collection(
    firestore,
    createCollectionRefPath([
      CollectionName.PROJECTS,
      params.projectId,
      CollectionName.JOBS,
      params.jobId,
      CollectionName.DEPLOYMENT_REQUESTS,
    ]),
  ).withConverter(jobDeploymentRequestConverter);
