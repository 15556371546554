import { collection, orderBy, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { runLogConverter } from "../utils/collections/logs";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useLogs = (params: { componentRef?: string }) => {
  const logsCollection = params.componentRef
    ? query(
        collection(firestore, params.componentRef, CollectionName.LOGS),
        orderBy("time", "asc"),
      ).withConverter(runLogConverter)
    : null;
  const [values, _loading, error] = useCollectionData(logsCollection);

  if (error) {
    logService.warn(error.message);
  }

  return { logs: values ?? [] };
};
