import create from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { isLocal, isPrLink } from "../utils/feature-flag-utils";
import { appConfigStore } from "./slices/app-config-slice";
import { appStore } from "./slices/app-slice";
import { authStore } from "./slices/auth-slice";
import { codeStore } from "./slices/code-slice";
import { componentStore } from "./slices/component-slice";
import { homeStore } from "./slices/home-slice";
import { projectStore } from "./slices/project-slice";
import { workspaceStore } from "./slices/workspace-slice";
import { StoreState } from "./types";

export const useStore = create<StoreState>()(
  subscribeWithSelector(
    devtools(
      immer((...a) => ({
        ...appStore(...a),
        ...homeStore(...a),
        ...authStore(...a),
        ...workspaceStore(...a),
        ...codeStore(...a),
        ...projectStore(...a),
        ...componentStore(...a),
        ...appConfigStore(...a),
      })),
      { enabled: isLocal() || isPrLink(), name: "databutton" },
    ),
  ),
);
