import { AppOrMultipageApp } from "../../components/AppGuard/AppGuard";
import { ProjectStats } from "../../components/ProjectCard/hooks";
import { Project, ProjectTemplate } from "../../types/persisted";
import { useStore } from "../store";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

export interface ProjectSummary {
  id: string;
  project: Project;
  relation: "own" | "member";
  stats?: ProjectStats;
}

interface HomeState {
  isInitialized: boolean;
  projects: {
    [key: string]: ProjectSummary;
  };
  appsSharedWithUser: AppOrMultipageApp[];
  projectTemplates: ProjectTemplate[];
}

export interface HomeSlice {
  homeState: HomeState;
  projectActions: {
    projectsReceived: (params: {
      projects: Project[];
      relation: ProjectSummary["relation"];
    }) => void;
    statsChanged: (params: {
      projectId: string;
      stats: ProjectStats;
    }) => void;
    projectDeleted: (params: {
      projectId: string;
    }) => void;
  };
  shareActions: {
    appsSharedReceived: (params: {
      items: AppOrMultipageApp[];
    }) => void;
  };
  projectTemplatesReceived: (params: { items: ProjectTemplate[] }) => void;
}

const sortByName = (a: ProjectSummary, b: ProjectSummary) =>
  a.project.name.localeCompare(b.project.name);

export const useHomeState = () => useStore((state) => state.homeState);

const useProjects = () =>
  useStore((state) => Object.values(state.homeState.projects).sort(sortByName));

export const useOwnProjects = () =>
  useProjects().filter((it) => it.relation === "own");

export const useMemberProjects = () =>
  useProjects().filter((it) => it.relation === "member");

export const useProjectSummary = (projectId: string): ProjectSummary | null =>
  useStore((state) => state.homeState.projects[projectId] ?? null);

const actionType = createActionTypeLogger("home");

export const homeStore: StoreSlice<HomeSlice> = (set, get) => ({
  homeState: {
    isInitialized: false,
    projects: {},
    appsSharedWithUser: [],
    projectTemplates: [],
  },
  projectTemplatesReceived: (params) => {
    set(
      (draft) => {
        draft.homeState.projectTemplates = params.items;
      },
      false,
      actionType("project-templates-received"),
    );
  },
  shareActions: {
    appsSharedReceived: (params) => {
      set(
        (draft) => {
          draft.homeState.appsSharedWithUser = params.items;
        },
        false,
        actionType("apps-shared-received"),
      );
    },
  },
  projectActions: {
    projectsReceived: (params) => {
      const prevProjects = get().homeState.projects;

      set(
        (draft) => {
          draft.homeState.isInitialized = true;

          // Remove old projects in case some were deleted
          Object.values(draft.homeState.projects)
            .filter((it) => it.relation === params.relation)
            .forEach((it) => {
              // rome-ignore lint/performance/noDelete: <explanation>
              delete draft.homeState.projects[it.id];
            });

          params.projects.forEach((it) => {
            const prevProject = prevProjects[it.id];

            const projectSummaryBase = {
              id: it.id,
              project: it,
              relation: params.relation,
            } satisfies Pick<ProjectSummary, "id" | "project" | "relation">;

            // Merge with previous project if project is already in store
            draft.homeState.projects[it.id] = prevProject
              ? {
                  ...prevProject,
                  ...projectSummaryBase,
                }
              : projectSummaryBase;
          });
        },
        false,
        actionType("projects-received"),
      );
    },
    statsChanged: (params) => {
      set(
        (draft) => {
          if (params.projectId in draft.homeState.projects) {
            draft.homeState.projects[params.projectId].stats = params.stats;
          }
        },
        false,
        actionType("stats-changed"),
      );
    },
    projectDeleted: (params) => {
      set(
        (draft) => {
          // rome-ignore lint/performance/noDelete: Allow delete in immer
          delete draft.homeState.projects[params.projectId];
        },
        false,
        actionType("project-deleted"),
      );
    },
  },
});
