import cronstrue from "cronstrue";
import { useMemo } from "react";
import { Schedule } from "../../../../types/persisted";
import { Txt } from "../../../../ui/TypographyV3/TypographyV3";

interface Props {
  schedule: Schedule | null;
}

export const VerboseScheduleText = ({ schedule }: Props) => {
  const parsedCronExpression = useMemo(
    () =>
      schedule
        ? `This job is scheduled to run ${cronstrue
            .toString(schedule.cronExpression, {
              verbose: true,
              use24HourTimeFormat: true,
            })
            .toLowerCase()}`
        : "This job is not scheduled to run.",
    [schedule],
  );

  return <Txt>{parsedCronExpression}</Txt>;
};
