import { collection, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Viewer } from "../../types/persisted";
import {
  getViewersCollectionKey,
  viewerConverter,
} from "../../utils/collections/viewers";
import { firestore } from "../../utils/firebase";

export const useViewers = (params: { projectId: string; appId: string }) => {
  const [viewers] = useCollectionData<Viewer>(
    query(
      collection(
        firestore,
        getViewersCollectionKey({
          projectId: params.projectId,
          appId: params.appId,
        }),
      ),
      where("revoked", "==", false),
    ).withConverter(viewerConverter),
  );

  return { viewers: viewers ?? [] };
};
