import { Div } from "../../ui/Layout";
import { H1 } from "../../ui/Typography";
import { Image } from "../../ui/Image/Image";
import { LoadingAnimation, LoadingAnimationType } from "./LoadingAnimation";

interface Props {
  animation?: LoadingAnimationType | "random";
  message: string;
  fontColor?: string;
  sizeInPx?: number;
  backgroundImageSrc?: string | null;
}

export const LoadingScreen = ({
  animation = LoadingAnimationType.HUNGRY_DASHES,
  message,
  fontColor,
  sizeInPx = 160,
  backgroundImageSrc = null,
}: Props) => (
  <Div
    css={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    }}
  >
    {backgroundImageSrc && (
      <Image
        src={backgroundImageSrc}
        css={{ position: "absolute", width: "100%", top: "90px" }}
      />
    )}

    <Div
      css={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0,
        gap: "$1",
        zIndex: 1,
        backgroundColor: backgroundImageSrc ? "rgba(0, 0, 0, 0.05)" : "inherit",
      }}
    >
      <LoadingAnimation animation={animation} size={`${sizeInPx}px`} />

      {!backgroundImageSrc && (
        <H1 css={{ color: fontColor ?? "inherit", fontSize: "$3" }}>
          {message}
        </H1>
      )}
    </Div>
  </Div>
);
