import { styled } from "../../../../stitches.config";
import {
  AppOrMultipageApp,
  isApp,
} from "../../../components/AppGuard/AppGuard";
import { useAppScreenshotByRef } from "../../../hooks/useAppScreenshot";
import { Flex } from "../../../ui/Layout/Flex";
import { NavigateLink } from "../../../ui/NavigateLink";
import { H4 } from "../../../ui/TypographyV2/TypographyV2";
import { convertToImageSrc } from "../../../utils/collections/screenshots";
import { BaseCard } from "./BaseCard";

interface Props {
  app: AppOrMultipageApp;
}

const Card = styled(BaseCard, {
  height: "140px",
  width: "100%",
  color: "$primaryDark",
});

const AppPreviewContainer = styled("div", {
  height: "80px",
  width: "100%",
  overflow: "hidden",
  borderBottom: "1px solid $mainBorder",
});

const AppPreview = styled("img", {
  width: "100%",
  overflow: "hidden",
});

export const SharedViewCard = ({ app }: Props) => {
  const { appScreenshot } = useAppScreenshotByRef({ appRef: app.refPath });

  return (
    <NavigateLink to={app.shortUrl}>
      <Card>
        <Flex direction="vertical" width="full">
          <AppPreviewContainer>
            {appScreenshot && (
              <AppPreview
                alt={`Preview of ${isApp(app) ? app.name : app.displayName}`}
                src={convertToImageSrc(appScreenshot.base64Image)}
              />
            )}
          </AppPreviewContainer>

          <Flex direction="vertical" css={{ padding: "$1" }}>
            <H4>{isApp(app) ? app.name : app.displayName}</H4>
          </Flex>
        </Flex>
      </Card>
    </NavigateLink>
  );
};
