import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { logService } from "../../services/log-service";
import { CodeBlock, WithoutId } from "../../types/persisted";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import { firestore } from "../firebase";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
  createDocRefPath,
} from "./shared";

export const codeBlockConverter = createConverter<CodeBlock>();

export const getCodeBlockCollectionKey = (projectId: string): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    projectId,
    CollectionName.CODE_BLOCKS,
  ]);

export type CodeBlockRef =
  | { refPath: string }
  | { projectId: string; codeBlockId: string };

export const createCodeBlockRefPath = (params: {
  projectId: string;
  codeBlockId: string;
}): string =>
  [getCodeBlockCollectionKey(params.projectId), params.codeBlockId].join("/");

export const createCodeBlockDocumentRef = (
  params: CodeBlockRef,
): DocumentReference<CodeBlock> => {
  if ("refPath" in params) {
    return doc(firestore, params.refPath).withConverter(codeBlockConverter);
  }

  if ("projectId" in params && "codeBlockId" in params) {
    return doc(
      firestore,
      getCodeBlockCollectionKey(params.projectId),
      params.codeBlockId,
    ).withConverter(codeBlockConverter);
  }

  throw new Error("Could not create code block document ref");
};

const createCodeBlockCollectionRef = (params: {
  projectId: string;
}): CollectionReference<CodeBlock> =>
  collection(
    firestore,
    getCodeBlockCollectionKey(params.projectId),
  ).withConverter(codeBlockConverter);

/**
 * Creates document and ID for a code block document without saving to Firebase
 */
export const createCodeBlockDocument = (params: {
  type: "app" | "job" | "module" | "page";
  variant?: "streamlit";
  componentId: string;
}): {
  id: string;
  doc: WithoutId<CodeBlock>;
} => {
  const id = createDatabuttonId(DatabuttonIdPrefix.CODE_BLOCK);

  const doc = {
    componentId: params.componentId,
    type: params.type,
    createdAtUtc: Timestamp.now(),
    // TODO: Replace 'as WithoutId' with 'satisfies WithoutId' when types have been updated
  } as WithoutId<CodeBlock>;

  return {
    id,
    doc,
  };
};

const fetchCodeBlockByRefPath = async (params: {
  refPath: string;
}): Promise<CodeBlock | null> => {
  const result = await getDoc(
    createCodeBlockDocumentRef({
      refPath: params.refPath,
    }),
  );

  return result.data() ?? null;
};

const fetchCodeBlockByComponentId = async (params: {
  projectId: string;
  componentId: string;
}): Promise<CodeBlock | null> => {
  const result = await getDocs(
    query(
      createCodeBlockCollectionRef({ projectId: params.projectId }),
      where("componentId", "==", params.componentId),
      limit(1),
    ),
  );

  return result.size > 0 ? result.docs[0].data() : null;
};

export const fetchCodeBlock = async (
  params:
    | {
        refPath: string;
      }
    | { projectId: string; componentId: string },
): Promise<CodeBlock | null> => {
  if ("refPath" in params) {
    return fetchCodeBlockByRefPath(params);
  }

  return fetchCodeBlockByComponentId(params);
};

export const resolveComponentRefPath = (params: {
  codeBlock: CodeBlock;
}): string => {
  const projectRefPath = params.codeBlock.refPath.split("/code-blocks/")[0];

  if (params.codeBlock.type === "app") {
    return createDocRefPath([
      projectRefPath,
      CollectionName.APPS,
      params.codeBlock.componentId,
    ]);
  }

  if (params.codeBlock.type === "job") {
    return createDocRefPath([
      projectRefPath,
      CollectionName.JOBS,
      params.codeBlock.componentId,
    ]);
  }

  if (params.codeBlock.type === "module") {
    return createDocRefPath([
      projectRefPath,
      CollectionName.MODULES,
      params.codeBlock.componentId,
    ]);
  }

  throw new Error(
    `Could not resolve component ref path: ${params.codeBlock.refPath}`,
  );
};

export const updateCodeBlock = async (params: {
  refPath: string;
  payload: Partial<WithoutId<CodeBlock>>;
}): Promise<void> => {
  logService.info(`Updating code block: ${params.refPath}`);
  await updateDoc(doc(firestore, params.refPath), params.payload);
};
