import { ReactNode } from "react";
import { styled } from "../../../../stitches.config";

// TODO: Use GP Haptik font here
const Wrapper = styled("div", {
  $$gridCellWidth: "500px",

  position: "fixed",
  inset: 0,
  backgroundColor: "$splashBackground",
  fontFamily: "GT Haptik",
  color: "#031b12",
  fontSize: "16px",
  overflow: "auto",

  // TODO: Add media queries for changing font size
  variants: {
    device: {
      small: {
        $$gridCellWidth: "100%",
        $$pageMargin: "0 2.5em",
        fontSize: "12px",
      },
      medium: {
        $$gridCellWidth: "400px",
        $$pageMargin: "0 3em",
        fontSize: "12px",
      },
      normal: {
        $$gridCellWidth: "500px",
        $$pageMargin: "0 3.75em",
        fontSize: "16px",
      },
    },
  },

  defaultVariants: {
    device: "normal",
  },
});

export const LoginPageWrapper = ({ children }: { children: ReactNode }) => (
  <Wrapper
    device={{
      "@initial": "small",
      "@bp2": "normal",
    }}
  >
    {children}
  </Wrapper>
);
