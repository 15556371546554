import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { HTTPError } from "ky";
import { dbtnApi } from "../services/dbtn-api";

// As we kill devx instantly we don't necessary get any response from devx
const EXPECTED_STATUS_CODE = [502, 429];

export const restartWorkspace = async (params: {
  projectId: string;
  user: User;
}): Promise<void> => {
  try {
    await dbtnApi.post({
      projectId: params.projectId,
      user: params.user,
      route: "/dbtn/devx/status/kill",
    });
  } catch (err) {
    if (err instanceof HTTPError) {
      if (!EXPECTED_STATUS_CODE.includes(err.response.status)) {
        console.log(err);
        Sentry.captureException(err);
      } else {
        console.log("Restarted successfully");
      }
    } else {
      console.log(err);
      Sentry.captureException(err);
    }
  }
};
