import type * as Stitches from "@stitches/react";
import { Link } from "react-router-dom";
import { styled } from "../../../stitches.config";

const linkStyle = {
  $$borderWidth: "1px",

  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$1",
  fontSize: "12px",
  fontWeight: 600,
  height: "30px",
  boxSizing: "border-box",
  width: "fit-content",
  padding: "$1 $2",
  borderRadius: "4px",

  variants: {
    content: {
      icon: {
        padding: "$1 12px",
      },
    },
    intent: {
      primary: {
        backgroundColor: "$darkPurpleSolid",
        color: "$white",
        border: "$$borderWidth solid $darkPurpleSolid",

        "&:hover": {
          backgroundColor: "$primaryPurple",
          border: "$$borderWidth solid $primaryPurple",
        },

        "&:disabled": {
          cursor: "not-allowed",
          color: "$gray5",
          backgroundColor: "$gray6",
          border: "$$borderWidth solid $gray6",
        },
      },
      secondary: {
        backgroundColor: "$backgroundMain",
        color: "$gray2",
        border: "$$borderWidth solid $mainBorder",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },

        "&:disabled": {
          cursor: "not-allowed",
          color: "$gray6",
          backgroundColor: "$backgroundLight",
          border: "$$borderWidth solid $gray6",
        },
      },
      pink: {
        backgroundColor: "#f8f3ff",
        color: "#9747ff",
        border: "$$borderWidth solid #f2ebfc",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },

        "&:disabled": {
          cursor: "not-allowed",
          color: "$gray6",
          backgroundColor: "$backgroundLight",
          border: "$$borderWidth solid $gray6",
        },
      },
      plain: {
        border: "none",
        background: "none",
        color: "$primaryDark",
      },
    },
  },

  defaultVariants: {
    intent: "primary",
  },
} satisfies Stitches.CSS;

export const LinkButton = styled(Link, linkStyle);

export const OutboundLinkButton = styled("a", linkStyle);
