import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { addDoc, collection } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useProjectMembers } from "../../hooks/useProjectMembers";
import { useUser } from "../../hooks/useUser";
import { Project } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { Form } from "../../ui/Form";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";

import { Row } from "../../ui/Layout";
import { Flex } from "../../ui/Layout/Flex";
import { FieldErrorTooltip } from "../../ui/Tooltip";
import { Txt } from "../../ui/TypographyV2/TypographyV2";
import { CollectionName } from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";
import { sleep } from "../../utils/ts-utils";
import { isValidEmailAddress } from "../../utils/validation-utils";
import { ProjectMemberList } from "./ProjectMemberList";

interface Props {
  project: Project;
}

interface RequiredInvitationFields {
  project: {
    id: string;
  };
  from: {
    userId: string;
  };
  to: {
    email: string;
  };
}

interface FormData {
  recipientEmail: string;
}

export const InvitaCollaboratorsForm = ({ project }: Props) => {
  const { user } = useUser();
  const {
    members,
    refetch,
    addMember,
    loading: isLoadingProjectMembers,
  } = useProjectMembers({
    projectId: project.id,
    shouldFetch: true,
  });
  const [isPosting, setIsPosting] = useState(false);
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      recipientEmail: "",
    },
  });

  useEffect(() => {
    setFocus("recipientEmail");
  }, [setFocus]);

  const inviteMember = useCallback(
    async (params: {
      recipientEmail: string;
      projectId: string;
    }): Promise<void> => {
      if (user) {
        setIsPosting(true);

        const invitation: RequiredInvitationFields = {
          project: {
            id: params.projectId,
          },
          from: {
            userId: user.uid,
          },
          to: {
            email: params.recipientEmail,
          },
        };

        const [response] = await Promise.all([
          addDoc(
            collection(firestore, CollectionName.PROJECT_INVITES),
            invitation,
          ),
          // Add 1 second sleep to avoid flickering UI
          sleep(1_000),
        ]);

        if (response) {
          addMember({
            role: "pending",
            invitationId: response.id,
            email: params.recipientEmail,
          });
        }

        setIsPosting(false);
      }
    },
    [user],
  );

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await toast.promise(
      inviteMember({
        recipientEmail: data.recipientEmail,
        projectId: project.id,
      }),
      {
        pending: "Inviting collaborator",
        success: "Invited collaborator",
        error: "Could not invite collaborator",
      },
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row css={{ marginBottom: "$2" }}>
        <Txt>
          Invite collaborators with read & write access to your data app. Need
          more granular access control? Let us know in the chat!
        </Txt>
      </Row>

      <Row
        css={{
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: "$2",
        }}
      >
        <Label css={{ flex: 1 }}>
          Email
          <FieldErrorTooltip text={errors.recipientEmail?.message}>
            <Input
              type="email"
              {...register("recipientEmail", {
                required: "This field is required",
                validate: {
                  isValidEmailCheck: (value) => {
                    return (
                      isValidEmailAddress(value) ?? "Invalid email address"
                    );
                  },
                },
              })}
            />
          </FieldErrorTooltip>
        </Label>

        <Button type="submit" disabled={isPosting}>
          Send invitation
        </Button>
      </Row>

      {isLoadingProjectMembers && members === null ? (
        <Flex gap="1">
          <StyledFontAwesomeIcon icon={faSpinner} pulse={true} />
          <Txt>Loading member list...</Txt>
        </Flex>
      ) : (
        <ProjectMemberList
          members={members}
          refetch={refetch}
          projectId={project.id}
          resendInvitation={async (params) => {
            await toast.promise(inviteMember(params), {
              pending: "Resending invitation",
              success: "Invitation sent",
              error: "Could not send invitation",
            });
          }}
          user={user}
        />
      )}
    </Form>
  );
};
