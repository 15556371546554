import urlSlug from "url-slug";

// Leaning into how Streamlit does this to attempt to have the same behavior when it comes to page names and URLs
// https://github.com/streamlit/streamlit/blob/e7e4e6e0bc9e206a53122622456e4779087cd67b/lib/streamlit/source_util.py#L66-L88
export const createPageSlug = (name: string): string =>
  urlSlug(name, {
    separator: "_",
    // Super simple replacement transformer to keep casing
    transformer: (fragments, separator) => fragments.join(separator),
  });
