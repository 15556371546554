import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { App } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { NavigateLink } from "../../ui/NavigateLink";

interface Props {
  app: App;
}

export const GoToPublishedViewButton = ({ app }: Props) => (
  <NavigateLink to={app.shortUrl} target="_blank">
    <Button intent="plain">
      <StyledFontAwesomeIcon icon={faEye} />
      Open published view
    </Button>
  </NavigateLink>
);
