import { styled } from "../../../stitches.config";

export const Heading = styled("h2", {
  color: "$primaryDark",
  variants: {
    size: {
      small: {
        fontSize: "$2",
        fontWeight: "$big",
      },
      medium: {
        fontSize: "$3",
        fontWeight: "$big",
      },
      large: {
        fontSize: "$5",
        lineHeight: "24px",
        fontWeight: "$big",
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});
