import { faPlay } from "@fortawesome/pro-regular-svg-icons";
import { faStop } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useDevRuns } from "../../../components/JobDevRuns/useDevRuns";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import { dbtnApi } from "../../../services/dbtn-api";
import { createInControlSelector } from "../../../store/slices/code-slice";
import { useIsDevxReady } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Tooltip } from "../../../ui/Tooltip";
import { TERMINATED_A_JOB } from "../../../utils/analytics-constants";

export const RunJobButton = () => {
  const { user } = useUserGuardContext();
  const identifier = useWorkspaceContext();
  const inControl = useStore(createInControlSelector(identifier.codeBlockId));
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const isDevxReady = useIsDevxReady();
  const runJobTriggered = useStore((state) => state.runJobTriggered);

  /**
   * Debounced function to avoid double-clicking
   */
  const runCodeFn = useDebouncedCallback(
    () => {
      runJobTriggered(identifier);
    },
    300,
    {
      leading: true,
      trailing: false,
    },
  );

  const { inProgress: runningDevJobs } = useDevRuns({
    projectId: identifier.projectId,
    jobId: identifier.componentId,
    codeBlockId: identifier.codeBlockId,
    limit: 1,
  });

  return runningDevJobs.length > 0 ? (
    <Tooltip text="Cancel run">
      <Button
        disabled={!(inControl && isDevxReady)}
        intent="primary"
        content="icon"
        onClick={async () => {
          await toast.promise(
            dbtnApi.post({
              projectId: identifier.projectId,
              route: "/dbtn/devx/jobs/kill-dev/",
              user,
              json: {
                jobId: identifier.componentId,
              },
            }),
            {
              success: "Run cancelled",
              error: "Unable to cancel run",
              pending: "Canceling run...",
            },
          );

          pulseTriggered({
            eventName: TERMINATED_A_JOB,
            properties: { ...identifier },
          });
        }}
      >
        <StyledFontAwesomeIcon icon={faStop} />
      </Button>
    </Tooltip>
  ) : (
    <Tooltip text="Run code (shift + enter)">
      <Button
        disabled={!(inControl && isDevxReady)}
        intent="primary"
        content="icon"
        onClick={runCodeFn}
      >
        <StyledFontAwesomeIcon icon={faPlay} />
      </Button>
    </Tooltip>
  );
};
