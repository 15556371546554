import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { saveCode } from "../../domain/code";
import { runCodeAsync } from "../../pages/BuildJobPage/utils";
import { updatePreview } from "../../pages/BuildPagePage/utils";
import { dbtnApi } from "../../services/dbtn-api";
import { logService } from "../../services/log-service";
import { CodeBlock, CodeBlockVersion } from "../../types/persisted";
import { isInControlOfCodeBlock } from "../../utils/code-block-utils";
import { createCodeBlockRefPath } from "../../utils/collections/code-blocks";
import { StoreSlice, StoreState } from "../types";
import { createActionTypeLogger } from "../utils";
import { WorkspaceIdentifier } from "./workspace-slice";

/**
 * Set to true to enable debug logs locally
 */
const DEBUG = false;

const debugLog = (message: string): void => {
  if (DEBUG) {
    logService.debug(`CODE: ${message}`);
  }
};

const actionType = createActionTypeLogger("code");

// https://stackoverflow.com/a/52171480
const cyrb53 = (str: string, seed: number = 0) => {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const computeHash = (value: string): number => cyrb53(value);

interface CodeBlockState {
  isRunningCode: boolean;
  codeBlock: CodeBlock | null;
  inControl: boolean;
  codeBlockVersion: CodeBlockVersion | null;
  code: string;
  hash: number | null;
}

export interface CodeSlice {
  /**
   * Store multiple states to enable navigating between components more seamless
   */
  codeBlocks: {
    [codeBlockId: string]: CodeBlockState;
  };
  codeChanged: (codeBlockId: string, code: string) => void;
  codeBlockChanged: (codeBlock: CodeBlock) => void;
  codeBlockUnmounted: (params: {
    projectId: string;
    codeBlockId: string;
    isDevxReady: boolean;
  }) => void;
  codeBlockVersionChanged: (
    codeBlockId: string,
    codeBlockVersion: CodeBlockVersion,
  ) => void;
  autosaveTriggered: (params: {
    codeBlockId: string;
    projectId: string;
    code: string;
    isDevxReady: boolean;
  }) => void;
  updatePreviewTriggered: (params: {
    code?: string;
    identifier: WorkspaceIdentifier;
  }) => Promise<void>;
  updateLegacyPreviewTriggered: (params: {
    identifier: WorkspaceIdentifier;
    previewUrl: string;
  }) => Promise<void>;
  runJobTriggered: (params: WorkspaceIdentifier) => Promise<void>;
}

const initialCodeBlockState: CodeBlockState = {
  isRunningCode: false,
  hash: null,
  code: "",
  inControl: true,
  codeBlock: null,
  codeBlockVersion: null,
};

/**
 * Checks if we should save code. Will default to true so that we don't loose any code.
 * Rather save too many times than too few.
 */
const shouldSave = (codeBlockState?: CodeBlockState | null): boolean => {
  if (!codeBlockState) {
    return false;
  }

  if (!codeBlockState.inControl) {
    return false;
  }

  if (codeBlockState.hash === codeBlockState.codeBlockVersion?.hash) {
    return false;
  }

  if (codeBlockState.code === "") {
    return false;
  }

  return true;
};

const isCodeBlockInState = (state: StoreState, codeBlockId: string): boolean =>
  Object.keys(state.codeBlocks).includes(codeBlockId);

const getCodeBlockState = (
  state: StoreState,
  codeBlockId: string,
): CodeBlockState | null =>
  isCodeBlockInState(state, codeBlockId) ? state.codeBlocks[codeBlockId] : null;

export const createCodeBlockSelector =
  (codeBlockId: string) => (state: StoreState) =>
    isCodeBlockInState(state, codeBlockId)
      ? state.codeBlocks[codeBlockId]
      : null;

export const createCodeSelector =
  (codeBlockId: string) => (state: StoreState) =>
    isCodeBlockInState(state, codeBlockId)
      ? state.codeBlocks[codeBlockId].code
      : "";

export const createInControlSelector =
  (codeBlockId: string) => (state: StoreState) =>
    isCodeBlockInState(state, codeBlockId)
      ? state.codeBlocks[codeBlockId].inControl
      : false;

export const codeStore: StoreSlice<CodeSlice> = (set, get) => ({
  codeBlocks: {},
  codeBlockChanged: (codeBlock) => {
    debugLog(`Code block mounted/changed: ${codeBlock.refPath}`);

    set(
      (draft) => {
        const user = get().auth.user;
        const inControl: boolean =
          !!user && isInControlOfCodeBlock({ codeBlock, user });

        if (isCodeBlockInState(get(), codeBlock.id)) {
          draft.codeBlocks[codeBlock.id].codeBlock = codeBlock;
          draft.codeBlocks[codeBlock.id].inControl = inControl;
        } else {
          draft.codeBlocks[codeBlock.id] = {
            ...initialCodeBlockState,
            codeBlock,
            inControl,
          };
        }
      },
      false,
      actionType("code-block-changed"),
    );
  },
  codeBlockVersionChanged: (codeBlockId, codeBlockVersion) => {
    debugLog(`Code block version changed: ${codeBlockVersion.refPath}`);

    set(
      (draft) => {
        if (isCodeBlockInState(get(), codeBlockId)) {
          draft.codeBlocks[codeBlockId].codeBlockVersion = codeBlockVersion;
          draft.codeBlocks[codeBlockId].code = codeBlockVersion.code;
          draft.codeBlocks[codeBlockId].hash =
            codeBlockVersion.hash || computeHash(codeBlockVersion.code);
        } else {
          draft.codeBlocks[codeBlockId] = {
            ...initialCodeBlockState,
            codeBlockVersion,
            code: codeBlockVersion.code,
            hash: codeBlockVersion.hash || computeHash(codeBlockVersion.code),
          };
        }
      },
      false,
      actionType("code-block-version-changed"),
    );
  },
  autosaveTriggered: async ({ codeBlockId, projectId, code, isDevxReady }) => {
    debugLog(`Autosave triggered: ${codeBlockId}`);

    const codeBlockState = getCodeBlockState(get(), codeBlockId);
    const user = get().auth.user;

    if (user && shouldSave(codeBlockState)) {
      debugLog("Should save");
      const newCodeBlockVersion = await saveCode({
        projectId,
        codeBlockRef: createCodeBlockRefPath({ projectId, codeBlockId }),
        user,
        code,
        isDevxReady,
      });

      set(
        (draft) => {
          draft.codeBlocks[codeBlockId].codeBlockVersion = newCodeBlockVersion;
        },
        false,
        actionType("autosave-triggered"),
      );
    } else {
      debugLog("Should not save");
    }
  },
  codeChanged: (codeBlockId, code) => {
    set(
      (draft) => {
        draft.codeBlocks[codeBlockId].code = code;
        draft.codeBlocks[codeBlockId].hash = computeHash(code);

        if (!draft.project.devxHealth.isDevxReady) {
          draft.project.devxHealth.hasMadeChangesWhileDevxNotReady = true;
        }
      },
      false,
      actionType("code-changed"),
    );
  },
  codeBlockUnmounted: async (params) => {
    debugLog(`Code block unmounted: ${params.codeBlockId}`);

    const codeBlockState = getCodeBlockState(get(), params.codeBlockId);
    const user = get().auth.user;

    if (user && shouldSave(codeBlockState) && codeBlockState?.code) {
      debugLog(`Saving code on unmount: ${params.codeBlockId}`);
      const newCodeBlockVersion = await saveCode({
        projectId: params.projectId,
        codeBlockRef: createCodeBlockRefPath({
          projectId: params.projectId,
          codeBlockId: params.codeBlockId,
        }),
        user,
        code: codeBlockState.code,
        isDevxReady: params.isDevxReady,
      });

      set(
        (draft) => {
          draft.codeBlocks[params.codeBlockId].codeBlockVersion =
            newCodeBlockVersion;
        },
        false,
        actionType("code-block-unmounted"),
      );
    }
  },
  updatePreviewTriggered: async ({ identifier, code }) => {
    const codeBlockState = getCodeBlockState(get(), identifier.codeBlockId);
    const user = get().auth.user;
    const isDevxReady = get().project.devxHealth.isDevxReady;

    if (
      user &&
      codeBlockState?.codeBlockVersion &&
      codeBlockState.codeBlock &&
      codeBlockState.inControl &&
      isDevxReady
    ) {
      set(
        (draft) => {
          if (identifier.codeBlockId in draft.codeBlocks) {
            draft.codeBlocks[identifier.codeBlockId].isRunningCode = true;
          }
        },
        false,
        actionType("code-run-started"),
      );

      await updatePreview({
        projectId: identifier.projectId,
        pageId: identifier.componentId,
        draftHash: codeBlockState.hash,
        latestCodeBlockVersion: codeBlockState.codeBlockVersion,
        codeBlock: codeBlockState.codeBlock,
        isDevxReady,
        code: code ?? codeBlockState.code,
        user,
        onInstanceTagReceived: (params) => {
          set(
            (draft) => {
              draft.project.devxHealth.instanceTag = params.instanceTag;
            },
            false,
            actionType("instance-tag-received"),
          );
        },
      });

      set(
        (draft) => {
          if (identifier.codeBlockId in draft.codeBlocks) {
            draft.codeBlocks[identifier.codeBlockId].isRunningCode = false;
          }
        },
        false,
        actionType("code-run-completed"),
      );
    } else if (!isDevxReady) {
      logService.info("Attempted to update preview before devx was ready");
    } else {
      Sentry.captureMessage(
        "Update preview clicked without user or code block",
      );
    }
  },
  runJobTriggered: async (params) => {
    const codeBlockState = getCodeBlockState(get(), params.codeBlockId);
    const user = get().auth.user;

    if (user && codeBlockState) {
      toast("Running code...");
      await runCodeAsync({
        projectId: params.projectId,
        jobId: params.componentId,
        runId: `${params.codeBlockId}-${Date.now()}`,
        code: codeBlockState.code,
        user,
      });
    } else if (!user) {
      Sentry.captureMessage("Run job clicked without user", "error");
    } else if (!codeBlockState) {
      Sentry.captureMessage("Run job clicked without code block", "error");
    }
  },
  updateLegacyPreviewTriggered: async (params) => {
    const user = get().auth.user;
    const codeBlockState = getCodeBlockState(
      get(),
      params.identifier.codeBlockId,
    );

    if (user && codeBlockState) {
      set(
        (draft) => {
          if (params.identifier.codeBlockId in draft.codeBlocks) {
            draft.codeBlocks[
              params.identifier.codeBlockId
            ].isRunningCode = true;
          }
        },
        false,
        actionType("update-legacy-preview-started"),
      );

      const response = await dbtnApi.post({
        user,
        url: params.previewUrl,
        json: {
          code: codeBlockState.code,
        },
      });

      const responseBody = await response.json<{
        instanceTag: string;
        ready: boolean;
      }>();

      set(
        (draft) => {
          if (params.identifier.codeBlockId in draft.codeBlocks) {
            draft.codeBlocks[
              params.identifier.codeBlockId
            ].isRunningCode = false;
          }
        },
        false,
        actionType("update-legacy-preview-completed"),
      );

      set(
        (draft) => {
          draft.project.devxHealth.instanceTag = responseBody.instanceTag;
        },
        false,
        actionType("instance-tag-received"),
      );
    } else if (!user) {
      Sentry.captureMessage(
        "Update preview legacy clicked without user",
        "error",
      );
    } else if (!codeBlockState) {
      Sentry.captureMessage(
        "Update preview legacy clicked without code block",
        "error",
      );
    }
  },
});
