import { styled } from "../../../stitches.config";

/**
 * @deprecated Use from TypographyV2
 */
export const Text = styled("span", {
  margin: 0,
  fontWeight: 500,
  fontVariantNumeric: "tabular-nums",
  color: "$primaryDark",
  fontFamily: "$sans",
  lineHeight: "20px",
  variants: {
    size: {
      inherit: {
        fontSize: "inherit",
        lineHeight: "inherit",
      },
      regular: {
        fontSize: "12px",
        lineHeight: "16px",
      },
      "16px": {
        fontSize: "16px",
        lineHeight: "24px",
      },

      "1": { fontSize: "$1", lineHeight: "15px" },
      "2": { fontSize: "$2" },
      "3": { fontSize: "$3", lineHeight: "24px" },
      "4": { fontSize: "$4" },
      "5": { fontSize: "$5", letterSpacing: "-.015em" },
    },
    align: {
      center: {
        textAlign: "center",
      },
    },
    weight: {
      inherit: {
        fontWeight: "inherit",
      },
      thin: {
        fontWeight: "400",
      },
      regular: {
        fontWeight: "600",
      },
      solid: {
        fontWeight: "$big",
      },

      light: { fontWeight: "$light" },
      medium: { fontWieght: "$medium" },
      big: { fontWeight: "$big" },
      bold: { fontWeight: "$big" },
    },
    color: {
      dark: { color: "$primaryDark" },
      white: { color: "$white" },
      light: { color: "$white" },
      gray: { color: "$gray4" },
      faded: { color: "$gray4" },
      inherit: { color: "inherit" },
    },
    margin: {
      none: {
        margin: 0,
      },
    },
  },
  defaultVariants: {
    size: "regular",
    weight: "regular",
    color: "dark",
  },
});
