import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { styled } from "../../../../stitches.config";
import {
  JobRunsTable,
  RunsTableFilter,
} from "../../../components/JobRunsTable/JobRunsTable";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import { ZonedTimestamp } from "../../../components/ZonedTimestamp/ZonedTimestamp";
import { useJobDeployments } from "../../../hooks/useJobDeployments";
import { useRuns } from "../../../hooks/useRuns";
import { useSchedule } from "../../../hooks/useSchedule";
import { useJobs } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { EditIcon, StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Flex } from "../../../ui/Layout/Flex";
import { LinkButton } from "../../../ui/LinkButton/LinkButton";
import { H1, H2, Txt } from "../../../ui/TypographyV3/TypographyV3";
import { VISITED_A_JOB } from "../../../utils/analytics-constants";
import { routeUtils } from "../../../utils/route-utils";
import { CenteredLayout, Span } from "../../UiPage/components/Layout";
import { SidecarContent } from "../SidecarContent";
import { NotScheduledActions } from "./components/NotScheduledActions";
import { ScheduledActions } from "./components/ScheduledActions";
import { VerboseScheduleText } from "./components/VerboseScheduleText";
import { getNextRun } from "./utils";

const Container = styled("div", {});

export const MonitorJobView = () => {
  const { jobIdOrSlug } = useParams() as {
    jobIdOrSlug: string;
  };
  const { project } = useProjectPageContext();
  const jobs = useJobs();
  const { user } = useUserGuardContext();
  const [filters, setFilters] = useImmer<RunsTableFilter>({
    filter: "all",
  });
  const componentOpened = useStore((state) => state.componentOpened);

  const job = useMemo(
    () => jobs.find((it) => it.slug === jobIdOrSlug || it.id === jobIdOrSlug),
    [jobs, jobIdOrSlug],
  );

  const { currentDeployment } = useJobDeployments({
    projectId: project.id,
    jobId: job?.id,
  });

  useEffect(() => {
    if (job) {
      componentOpened({ component: job, mode: "monitor" });
    }
  }, [job]);

  const { runs } = useRuns(project.id, job?.id, filters);
  const { schedule } = useSchedule(project.id, job?.id);
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  const nextRun = getNextRun({ schedule });

  useEffect(() => {
    pulseTriggered({
      user,
      eventName: VISITED_A_JOB,
      properties: {
        projectId: project.id,
        jobId: job?.id ?? null,
      },
    });
  }, []);

  const isProjectNavigatorClosed = useStore(
    (state) => state.appState.isProjectNavigatorClosed,
  );

  const jobUrl = useMemo(
    () =>
      job
        ? routeUtils.path({
            to: "job",
            params: {
              projectId: project.id,
              jobSlug: job.slug,
              mode: "build",
            },
          })
        : "",
    [project, job],
  );

  // TODO: Create not found page
  if (!job) {
    return null;
  }

  return (
    <Container>
      <CenteredLayout
        css={{ paddingTop: "$6" }}
        paddingX={isProjectNavigatorClosed ? "default" : "small"}
      >
        <Flex direction="vertical" gap="6">
          <Flex direction="vertical" gap="2">
            <Flex gap="3">
              <H1>{job.name}</H1>

              <LinkButton to={jobUrl} intent="pink">
                <EditIcon />
                Edit Code
              </LinkButton>
            </Flex>

            {currentDeployment && (
              <Txt size="12" color="disabled" weight="semiBold">
                <Flex gap="1">
                  <StyledFontAwesomeIcon icon={faCheckCircle} />
                  <Span>
                    Schedule last updated at{" "}
                    <ZonedTimestamp
                      timestamp={currentDeployment.createdAt}
                      showAsRelative={true}
                    />
                  </Span>
                </Flex>
              </Txt>
            )}
          </Flex>

          <Flex direction="vertical" gap="2">
            <H2>Status</H2>
            <VerboseScheduleText schedule={schedule} />
          </Flex>

          {schedule ? (
            <ScheduledActions
              job={job}
              schedule={schedule}
              nextRun={nextRun}
              projectId={project.id}
            />
          ) : (
            <NotScheduledActions job={job} projectId={project.id} />
          )}

          <SidecarContent>
            {/* TODO: What if it hasn't run yet, but is scheduled? */}
            {schedule && (
              <JobRunsTable
                runs={runs}
                projectId={project?.id}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          </SidecarContent>
        </Flex>
      </CenteredLayout>
    </Container>
  );
};
