import { User } from "firebase/auth";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useProfile } from "../../hooks/useProfile";
import { Profile } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { firestore } from "../../utils/firebase";

interface Props {
  projectId: string;
  user: User;
}

export const AllReadButton = ({ projectId, user }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { profile } = useProfile(user.email);

  if (!profile) {
    return null;
  }

  const handleClick = async () => {
    setIsUpdating(true);

    const existingMap = profile.projectEventsReadMap ?? {};

    const updateProjectEventsReadMapRequest: Pick<
      Profile,
      "projectEventsReadMap"
    > = {
      projectEventsReadMap: {
        ...existingMap,
        [projectId]: Timestamp.now(),
      },
    };

    await updateDoc(
      doc(firestore, profile.refPath),
      updateProjectEventsReadMapRequest,
    );

    setIsUpdating(false);
  };

  return (
    <Button onClick={handleClick} disabled={isUpdating}>
      All read
    </Button>
  );
};
