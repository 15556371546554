import { collection, limit, orderBy, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { codeBlockVersionConverter } from "../utils/collections/code-block-versions";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useLatestCodeBlockVersion = (params: {
  codeBlockRef?: string;
}) => {
  const [codeBlockVersions, _loading, error] = useCollectionData(
    params.codeBlockRef
      ? query(
          collection(
            firestore,
            params.codeBlockRef,
            CollectionName.VERSIONS,
          ).withConverter(codeBlockVersionConverter),
          orderBy("createdAtUtc", "desc"),
          limit(1),
        )
      : null,
  );

  if (error) {
    logService.warn(
      `Could not get latest code block version: ${error.message}`,
    );
  }

  return {
    latestCodeBlockVersion:
      codeBlockVersions?.length === 1 ? codeBlockVersions[0] : null,
  };
};
