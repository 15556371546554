import { styled } from "../../../stitches.config";

export const Input = styled("input", {
  background: "$white",
  border: "1px solid $gray5",
  borderRadius: "4px",
  padding: "8px 12px",
  width: "100%",
  fontSize: "$1",
  height: "30px",

  "&::placeholder": {
    color: "$gray4",
  },

  "&:disabled": {
    backgroundColor: "$inputDisabledGray",
    cursor: "not-allowed",
  },

  variants: {
    state: {
      valid: {},
      invalid: {
        border: "1px solid red",
      },
    },
  },
});
