export const validateHour = (
  value: number,
  min: string,
  max: string,
): string => {
  const minAsNumber = Number.parseInt(min, 10);
  const maxAsNumber = Number.parseInt(max, 10);
  if (value < minAsNumber || value > maxAsNumber) {
    return "Invalid hour";
  }

  return "";
};

export const validateTimestring = (value: string): string => {
  if (!/^\d{2}:\d{2}$/.test(value)) {
    return "Invalid time format. Use HH:MM";
  }

  const [hour, minute] = value.split(":");

  const hourStripped = Number.parseInt(
    hour.length > 0 ? hour.replace(/^0/, "") : hour,
    10,
  );
  if (hourStripped < 0 || hourStripped > 23) {
    return "Invalid time";
  }

  const minuteStripped = Number.parseInt(
    minute.length > 0 ? minute.replace(/^0/, "") : minute,
    10,
  );
  if (minuteStripped < 0 || minuteStripped > 59) {
    return "Invalid time";
  }

  return "";
};
