import { deleteDoc, doc } from "firebase/firestore";
import { Viewer } from "../../types/persisted";
import { firestore } from "../firebase";
import { CollectionName, createConverter } from "./shared";

export const viewerConverter = createConverter<Viewer>();

export const getViewersCollectionKey = (params: {
  appId: string;
  projectId: string;
}): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.APPS}/${params.appId}/${CollectionName.VIEWERS}`;

const revokeAccessForViewer = async (params: {
  viewerRef: string;
}): Promise<void> => {
  await deleteDoc(doc(firestore, params.viewerRef));
};

export const viewerDoc = { revokeAccess: revokeAccessForViewer };
