// https://liveblocks.io/docs/get-started/react
import { createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { User } from "firebase/auth";
import { isLocal, isPrLink } from "../utils/feature-flag-utils";

const client = createClient({
  publicApiKey:
    isLocal() || isPrLink()
      ? "pk_dev_zRZZUGyHkt_RjGhkg2k39vei"
      : "pk_prod_ODlBF65PV_Pw9npHgkQXo3sS",
});

export type Avatar = {
  type: "letter" | "image";
  value: string;
  userId: string;
};

type Presence = {
  name: string | null;
  pathname: string | null;
  avatar: Avatar | null;
  cursor: {
    x: number;
    y: number;
  } | null;
};

export const { RoomProvider, useMyPresence, useOthers, useUpdateMyPresence } =
  createRoomContext<Presence>(client);

const createAvatar = (user: User | null): Avatar | null => {
  if (!user) {
    return null;
  }

  return user.photoURL
    ? {
        type: "image",
        value: user.photoURL,
        userId: user.uid,
      }
    : {
        type: "letter",
        value: user.displayName?.[0] ?? "?",
        userId: user.uid,
      };
};

export const createPresence = ({
  user,
  cursor = null,
  pathname = window.location.pathname,
}: {
  user: User | null;
  cursor?: { x: number; y: number } | null;
  pathname?: string;
}): Presence => ({
  name: user?.displayName ?? "Unknown",
  pathname,
  avatar: createAvatar(user),
  cursor,
});
