import { DocumentData, FirestoreDataConverter } from "firebase/firestore";

/**
 * Collection names used in firestore
 */
export enum CollectionName {
  APP_CONFIG_SNAPSHOTS = "app-config-snapshots",
  APPS = "apps",
  PULSES = "pulses",
  METRICS = "metrics",
  JOBS = "jobs",
  DEVRUNS = "devruns",
  DEPLOYMENT_REQUESTS = "deployment-requests",
  RUNS = "runs",
  PROJECT_INVITES = "project-invites",
  PROFILES = "profiles",
  USER_SEGMENTS = "user-segments",
  FEATURE_FLAGS = "feature-flags",
  LOGS = "logs",
  SCHEDULES = "schedules",
  MULTIPAGE_APPS = "multipage-apps",
  PAGES = "pages",
  DATAFRAMES = "dataframes",
  DATAFILES = "datafiles",
  SHORT_URLS = "short-urls",
  DEPLOYMENTS = "deployments",
  REQUIREMENTS = "requirements",
  CODE_BLOCK_VERSIONS = "versions",
  CODE_BLOCKS = "code-blocks",
  VIEWERS = "viewers",
  PROJECTS = "projects",
  PROJECT_TEMPLATES = "project-templates",
  VERSIONS = "versions",
  MODULES = "modules",
  VISITS = "visits",
  SCREENSHOTS = "screenshots",
  EVENTS = "events",
}

export const createDocRefPath = (parts: string[]): string => {
  const refPath = parts.join("/");

  return refPath;
};

export const createCollectionRefPath = (parts: string[]): string => {
  const refPath = parts.join("/");

  return refPath;
};

export const createConverter = <T>(params?: {
  generateSlugFn: (
    id: string,
    value: Omit<T, "id" | "refPath" | "generatedSlug">,
  ) => string;
}): FirestoreDataConverter<T> => ({
  toFirestore: (it: T): DocumentData => it as DocumentData,
  fromFirestore: (snapshot, _options) => {
    const data = snapshot.data({
      serverTimestamps: "estimate",
    }) as Omit<T, "id" | "refPath" | "generatedSlug">;

    if (params?.generateSlugFn) {
      return {
        id: snapshot.id,
        refPath: snapshot.ref.path,
        generatedSlug: params.generateSlugFn(snapshot.id, data),
        ...data,
      } as T;
    }

    return {
      id: snapshot.id,
      refPath: snapshot.ref.path,
      ...data,
    } as T;
  },
});
