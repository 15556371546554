import { User } from "firebase/auth";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { CreateCodeBlockVersionPayload } from "../../types/payloads";
import { CodeBlockVersion } from "../../types/persisted";
import { resolveCodeBlockVersionName } from "../code-block-utils";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import { firestore } from "../firebase";
import { createPerformedByObjWithServerTimestamp } from "../user-utils";
import { CodeBlockRef } from "./code-blocks";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
  createDocRefPath,
} from "./shared";

export const codeBlockVersionConverter = createConverter<CodeBlockVersion>();

export const getCodeBlockVersionCollectionKey = (
  params: CodeBlockRef,
): string => {
  if ("refPath" in params) {
    return createCollectionRefPath([params.refPath, CollectionName.VERSIONS]);
  }

  return createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.CODE_BLOCKS,
    params.codeBlockId,
    CollectionName.VERSIONS,
  ]);
};

type CreateCodeBlockVersionRefPathParams =
  | { refPath: string }
  | {
      codeBlockRef: string;
      codeBlockVersionId: string;
    }
  | {
      projectId: string;
      codeBlockId: string;
      codeBlockVersionId: string;
    };

const createCodeBlockVersionRefPath = (
  params: CreateCodeBlockVersionRefPathParams,
): string => {
  if ("refPath" in params) {
    return params.refPath;
  }

  if ("codeBlockRef" in params) {
    return createDocRefPath([
      params.codeBlockRef,
      CollectionName.VERSIONS,
      params.codeBlockVersionId,
    ]);
  }

  return createDocRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.CODE_BLOCKS,
    params.codeBlockId,
    CollectionName.CODE_BLOCK_VERSIONS,
    params.codeBlockVersionId,
  ]);
};

const createCodeBlockVersionDocumentRef = (
  params: CreateCodeBlockVersionRefPathParams,
): DocumentReference<CodeBlockVersion> =>
  doc(firestore, createCodeBlockVersionRefPath(params)).withConverter(
    codeBlockVersionConverter,
  );

const createHumanReadableVersion = (): string =>
  `v${Timestamp.now()
    .toDate()
    .toISOString()
    .replace("T", " ")
    .replace(/\.\d{3}Z{0,1}/, "")}`;

/**
 * Create document and ID for a code block version without saving to firebase
 */
export const createCodeBlockVersionDocument = (params: {
  user: User;
  name: string;
  code: string;
}): {
  id: string;
  doc: CreateCodeBlockVersionPayload;
} => {
  const id = createDatabuttonId(DatabuttonIdPrefix.CODE_BLOCK_VERSION);

  const doc = {
    name: params.name,
    code: params.code,
    createdAtUtc: serverTimestamp(),
    createdBy: createPerformedByObjWithServerTimestamp({ user: params.user }),
    version: createHumanReadableVersion(),
    description: "",
  } satisfies CreateCodeBlockVersionPayload;

  return {
    id,
    doc,
  };
};

export const fetchLatestCodeBlockVersion = async (
  params: CodeBlockRef,
): Promise<CodeBlockVersion> => {
  const refPath = getCodeBlockVersionCollectionKey(params);

  const result = await getDocs(
    query(
      collection(firestore, refPath),
      orderBy("createdAtUtc", "desc"),
      limit(1),
    ).withConverter(codeBlockVersionConverter),
  );

  if (result.size === 1) {
    return result.docs[0].data();
  }

  throw new Error(`Could not find latest code block version: ${refPath}`);
};

export const fetchCodeBlockVersion = async (
  params: CreateCodeBlockVersionRefPathParams,
): Promise<CodeBlockVersion | null> => {
  const result = await getDoc(createCodeBlockVersionDocumentRef(params));

  return result.exists() ? result.data() : null;
};

const saveCodeBlockVersion = async (params: {
  codeBlockRef: string;
  code: string;
  user: User;
  name: string;
  prevCodeBlockVersionRef?: string | null;
}): Promise<CodeBlockVersion> => {
  const newCodeBlockVersionId = createDatabuttonId(
    DatabuttonIdPrefix.CODE_BLOCK_VERSION,
  );

  const newCodeBlockVersion = {
    name: params.name,
    createdAtUtc: serverTimestamp(),
    version: createHumanReadableVersion(),
    createdBy: createPerformedByObjWithServerTimestamp({ user: params.user }),
    code: params.code,
    prevCodeBlockVersionRef: params.prevCodeBlockVersionRef ?? null,
    description: "",
  } satisfies CreateCodeBlockVersionPayload;

  const newCodeBlockVersionRefPath = createCodeBlockVersionRefPath({
    codeBlockRef: params.codeBlockRef,
    codeBlockVersionId: newCodeBlockVersionId,
  });

  await setDoc(doc(firestore, newCodeBlockVersionRefPath), newCodeBlockVersion);

  const savedCodeBlockVersion = await fetchCodeBlockVersion({
    refPath: newCodeBlockVersionRefPath,
  });

  if (!savedCodeBlockVersion) {
    throw new Error(
      `Could not find recently saved code block version: ${newCodeBlockVersionRefPath}`,
    );
  }

  return savedCodeBlockVersion;
};

/**
 * Append a new code block version on the existing 'history' and add a reference to the previous version
 *
 * @returns {CodeBlockVersion} The new code block version
 */
export const appendNewCodeBlockVersion = async (params: {
  codeBlockRef: string;
  user: User;
  newCode?: string;
  newName?: string;

  // Set to null to append to latest version
  previousCodeBlockVersionRef: string | null;
}): Promise<CodeBlockVersion> => {
  const previousCodeBlockVersion =
    params.previousCodeBlockVersionRef === null
      ? await fetchLatestCodeBlockVersion({
          refPath: params.codeBlockRef,
        })
      : await fetchCodeBlockVersion({
          refPath: params.previousCodeBlockVersionRef,
        });

  if (!previousCodeBlockVersion) {
    throw new Error(
      `Could not find previous code block version. Looking for: ${params.previousCodeBlockVersionRef}`,
    );
  }

  const resolvedCodeBlockVersionName = await resolveCodeBlockVersionName(
    previousCodeBlockVersion,
  );

  return saveCodeBlockVersion({
    codeBlockRef: params.codeBlockRef,
    user: params.user,
    code: params.newCode ?? previousCodeBlockVersion.code,
    name: params.newName ?? resolvedCodeBlockVersionName,
    prevCodeBlockVersionRef: previousCodeBlockVersion.refPath,
  });
};
