import { styled } from "../../../stitches.config";
import { Div } from "../Layout";

interface Props {
  label: string;
  name: string;
  checked: boolean;
  onChange: (name: string, checked: boolean) => void;
}

const CheckboxInput = styled("input", {
  display: "none",
});

const CheckboxLabel = styled("label", {
  $$size: "30px",
  $$borderWidth: "1px",

  minHeight: "$$size",
  minWidth: "calc($$size - 2 * $1)",

  paddingX: "$1",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  backgroundColor: "$white",
  color: "#5f5f5f",
  // fontWeight: "$big",
  border: "$$borderWidth solid $gray6BoardBackground",

  "&:hover": {
    background: "$gray5",
    border: "$$borderWidth solid $gray5",
  },

  variants: {
    checked: {
      yes: {
        backgroundColor: "$darkPurpleSolid",
        border: "$$borderWidth solid $darkPurpleSolid",
        color: "$white",

        "&:hover": {
          backgroundColor: "$primaryPurple",
          border: "$$borderWidth solid $primaryPurple",
        },
      },
      no: {},
    },
  },
});

export const Checkbox = ({ name, label, checked, onChange }: Props) => (
  <Div>
    <CheckboxInput
      id={name}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={(e) => {
        onChange(e.target.name, e.target.checked);
      }}
    />
    <CheckboxLabel checked={checked ? "yes" : "no"} htmlFor={name}>
      {label}
    </CheckboxLabel>
  </Div>
);
