import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { JobRun } from "../../types/persisted";
import { FailedIcon, StyledFontAwesomeIcon, SuccessIcon } from "../../ui/Icon";
import { Tooltip } from "../../ui/Tooltip";

interface Props {
  run: JobRun;
}

const isTerminatedExitCode = (exitcode: number): boolean =>
  exitcode === -9 || exitcode === -15;

const isPastDeadline = (deadline?: Timestamp): boolean =>
  !!deadline && dayjs().isAfter(deadline.toDate());

// https://databutton.slack.com/archives/C038UPDLS4R/p1664278893738879
export const JobRunStatusIcon = ({ run }: Props) => {
  const isRunning = !run.endTime; // TODO: Check that deadline hasn't passed

  // Used if a job is running longer than deadline on 30mins and a end time has not been set.
  const isLost = !run.endTime && isPastDeadline(run.deadline);

  if (isLost) {
    return (
      <Tooltip text="Run timed out">
        <FailedIcon />
      </Tooltip>
    );
  }

  if (isRunning) {
    return (
      <Tooltip text="Code is running">
        <StyledFontAwesomeIcon
          icon={faSpinner}
          pulse={true}
          css={{ color: "#E3A64B" }}
        />
      </Tooltip>
    );
  }

  if (run.success) {
    return (
      <Tooltip text="Code ran successfully">
        <SuccessIcon />
      </Tooltip>
    );
  }

  // For example if another run of the same job started and this one was killed
  if (isTerminatedExitCode(run.exitcode)) {
    return (
      <Tooltip text="Run terminated">
        <StyledFontAwesomeIcon css={{ color: "$gray4" }} icon={faBan} />
      </Tooltip>
    );
  }

  // Most likely exception thrown
  if (run.exitcode === 1) {
    return (
      <Tooltip text="Run failed">
        <FailedIcon />
      </Tooltip>
    );
  }

  Sentry.captureMessage(
    `Found unsupported status code: ${run.exitcode}`,
    "warning",
  );

  return (
    <Tooltip text="Run failed">
      <FailedIcon />
    </Tooltip>
  );
};
