import { User } from "firebase/auth";
import {
  MultipageAppPage,
  Profile,
  Project,
  ProjectSummary,
} from "../types/persisted";

type ProjectPermissionCheck = (params: {
  project: Project | ProjectSummary;
  user: User | null;
}) => boolean;

type PagePermissionCheck = (params: {
  project: Project | ProjectSummary;
  page: MultipageAppPage;
  user: User | null;
}) => boolean;

type UserPermissionCheck = (params: {
  user?: User | null;
  profile?: Profile | null;
}) => boolean;

const isMemberOfProject: ProjectPermissionCheck = (params) => {
  const userId = params.user?.uid;
  if (userId && params.project.members) {
    return params.project.members.includes(userId);
  }

  return false;
};

const isOwnerOfProject: ProjectPermissionCheck = (params) => {
  const userId = params.user?.uid;

  if (userId) {
    return params.project.owner === userId;
  }

  return false;
};

const isHomePage: PagePermissionCheck = (params) =>
  /^home$/i.test(params.page.name);

export const isAdmin: UserPermissionCheck = (params) =>
  params.profile?.admin === true;

export const canCreateProjectTemplate: UserPermissionCheck = isAdmin;

const isCollaboratorOnProject: ProjectPermissionCheck = (params) =>
  isOwnerOfProject(params) || isMemberOfProject(params);

export const canEditProject: ProjectPermissionCheck = isCollaboratorOnProject;

export const canSeeProjectEvents: ProjectPermissionCheck =
  isCollaboratorOnProject;

export const canEditApp: ProjectPermissionCheck = isCollaboratorOnProject;

export const canRenamePage: PagePermissionCheck = (params) =>
  isCollaboratorOnProject(params) && !isHomePage(params);

export const canHidePage: PagePermissionCheck = (params) =>
  isCollaboratorOnProject(params) && !isHomePage(params);

export const canEditJob: ProjectPermissionCheck = isCollaboratorOnProject;

export const canEditModule: ProjectPermissionCheck = isCollaboratorOnProject;

export const canShareProject: ProjectPermissionCheck = isCollaboratorOnProject;

export const canShareApp: ProjectPermissionCheck = isCollaboratorOnProject;

export const canDeleteProject: ProjectPermissionCheck = isOwnerOfProject;

export const canDeleteApp: ProjectPermissionCheck = isCollaboratorOnProject;

export const canDeletePage: PagePermissionCheck = (params) =>
  isCollaboratorOnProject(params) && !isHomePage(params);

export const canDeleteModule: ProjectPermissionCheck = isCollaboratorOnProject;

export const canEditDataframe: ProjectPermissionCheck = isCollaboratorOnProject;

export const canEditDatafile: ProjectPermissionCheck = isCollaboratorOnProject;

export const canDeleteDataframe: ProjectPermissionCheck =
  isCollaboratorOnProject;

export const canDeleteDatafile: ProjectPermissionCheck =
  isCollaboratorOnProject;

export const canDeleteJob: ProjectPermissionCheck = isCollaboratorOnProject;

export const canConfigureProject: ProjectPermissionCheck =
  isCollaboratorOnProject;
