import { memo } from "react";
import Lottie from "react-lottie-player";
import { randomChoice } from "../../utils/ts-utils";
import dashedLines from "./dashed-lines.json";
import drawOn from "./draw-on.json";
import drawingLoop from "./drawing-loop.json";
import hungryDashes from "./hungry-dashes.json";
import lightSpeed from "./light-speed.json";
import pushPull from "./push-pull.json";
import rotatingLogosPush from "./rotating-logos-push.json";
import rotatingLogos from "./rotating-logos.json";
import stairwayHorizontal from "./stairway-horizontal.json";

export enum LoadingAnimationType {
  DASHED_LINES = "dashed-lines",
  DRAW_ON = "draw-on",
  DRAWING_LOOP = "drawing-loop",
  HUNGRY_DASHES = "hungry-dashes",
  LIGHT_SPEED = "light-speed",
  PUSH_PULL = "push-pull",
  ROTATING_LOGOS_PUSH = "rotating-logos-push",
  ROTATING_LOGOS = "rotating-logos",
  STAIRWAY_HORIZONTAL = "stairway-horizontal",
}

interface Props {
  animation: LoadingAnimationType | "random";
  size: string;
}

const selectAnimation = (value: LoadingAnimationType | "random") => {
  if (value === LoadingAnimationType.HUNGRY_DASHES) {
    return hungryDashes;
  }

  if (value === LoadingAnimationType.LIGHT_SPEED) {
    return lightSpeed;
  }

  if (value === LoadingAnimationType.PUSH_PULL) {
    return pushPull;
  }

  if (value === LoadingAnimationType.ROTATING_LOGOS_PUSH) {
    return rotatingLogosPush;
  }

  if (value === LoadingAnimationType.ROTATING_LOGOS) {
    return rotatingLogos;
  }

  if (value === LoadingAnimationType.STAIRWAY_HORIZONTAL) {
    return stairwayHorizontal;
  }

  if (value === LoadingAnimationType.DASHED_LINES) {
    return dashedLines;
  }

  if (value === LoadingAnimationType.DRAW_ON) {
    return drawOn;
  }

  if (value === LoadingAnimationType.DRAWING_LOOP) {
    return drawingLoop;
  }

  if (value === "random") {
    return randomChoice([
      hungryDashes,
      lightSpeed,
      pushPull,
      rotatingLogos,
      rotatingLogosPush,
      stairwayHorizontal,
      dashedLines,
      drawOn,
      drawingLoop,
    ]);
  }

  // DEFAULT
  return hungryDashes;
};

const LottieAnimation = ({ animation, size }: Props) => (
  <Lottie
    animationData={selectAnimation(animation)}
    play={true}
    style={{ width: size, height: size }}
  />
);

export const LoadingAnimation = memo(LottieAnimation);
