import { styled } from "../../../../stitches.config";
import { Div } from "../../../ui/Layout";

export const Centered = styled(Div, {
  margin: "0 auto",
  width: "800px",
  paddingTop: "200px",
  position: "absolute",
  inset: 0,
});

export const CenteredLayout = styled("div", {
  position: "absolute",
  inset: 0,
  overflowY: "auto",
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  variants: {
    paddingX: {
      small: {
        paddingX: "$8",
      },
      default: {
        paddingX: "128px",
      },
    },
  },
});

export const Span = styled("span");
