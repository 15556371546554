import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import ky, {
  BeforeErrorHook,
  BeforeRequestHook,
  BeforeRetryHook,
  Options,
} from "ky";
import { getProjectPath } from "../utils/project-utils";
import { logService } from "./log-service";

/**
 * Allow without user for unauthenticated calls such as loading "public" views
 */
type DbtnBaseParams =
  | {
      projectId: string;
      route: string;
      user?: User | null;
    }
  | {
      url: string;
      user?: User | null;
    };

type DbtnPostParams = DbtnBaseParams & {
  json?: object;
  timeout?: number;
  stream?: boolean;
};

type DbtnPutParams = DbtnBaseParams & {
  json: object;
};

type DbtnPostFormDataParams = DbtnBaseParams & {
  form: FormData;
};

type DbtnGetParams = DbtnBaseParams & {};

type DbtnDeleteParams = DbtnBaseParams & {
  json?: unknown;
};

export const addRequestIdToSentry: BeforeErrorHook = (error) => {
  const requestId = error.response.headers.get("x-request-id");
  if (requestId) {
    Sentry.setTag("x-request-id", requestId);
  }

  return error;
};

export const beforeRetryHook: BeforeRetryHook = (options) => {
  logService.warn(
    `Retrying request: ${options.request.method} ${options.request.url}. Retry count: ${options.retryCount}`,
  );
};

export const addAppVersionToHeaders: BeforeRequestHook = (request) => {
  request.headers.set("x-dbtn-webapp-version", window.APP_VERBOSE_VERSION);
  return request;
};

const dbtn = ky.extend({
  hooks: {
    beforeRequest: [addAppVersionToHeaders],
    beforeRetry: [beforeRetryHook],
    beforeError: [addRequestIdToSentry],
  },
  retry: {
    // Default from ky + 429
    methods: ["get", "put", "head", "delete", "options", "trace", "post"],
    statusCodes: [408, 413, 429, 500, 502, 503, 504],
  },
});

const constructUrl = (params: DbtnBaseParams): string => {
  if ("url" in params) {
    return params.url;
  }

  return getProjectPath({
    projectId: params.projectId,
    route: params.route,
  });
};

const constructAuthHeader = async (
  user?: User | null,
): Promise<Options["headers"]> =>
  user ? { Authorization: `Bearer ${await user.getIdToken()}` } : {};

const postFormData = async (params: DbtnPostFormDataParams) => {
  const url = constructUrl(params);

  // This can take a long time, based on the internet speed of the user
  // and the size of the upload.
  // Luckily we have a REALLY high timeout on the backend, so we can wait for 10 minutes.
  const timeout = 1000 * 60 * 10;

  return dbtn.post(url, {
    headers: await constructAuthHeader(params.user),
    body: params.form,
    timeout,
  });
};

// Currently, prodx instances with many views can take up to 32 seconds (recorded)
// having a timeout of 45 should catch ALL occurences (since prodx itself fails and reports at 40 second boot-time)
const DEVX_TIMEOUT = 1000 * 45;
const put = async (params: DbtnPutParams) => {
  const url = constructUrl(params);

  return dbtn.put(url, {
    headers: await constructAuthHeader(params.user),
    timeout: DEVX_TIMEOUT,
    json: params.json,
  });
};

const post = async (params: DbtnPostParams) => {
  const url = constructUrl(params);

  return dbtn.post(url, {
    headers: await constructAuthHeader(params.user),
    timeout: params.timeout || DEVX_TIMEOUT,
    json: params.json,
  });
};

const get = async (params: DbtnGetParams) => {
  const url = constructUrl(params);

  return dbtn.get(url, {
    headers: await constructAuthHeader(params.user),
    timeout: DEVX_TIMEOUT,
  });
};

const deleteFn = async (params: DbtnDeleteParams) => {
  const url = constructUrl(params);

  return dbtn.delete(url, {
    headers: await constructAuthHeader(params.user),
    timeout: DEVX_TIMEOUT,
    json: params.json,
  });
};

export const dbtnApi = {
  post,
  put,
  delete: deleteFn,
  postFormData,
  get,
};
