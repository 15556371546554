import { parseCronExpression } from "cron-schedule";
import { isValidCron } from "cron-validator";
import { Flex } from "../../ui/Layout/Flex";
import { Txt } from "../../ui/TypographyV3/TypographyV3";
import { ZonedTimestampByDate } from "../ZonedTimestamp/ZonedTimestamp";

interface Props {
  schedule: string | null;
}

export const ListUpcomingRuns = ({ schedule }: Props) => {
  if (!(schedule && isValidCron(schedule))) {
    return null;
  }

  return (
    <Flex direction="vertical" gap="2">
      <Txt weight="semiBold">Your next three runs will be at:</Txt>

      <Flex direction="vertical" gap="1">
        {parseCronExpression(schedule)
          .getNextDates(3)
          .map((it) => (
            <Txt key={it.getTime()} size="12">
              <ZonedTimestampByDate date={it} format="HH:mm on MMMM Do" />
            </Txt>
          ))}
      </Flex>
    </Flex>
  );
};
