const PROJECT_REGEX = /^\/{0,1}projects\/(?<projectId>[\da-z-]*)\/{0,1}/i;

export const extractProjectIdFromAppRefPath = (
  ref: string,
): { projectId: string } | null => {
  if (PROJECT_REGEX.test(ref)) {
    const match = PROJECT_REGEX.exec(ref);
    return match
      ? {
          projectId: match[1],
        }
      : null;
  }

  return null;
};
