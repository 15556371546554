import { faOctagon } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { styled } from "../../../../stitches.config";
import { JobRunStatusIcon } from "../../../components/JobRunStatusIcon/JobRunStatusIcon";
import {
  ZonedTimestamp,
  ZonedTimestampByDate,
} from "../../../components/ZonedTimestamp/ZonedTimestamp";
import { useLastRun } from "../../../hooks/useLastRun";
import { useSchedule } from "../../../hooks/useSchedule";
import { Job } from "../../../types/persisted";
import { BaseCard } from "../../../ui/Card/BaseCard";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Flex } from "../../../ui/Layout/Flex";
import { Grid } from "../../../ui/Layout/Grid";
import { NavigateLink } from "../../../ui/NavigateLink";
import { Txt } from "../../../ui/TypographyV3/TypographyV3";
import { routeUtils } from "../../../utils/route-utils";
import { getNextRun } from "../MonitorJobPage/utils";
import { ComponentName } from "./ComponentName";

interface Props {
  projectId: string;
  job: Job;
}

export const SCHEDULE_CARD_WIDTH = "270px";
export const SCHEDULE_CARD_HEIGHT = "120px";

const Card = styled(BaseCard, {
  padding: "$1 $2 $2",
  width: SCHEDULE_CARD_WIDTH,
  height: SCHEDULE_CARD_HEIGHT,
  gap: "$2",
  color: "$primaryDark",
  flexFlow: "column",

  variants: {
    padding: {
      none: {
        padding: 0,
      },
    },
  },
});

export const JobCard = ({ projectId, job }: Props) => {
  const { schedule } = useSchedule(projectId, job.id);
  const { lastRun } = useLastRun({
    projectId,
    jobId: job.id,
    shouldFetch: schedule !== null,
  });
  const nextRun = getNextRun({ schedule });

  const jobUrl = useMemo(
    () =>
      routeUtils.path({
        to: "job",
        params: {
          projectId,
          jobSlug: job.slug,
          mode: "monitor",
        },
      }),
    [projectId, job],
  );

  return (
    <NavigateLink to={jobUrl}>
      <Card padding="none">
        <Grid
          css={{
            height: "100%",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <Grid
            css={{
              // gridTemplateColumns: "max-content auto max-content",
              gridTemplateColumns: "max-content auto",
              borderBottom: "1px solid $mainBorder",
              paddingX: "$2",
              gap: "$2",
            }}
          >
            <Flex>
              {lastRun ? (
                <JobRunStatusIcon run={lastRun} />
              ) : (
                <StyledFontAwesomeIcon
                  icon={faOctagon}
                  css={{
                    color: "$fontSecondary",
                  }}
                />
              )}
            </Flex>

            <Flex
              direction="vertical"
              css={{
                justifyContent: "center",
                overflow: "hidden",
                gap: "calc($1/2)",
              }}
            >
              <ComponentName>{job.name}</ComponentName>

              {schedule && <Txt size="10">SCHEDULED</Txt>}
            </Flex>

            {/* <Flex css={{ padding: "$1" }}>
              <StyledFontAwesomeIcon icon={faEllipsis} rotation={90} />
            </Flex> */}
          </Grid>

          <Grid
            css={{
              width: "100%",
              gridTemplateColumns: "1fr 1fr",
              paddingX: "$2",
              paddingY: "$1",
            }}
          >
            {schedule && (
              <Flex direction="vertical" gap="1">
                <Txt size="10">LAST RUN</Txt>

                <Txt size="12">
                  {lastRun ? (
                    <ZonedTimestamp
                      timestamp={lastRun.startTime}
                      showAsRelative={true}
                      format="YYYY-MM-DD HH:mm"
                    />
                  ) : (
                    "Not run yet"
                  )}
                </Txt>
              </Flex>
            )}

            <Flex direction="vertical" gap="1">
              <Txt size="10">NEXT RUN</Txt>
              {nextRun && schedule ? (
                <Txt size="12">
                  {schedule?.state === "PAUSED" && "Paused"}

                  {schedule?.state === "ACTIVE" && (
                    <ZonedTimestampByDate
                      date={nextRun}
                      showAsRelative={true}
                      format="YYYY-MM-DD HH:mm"
                    />
                  )}
                </Txt>
              ) : (
                <Txt size="12">Not scheduled</Txt>
              )}
            </Flex>
          </Grid>
        </Grid>
      </Card>
    </NavigateLink>
  );
};
