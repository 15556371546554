import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import { ProjectTemplate, WithoutId } from "../../types/persisted";
import { firestore } from "../firebase";
import { CollectionName, createConverter, createDocRefPath } from "./shared";

export const projectTemplateConverter = createConverter<ProjectTemplate>();

export const createProjectTemplateDocumentRef = (params: {
  projectTemplateId: string;
}): DocumentReference<ProjectTemplate> =>
  doc(
    firestore,
    createDocRefPath([
      CollectionName.PROJECT_TEMPLATES,
      params.projectTemplateId,
    ]),
  ).withConverter(projectTemplateConverter);

export const updateProjectTemplate = async (params: {
  projectTemplateId: string;
  payload: Partial<WithoutId<ProjectTemplate>>;
}): Promise<void> => {
  await updateDoc(
    createProjectTemplateDocumentRef({
      projectTemplateId: params.projectTemplateId,
    }),
    params.payload,
  );
};
