import { User } from "firebase/auth";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { Viewer, WithoutId } from "../../types/persisted";
import {
  CollectionName,
  createDocRefPath,
} from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";

export const addViewerToMultipageApp = async (params: {
  multipageAppRef: string;
  projectId: string;
  recipient: string;
  user: User;
}): Promise<Viewer> => {
  // Using recipients email address as ID as we don't know if the email belongs to a user or not yet
  const viewerId = params.recipient.toLowerCase();
  const viewerRef = createDocRefPath([
    params.multipageAppRef,
    CollectionName.VIEWERS,
    viewerId,
  ]);

  const newViewer = {
    userId: null,
    recipient: params.recipient,
    revoked: false,
    createdByUserId: params.user.uid,
    createdAtUtc: Timestamp.now(),
  } satisfies WithoutId<Viewer>;

  await setDoc(doc(firestore, viewerRef), newViewer);

  return {
    ...newViewer,
    id: viewerId,
    refPath: viewerRef,
  };
};
