import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import {
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { createRef, Fragment, useEffect, useState } from "react";
import { Updater } from "use-immer";
import { styled } from "../../../stitches.config";
import { RunLog } from "../../pages/ProjectPage/MonitorJobPage/RunLog";
import { JobRun } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { H2, Txt } from "../../ui/TypographyV3/TypographyV3";
import { convertMillisToReadable } from "../../utils/time-utils";
import { JobRunStatusIcon } from "../JobRunStatusIcon/JobRunStatusIcon";
import { ZonedTimestamp } from "../ZonedTimestamp/ZonedTimestamp";
import { FilterRadioButton } from "./components/FilterButton";

export interface RunsTableFilter {
  filter: string;
}

interface Props {
  projectId: string;
  runs: JobRun[];
  setFilters: Updater<RunsTableFilter>;
  filters: RunsTableFilter;
}

const Table = styled("table", { width: "100%", borderCollapse: "collapse" });

const Tbody = styled("tbody", {});

const Td = styled("td", {
  padding: "$1",
});

const Tr = styled("tr", {
  borderBottom: "1px solid $gray6BoardBackground",
  variants: {
    expanded: {
      yes: {
        backgroundColor: "$activeBlue",
      },
      no: {},
    },
  },
});

const columnHelper = createColumnHelper<JobRun>();

const TableText = styled("span", {
  fontSize: "$1",
  color: "$gray4",
});

const columns = [
  columnHelper.accessor((row) => row.jobId, {
    header: "",
    id: "run-status",
    cell: (info) => (
      <Flex css={{ width: "100%", justifyContent: "center" }}>
        <JobRunStatusIcon run={info.row.original} />
      </Flex>
    ),
  }),
  columnHelper.accessor("startTime", {
    cell: (info) => (
      <Flex gap="4">
        <Txt
          css={{
            width: "70px",
          }}
        >
          <ZonedTimestamp timestamp={info.getValue()} format="HH:mm:ss" />
        </Txt>
        <Txt color="secondary">
          <ZonedTimestamp timestamp={info.getValue()} format="YYYY-MM-DD" />
        </Txt>
      </Flex>
    ),
    header: "Start time",
  }),
  columnHelper.accessor((row) => row.jobId, {
    header: "",
    id: "code",
    cell: (_info) => {
      return null;
    },
  }),
  columnHelper.accessor("duration", {
    header: "Duration",
    cell: (info) => {
      const duration = info.getValue();
      return (
        <Flex css={{ justifyContent: "flex-end", width: "100%" }}>
          <TableText>{convertMillisToReadable(duration, 1)}</TableText>
        </Flex>
      );
    },
  }),
  columnHelper.accessor((row) => row.jobId, {
    header: "",
    id: "expand-job",
    cell: (info) => (
      <Flex
        css={{
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          content="icon"
          intent="plain"
          type="button"
          onClick={() => {
            info.row.toggleExpanded();
          }}
        >
          <StyledFontAwesomeIcon
            icon={info.row.getIsExpanded() ? faChevronUp : faChevronDown}
          />
        </Button>
      </Flex>
    ),
  }),
];

export const JobRunsTable = ({
  runs,
  projectId,
  filters,
  setFilters,
}: Props) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const ref = createRef<HTMLTableElement>();
  const [tableWidth, setTableWidth] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current && tableWidth === null) {
      const width = ref.current.offsetWidth;
      setTableWidth(width ? width : null);
    }
  }, [ref, tableWidth]);

  const table = useReactTable<JobRun>({
    autoResetPageIndex: false,
    data: runs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleRadioButtonChange = (value: string) => {
    table.toggleAllRowsExpanded(false);
    setFilters((draft) => {
      draft.filter = value;
    });
  };

  return (
    <>
      <Flex
        css={{
          marginBottom: "$2",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Flex gap="4">
          <H2>Runs</H2>

          <Flex gap="3">
            <FilterRadioButton
              checked={(value) => value === filters.filter}
              label="All"
              name="filter"
              value="all"
              onChange={handleRadioButtonChange}
            />
            <FilterRadioButton
              checked={(value) => value === filters.filter}
              label="Success"
              name="filter"
              value="success"
              onChange={handleRadioButtonChange}
            />
            <FilterRadioButton
              checked={(value) => value === filters.filter}
              label="Failed"
              name="filter"
              value="failed"
              onChange={handleRadioButtonChange}
            />
          </Flex>
        </Flex>

        <Flex
          gap="1"
          css={{
            visibility: table.getPageCount() > 1 ? "visible" : "hidden",
            justifyContent: "flex-end",
          }}
        >
          <Button
            content="icon"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            intent="pink"
          >
            <StyledFontAwesomeIcon icon={faChevronLeft} />
          </Button>

          <Txt color="secondary">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </Txt>

          <Button
            type="button"
            content="icon"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            intent="pink"
          >
            <StyledFontAwesomeIcon icon={faChevronRight} />
          </Button>
        </Flex>
      </Flex>

      {runs.length > 0 ? (
        <Table ref={ref}>
          <Tbody>
            {table.getRowModel().rows.map((row, index, pageRows) => {
              const isExpanded = row.getIsExpanded();
              const isFirstRow = index === 0;
              const isLastRow = index === pageRows.length - 1;

              return (
                <Fragment key={row.id}>
                  <Tr
                    expanded={isExpanded ? "yes" : "no"}
                    css={{
                      display: "grid",
                      borderTopLeftRadius: isFirstRow ? "4px" : "0px",
                      borderTopRightRadius: isFirstRow ? "4px" : "0px",
                      borderBottomRightRadius: isLastRow ? "4px" : "0px",
                      borderBottomLeftRadius: isLastRow ? "4px" : "0px",
                      border: "1px solid $mainBorder",
                      borderBottom:
                        isLastRow || isExpanded
                          ? "1px solid $mainBorder"
                          : "none",
                      padding: "$1 calc($1 / 2)",
                      gridTemplateColumns: "50px auto max-content 70px 60px",
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        css={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    ))}
                  </Tr>

                  {row.getIsExpanded() && (
                    <Tr>
                      <Td
                        colSpan={columns.length}
                        css={{
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      >
                        <RunLog
                          projectId={projectId}
                          run={row.original}
                          // Used to avoid making the run logs pushing the table width.
                          // Calculated as table width - padding in expanded section
                          width={tableWidth ? `${tableWidth - 2 * 8}px` : null}
                          maxHeight='350px'
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Txt>Your schedule haven't run yet.</Txt>
      )}
    </>
  );
};
