import { useMemo } from "react";
import { LogOutput } from "../../../components/LogOutput/LogOutput";
import { useLogs } from "../../../hooks/useLogs";
import { JobRun } from "../../../types/persisted";
import { getRunRef } from "../../../utils/collections/logs";
import { createDocRefPath } from "../../../utils/collections/shared";

interface Props {
  projectId: string;
  run: JobRun;
  width: string | null;
  maxHeight: string | null;
}

export const RunLog = ({ projectId, run, width, maxHeight }: Props) => {
  const { logs } = useLogs({
    componentRef: createDocRefPath([getRunRef({ projectId, runId: run.id })]),
  });

  const output = useMemo(() => {
    if (logs && logs.length > 0) {
      const logLines = logs.map((it) => it.msg);

      if (run.exitcode === -9 || run.exitcode === -15) {
        return [...logLines, "Aborted"].join("");
      }

      return logLines.join("");
    }

    if (logs !== undefined && logs.length === 0) {
      return "No logs. Use print() in your code to create logs";
    }

    return null;
  }, [logs, run]);

  return output ? (
    <LogOutput width={width} logs={output} maxHeight={maxHeight} />
  ) : null;
};
