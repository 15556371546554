import { memo, useCallback, useState } from "react";
import { useWorkspaceContext } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import {
  ActionBar,
  LayoutWithActionBar,
} from "../../components/BuildLayout/LayoutWithActionbar";
import { PlayButton } from "../../components/BuildLayout/PlayButton";
import { SaveStatus } from "../../components/BuildLayout/SaveStatus";
import { useStore } from "../../store/store";
import { MultipageAppPage } from "../../types/persisted";
import { Flex } from "../../ui/Layout/Flex";
import { SplitPaneButtons } from "../../ui/SplitPane/components/SplitPaneButtons";
import { SplitPane } from "../../ui/SplitPane/SplitPane";
import { SplitPaneLayout } from "../../ui/SplitPane/utils";
import { ADJUSTED_WORKSPACE } from "../../utils/analytics-constants";
import { HotReloadingButton } from "./components/HotReloadingButton";
import { MemoizedPageEditor } from "./components/PageEditor";
import { MemoizedPagePreview } from "./components/PagePreview";
import { PublishPagesButton } from "./components/PublishPagesButton";
import { SharePagesModalButton } from "./components/SharePagesModalButton";

interface Props {
  page: MultipageAppPage;
  codeBlockId: string;
}

const BuildPageForm = ({ page }: Props) => {
  const [layout, setLayout] = useState<SplitPaneLayout>(
    SplitPaneLayout.VERTICAL_50_50,
  );
  const identifier = useWorkspaceContext();
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  const handleCustomLayoutChange = useCallback(
    (params: {
      layout: SplitPaneLayout;
      customGrid: string;
    }) => {
      pulseTriggered({
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          layout: params.layout,
          customGrid: params.customGrid,
          workspaceSetting: "layout",
        },
      });
    },
    [identifier],
  );

  const handleLayoutChanged = useCallback((layout: SplitPaneLayout) => {
    setLayout(layout);
  }, []);

  return (
    <LayoutWithActionBar>
      <ActionBar justifyContent="space-between">
        <Flex gap="1">
          <PlayButton />

          <HotReloadingButton />
        </Flex>

        <SplitPaneButtons
          layout={layout}
          onLayoutClicked={handleLayoutChanged}
        />

        <Flex gap="1" justifyContent="flex-end">
          <PublishPagesButton
            codeBlockId={identifier.codeBlockId}
            pageId={identifier.componentId}
          />

          <SharePagesModalButton />

          <SaveStatus />
        </Flex>
      </ActionBar>

      <SplitPane
        layout={layout}
        onLayoutChange={handleLayoutChanged}
        onCustomLayoutChange={handleCustomLayoutChange}
        primaryTile={<MemoizedPageEditor layout={layout} />}
        secondaryTile={<MemoizedPagePreview page={page} />}
        storageKey={identifier.componentId}
      />
    </LayoutWithActionBar>
  );
};

export const MemoizedBuildPageForm = memo(
  BuildPageForm,
  (prev, next) => prev.codeBlockId === next.codeBlockId,
);
