import * as Sentry from "@sentry/react";
import { Pulse } from "@databutton/firebase-types";
import { addDoc, collection } from "firebase/firestore";
import { logService } from "../services/log-service";
import { CollectionName } from "./collections/shared";
import { isLocal, isPrLink } from "./feature-flag-utils";
import { firestore } from "./firebase";
import { SetOptional } from "type-fest";
import { User } from "firebase/auth";
import { createPerformedByObj } from "./user-utils";

// Projects
export const ENTERED_A_PROJECT = "Entered a project";

// Workspace adjustments
export const ADJUSTED_WORKSPACE = "Adjusted workspace";

// Code sessions
export const CODE_SESSION_STARTED = "Code session started";
export const CODE_SESSION_ENDED = "Code session ended";

// Versions
export const BROWSED_PREVIOUS_VERSION = "Browsed previous version";
export const RESTORED_PREVIOUS_VERSION = "Restored previous version";

// Views
export const VISITED_A_VIEW = "Visited a view";
export const MADE_VIEW_ACCESSIBLE_BY_LINK = "Made view accessible by link";

// Multipage apps
export const VISITED_MULTIPAGE_APP = "Visited multipage app";
export const MADE_MULTIPAGE_APP_ACCESSIBLE_BY_LINK =
  "Made multipage app accessible by link";

// Jobs
export const VISITED_A_JOB = "Visited a job";
export const TERMINATED_A_JOB = "Terminated a job";

// Datafiles
export const VISITED_A_DATAFILE = "Visited a datafile";
export const VISITED_A_DATAFRAME = "Visited a dataframe";

export const OPENED_COMMAND_PALETTE = "Opened command palette";

export type PulseParams = SetOptional<
  Pick<Pulse, "eventName" | "text" | "properties">,
  "text" | "properties"
> & {
  user: User;
};

export const pulse = async (params: PulseParams) => {
  if ("userId" in params) {
    Sentry.captureMessage(
      "UserId pulse not supported. Use createdBy",
      "warning",
    );
  } else {
    logService.debug(`Logging event: ${params.eventName}`, params.properties);

    const shouldPulse = !(isLocal() || isPrLink());

    if (shouldPulse) {
      const payload = {
        createdBy: createPerformedByObj({ user: params.user }),
        eventName: params.eventName,
        text: params.text ?? params.eventName,
        properties: params.properties ?? {},
      } satisfies Pulse;

      await addDoc(collection(firestore, CollectionName.PULSES), payload);
    }
  }
};

// Onboarding flow
export const SELECTED_TAKE_THE_TOUR = "Selected take the tour";
export const SELECTED_START_CREATING = "Selected start creating";

export const OPENED_COMPONENT_TEMPLATE = "Opened component template";
export const STARTED_FROM_COMPONENT_TEMPLATE =
  "Started from component template";
export const HIT_NO_TEMPLATE_FOUND_MESSAGE = "Hit no template found message";

export const SENT_MESSAGE_TO_DATABUTLER_AI = "Sent message to Databutler AI";
export const COPIED_CODE_FROM_DATABUTLER_AI = "Copied code from Databutler AI";
