import { styled } from "../../../../stitches.config";
import { SplitPaneLayout } from "../utils";
import { DRAGBAR_SIZE } from "./Dragbar";

export const SplitPaneContainer = styled("div", {
  position: "relative",
  width: "100%",
  height: "100%",

  variants: {
    layout: {
      [SplitPaneLayout.VERTICAL_50_50]: {
        display: "grid",
        gridTemplateColumns: `[primary] 50% [dragbar] ${DRAGBAR_SIZE} [secondary] auto`,
      },
      [SplitPaneLayout.HORIZONTAL_50_50]: {
        display: "grid",
        gridTemplateRows: `[primary] 50% [dragbar] ${DRAGBAR_SIZE} [secondary] auto`,
      },
      [SplitPaneLayout.VERTICAL_100_0]: {
        display: "block",
      },
      [SplitPaneLayout.VERTICAL_0_100]: {
        display: "block",
      },
      [SplitPaneLayout.HORIZONTAL_CUSTOM]: {
        display: "grid",
      },
      [SplitPaneLayout.VERTICAL_CUSTOM]: {
        display: "grid",
      },
    },
  },
});
