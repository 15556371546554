import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import {
  createContext,
  createRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { styled } from "../../../stitches.config";
import { useStore } from "../../store/store";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";
import { Flex } from "../../ui/Layout/Flex";
import { Popover } from "../../ui/Popover";
import {
  isOnOffFeatureEnabled,
  OnOffFeatureFlag,
} from "../../utils/feature-flag-utils";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";
import { IconButton } from "../IconButton";
import { Others } from "../Others/Others";
import { SaveStatus } from "./SaveStatus";
import { WorkspaceMenu } from "./WorkspaceMenu";

const DRAGBAR_SIZE = "2px";

const BuildView = styled("div", {
  $$statusBarHeight: "30px",
  $$actionBarHeight: "50px",

  display: "grid",
  height: "calc(100vh - $$topBarHeight - 4px)",
  gridTemplateRows: "$$actionBarHeight auto",
});

const StatusBar = styled("div", {
  borderTop: "1px solid $mainBorder",
  display: "flex",
  alignItems: "center",
});

const BuildLayoutWrapper = styled("div", {
  width: "100%",
  height: "100%",
  display: "grid",
  position: "relative",

  variants: {
    layout: {
      "vertical-50-50": {
        gridTemplateColumns: `[build-area] 50% [dragbar] ${DRAGBAR_SIZE} [output-area] auto`,
      },
      "horizontal-50-50": {
        gridTemplateRows: `[build-area] 50% [dragbar] ${DRAGBAR_SIZE} [output-area] auto`,
      },
      "vertical-100-0": {
        gridTemplateColumns: `[build-area] auto [dragbar] ${DRAGBAR_SIZE} [output-area] 0%`,
      },
      custom: {},
    },
  },

  defaultVariants: {
    layout: "vertical-50-50",
  },
});

const BuildArea = styled("div", {
  position: "relative",
  display: "grid",
  gridTemplateRows: "calc(100% - $$statusBarHeight) $$statusBarHeight",
  gridArea: "build-area",
  // height: "100%",
  width: "100%",
});

const LayoutDragbar = styled("div", {
  gridArea: "dragbar",
  backgroundColor: "$mainBorder",
  display: "block",
  height: "100%",
  width: "100%",

  variants: {
    orientation: {
      vertical: {
        cursor: "ew-resize",
      },
      horizontal: {
        cursor: "ns-resize",
      },
    },
  },
});

const OutputArea = styled("div", {
  position: "absolute",
  inset: 0,
  display: "flex",
  backgroundColor: "$backgroundMain",
  flexFlow: "column",
  gridArea: "output-area",
  overflowY: "auto",
});

export const OutputAreaContent = styled("div", {
  backgroundColor: "$backgroundMain",
  height: "100%",
  position: "relative",
});

const ActionBarWrapper = styled("div", {
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "0 $1",
  backgroundColor: "#fdfdfe",
  borderBottom: "1px solid $mainBorder",
});

interface Props {
  codeBlockId: string;
  componentType: "app" | "job" | "module" | "page";
  // If the buildlayout has a splitscreen with iframme, this is the URL
  iframeSrc?: string;
  buildArea: ReactNode;
  outputArea: ReactNode;
  buildAreaActions: ReactNode;
  outputAreaActions: ReactNode;
  statusBarContent: ReactNode;
}

const BuildLayoutContext = createContext<"light" | "dark">("light");

const GRID_REGEX =
  /^\[[a-z-]*\] (?<buildArea>\d*\.\d*|\d*)px \[[a-z-]*\] (?<dragbar>\d*\.\d*|\d*)px \[[a-z-]*\] (?<outputArea>\d*.\d*|\d*)px$/;

const getSizeOfCustomLayout = (
  customGrid: string,
): {
  buildColSizeInPx: number;
  dragbarSizeInPx: number;
  outputColSizeInPx: number;
  totalSizeInPx: number;
} => {
  const match = GRID_REGEX.exec(customGrid);
  if (match) {
    const buildColSizeInPx = parseFloat(match[1]);
    const dragbarSizeInPx = parseFloat(match[2]);
    const outputColSizeInPx = parseFloat(match[3]);

    return {
      buildColSizeInPx,
      dragbarSizeInPx,
      outputColSizeInPx,
      totalSizeInPx: buildColSizeInPx + dragbarSizeInPx + outputColSizeInPx,
    };
  }

  throw new Error(`Tried to find size of invalid grid: ${customGrid}`);
};

/**
 * Checks if the saved custom grid will fit in the current window or if it needs to be shrunk or expanded
 * to match the window width. Also takes into account if the project navigator is collapsed or expanded.
 */
const adjustGridToFitWindow = (params: {
  customGrid: string;
  isProjectNavigatorClosed: boolean;
}): string => {
  const sizeOfCustomGrid = getSizeOfCustomLayout(params.customGrid);

  const newSizeOfBuildCol =
    (sizeOfCustomGrid.buildColSizeInPx / sizeOfCustomGrid.totalSizeInPx) * 100;

  return `[build-area] ${newSizeOfBuildCol}% [dragbar] ${sizeOfCustomGrid.dragbarSizeInPx}px [output-area] auto`;
};

export const BuildLayout = ({
  iframeSrc,
  componentType,
  buildArea,
  buildAreaActions,
  outputAreaActions,
  outputArea,
  statusBarContent,
}: Props) => {
  const identifier = useWorkspaceContext();
  const pageRef = createRef<HTMLDivElement>();
  const [isDragging, setIsDragging] = useState(false);
  const darkMode = useStore((state) => state.workspace.darkMode);
  const layoutDragCompleted = useStore((state) => state.layoutDragCompleted);
  const lightDarkMode = darkMode ? "dark" : "light";
  const layout = useStore((state) => state.workspace.layout);
  const isProjectNavigatorClosed = useStore(
    (state) => state.appState.isProjectNavigatorClosed,
  );

  useEffect(() => {
    if (layout.preset === "custom" && layout.customGrid) {
      const adjustedGrid = adjustGridToFitWindow({
        customGrid: layout.customGrid,
        isProjectNavigatorClosed: isProjectNavigatorClosed,
      });

      const page = pageRef.current;
      if (page) {
        if (layout.direction === "vertical") {
          page.setAttribute("style", `grid-template-columns: ${adjustedGrid};`);
        } else {
          page.setAttribute("style", `grid-template-rows: ${adjustedGrid};`);
        }
      }
    }
  }, [layout]);

  return (
    <BuildLayoutContext.Provider value={lightDarkMode}>
      <BuildView>
        <ActionBarWrapper css={{ justifyContent: "space-between" }}>
          <Flex>{buildAreaActions}</Flex>

          <Flex gap="2">
            <SaveStatus />

            {outputAreaActions}

            <Popover
              tooltipText="Configure workspace"
              buttonAs={IconButton}
              buttonContent={
                <StyledFontAwesomeIcon icon={faEllipsisVertical} />
              }
              panelContent={
                <WorkspaceMenu
                  componentType={componentType}
                  iframeSrc={iframeSrc}
                  onLayoutAdjusted={() => {
                    pageRef.current?.removeAttribute("style");
                  }}
                />
              }
            />
          </Flex>
        </ActionBarWrapper>

        <BuildLayoutWrapper
          layout={layout.preset}
          ref={pageRef}
          onMouseUp={() => {
            if (isDragging && pageRef.current) {
              const style = window.getComputedStyle(pageRef.current);

              layoutDragCompleted(identifier, {
                customGrid: style.getPropertyValue(
                  layout.direction === "vertical"
                    ? "grid-template-columns"
                    : "grid-template-rows",
                ),
              });
            }

            setIsDragging(false);
          }}
          onMouseMove={(e) => {
            if (isDragging) {
              e.preventDefault();

              const page = pageRef.current;
              if (page) {
                const rect = page.getBoundingClientRect();

                if (layout.direction === "vertical") {
                  const buildColSize = e.clientX - rect.left;
                  const newColDef = `[build-area] ${buildColSize}px [dragbar] ${DRAGBAR_SIZE} [output-area] auto`;

                  page.setAttribute(
                    "style",
                    `grid-template-columns: ${newColDef};`,
                  );
                } else {
                  const buildColSize = e.clientY - rect.top;
                  const newColDef = `[build-area] ${buildColSize}px [dragbar] ${DRAGBAR_SIZE} [output-area] auto`;

                  page.setAttribute(
                    "style",
                    `grid-template-rows: ${newColDef};`,
                  );
                }
              }
            }
          }}
        >
          {isOnOffFeatureEnabled(OnOffFeatureFlag.SHOW_CURSORS) && <Others />}

          <BuildArea>
            {buildArea}
            <StatusBar
              css={{
                // Make room for sidebar expander button
                paddingLeft:
                  isProjectNavigatorClosed && layout.direction === "vertical"
                    ? "48px"
                    : 0,
              }}
            >
              {statusBarContent}
            </StatusBar>
          </BuildArea>

          <LayoutDragbar
            orientation={layout.direction}
            onMouseDown={() => {
              setIsDragging(true);
            }}
          />

          <OutputArea>
            {isDragging && (
              <Div
                // Mousedrag over iframe hack
                css={{
                  position: "absolute",
                  inset: 0,
                  zIndex: 1,
                  opacity: 0,
                }}
              />
            )}
            {outputArea}
          </OutputArea>
        </BuildLayoutWrapper>
      </BuildView>
    </BuildLayoutContext.Provider>
  );
};
