import { createStitches } from "@stitches/react";

export const { styled, theme } = createStitches({
  prefix: "db-",
  theme: {
    colors: {
      backgroundMain: "#fdfdfe",
      darkPurpleSolid: "#753fcf",
      darkPurpleGradient:
        "linear-gradient(56.07deg, #AD51FF -2.4%, #7528FF 93.25%)",
      activeBlue: "rgba(112, 176, 255, 0.1)",
      darkPurpleGradientInverted:
        "linear-gradient(56.07deg, #7528FF -2.4%, #AD51FF 93.25%)",
      mediumGrayGradient: "linear-gradient(180deg, #C090FF 0%, #8C57FF 100%)",
      lightPurpleGradient: "linear-gradient(180deg, #F3C4FF 0%, #AF92FF 100%)",
      purpleStroke: "#AD51FF",
      linkBlue: "#70b0ff",
      redGradient: "linear-gradient(47.48deg, #94005C -4.73%, #FF0F73 100%)",
      linearRedInverted:
        "linear-gradient(47.48deg, #FF0F73 -4.73%, #94005C 100%)",
      linearGray:
        "linear-gradient(180deg, #151317 0%, rgba(21, 19, 23, 0.8) 100%)",
      gray1: "#161418",
      gray2: "#212121",
      gray3: "#2e2e2e",
      gray4: "#8c8b8c",
      gray5: "#bebebe",
      gray6: "#dcdcdc",
      gray6BoardBackground: "#f2f2f2",
      gray7CardBackground: "#fbfbfb",
      gray8BoardBackground: "#f2f2f2",
      gray9CardBackground: "#fcfcfc",
      gridBorderGray: "#eaeaea",
      inputDisabledGray: "rgba(0, 0, 0, 0.03)",
      linearGrayDisabled:
        "linear-gradient(56.07deg, #DDDDDD -2.4%, #EAEAEA 93.25%)",
      white: "#fff",
      hoverWhite: "rgba(255,255,255,0.2)",
      hoverGray: "rgba(0,0,0,0.05)",
      cardGreen: "rgba(124, 171, 168, 0.7)",
      cardCircleGreen: "rgba(124, 171, 168, 0.7)",
      linearDeep: "linear-gradient(56.07deg, #7B61FF -2.4%, #7528FF 93.25%)",
      linearDeeper: "linear-gradient(56.07deg, #3A13FF -2.4%, #7528FF 93.25%)",
      transparentPurpleHoverFill:
        "linear-gradient(56.07deg, rgba(117, 40, 255, 0.25) -2.4%, rgba(173, 81, 255, 0.25) 93.25%)",
      wcagTextPurple: "#5B00FF",
      focusOutline: "#AFCBEB",
      placeholderGray: "#5f5f5f",
      solidHoverPink: "#d5c1f4",
      redSolid: "#C7074C",
      redHover: "#DD6A94",
      transparentRedError:
        "linear-gradient(47.48deg, rgba(148, 0, 92, 0.1) -4.73%, rgba(255, 15, 115, 0.1) 100%)",
      hoverPurple: "rgba(154, 40, 255, 0.25)",
      iconGray: "#5f5f5f",

      appCardColor: "#6e57b1",
      jobCardColor: "#3F548B",
      dataCardColor: "#5686B1",

      backgroundLight: "#fafafa",

      // Consider if these should be phased out (deprecation)
      linearDeeper2: "linear-gradient(56.07deg, #7B61FF -2.4%, #7528FF 93.25%)",
      boardBackground: "#f2f2f2",
      lightGray: "#f2f2f2",
      gray400: "gainsboro",
      gray500: "lightgray",
      mutedDark: "#8F95B2",
      primaryDark: "#151317",
      // primaryPurple: "#b081ff",
      blackGradient:
        "linear-gradient(180deg, #151317 0%, rgba(21, 19, 23, 0.8) 100%)",
      darkPurpleGradientDeep:
        "linear-gradient(56.07deg, #7B61FF -2.4%, #7528FF 93.25%)",

      statusColorGreen: "#0D6D0B",
      statusColorYellow: "#E3A64B",
      statusColorGray: "$gray4",
      statusColorRed: "#AE2758",

      // NEW BELOW THIS
      mainBorder: "#E0D7ED",
      primaryPurple: "#9747FF",
      purpleBright: "#F8F3FF",
      purpleDark: "#753FCF",
      greenBright: "#D5FFD9",

      fontDark: "#2B1548",
      fontSecondary: "#857698",

      splashBackground: "#E5E7EA",
    },
    fonts: {
      sans: "Inter-Display, sans-serif",
    },
    fontWeights: {
      normal: "500",
      semiBold: "700",
      light: "300",

      // Inter Display weigths
      interLight: "300",
      interRegular400: "400",
      interMedium: "500",
      interSemiBold600: "600",
      interBold: "700",
      interXBold: "800",
      interBlack: "900",

      // Deprecated below this
      lightOld: "400",
      medium: "500",
      big: "600",
    },
    fontSizes: {
      1: "12px",
      2: "14px",
      3: "16px",
      4: "20px",
      5: "24px",
      6: "32px",
    },
    space: {
      1: "8px",
      2: "16px",
      3: "24px",
      4: "32px",
      5: "40px",
      6: "48px",
      7: "56px",
      8: "64px",
      9: "72px",
      10: "80px",
      11: "88px",
      12: "96px",
    },
    sizes: {
      1: "8px",
      2: "16px",
      3: "24px",
      4: "32px",
      5: "40px",
      6: "48px",
      7: "56px",
      8: "64px",
    },
    radii: {
      1: "2px",
      2: "4px",
      3: "8px",
      round: "50%",
      pill: "9999px",
    },
    zIndices: {
      1: "100",
      2: "200",
      3: "300",
      4: "400",
      max: "999",
    },
    shadows: {
      mediumElevation: "0px 4px 8px rgba(55, 61, 64, 0.16)",
      largeElevation: "0px 8px 16px rgba(0, 0, 0, 0.16)",
    },
  },
  media: {
    mobileScreen: "only screen and (max-width: 767px)",
    tabletScreen: "only screen and (min-width: 768px) and (max-width: 991px)",
    laptopScreen: "only screen and (min-width: 992px) and (max-width: 1199px)",
    desktopScreen: "only screen and (min-width: 1200px)",

    // Taken from Chrome Device Toolbar
    mobile: "(min-width: 320px)",
    tablet: "(min-width: 767px)",
    laptop: "(min-width: 1024px)",
    desktop: "(min-width: 1440px)",
    largeDesktop: "(min-width: 1800px)",
    extraLargeDesktop: "(min-width: 2160px)",

    bp1: "(min-width: 520px)", // Replace with mobile
    bp2: "(min-width: 900px)", // Replace with tablet
    bp3: "(min-width: 1200px)", // Replace with laptop
    bp4: "(min-width: 1800px)", // Replace with large desktop
    motion: "(prefers-reduced-motion)",
    hover: "(any-hover: hover)",
    dark: "(prefers-color-scheme: dark)",
    light: "(prefers-color-scheme: light)",
  },

  utils: {
    marginX: (value: number | string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: number | string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: number | string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: number | string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    size: (value: number | string) => ({
      width: value,
      height: value,
    }),
  },
});
