import { createContext } from "react";
import { App, MultipageApp } from "../../types/persisted";

export type AppContextType =
  | {
      type: "view";
      app: App;
      projectId: string;
    }
  | { type: "multipage-app"; app: MultipageApp; projectId: string }
  | null;

export const AppContext = createContext<AppContextType>(null);
