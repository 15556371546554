import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Deployment, MultipageAppDeployment } from "../types/persisted";
import { deploymentConverter } from "../utils/collections/deployments";
import { createConverter } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useDeployments = ({
  deploymentCollection,
  count = 10,
  shouldFetch = true,
}: {
  deploymentCollection?: string | null;
  count?: number;
  shouldFetch?: boolean;
}) => {
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [latestDeployment, setLatestDeployment] = useState<Deployment | null>(
    null,
  );

  useEffect(() => {
    const unsub =
      deploymentCollection && shouldFetch
        ? onSnapshot(
            query(
              collection(firestore, deploymentCollection),
              orderBy("createdAt", "desc"),
              limit(count),
            ).withConverter(deploymentConverter),
            (snapshot) => {
              if (snapshot.size > 0) {
                setLatestDeployment(snapshot.docs[0].data());
              } else {
                setLatestDeployment(null);
              }

              setDeployments(snapshot.docs.map((it) => it.data()));
            },
          )
        : null;

    return () => {
      unsub?.();
    };
  }, [deploymentCollection, count]);

  return { deployments, latestDeployment };
};

const multipageAppDeploymentConverter =
  createConverter<MultipageAppDeployment>();

export const useMultipageAppDeployments = ({
  deploymentCollection,
  count = 10,
  shouldFetch = true,
}: {
  deploymentCollection?: string | null;
  count?: number;
  shouldFetch?: boolean;
}) => {
  const [values] = useCollectionData(
    deploymentCollection && shouldFetch
      ? query(
          collection(firestore, deploymentCollection).withConverter(
            multipageAppDeploymentConverter,
          ),
          where("deployFinishedAt", "!=", null),
          orderBy("deployFinishedAt", "desc"),
          // orderBy("createdBy.timestamp", "desc"),
          limit(count),
        )
      : null,
  );

  return { deployments: values ?? [], latestDeployment: values?.[0] ?? null };
};
