import { createContext, ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";
import { RoomProvider } from "../../context/liveblocks-context";
import { useProjectSubscription } from "../../hooks/useProject";
import { useUser } from "../../hooks/useUser";
import { ProjectSummary } from "../../types/persisted";
import { ProjectNotFound } from "../ProjectNotFound/ProjectNotFound";
import { UserGuard } from "../UserGuard/UserGuard";

interface Props {
  children: ReactNode;
}

/**
 * Temp workaround for ensuring project is always set and typed
 *
 * This Context must not be used without checking that project is actually present
 */
export const ProjectGuardContext = createContext<ProjectSummary>(
  {} as ProjectSummary,
);

export const ProjectGuard = ({ children }: Props) => {
  const params = useParams() as {
    projectId: string;
  };

  const { projectSummary, loading } = useProjectSubscription(params.projectId);
  const { user } = useUser();

  // ID check to avoid flaky behaviour when switching between two projects
  if (loading || (projectSummary && projectSummary?.id !== params.projectId)) {
    return null;
  }

  if (projectSummary?.markedForDeletionAt) {
    return <ProjectNotFound />;
  }

  if (projectSummary) {
    return (
      <ProjectGuardContext.Provider value={projectSummary}>
        <RoomProvider
          id={projectSummary.id}
          initialPresence={{
            cursor: null,
            name: null,
            avatar: null,
            pathname: null,
          }}
        >
          <UserGuard>{children}</UserGuard>
        </RoomProvider>
      </ProjectGuardContext.Provider>
    );
  }

  if (user) {
    return <ProjectNotFound />;
  }

  return (
    <Navigate replace={true} to={`/login?next=${window.location.pathname}`} />
  );
};
