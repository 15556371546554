import { collection, CollectionReference } from "firebase/firestore";
import { Deployment } from "../../types/persisted";
import { firestore } from "../firebase";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

export const deploymentConverter = createConverter<Deployment>();

export const getAppDeploymentsCollectionKey = (params: {
  projectId: string;
  appId: string;
}): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.APPS}/${params.appId}/${CollectionName.DEPLOYMENTS}`;

export const getJobDeploymentsCollectionKey = (params: {
  projectId: string;
  jobId: string;
}): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.JOBS}/${params.jobId}/${CollectionName.DEPLOYMENTS}`;

export const createDeploymentCollectionRef = (params: {
  componentRefPath: string;
}): CollectionReference<Deployment> =>
  collection(
    firestore,
    createCollectionRefPath([
      params.componentRefPath,
      CollectionName.DEPLOYMENTS,
    ]),
  ).withConverter(deploymentConverter);
