import { useEffect } from "react";
import { styled } from "../../../stitches.config";
import { useStore } from "../../store/store";
import { LiveIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { Tooltip } from "../../ui/Tooltip";
import { Txt } from "../../ui/TypographyV3/TypographyV3";
import { Sparkline } from "./components/Sparkline";

const SparklineCard = styled("div", {
  display: "grid",
  minHeight: "80px",
  width: "350px",
  border: "1px solid $mainBorder",
  padding: "$2",
  borderRadius: "4px",
  gridTemplateColumns: "min-content auto",
  columnGap: "$1",
});

const ActiveVisitorWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$1",
  backgroundColor: "$greenBright",
  width: "fit-content",
  padding: "$1",
});

export const VisitorSparkline = () => {
  const sparklineMounted = useStore((state) => state.sparklineMounted);
  const sparkline = useStore((state) => state.project.sparkline);

  useEffect(() => {
    sparklineMounted();
  }, []);

  return sparkline && sparkline.newVisits > 0 ? (
    <SparklineCard>
      <Flex direction="vertical" gap="1" justifyContent="space-between">
        <Tooltip text="Visits last 7 days">
          <Txt weight="semiBold" css={{ whiteSpace: "nowrap" }}>
            {sparkline.newVisits} New Visits
          </Txt>
        </Tooltip>

        <ActiveVisitorWrapper
          css={{
            visibility: sparkline.activeVisitors > 0 ? "visible" : "hidden",
          }}
        >
          <Txt weight="semiBold" color="green" css={{ whiteSpace: "nowrap" }}>
            {sparkline.activeVisitors} Active
          </Txt>

          <LiveIcon />
        </ActiveVisitorWrapper>
      </Flex>

      <Flex direction="vertical" gap="1">
        <Sparkline data={sparkline.sparklineChart} />

        <Txt
          color="secondary"
          weight="semiBold"
          css={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
        >
          LAST 7 DAYS
        </Txt>
      </Flex>
    </SparklineCard>
  ) : null;
};
