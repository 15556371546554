import { styled } from "../../../../stitches.config";
import { AppOrMultipageApp } from "../../../components/AppGuard/AppGuard";
import { Box } from "../../../ui/Box";
import { SharedViewCard } from "./SharedViewCard";

interface Props {
  apps: AppOrMultipageApp[];
}

const Group = styled("div", {
  display: "grid",
  width: "100%",
  gap: "$2",

  variants: {
    columns: {
      "2": {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      "4": {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
      "6": {
        gridTemplateColumns: "repeat(6, 1fr)",
      },
      "8": {
        gridTemplateColumns: "repeat(8, 1fr)",
      },
      "10": {
        gridTemplateColumns: "repeat(10, 1fr)",
      },
      "12": {
        gridTemplateColumns: "repeat(12, 1fr)",
      },
    },
  },
});

export const ViewCardGroup = ({ apps }: Props) => (
  <Box>
    <Group
      columns={{
        "@mobile": "2",
        "@tablet": "4",
        "@laptop": "6",
        "@desktop": "8",
        "@largeDesktop": "10",
        "@extraLargeDesktop": "12",
      }}
    >
      {apps.map((it) => (
        <SharedViewCard key={it.refPath} app={it} />
      ))}
    </Group>
  </Box>
);
