import { useMemo } from "react";
import { Datafile, Dataframe } from "../types/persisted";
import { useDatafiles } from "./useDatafiles";
import { useDataframes } from "./useDataframes";

export interface DataStorageGroups {
  dataframes: Dataframe[];
  allDatafiles: Datafile[];
  json: Datafile[];
  text: Datafile[];
  binary: Datafile[];
  numberOfFiles: number;
  numberOfGroups: number;
}

export const useDataStorageGroups = ({ projectId }: { projectId: string }) => {
  const { dataframes } = useDataframes({ projectId });

  const { datafiles } = useDatafiles({ projectId });

  const groupedDatafiles: Pick<DataStorageGroups, "json" | "text" | "binary"> =
    useMemo(
      () => ({
        json: datafiles.filter((it) => it.contentType === "application/json"),
        text: datafiles.filter((it) => it.contentType === "text/plain"),
        binary: datafiles.filter(
          (it) => it.contentType === "application/octet-stream",
        ),
      }),
      [datafiles],
    );

  const numberOfGroups = useMemo(
    () =>
      Object.values(groupedDatafiles).reduce(
        (prev, curr) => (curr.length > 0 ? prev + 1 : prev),
        0,
      ) + (dataframes.length > 0 ? 1 : 0),
    [groupedDatafiles, dataframes],
  );

  const dataStorageGroups: DataStorageGroups = {
    dataframes,
    allDatafiles: datafiles,
    json: groupedDatafiles.json,
    text: groupedDatafiles.text,
    binary: groupedDatafiles.binary,
    numberOfFiles: datafiles.length + dataframes.length,
    numberOfGroups,
  };

  return dataStorageGroups;
};
