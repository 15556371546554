import { toast } from "react-toastify";
import { useUserGuardContext } from "../../../../components/UserGuard/UserGuard";
import { ZonedTimestampByDate } from "../../../../components/ZonedTimestamp/ZonedTimestamp";
import { pauseSchedule, startSchedule } from "../../../../domain/schedules";
import { Job, Schedule } from "../../../../types/persisted";
import { Button } from "../../../../ui/Button";
import { PauseIcon, PlayIcon } from "../../../../ui/Icon";
import { Flex } from "../../../../ui/Layout/Flex";
import { Txt } from "../../../../ui/TypographyV3/TypographyV3";
import { scheduleJob } from "../../../../utils/job-utils";
import { SimpleScheduleButton } from "../../EditJobForm/SimpleScheduleButton";

interface Props {
  projectId: string;
  job: Job;
  schedule: Schedule;
  nextRun: Date | null;
}

export const ScheduledActions = ({
  projectId,
  job,
  schedule,
  nextRun,
}: Props) => {
  const { user } = useUserGuardContext();

  const handleStartScheduleClicked = async () => {
    await toast.promise(startSchedule({ job }), {
      error: "Could not start schedule",
      pending: "Starting schedule",
      success: "Schedule started",
    });
  };

  const handlePauseScheduleClicked = async () => {
    await toast.promise(pauseSchedule({ job }), {
      error: "Could not pause schedule",
      pending: "Pausing schedule",
      success: "Schedule paused",
    });
  };

  return (
    <Flex gap="3" direction="vertical">
      <Flex gap="1">
        {schedule.state === "ACTIVE" ? (
          <Button
            intent="plain"
            content="icon"
            onClick={handlePauseScheduleClicked}
          >
            <PauseIcon />
          </Button>
        ) : (
          <Button
            intent="plain"
            content="icon"
            onClick={handleStartScheduleClicked}
          >
            <PlayIcon />
          </Button>
        )}

        <Flex gap="1" direction="vertical">
          <Txt color="purple">
            {schedule.state === "ACTIVE"
              ? "Running Schedule"
              : "Paused Schedule"}
          </Txt>

          {nextRun && (
            <Txt color="disabled" size="10">
              Next run:{" "}
              {schedule.state === "ACTIVE" ? (
                <ZonedTimestampByDate date={nextRun} />
              ) : (
                "PAUSED"
              )}
            </Txt>
          )}
        </Flex>
      </Flex>

      <Flex gap="2">
        <SimpleScheduleButton
          size="large"
          onSubmit={async (cronExpression, scheduleForm) => {
            await scheduleJob({
              projectId,
              job,
              cronExpression,
              user,
              scheduleForm,
            });
          }}
          existingSchedule={schedule}
        />
      </Flex>
    </Flex>
  );
};
