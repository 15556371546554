import { Div } from "../../ui/Layout";
import { DevRunsTable } from "./DevRunsTable";
import { useDevRuns } from "./useDevRuns";

// Expand latest run and runs the user expands

interface Props {
  projectId: string;
  jobId: string;
  codeBlockId: string;
}

export const JobDevRuns = ({ projectId, jobId, codeBlockId }: Props) => {
  const { values: runs } = useDevRuns({
    projectId,
    jobId,
    codeBlockId,
  });

  if (!runs || runs.length === 0) {
    return (
      <Div
        css={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        Hint: Press Shift + Enter to run your code
      </Div>
    );
  }

  return <DevRunsTable projectId={projectId} jobId={jobId} runs={runs} />;
};
