import { styled } from "../../../stitches.config";
import { Box } from "../Box";

export const Row = styled(Box, {
  display: "flex",
  gap: "$2",

  variants: {
    align: {
      center: {
        justifyContent: "center",
      },
      right: {
        justifyContent: "flex-end",
      },
    },

    marginBottom: {
      "1": {
        marginBottom: "$1",
      },
      "2": {
        marginBottom: "$2",
      },
      "3": {
        marginBottom: "$3",
      },
    },

    justifyContent: {
      "space-between": {
        justifyContent: "space-between",
      },
    },
  },
});
