import * as Sentry from "@sentry/react";
import { setUserProperties } from "firebase/analytics";
import { getAuth, User } from "firebase/auth";
import { postIdToken } from "../../components/LoginButton/utils";
import { analytics, firebaseApp } from "../../utils/firebase";
import { isDatabuttonEmail } from "../../utils/user-utils";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

interface AuthModel {
  user: User | null;
  segments: string[];
  enabledFeatures: string[];
  isInitialized: boolean;
  isListening: boolean;
}

export interface AuthSlice {
  fetchUser: () => void;
  setUser: (user: User) => Promise<void>;
  auth: AuthModel;
}

const actionType = createActionTypeLogger("auth");

export const authStore: StoreSlice<AuthSlice> = (set, get) => ({
  auth: {
    user: null,
    segments: [],
    enabledFeatures: [],
    isListening: false,
    isInitialized: false,
  },
  setUser: async (user: User) => {
    const idToken = await user.getIdToken();
    await postIdToken(idToken);

    set(
      (draft) => {
        draft.auth.user = user;
        draft.auth.isInitialized = true;
      },
      false,
      actionType("set-user"),
    );
  },
  fetchUser: async () => {
    const { isListening } = get().auth;
    if (!isListening) {
      set(
        (draft) => {
          draft.auth.isListening = true;
        },
        false,
        actionType("set-listening"),
      );

      getAuth(firebaseApp).onAuthStateChanged(async (user?: User | null) => {
        if (user) {
          Sentry.setUser({
            email: user.email ?? "no-email",
            id: user.uid,
          });
          window.analytics.identify(
            user.uid,
            {
              email: user.email,
              name: user.displayName,
            },
            () => {
              setUserProperties(analytics, {
                is_databutton_employee:
                  user.email && isDatabuttonEmail(user.email),
              });
            },
          );

          const [idTokenResult, idToken] = await Promise.all([
            user.getIdTokenResult(true),
            user.getIdToken(),
          ]);
          await postIdToken(idToken);

          const claims = idTokenResult.claims;

          set(
            (draft) => {
              draft.auth.isInitialized = true;
              draft.auth.user = user;
              draft.auth.enabledFeatures =
                "enabledFeatures" in claims ? claims["enabledFeatures"] : [];
              draft.auth.segments =
                "segments" in claims ? claims["segments"] : [];
            },
            false,
            actionType("set-user"),
          );
        } else {
          window.analytics.reset();
          Sentry.setUser(null);
          set(
            (draft) => {
              draft.auth.isInitialized = true;
              draft.auth.user = null;
              draft.auth.enabledFeatures = [];
              draft.auth.segments = [];
            },
            false,
            actionType("reset-user"),
          );
        }
      });
    }
  },
});
