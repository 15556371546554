import { User } from "firebase/auth";
import { doc, getDoc, runTransaction, updateDoc } from "firebase/firestore";
import urlSlug from "url-slug";
import {
  CodeBlock,
  CodeBlockVersion,
  Module,
  WithoutId,
} from "../../types/persisted";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import { firestore } from "../firebase";
import { createPerformedByObj } from "../user-utils";
import {
  codeBlockVersionConverter,
  createCodeBlockVersionDocument,
  getCodeBlockVersionCollectionKey,
} from "./code-block-versions";
import {
  createCodeBlockDocument,
  getCodeBlockCollectionKey,
} from "./code-blocks";
import { CollectionName, createConverter, createDocRefPath } from "./shared";

export const moduleConverter = createConverter<Module>();

export const getModuleCollectionKey = (params: { projectId: string }): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.MODULES}`;

type CreateModuleParams = WithoutId<Module>;

const createNewModuleRequest = (params: {
  name: string;
  user: User;
  codeBlockRef: string;
}): CreateModuleParams => ({
  name: params.name,
  slug: urlSlug(params.name),
  createdBy: createPerformedByObj({ user: params.user }),
  markedForDeletionBy: null,
  codeBlockRef: params.codeBlockRef,
});

const getDefaultModuleCode = (moduleName: string): string => `# Here you can write code for functions and variables that you want to use
# across your views and jobs. The name of the library is used to import code.
#
# To import code from this library in your views or jobs you can use the
# following code:
#
# import ${moduleName}
# ${moduleName}.my_function()
#
# or
#
# from ${moduleName} import my_function
# my_function()
#
`;

/**
 * Creates a module with a code block and a code block versions and saves it to Firebase.
 *
 * Also syncs the new module to devx
 */
export const createModuleInFirebase = async (params: {
  name: string;
  projectId: string;
  user: User;
  code?: string;
}): Promise<{
  module: Module;
  codeBlock: CodeBlock;
  codeBlockVersion: CodeBlockVersion;
}> => {
  const moduleId = createDatabuttonId(DatabuttonIdPrefix.MODULE);

  const { id: newCodeBlockId, doc: newCodeBlock } = createCodeBlockDocument({
    componentId: moduleId,
    type: "module",
  });

  const { id: newCodeBlockVersionId, doc: newCodeBlockVersion } =
    createCodeBlockVersionDocument({
      name: params.name,
      code: params.code ?? getDefaultModuleCode(params.name),
      user: params.user,
    });

  const newCodeBlockRef = createDocRefPath([
    getCodeBlockCollectionKey(params.projectId),
    newCodeBlockId,
  ]);

  const newModule = createNewModuleRequest({
    name: params.name,
    user: params.user,
    codeBlockRef: newCodeBlockRef,
  });

  const moduleRef = doc(
    firestore,
    getModuleCollectionKey({ projectId: params.projectId }),
    moduleId,
  );

  const codeBlockRef = doc(
    firestore,
    getCodeBlockCollectionKey(params.projectId),
    newCodeBlockId,
  );

  const codeBlockVersionRef = doc(
    firestore,
    getCodeBlockVersionCollectionKey({
      projectId: params.projectId,
      codeBlockId: newCodeBlockId,
    }),
    newCodeBlockVersionId,
  );

  await Promise.all([
    runTransaction(firestore, async (transaction) => {
      transaction
        .set(moduleRef, newModule)
        .set(codeBlockRef, newCodeBlock)
        .set(codeBlockVersionRef, newCodeBlockVersion);
    }),
  ]);

  const persistedCodeBlockVersionDoc = await getDoc(
    codeBlockVersionRef.withConverter(codeBlockVersionConverter),
  );

  const persistedCodeBlockVersion = persistedCodeBlockVersionDoc.data();

  if (!persistedCodeBlockVersion) {
    throw new Error(
      `Unable to fetch newely created code block version: ${codeBlockVersionRef.path}`,
    );
  }

  return {
    module: {
      ...newModule,
      id: moduleRef.id,
      refPath: moduleRef.path,
    },
    codeBlock: {
      ...newCodeBlock,
      id: codeBlockRef.id,
      refPath: codeBlockRef.path,
    },
    codeBlockVersion: persistedCodeBlockVersion,
  };
};

export const updateModule = async (params: {
  refPath: string;
  payload: Partial<Module>;
}): Promise<void> => updateDoc(doc(firestore, params.refPath), params.payload);
