import { useEffect } from "react";

const DEFAULT_PAGE_TITLE = "Databutton";

export const useTitle = (title?: string) => {
  useEffect(() => {
    document.title = title
      ? `${DEFAULT_PAGE_TITLE} | ${title}`
      : DEFAULT_PAGE_TITLE;

    return () => {
      document.title = DEFAULT_PAGE_TITLE;
    };
  }, [title]);
};
