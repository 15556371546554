import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalDialog } from "../../../components/ModalDialog";
import { NewProjectForm } from "../../../components/NewProjectForm/NewProjectForm";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import { routeUtils } from "../../../utils/route-utils";

interface Props {
  buttonText?: string;
  onOpenModalClick?: () => void;
  isModalOpen: boolean;
}

export const NewDataAppButton = ({
  buttonText,
  onOpenModalClick,
  isModalOpen: isModalOpenFromProps,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(isModalOpenFromProps);
  const projectTemplates = useStore((state) =>
    state.homeState.projectTemplates.filter((it) => it.active),
  );
  const initialFocusRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => {
          onOpenModalClick?.();
          setIsModalOpen(true);
        }}
      >
        {buttonText ?? "Create new data app"}
      </Button>
      <ModalDialog
        modalSize="wide"
        isOpen={isModalOpen}
        initialFocus={initialFocusRef}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title="Select template"
        content={
          <NewProjectForm
            projectTemplates={projectTemplates}
            onSuccess={({ projectId }) => {
              navigate(
                routeUtils.path({
                  to: "project",
                  params: {
                    projectId,
                  },
                }),
              );
            }}
          />
        }
      />
    </>
  );
};
