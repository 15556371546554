import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { styled } from "../../../stitches.config";
import { useProfile } from "../../hooks/useProfile";
import {
  getReadProjectEventsAt,
  useProjectEvents,
} from "../../hooks/useProjectEvents";
import { useUser } from "../../hooks/useUser";
import { ProjectEvent } from "../../types/persisted";
import { TopBarButton } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div, Group } from "../../ui/Layout";
import { NavigateLink } from "../../ui/NavigateLink";
import { Popover } from "../../ui/Popover";
import { H2 } from "../../ui/Typography";
import { ProjectEventRow } from "../ProjectEventRow/ProjectEventRow";
import { AllReadButton } from "./AllReadButton";
import { EventSuccessListener } from "./EventSuccessListener";
import { NotificationBadge } from "./NotificationBadge";
import { ProjectEventInProgressIcon } from "./ProjectEventIcon";

interface Props {
  projectId: string;
}

const PopoverButton = styled(TopBarButton, {
  defaultVariants: {
    intent: "secondary",
    content: "icon",
  },
});

const deriveButtonText = (params: {
  latestInProgressEvent: ProjectEvent | null;
  inProgressEvents: ProjectEvent[];
}): string | null => {
  if (!params.latestInProgressEvent) {
    return null;
  }

  if (params.inProgressEvents.length > 1) {
    return `${
      params.latestInProgressEvent.shortTitle ??
      params.latestInProgressEvent.title
    } (+${params.inProgressEvents.length - 1})`;
  }

  return (
    params.latestInProgressEvent.shortTitle ??
    params.latestInProgressEvent.title
  );
};

export const ProjectEvents = ({ projectId }: Props) => {
  const { user } = useUser();
  const { profile } = useProfile(user?.email);
  const {
    events: projectEvents,
    hasUnreadProjectEvents,
    latestInProgressEvent,
    inProgressEvents,
    hasUnreadFailureEvent,
    eventsLast20Minutes,
  } = useProjectEvents({
    projectId,
    readProjectEventsAt: getReadProjectEventsAt({
      map: profile?.projectEventsReadMap,
      projectId,
    }),
  });

  const buttonText = useMemo(
    () => deriveButtonText({ latestInProgressEvent, inProgressEvents }),
    [latestInProgressEvent, inProgressEvents],
  );

  return (
    <Div css={{ position: "relative" }}>
      {eventsLast20Minutes.map((it) => (
        <EventSuccessListener refPath={it.refPath} key={it.id} />
      ))}

      {hasUnreadProjectEvents && (
        <NotificationBadge intent={hasUnreadFailureEvent ? "danger" : "info"} />
      )}

      <Popover
        buttonAs={PopoverButton}
        buttonContent={
          buttonText ? (
            <>
              <ProjectEventInProgressIcon />
              {buttonText}
            </>
          ) : (
            <StyledFontAwesomeIcon icon={faBell} />
          )
        }
        panelContent={
          <Group
            css={{
              width: "350px",
              padding: "$1 0 0",
              fontSize: "$1",
            }}
            direction="vertical"
          >
            <Group
              css={{
                justifyContent: "space-between",
                padding: "$1 $2",
              }}
            >
              <H2 margin="none">Notifications</H2>

              {user && <AllReadButton projectId={projectId} user={user} />}
            </Group>

            {projectEvents?.map((it) => (
              <ProjectEventRow
                key={it.id}
                event={it}
                profile={profile}
                projectId={projectId}
              />
            ))}

            <Group css={{ padding: "$2 $2 $1", justifyContent: "center" }}>
              <NavigateLink to="events">See all events</NavigateLink>
            </Group>
          </Group>
        }
      />
    </Div>
  );
};
