import { User } from "firebase/auth";
import { dbtnApi } from "../../services/dbtn-api";

export const runCodeAsync = async ({
  projectId,
  jobId,
  runId,
  code,
  user,
}: {
  projectId: string;
  jobId: string;
  runId: string;
  code: string;
  user: User;
}): Promise<boolean> => {
  const route = "/dbtn/jobs/run-in-devx";

  const response = await dbtnApi.post({
    projectId,
    route,
    user,
    json: {
      jobId,
      runId,
      requirements: "",
      code,
    },
  });

  return response.ok;
};
