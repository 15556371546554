import { faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { CodeBlockVersion } from "../../types/persisted";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Text } from "../../ui/Text";
import { ZonedTimestamp } from "../ZonedTimestamp/ZonedTimestamp";

interface Props {
  version: CodeBlockVersion;
}

export const VersionText = ({ version }: Props) => (
  <Text
    margin="none"
    css={{
      maxWidth: "calc(100% - 150px - $1)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }}
  >
    <StyledFontAwesomeIcon
      icon={faRectangleHistory}
      css={{ marginRight: "$1" }}
    />
    <ZonedTimestamp timestamp={version.createdAtUtc} />
    {version.createdBy?.type === "user" ? ` by ${version.createdBy.name}` : ""}
  </Text>
);
