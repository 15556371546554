import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Dataframe } from "../types/persisted";
import {
  dataframeConverter,
  getDataframesCollectionRefPath,
} from "../utils/collections/dataframes";
import { firestore } from "../utils/firebase";

export const useDataframes = ({ projectId }: { projectId: string }) => {
  const [dataframes, setDataframes] = useState<Dataframe[]>([]);

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(firestore, getDataframesCollectionRefPath({ projectId })),
        orderBy("createdAtMillis", "asc"),
      ).withConverter(dataframeConverter),
      (snapshot) => {
        setDataframes(
          snapshot.docs
            .map((it) => it.data())
            .filter(
              (it) => !(it.markedForDeletionAt || it.markedForDeletionBy),
            ), // TODO: Move to query when data has been migrated (to not optional)
        );
      },
    );

    return () => {
      unsub();
    };
  }, [projectId]);

  return { dataframes };
};
