import { styled } from "../../../stitches.config";

export const Divider = styled("div", {
  height: "1px",
  width: "100%",
  backgroundColor: "$mainBorder",

  variants: {
    marginY: {
      "1": {
        marginTop: "$1",
        marginBottom: "$1",
      },
      "2": {
        marginTop: "$2",
        marginBottom: "$2",
      },
      "3": {
        marginTop: "$3",
        marginBottom: "$3",
      },
      "4": {
        marginTop: "$4",
        marginBottom: "$4",
      },
      "5": {
        marginTop: "$5",
        marginBottom: "$5",
      },
    },
  },
});
