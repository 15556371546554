import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
} from "firebase/firestore";
import { Schedule } from "../../types/persisted";
import { firestore } from "../firebase";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

export const scheduleConverter = createConverter<Schedule>();

export const getScheduleCollectionKey = (
  params:
    | {
        projectId: string;
        jobId: string;
      }
    | { jobRefPath: string },
): string =>
  "jobRefPath" in params
    ? createCollectionRefPath([params.jobRefPath, CollectionName.SCHEDULES])
    : createCollectionRefPath([
        CollectionName.PROJECTS,
        params.projectId,
        CollectionName.JOBS,
        params.jobId,
        CollectionName.SCHEDULES,
      ]);

export const updateSchedule = async (params: {
  refPath: string;
  request: Partial<Schedule>;
}): Promise<void> => {
  await updateDoc(doc(firestore, params.refPath), params.request);
};

export const fetchSchedule = async (params: {
  jobRefPath: string;
}): Promise<Schedule | null> => {
  const result = await getDocs(
    query(
      collection(
        firestore,
        getScheduleCollectionKey({
          jobRefPath: params.jobRefPath,
        }),
      ).withConverter(scheduleConverter),
      limit(1),
    ),
  );

  return result.size > 0 ? result.docs[0].data() : null;
};
