import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { useContext } from "react";
import { createCodeBlockSelector } from "../../store/slices/code-slice";
import { useStore } from "../../store/store";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { takeControlOverCodeBlock } from "../../utils/code-block-utils";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";
import { UserGuardContext } from "../UserGuard/UserGuard";

export const NotInControlWarning = () => {
  const { projectId, codeBlockId } = useWorkspaceContext();
  const { user } = useContext(UserGuardContext);
  const codeBlockState = useStore(createCodeBlockSelector(codeBlockId));

  return (
    <Flex
      direction="horizontal"
      gap="3"
      css={{
        position: "absolute",
        backgroundColor: "$primaryDark",
        fontSize: "$1",
        fontWeight: "600",
        color: "$white",
        left: 0,
        top: 0,
        right: 0,
        padding: "calc($1/2) $1",
        justifyContent: "space-between",
      }}
    >
      {codeBlockState?.codeBlock?.controlTakenBy?.type === "user"
        ? codeBlockState?.codeBlock?.controlTakenBy?.name
        : "Unknown"}{" "}
      is currently editing.
      <Button
        intent="secondary-alternate"
        onClick={() => {
          takeControlOverCodeBlock({
            projectId,
            codeBlockId,
            user,
          });
        }}
      >
        <StyledFontAwesomeIcon icon={faPenToSquare} />
        Take over
      </Button>
    </Flex>
  );
};
