import { styled } from "../../../../../stitches.config";

export const BlurredText = styled("div", {
  backgroundColor: "$mainBorder",
  borderRadius: "8px",

  variants: {
    size: {
      large: {
        height: "12px",
        width: "160px",
      },
      medium: {
        height: "10px",
        width: "50px",
      },
    },
  },

  defaultVariants: {
    size: "medium",
  },
});
