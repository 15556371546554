import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { restoreVersion } from "../../domain/code";
import { useQuery } from "../../hooks/useQuery";
import { useIsDevxReady } from "../../store/slices/project-slice";
import { useStore } from "../../store/store";
import { CodeBlockVersion } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { Div } from "../../ui/Layout";
import { Flex } from "../../ui/Layout/Flex";
import { Text } from "../../ui/Text";
import {
  BROWSED_PREVIOUS_VERSION,
  RESTORED_PREVIOUS_VERSION,
} from "../../utils/analytics-constants";
import {
  fetchCodeBlockVersion,
  fetchLatestCodeBlockVersion,
} from "../../utils/collections/code-block-versions";
import { DatabuttonDiffEditorLazy } from "../DatabuttonEditor/DatabuttonDiffEditorLazy";
import { LoadingScreen } from "../LoadingScreen";
import { useUserGuardContext } from "../UserGuard/UserGuard";
import { getVerboseTimestamp } from "../ZonedTimestamp/utils";
import { VersionText } from "./VersionText";

const VERSION_SEPARATOR = "---";

export const CodeDiff = () => {
  const navigate = useNavigate();
  const { queryParams } = useQuery();
  const { user } = useUserGuardContext();
  const isDevxReady = useIsDevxReady();
  const { projectId, codeBlockId, leftAndRight } = useParams() as {
    projectId: string;
    codeBlockId: string;
    leftAndRight: string;
  };
  const leftCodeBlockVersion = useMemo(
    () => leftAndRight.split(VERSION_SEPARATOR)[0],
    [leftAndRight],
  );
  const rightCodeBlockVersion = useMemo(
    () => leftAndRight.split(VERSION_SEPARATOR)[1],
    [leftAndRight],
  );
  const [isRestoring, setIsRestoring] = useState(false);

  const isRestorable = queryParams.get("restorable") === "true";

  const [left, setLeft] = useState<CodeBlockVersion | null>(null);
  const [right, setRight] = useState<CodeBlockVersion | null>(null);
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  useEffect(() => {
    pulseTriggered({
      user,
      eventName: BROWSED_PREVIOUS_VERSION,
      properties: {
        projectId,
      },
    });
  }, []);

  useEffect(() => {
    fetchCodeBlockVersion({
      projectId,
      codeBlockId,
      codeBlockVersionId: leftCodeBlockVersion,
    }).then(setLeft);
  }, [leftCodeBlockVersion]);

  useEffect(() => {
    if (rightCodeBlockVersion === "latest") {
      fetchLatestCodeBlockVersion({
        projectId,
        codeBlockId,
      }).then(setRight);
    } else {
      fetchCodeBlockVersion({
        projectId,
        codeBlockId,
        codeBlockVersionId: rightCodeBlockVersion,
      }).then(setRight);
    }
  }, [rightCodeBlockVersion]);

  const handleRestoreVersion = async (version: CodeBlockVersion) => {
    setIsRestoring(true);

    await restoreVersion({
      projectId,
      codeBlockId,
      version,
      user,
      isDevxReady,
    });

    pulseTriggered({
      eventName: RESTORED_PREVIOUS_VERSION,
      user,
      properties: {
        projectId,
      },
    });

    toast(
      `Restored code to ${getVerboseTimestamp({
        timestamp: version.createdAtUtc,
        format: "YYYY-MM-DD HH:mm:ss",
      })}`,
    );
    navigate(-1);
    setIsRestoring(false);
  };

  if (left !== null && right !== null) {
    return (
      <Div
        css={{
          display: "grid",
          gridTemplateRows: "50px auto",
          height: "100%",
        }}
      >
        <Div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "$1",
            padding: "0 $1",
            borderBottom: "1px solid $mainBorder",
          }}
        >
          <Flex direction="horizontal" gap="2" css={{ paddingRight: "$2" }}>
            <VersionText version={left} />

            {isRestorable && (
              <Button
                css={{
                  wordWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
                disabled={isRestoring}
                onClick={() => {
                  handleRestoreVersion(left);
                }}
              >
                Restore left version
              </Button>
            )}

            <Button
              intent="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Flex>

          <Flex
            direction="horizontal"
            css={{ justifyContent: "space-between" }}
          >
            {rightCodeBlockVersion === "latest" ? (
              <Text margin="none">Current draft</Text>
            ) : (
              <VersionText version={right} />
            )}
          </Flex>
        </Div>
        <DatabuttonDiffEditorLazy left={left.code} right={right.code} />
      </Div>
    );
  }

  return <LoadingScreen message="Loading diff..." />;
};
