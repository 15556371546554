import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import {
  collectionGroup,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { AppOrMultipageApp } from "../components/AppGuard/AppGuard";
import { fetchAppOrMultipageApp } from "../components/AppGuard/utils";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";
import { distinct, notEmpty } from "../utils/ts-utils";
import { appOrMultipageAppConverter } from "./useAppOrMultipageAppByRef";

const toAppRefPaths = (
  appSnapshot?: QuerySnapshot<AppOrMultipageApp>,
): string[] =>
  (appSnapshot?.docs ?? []).map(
    (it) => it.data().refPath.split(`/${CollectionName.VIEWERS}/`)[0],
  );

export const useAppsSharedWithUser = (user: User | null) => {
  const shareActions = useStore((state) => state.shareActions);

  const [viewersByUserId, viewersByUserIdLoading] = useCollectionOnce(
    user
      ? query(
          collectionGroup(firestore, CollectionName.VIEWERS),
          where("revoked", "==", false),
          where("userId", "==", user.uid),
        ).withConverter(appOrMultipageAppConverter)
      : null,
  );

  const [viewersByEmail, viewersByEmailLoading] = useCollectionOnce(
    user
      ? query(
          collectionGroup(firestore, CollectionName.VIEWERS),
          where("revoked", "==", false),
          where("recipient", "==", user.email),
        ).withConverter(appOrMultipageAppConverter)
      : null,
  );

  const appRefPaths = useMemo(
    () =>
      [
        ...toAppRefPaths(viewersByUserId),
        ...toAppRefPaths(viewersByEmail),
      ].filter(distinct),
    [viewersByUserId, viewersByEmail],
  );

  useEffect(() => {
    if (
      !(viewersByUserIdLoading || viewersByEmailLoading) &&
      appRefPaths.length > 0
    ) {
      logService.debug("Fetching apps shared with user");
      Promise.all(
        appRefPaths.map((it) =>
          fetchAppOrMultipageApp({ refPath: it }).catch((e) => {
            Sentry.captureException(e);
            return null;
          }),
        ),
      ).then((it) => {
        shareActions.appsSharedReceived({ items: it.filter(notEmpty) });
      });
    }
  }, [appRefPaths, viewersByUserIdLoading, viewersByEmailLoading]);
};
