import { collection, query } from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Datafile } from "../types/persisted";
import {
  datafileConverter,
  getDatafilesCollectionKey,
} from "../utils/collections/datafiles";
import { firestore } from "../utils/firebase";

export const useDatafiles = (params: { projectId: string }) => {
  const [values] = useCollectionData<Datafile>(
    query(
      collection(
        firestore,
        getDatafilesCollectionKey({ projectId: params.projectId }),
      ),
    ).withConverter(datafileConverter),
  );

  const datafileExcludingDeleted = useMemo(
    () =>
      values
        ? values.filter(
            // TODO: Move to query when data has been migrated (to not optional)
            (it) => !(it.markedForDeletionAt || it.markedForDeletionBy),
          )
        : [],
    [values],
  );

  return { datafiles: datafileExcludingDeleted };
};
