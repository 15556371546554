import { PerformedBy as PerformedByDoc } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { PerformedBy } from "../types/payloads";
import { randomChoice } from "./ts-utils";

export const isDatabuttonEmail = (email: string): boolean =>
  /@databutton\.(io|com)$/i.test(email);

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if ("clipboard" in navigator) {
    navigator.clipboard.writeText(text);
    return true;
  }

  // Fallback for old browser (IE)
  document.execCommand("copy", true, text);
  return true;
};

export const createPerformedByObj = (params: {
  user: User;
}): PerformedByDoc => ({
  type: "user",
  timestamp: Timestamp.now(),
  id: params.user.uid,
  name: params.user.displayName,
});

export const createPerformedByObjWithServerTimestamp = (params: {
  user: User;
}): PerformedBy => ({
  type: "user",
  timestamp: serverTimestamp(),
  id: params.user.uid,
  name: params.user.displayName,
});

export const createPerformedBySystemObj = (): PerformedByDoc => ({
  type: "system",
  id: "databutton",
  timestamp: Timestamp.now(),
});

const BASE_TIPS: string[] = [
  "Warming up environment...",
  "Preparing your workspace",
  "Soon ready!",
  "Tip: Secrets can be retrieved with db.secrets.get()",
  "Tip: All files can be stored safely as bytes using db.storage.binary.put()",
  "Tip: You can store JSON using db.storage.json.put()",
  "Tip: Dataframes can be stored with db.storage.dataframes.put('key', df)",
  "Tip: Return values from functions can be cached using the @db.cache decorator",
  "Tip: You can install packages under 'Configuration'",
  "Tip: You can invite collaborators to your data app via email",
  "Tip: You can add secrets to your data app under 'Configuration'",
  "Tip: Run your code using SHIFT + ENTER",
  "Tip: Autoformat your code with CTRL/CMD + S",
  "Tip: Open our command palette with CTRL/CMD + K",
  "Tip: Open and close the sidebar using CTRL/CMD + .",
];

export const randomAppTip = (): string =>
  randomChoice([
    ...BASE_TIPS,
    "Tip: You can share your app via email",
    "Tip: You can share your app to anyone with a link",
    "Tip: Enable hot-reloading to get instant feedback on your code changes",
    "Tip: Use st.write() instead of print() to debug. Your printouts will look awesome!",
  ]);

export const randomJobTip = (): string =>
  randomChoice([
    ...BASE_TIPS,
    "Tip: Your job can be scheduled to run whenever you want",
    "Tip: Your job will continue to run if you close the window",
  ]);
