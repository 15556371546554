import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { useContext } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { styled } from "../../../stitches.config";
import { SidecarContainer } from "../../pages/ProjectPage/SidecarContainer";
import { SidecarContent } from "../../pages/ProjectPage/SidecarContent";
import { SidecarNavbar } from "../../pages/ProjectPage/SidecarNavbar";
import { Dataframe } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div, Row } from "../../ui/Layout";
import { Flex } from "../../ui/Layout/Flex";
import { Message } from "../../ui/Message";
import { Text } from "../../ui/Text";
import { Tooltip } from "../../ui/Tooltip";
import { H1 } from "../../ui/Typography";
import { getCodeSnippetForDataframe } from "../../utils/dataframe-utils";
import { getProjectPath } from "../../utils/project-utils";
import { copyTextToClipboard } from "../../utils/user-utils";
import { IconButton } from "../IconButton";
import { TableExplorerLazy } from "../TableExplorer/TableExplorerLazy";
import { UserGuardContext } from "../UserGuard/UserGuard";
import { howLongSince } from "../../utils/time-utils";

interface Props {
  projectId: string;
  dataframe: Dataframe;
}

const Group = styled("div");

const fetchTable = async (url: string, user: User) => {
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${await user.getIdToken()}`,
    },
  });

  const requestId = response.headers.get("x-request-id");

  if (requestId) {
    Sentry.setTag("x-request-id", requestId);
  }

  return response;
};

export const InlineTable = ({ projectId, dataframe }: Props) => {
  const { user } = useContext(UserGuardContext);
  const {
    data: tableData,
    error,
    mutate,
  } = useSWR(
    getProjectPath({
      projectId,
      route: `/dbtn/dataframes/${dataframe.id}/table`,
    }),
    async (url: string) => {
      const { tableFromIPC } = await import("apache-arrow");
      const table = await tableFromIPC(fetchTable(url, user));
      // .toArray throws when there are no rows
      const rows = table.numRows === 0 ? [] : table.toArray();
      const columns = table.schema.fields.map((field) => ({
        name: field.name,
        type: field.type,
      }));
      return {
        rows,
        columns,
      };
    },
  );

  const renderContent = () => {
    if (error) {
      Sentry.captureMessage(error, "error");
      return <Message intent="danger">Loading dataframe failed.</Message>;
    }

    if (!tableData) {
      return (
        <Message intent="info" css={{ display: "flex", gap: "$1" }}>
          <FontAwesomeIcon pulse={true} icon={faSpinner} />
          Loading dataframe. For large datasets this might take a moment.
        </Message>
      );
    }
    return (
      <Div
        css={{
          height: "100%",

          "&>.ag-theme-alpine": {
            height: "100%",
            width: "100%",
          },
        }}
      >
        <TableExplorerLazy
          rows={tableData?.rows ?? []}
          columns={tableData?.columns ?? []}
        />
      </Div>
    );
  };

  return (
    <SidecarContainer>
      <SidecarNavbar>
        <Row css={{ justifyContent: "space-between" }}>
          <Flex direction={dataframe.name ? "vertical" : "horizontal"}>
            <H1 margin="none">{dataframe.name ?? dataframe.id}</H1>
            {dataframe.name && (
              <Text margin="none" css={{ fontSize: "$1" }}>
                Key: {dataframe.id}
              </Text>
            )}
          </Flex>

          <Group
            css={{
              display: "flex",
              gap: "$1",
              alignItems: "center",
            }}
          >
            {dataframe.lastUpdatedBy && (
              <Text>
                Last updated {howLongSince(dataframe.lastUpdatedBy?.timestamp)}
              </Text>
            )}
            <Tooltip text="Copy code snippet" placement="bottom">
              <IconButton
                onClick={() => {
                  copyTextToClipboard(getCodeSnippetForDataframe(dataframe));
                  toast("Copied to clipboard");
                }}
              >
                <StyledFontAwesomeIcon icon={faCopy} />
              </IconButton>
            </Tooltip>

            <Button
              disabled={!tableData}
              onClick={() => {
                mutate(undefined, {
                  revalidate: true,
                });
              }}
            >
              Refresh
            </Button>
          </Group>
        </Row>
      </SidecarNavbar>

      <SidecarContent>{renderContent()}</SidecarContent>
    </SidecarContainer>
  );
};
