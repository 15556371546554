import { Timestamp } from "firebase/firestore";
import { useMemo } from "react";
import { Tooltip } from "../../ui/Tooltip";
import { getVerboseTimestamp, TimestampFormat } from "./utils";

interface BaseProps {
  format?: TimestampFormat;
  showAsRelative?: boolean;
}

export const ZonedTimestamp = ({
  timestamp,
  format = "YYYY-MM-DD HH:mm:ss",
  showAsRelative = false,
}: { timestamp: Timestamp } & BaseProps) => {
  const verboseTimestamp = useMemo(
    () =>
      getVerboseTimestamp({
        timestamp,
        format,
        showAsRelative,
      }),
    [timestamp, format],
  );

  const absoluteTimestamp = useMemo(
    () =>
      getVerboseTimestamp({
        timestamp,
        format,
        showAsRelative: false,
        asUtc: true,
      }),
    [timestamp, format],
  );

  return (
    <Tooltip text={`${absoluteTimestamp} (UTC)`}>
      <span>{verboseTimestamp}</span>
    </Tooltip>
  );
};

export const ZonedTimestampByDate = ({
  date,
  ...rest
}: { date: Date } & BaseProps) => (
  <ZonedTimestamp timestamp={Timestamp.fromDate(date)} {...rest} />
);
