import { styled } from "../../../../stitches.config";
import { useUser } from "../../../hooks/useUser";
import { Flex } from "../../../ui/Layout/Flex";
import {
  DATABUTTON_DOCS_URL,
  DATABUTTON_LANDING_URL,
  WAITLIST_FORM_URL,
} from "../constants";
import { LoginA } from "../ui/PlainLink";
import { RoundButtonLink } from "../ui/RoundButton";
import { LogoLink } from "./LogoLink";

const NavbarWrapper = styled("nav", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  width: "100%",
  padding: "3.1em 3.7em",
  zIndex: 1,

  variants: {
    device: {
      small: { flexFlow: "column", gap: "$2" },
      normal: { flexFlow: "row" },
    },
  },
});

// TODO: Implement hamburger menu for mobile
// TODO: Implement sign out button
export const Navbar = () => {
  const { user } = useUser();

  return (
    <NavbarWrapper
      device={{
        "@initial": "small",
        "@bp2": "normal",
      }}
    >
      <LogoLink />

      <Flex
        css={{
          gap: "2.5em",
        }}
      >
        <LoginA href={`${DATABUTTON_LANDING_URL}/about`}>About</LoginA>
        <LoginA href={`${DATABUTTON_LANDING_URL}/pricing`}>Pricing</LoginA>
        <LoginA
          href={`${DATABUTTON_DOCS_URL}/#/?id=what-is-databutton`}
          target="_blank"
        >
          Docs
        </LoginA>

        {user ? (
          <LoginA href="/logout">Sign out</LoginA>
        ) : (
          <>
            <LoginA href='/login'>Sign In</LoginA>
            <RoundButtonLink href={WAITLIST_FORM_URL}>Sign Up</RoundButtonLink>
          </>
        )}
      </Flex>
    </NavbarWrapper>
  );
};
