import {
  faCircleCheck,
  faCircleExclamation,
  faSpinner,
} from "@fortawesome/pro-light-svg-icons";
import * as Sentry from "@sentry/react";
import { ProjectEvent } from "../../types/persisted";
import { StyledFontAwesomeIcon } from "../../ui/Icon";

interface Props {
  event: ProjectEvent;
}

export const ProjectEventInProgressIcon = () => (
  <StyledFontAwesomeIcon icon={faSpinner} pulse={true} />
);

const ProjectEventFailureIcon = () => (
  <StyledFontAwesomeIcon icon={faCircleExclamation} />
);

const ProjectEventSuccessIcon = () => (
  <StyledFontAwesomeIcon icon={faCircleCheck} />
);

export const ProjectEventIcon = ({ event }: Props) => {
  if (event.status === "PENDING" || event.status === "IN_PROGRESS") {
    return <ProjectEventInProgressIcon />;
  }

  if (event.status === "FAILURE") {
    return <ProjectEventFailureIcon />;
  }

  if (event.status === "SUCCESS") {
    return <ProjectEventSuccessIcon />;
  }

  Sentry.captureMessage(
    `Unable to determine icon from project event stats: "${event.status}"`,
    "warning",
  );
  return null;
};
