import { styled } from "../../../../stitches.config";

export const SplitPaneTile = styled("div", {
  position: "relative",

  height: "100%",
  width: "100%",
  overflow: "auto",
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  variants: {
    tile: {
      primary: {
        gridArea: "primary",
      },
      secondary: {
        gridArea: "secondary",
      },
    },
    visible: {
      no: {
        display: "none",
      },
      yes: {},
    },
  },
});
