import { collection, limit, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { getAppDeploymentsCollectionKey } from "../utils/collections/deployments";
import { firestore } from "../utils/firebase";

/**
 * TODO: Rewrite to use count and not fetch the actual deployments
 */
/**
 * @deprecated use useIsPublishedApp
 */
export const useAppIsPublished = ({
  projectId,
  appId,
  shouldFetch,
}: {
  projectId: string;
  appId: string;
  shouldFetch: boolean;
}) => {
  if (shouldFetch) {
    logService.debug("Checking if app is published");
  }

  const [values] = useCollectionData(
    shouldFetch
      ? query(
          collection(
            firestore,
            getAppDeploymentsCollectionKey({
              projectId,
              appId,
            }),
          ),
          limit(1),
        )
      : null,
  );

  return { isPublished: values && values.length > 0 ? true : false };
};
