import dayjs from "dayjs";
import { createContext, memo, ReactNode, useContext, useEffect } from "react";
import { WorkspaceIdentifier } from "../../store/slices/workspace-slice";
import { useStore } from "../../store/store";
import { App, Job, Module, MultipageAppPage } from "../../types/persisted";
import {
  CODE_SESSION_ENDED,
  CODE_SESSION_STARTED,
} from "../../utils/analytics-constants";
import { A_TOUR_OF_DATABUTTON_TEMPLATE_ID } from "../../utils/constants";
import { useUserGuardContext } from "../UserGuard/UserGuard";

interface Props {
  createdFromProjectTemplateId?: string | null;
  component: MultipageAppPage | App | Job | Module;
  identifier: WorkspaceIdentifier;
  children: ReactNode;
  codeBlockId: string;
}

const WorkspaceContext = createContext<WorkspaceIdentifier>(
  {} as WorkspaceIdentifier,
);

export const useWorkspaceContext = () => useContext(WorkspaceContext);

const BuildComponentWrapper = ({
  component,
  identifier,
  children,
  createdFromProjectTemplateId,
  codeBlockId,
}: Props) => {
  const { user } = useUserGuardContext();
  const componentOpened = useStore((state) => state.componentOpened);
  const workspaceLoaded = useStore((state) => state.workspaceLoaded);
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const projectNavigatorToggled = useStore(
    (state) => state.projectNavigatorToggled,
  );

  useEffect(() => {
    projectNavigatorToggled({ open: true });
  }, []);

  useEffect(() => {
    componentOpened({ component, mode: "build" });
  }, [componentOpened]);

  useEffect(() => {
    workspaceLoaded({
      identifier,
      isOnboardingTemplate:
        createdFromProjectTemplateId === A_TOUR_OF_DATABUTTON_TEMPLATE_ID,
    });
  }, []);

  // Record session start
  useEffect(() => {
    pulseTriggered({
      user,
      eventName: CODE_SESSION_STARTED,
      properties: { ...identifier },
    });
  }, []);

  // Record session end on unmount
  useEffect(() => {
    const startTime = dayjs();

    return () => {
      pulseTriggered({
        user,
        eventName: CODE_SESSION_ENDED,
        properties: {
          ...identifier,
          duration: dayjs().diff(startTime, "s"),
        },
      });
    };
  }, []);

  // Record session end on tab close
  useEffect(() => {
    const startTime = dayjs();

    const handleTabClose: (e: BeforeUnloadEvent) => void = () => {
      pulseTriggered({
        user,
        eventName: CODE_SESSION_ENDED,
        properties: {
          ...identifier,
          duration: dayjs().diff(startTime, "s"),
        },
      });
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <WorkspaceContext.Provider value={{ ...identifier, codeBlockId }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const MemoizedBuildComponentWrapper = memo(
  BuildComponentWrapper,
  (prev, next) =>
    prev.codeBlockId === next.codeBlockId &&
    prev.identifier.componentId === next.identifier.componentId,
);
