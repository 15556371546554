import { ProjectTemplate } from "../../../types/persisted";
import { Button } from "../../../ui/Button";
import { Image } from "../../../ui/Image/Image";
import { Flex } from "../../../ui/Layout/Flex";
import { Text } from "../../../ui/Text";

interface Props {
  template: ProjectTemplate;
  disabled: boolean;
  onClick: (template: ProjectTemplate) => void;
  illustrationSrc: string | null;
}

export const ProjectTemplateButton = ({
  template,
  onClick,
  disabled,
  illustrationSrc,
}: Props) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(template);
    }
  };

  return (
    <Flex
      direction="vertical"
      css={{
        border: "1px solid $mainBorder",
        borderRadius: "4px",
        backgroundColor: "$white",

        "&:hover": {
          border: "1px solid #dcdcdc",
        },

        "&:hover .use-this-button": {
          opacity: 1,
        },
      }}
    >
      <Flex
        css={{
          justifyContent: "center",
          height: "150px",
          borderBottom: "1px solid $mainBorder",
          position: "relative",
        }}
      >
        {illustrationSrc && (
          <Image
            src={illustrationSrc}
            css={{
              position: "absolute",
              inset: 0,
              margin: "auto",
              maxHeight: "100%",
              maxWidth: "100%",

              "&:hover > .use-this-button": {
                opacity: 1,
              },
            }}
          />
        )}

        <Flex
          className="use-this-button"
          css={{
            position: "absolute",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            zIndex: 1,
            opacity: 0,
            justifyContent: "center",
          }}
        >
          <Button disabled={disabled} onClick={handleClick}>
            Use template
          </Button>
        </Flex>
      </Flex>

      <Flex
        css={{ padding: "$1", minHeight: "80px", backgroundColor: "$white" }}
        direction="vertical"
        gap="1"
      >
        <Text size="2">{template.name}</Text>

        <Text weight="thin">{template.description}</Text>
      </Flex>
    </Flex>
  );
};
