import { Metric } from "@databutton/firebase-types";
import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import ky, { HTTPError } from "ky";
import {
  addAppVersionToHeaders,
  beforeRetryHook,
} from "../../services/dbtn-api";
import { HealthCheckResponse } from "../../store/slices/project-slice";
import { CollectionName } from "../../utils/collections/shared";
import { firestore } from "../../utils/firebase";
import { getProjectPath } from "../../utils/project-utils";
import { createPerformedBySystemObj } from "../../utils/user-utils";

const logMetric = async (
  metric: Pick<Metric, "meta" | "projectId" | "title">,
) => {
  const payload = {
    ...metric,
    createdBy: createPerformedBySystemObj(),
  } satisfies Metric;
  await addDoc(collection(firestore, CollectionName.METRICS), payload);
};

const calculateLatency = (timeStart: number): number =>
  new Date().getTime() - timeStart;

interface DevxStatus {
  fetchedAtTimestamp: string;
  projectId: string;
  devxEnv: string;
  devxVersion: string;
  devxInstanceTag: string;
}

export const runHealthCheck = async (params: {
  user: User;
  projectId: string;
}): Promise<HealthCheckResponse> => {
  if (navigator.onLine) {
    const timeStart = new Date().getTime();

    try {
      const response = await getHealthz({
        user: params.user,
        projectId: params.projectId,
      });

      const body = await response.json<DevxStatus>();

      return {
        online: true,
        ok: response.ok,
        statusCode: response.status,
        latency: calculateLatency(timeStart),
        instanceTag: body.devxInstanceTag,
      };
    } catch (err: unknown) {
      const latency = calculateLatency(timeStart);
      if (err instanceof HTTPError) {
        return {
          online: true,
          ok: false,
          statusCode: err.response.status,
          latency,
        };
      } else {
        Sentry.captureException(err);
        return {
          online: true,
          ok: false,
          statusCode: 418,
          latency,
        };
      }
    }
  } else {
    return {
      online: false,
    };
  }
};

const handleError = async (params: {
  projectId: string;
  latency: number;
  error: HTTPError;
}): Promise<HTTPError> => {
  await logMetric({
    projectId: params.projectId,
    title: "Health check",
    meta: {
      statusCode: params.error.response.status,
      requestId: params.error.response.headers.get("x-request-id"),
      latency: params.latency,
    },
  });

  return params.error;
};

const getHealthz = async (params: { user: User; projectId: string }) => {
  const url = getProjectPath({
    projectId: params.projectId,
    route: "/dbtn/devx/status",
  });

  const idToken = await params.user.getIdToken();
  const timeStart = new Date().getTime();
  return ky.get(url, {
    headers: { Authorization: `Bearer ${idToken}` },
    timeout: 1_000 * 45,
    retry: {
      limit: 2,
      statusCodes: [429],
    },
    hooks: {
      beforeRequest: [addAppVersionToHeaders],
      beforeRetry: [beforeRetryHook],
      beforeError: [
        (err) =>
          handleError({
            projectId: params.projectId,
            error: err,
            latency: calculateLatency(timeStart),
          }),
      ],
    },
  });
};
