import {
  createRef,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { logService } from "../../services/log-service";
import { localStorageUtils } from "../../utils/local-storage-utils";
import { Dragbar, DRAGBAR_SIZE } from "./components/Dragbar";
import { DragGuard } from "./components/DragGuard";
import { SplitPaneContainer } from "./components/SplitPaneContainer";
import { SplitPaneTile } from "./components/SplitPaneTile";
import {
  convertToRelativeGridTemplate,
  determineOrientation,
  isCustomLayout,
  isSplitLayout,
  SplitPaneLayout,
  SplitPaneOrientation,
} from "./utils";

interface Props {
  primaryTile: ReactNode;
  secondaryTile: ReactNode;
  layout?: SplitPaneLayout;
  storageKey: string;
  onLayoutChange?: (layout: SplitPaneLayout) => void;
  onCustomLayoutChange?: (params: {
    layout: SplitPaneLayout;
    customGrid: string;
  }) => void;
}

interface LayoutState {
  layout: SplitPaneLayout;
  customGrid?: {
    key: "gridTemplateRows" | "gridTemplateColumns";
    value: string;
  };
}

/**
 * Adds prefix and suffix to storage key
 *
 * The last part or the storage key is a date and can be changed in order
 * to reset the save layout for all users
 */
const createStorageKey = (storageKey: string): string =>
  `dbtn-layout-${storageKey}-20230317`;

const getInitialLayout = (params: {
  storageKey: string;
  layoutFromProps?: SplitPaneLayout;
}): LayoutState => {
  logService.debug("SPLIT-PANE: Determining initial layout", {
    storageKey: params.storageKey,
  });
  const savedLayout = localStorageUtils.getValue<LayoutState | null>(
    params.storageKey,
    null,
  );

  if (savedLayout) {
    logService.debug("SPLIT-PANE: Loaded saved layout", savedLayout);
    return savedLayout;
  }

  if (params.layoutFromProps) {
    const layoutStateFromProps = {
      layout: params.layoutFromProps,
    } satisfies LayoutState;

    logService.debug(
      "SPLIT-PANE: Using layout from props",
      layoutStateFromProps,
    );
    return layoutStateFromProps;
  }

  logService.debug("SPLIT-PANE: Using default layout");
  return {
    layout: SplitPaneLayout.VERTICAL_50_50,
  };
};

export const SplitPane = ({
  primaryTile,
  secondaryTile,
  layout: layoutFromProps,
  storageKey: storageKeyFromProps,
  onLayoutChange,
  onCustomLayoutChange,
}: Props) => {
  const containerRef = createRef<HTMLDivElement>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const storageKey = useMemo(
    () => createStorageKey(storageKeyFromProps),
    [storageKeyFromProps],
  );
  const [hasBeenInitialized, setHasBeenInitialized] = useState<boolean>(false);

  const [state, setState] = useState<LayoutState>({
    layout: SplitPaneLayout.VERTICAL_50_50,
  });

  useEffect(() => {
    const initialLayout = getInitialLayout({
      storageKey,
      layoutFromProps,
    });

    setState(initialLayout);
    setHasBeenInitialized(true);
  }, []);

  /**
   * Allow updating layout from outside, e.g. from the adjust layout buttons
   * Will also unset the opposite grid template so we ensure we don't have
   * both rows and columns in our grid
   */
  useEffect(() => {
    const shouldAllowOutsideUpdate =
      layoutFromProps && hasBeenInitialized && !isCustomLayout(layoutFromProps);

    if (shouldAllowOutsideUpdate) {
      logService.debug("SPLIT-PANE: Updating layout from props", {
        layoutFromProps,
      });
      setState({ layout: layoutFromProps });

      const container = containerRef.current;
      if (container) {
        const orientationFromProps = determineOrientation(layoutFromProps);

        if (orientationFromProps === SplitPaneOrientation.HORIZONTAL) {
          container.setAttribute("style", "grid-template-columns: unset");
        } else if (orientationFromProps === SplitPaneOrientation.VERTICAL) {
          container.setAttribute("style", "grid-template-rows: unset");
        }
      }
    }
  }, [layoutFromProps]);

  useEffect(() => {
    onLayoutChange?.(state.layout);

    if (hasBeenInitialized) {
      logService.debug("SPLIT-PANE: Saving layout", { storageKey, ...state });
      localStorageUtils.setValue<LayoutState>(storageKey, state);
    }
  }, [state, hasBeenInitialized]);

  const shouldRenderPrimaryTile = useMemo(
    () => state.layout !== SplitPaneLayout.VERTICAL_0_100,
    [state.layout],
  );

  const shouldRenderSecondaryTile = useMemo(
    () => state.layout !== SplitPaneLayout.VERTICAL_100_0,
    [state.layout],
  );

  const shouldRenderDragbar = useMemo(
    () => isSplitLayout(state.layout),
    [state.layout],
  );

  const orientation = useMemo(
    () => determineOrientation(state.layout),
    [state.layout],
  );

  const handleMouseUp: MouseEventHandler<HTMLDivElement> = useCallback(
    (_e) => {
      if (isDragging && containerRef.current) {
        setIsDragging(false);
        const style = window.getComputedStyle(containerRef.current);

        const computedGridTemplate =
          orientation === SplitPaneOrientation.VERTICAL
            ? style.getPropertyValue("grid-template-columns")
            : style.getPropertyValue("grid-template-rows");

        const relativeGridTemplate = convertToRelativeGridTemplate({
          template: computedGridTemplate,
        });

        const newLayout =
          orientation === SplitPaneOrientation.VERTICAL
            ? SplitPaneLayout.VERTICAL_CUSTOM
            : SplitPaneLayout.HORIZONTAL_CUSTOM;

        setState({
          layout: newLayout,
          customGrid: {
            key:
              newLayout === SplitPaneLayout.VERTICAL_CUSTOM
                ? "gridTemplateColumns"
                : "gridTemplateRows",
            value: relativeGridTemplate,
          },
        });

        onCustomLayoutChange?.({
          layout: newLayout,
          customGrid: relativeGridTemplate,
        });
      }
    },
    [isDragging],
  );

  const handleDragging: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();

        const container = containerRef.current;
        if (container) {
          const rect = container.getBoundingClientRect();
          if (orientation === SplitPaneOrientation.VERTICAL) {
            const primaryTileSize = e.clientX - rect.left;
            const newColDef = `[primary] ${primaryTileSize}px [dragbar] ${DRAGBAR_SIZE} [secondary] auto`;

            container.setAttribute(
              "style",
              `grid-template-columns: ${newColDef};`,
            );
          } else {
            const primaryTileSize = e.clientY - rect.top;
            const newColDef = `[primary] ${primaryTileSize}px [dragbar] ${DRAGBAR_SIZE} [secondary] auto`;

            container.setAttribute(
              "style",
              `grid-template-rows: ${newColDef};`,
            );
          }
        }
      }
    },
    [isDragging],
  );

  const handleDragStart: MouseEventHandler<HTMLDivElement> = useCallback(
    (_e) => {
      setIsDragging(true);
    },
    [],
  );

  logService.debug("SPLIT-PANE: Render", state);

  const customCss = useMemo(
    () =>
      state.customGrid
        ? {
            [state.customGrid.key]: state.customGrid.value,
          }
        : undefined,
    [state.customGrid],
  );

  return (
    <SplitPaneContainer
      layout={state.layout}
      ref={containerRef}
      onMouseUp={handleMouseUp}
      onMouseMove={handleDragging}
      css={customCss}
    >
      <SplitPaneTile
        tile="primary"
        visible={shouldRenderPrimaryTile ? "yes" : "no"}
      >
        {primaryTile}
      </SplitPaneTile>

      <Dragbar
        visible={shouldRenderDragbar ? "yes" : "no"}
        orientation={orientation}
        onMouseDown={handleDragStart}
      />

      <SplitPaneTile
        tile="secondary"
        visible={shouldRenderSecondaryTile ? "yes" : "no"}
      >
        {secondaryTile}

        {/* Mousedrag over iframe hack */}
        {isDragging && <DragGuard />}
      </SplitPaneTile>
    </SplitPaneContainer>
  );
};
