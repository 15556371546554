import type * as Stitches from "@stitches/react";
import { styled } from "../../../stitches.config";

const textStyles = {
  margin: 0,

  variants: {
    size: {
      "10": {
        fontSize: "10px",
        lineHeight: "16px",
      },
      "12": {
        fontSize: "12px",
        lineHeight: "16px",
      },
      "14": {
        fontSize: "14px",
        lineHeight: "16px",
      },
      "20": {
        fontSize: "20px",
        lineHeight: "16px",
      },
      "30": {
        fontSize: "30px",
        lineHeight: "24px",
      },
    },
    weight: {
      medium: {
        fontWeight: 500,
      },
      semiBold: {
        fontWeight: 600,
      },
    },
    color: {
      dark: {
        color: "$fontDark",
      },
      secondary: {
        color: "$fontSecondary",
      },
      purple: {
        color: "#9747FF",
      },
      disabled: {
        color: "$gray4",
      },
      inherit: {
        color: "inherit",
      },
      green: {
        color: "#197D23",
      },
    },
  },
} satisfies Stitches.CSS;

export const Txt = styled("p", textStyles, {
  defaultVariants: {
    color: "dark",
    weight: "medium",
    size: "14",
  },
});

export const H1 = styled("h1", textStyles, {
  defaultVariants: {
    color: "dark",
    weight: "semiBold",
    size: "30",
  },
});

export const H2 = styled("h2", textStyles, {
  defaultVariants: {
    color: "dark",
    weight: "medium",
    size: "20",
  },
});
