import { ComponentProps } from "react";
import { styled } from "../../../stitches.config";

export const Icon = styled("img", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const FailedIcon = (props: ComponentProps<typeof Icon>) => (
  <Icon src="/error-icon.svg" alt="Failed icon" {...props} />
);

export const SuccessIcon = (props: ComponentProps<typeof Icon>) => (
  <Icon src="/success-icon.svg" alt="Success icon" {...props} />
);

export const EditIcon = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1H2L3 2M3 2L4 1H6M3 2V8M0 9H2L3 8M3 8L4 9H6M8 9H13"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 4L7.5 8.5L10 7"
      stroke="#9747FF"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="13.5"
      height="13.5"
      rx="6.75"
      stroke="#9747FF"
      strokeWidth="1.5"
    />
  </svg>
);

export const PauseIcon = (props: ComponentProps<typeof Icon>) => (
  <Icon src="/pause-icon.svg" alt="Pause icon" {...props} />
);

export const PlayIcon = () => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9.80873V2.19127C1 1.43067 1.81546 0.948506 2.48192 1.31505L9.40688 5.12378C10.0977 5.50371 10.0977 6.49629 9.40688 6.87622L2.48192 10.6849C1.81547 11.0515 1 10.5693 1 9.80873Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export const ColumnsIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.25" y="0.5" width="1.5" height="13" fill="currentColor" />
    <rect
      x="1.25"
      y="1.25"
      width="13.5"
      height="11.5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const RowsIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="6.25" width="15" height="1.5" fill="currentColor" />
    <rect
      x="1.25"
      y="1.25"
      width="13.5"
      height="11.5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const EyeIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1C3 1 1 6 1 6C1 6 3 11 8 11C13 11 15 6 15 6C15 6 13 1 8 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <circle cx="8" cy="6" r="2.5" fill="currentColor" />
  </svg>
);

export const CloudCheckIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-outside-1_160_5081"
      maskUnits="userSpaceOnUse"
      x="-0.5"
      y="1"
      width="18"
      height="14"
      fill="black"
    >
      <rect fill="white" x="-0.5" y="1" width="18" height="14" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55534 12.9381C4.37673 12.9786 4.19086 13 4 13C2.61929 13 1.5 11.8807 1.5 10.5C1.5 9.27906 2.37523 8.26255 3.5325 8.04364C3.51104 7.86542 3.5 7.684 3.5 7.5C3.5 5.01472 5.51472 3 8 3C10.3163 3 12.2239 4.75009 12.4725 7.00012L12.5 7C14.1569 7 15.5 8.34315 15.5 10C15.5 11.6569 14.1569 13 12.5 13C12.4466 13 12.3935 12.9986 12.3407 12.9958C12.3276 12.9986 12.314 13 12.3 13H4.7C4.6431 13 4.59176 12.9762 4.55534 12.9381Z"
      />
    </mask>
    <path
      d="M4.55534 12.9381L5.64018 11.9022L5.05288 11.2871L4.22354 11.4753L4.55534 12.9381ZM3.5325 8.04364L3.8113 9.5175L5.18943 9.25681L5.02175 7.86431L3.5325 8.04364ZM12.4725 7.00012L10.9816 7.1649L11.1299 8.50625L12.4794 8.50011L12.4725 7.00012ZM12.5 7V5.49998L12.4932 5.50002L12.5 7ZM12.3407 12.9958L12.4191 11.4979L12.226 11.4878L12.0367 11.527L12.3407 12.9958ZM4.22354 11.4753C4.15306 11.4912 4.07847 11.5 4 11.5V14.5C4.30326 14.5 4.6004 14.466 4.88714 14.4009L4.22354 11.4753ZM4 11.5C3.44772 11.5 3 11.0523 3 10.5H0C0 12.7091 1.79086 14.5 4 14.5V11.5ZM3 10.5C3 10.0133 3.34923 9.60491 3.8113 9.5175L3.25371 6.56978C1.40122 6.92019 0 8.54485 0 10.5H3ZM5.02175 7.86431C5.00743 7.74547 5 7.62393 5 7.5H2C2 7.74407 2.01465 7.98538 2.04326 8.22297L5.02175 7.86431ZM5 7.5C5 5.84315 6.34315 4.5 8 4.5V1.5C4.68629 1.5 2 4.18629 2 7.5H5ZM8 4.5C9.54321 4.5 10.816 5.66646 10.9816 7.1649L13.9635 6.83535C13.6317 3.83372 11.0894 1.5 8 1.5V4.5ZM12.4794 8.50011L12.5068 8.49998L12.4932 5.50002L12.4657 5.50014L12.4794 8.50011ZM12.5 8.5C13.3284 8.5 14 9.17157 14 10H17C17 7.51472 14.9853 5.5 12.5 5.5V8.5ZM14 10C14 10.8284 13.3284 11.5 12.5 11.5V14.5C14.9853 14.5 17 12.4853 17 10H14ZM12.5 11.5C12.4727 11.5 12.4457 11.4993 12.4191 11.4979L12.2624 14.4938C12.3412 14.4979 12.4204 14.5 12.5 14.5V11.5ZM12.0367 11.527C12.123 11.5091 12.2113 11.5 12.3 11.5V14.5C12.4167 14.5 12.5322 14.488 12.6448 14.4647L12.0367 11.527ZM12.3 11.5H4.7V14.5H12.3V11.5ZM4.7 11.5C5.06968 11.5 5.40535 11.6563 5.64018 11.9022L3.47049 13.974C3.77816 14.2962 4.21652 14.5 4.7 14.5V11.5Z"
      fill="currentColor"
      mask="url(#path-1-outside-1_160_5081)"
    />
    <path
      d="M5.5 9.4L7.5 11L10.5 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="bevel"
    />
  </svg>
);

export const ArrowUpRightIcon = ({
  width = "10",
  height = "10",
}: { width?: string; height?: string }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 10 10'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.95652V1M9 1H2.04348M9 1L1 9"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.650391"
      y="1.1499"
      width="8.66667"
      height="8.66667"
      rx="0.866667"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <path
      d="M12.5671 4.3999V12.1999C12.5671 12.6785 12.179 13.0666 11.7004 13.0666H3.90039"
      stroke="currentColor"
      strokeWidth="1.3"
    />
  </svg>
);

export const CompareIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1"
      width="5"
      height="12"
      rx="0.866667"
      stroke="currentColor"
      strokeWidth="1.3"
    />
    <rect
      x="9"
      y="1"
      width="5"
      height="12"
      rx="0.866667"
      stroke="currentColor"
      strokeWidth="1.3"
    />
  </svg>
);

export const LiveIcon = styled("div", {
  $$size: "10px",
  backgroundColor: "#4FEA5E",
  height: "$$size",
  width: "$$size",
  borderRadius: "calc($$size / 2)",
  border: "2px solid #fff",
});

export const GoogleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6 10.2271C19.6 9.518 19.5364 8.83619 19.4182 8.18164H10V12.0498H15.3818C15.15 13.2998 14.4455 14.3589 13.3864 15.068V17.5771H16.6182C18.5091 15.8362 19.6 13.2725 19.6 10.2271Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 20.0004C12.7001 20.0004 14.9637 19.1049 16.6183 17.5777L13.3864 15.0686C12.491 15.6686 11.3455 16.0231 10.0001 16.0231C7.39554 16.0231 5.19099 14.264 4.40463 11.9004H1.06372V14.4913C2.70918 17.7595 6.09099 20.0004 10.0001 20.0004Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.40455 11.8997C4.20455 11.2997 4.09091 10.6588 4.09091 9.9997C4.09091 9.34061 4.20455 8.6997 4.40455 8.0997V5.50879H1.06364C0.386364 6.85879 0 8.38606 0 9.9997C0 11.6133 0.386364 13.1406 1.06364 14.4906L4.40455 11.8997Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 3.97727C11.4683 3.97727 12.7864 4.48182 13.8228 5.47273L16.691 2.60455C14.9592 0.990909 12.6955 0 10.0001 0C6.09099 0 2.70918 2.24091 1.06372 5.50909L4.40463 8.1C5.19099 5.73636 7.39554 3.97727 10.0001 3.97727Z"
      fill="#EA4335"
    />
  </svg>
);
