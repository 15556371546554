import { styled } from "../../../stitches.config";

const ButtonLink = styled("a", {
  $$borderWidth: "1px",

  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "$1",
  fontSize: "$1",
  fontFamily: "$sans",
  fontWeight: "600",
  padding: "$1 $2",
  borderRadius: "4px",

  variants: {
    intent: {
      secondary: {
        backgroundColor: "$backgroundMain",
        color: "$gray2",
        border: "$$borderWidth solid $gray2",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
    },
  },
});

export const TopBarButtonLink = styled(ButtonLink, {
  borderRadius: "8px",

  variants: {
    intent: {
      secondary: {
        backgroundColor: "$backgroundMain",
        color: "$gray2",
        border: "$$borderWidth solid $mainBorder",

        "&:hover": {
          color: "$white",
          backgroundColor: "$darkPurpleSolid",
          border: "$$borderWidth solid $darkPurpleSolid",
        },
      },
    },
  },
});
