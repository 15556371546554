import { User } from "firebase/auth";
import { useAppsSharedWithUser } from "./useApps";
import { useProjects } from "./useProjects";
import { useProjectTemplates } from "./useProjectTemplates";

export const useHomePageContent = (user: User | null) => {
  useProjects(user);
  useAppsSharedWithUser(user);
  useProjectTemplates({ filter: "active-only", shouldFetch: user !== null });
};
