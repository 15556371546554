import { dbtnApi } from "./dbtn-api";
import { User } from "firebase/auth";
import { fixJson } from "../components/Assistant/utils";

export interface ChatGptMessage {
  hidden: boolean;
  role: string;
  content: string;
  id?: string;
}

interface StreamingChunk {
  choices: {
    finish_reason: string | null;
    index: number;
    delta: { content: string } | { role: "user" | "assistant" };
  }[];
  id: string;
  model: string;
  object: string;
}

interface MessageChunk {
  id: string;
  content: string;
}

export const chatgptApi = {
  post: async function* (params: {
    messages: ChatGptMessage[];
    user: User;
    projectId: string;
  }): AsyncGenerator<MessageChunk, void, MessageChunk> {
    const response = await dbtnApi.post({
      route: "/dbtn/gpt/stream",
      user: params.user,
      projectId: params.projectId,
      // This can take a really long time as of now.
      timeout: 1000 * 120,
      json: {
        messages: params.messages.map((it) => {
          if ("hidden" in it) {
            const { hidden: _hidden, id: _id, ...message } = it;
            return message;
          }

          return it;
        }),
      },
      stream: true,
    });

    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();

    if (reader) {
      while (true) {
        const { value, done } = await reader.read();

        if (value) {
          const chunk = fixJson<StreamingChunk>(value);

          for (const c of chunk) {
            const choice = c.choices[0];
            if ("content" in choice.delta && choice.delta.content) {
              yield { content: choice.delta.content, id: c.id };
            }
          }
        }

        if (done) {
          break;
        }
      }
    }
  },
};
