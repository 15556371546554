import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { JobRun } from "../types/persisted";
import {
  getRunsCollectionKey,
  jobRunConverter,
} from "../utils/collections/runs";
import { firestore } from "../utils/firebase";

export const useLastRun = (params: {
  projectId: string;
  jobId: string | undefined;
  shouldFetch: boolean;
}) => {
  const [lastRun, setLastRun] = useState<JobRun | null>(null);

  useEffect(() => {
    const collectionKey = getRunsCollectionKey({ projectId: params.projectId });

    const unsub = params.jobId
      ? onSnapshot(
          query(
            collection(firestore, collectionKey),
            where("jobId", "==", params.jobId),
            orderBy("startTime", "desc"),
            limit(1),
          ).withConverter(jobRunConverter),
          (snapshot) => {
            if (snapshot.size === 1) {
              setLastRun(snapshot.docs[0].data());
            } else if (snapshot.size === 0) {
              setLastRun(null);
            } else {
              throw new Error(
                "Got more than one last run. This should not happend",
              );
            }
          },
        )
      : undefined;

    return () => {
      unsub?.();
    };
  }, [params.projectId, params.jobId]);

  return { lastRun };
};
