import { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { getReadProjectEventsAt } from "../../hooks/useProjectEvents";
import { Profile, ProjectEvent } from "../../types/persisted";
import { Group } from "../../ui/Layout";
import { NavigateLink } from "../../ui/NavigateLink";
import { Text } from "../../ui/Text";
import { FadedText } from "../../ui/Typography";
import { howLongSince, isAfter } from "../../utils/time-utils";
import { NotificationBadge } from "../TopBar/NotificationBadge";
import { ProjectEventIcon } from "../TopBar/ProjectEventIcon";

interface Props {
  projectId: string;
  event: ProjectEvent;
  profile: Profile | null;
}

const Container = styled(Group, {
  display: "grid",
  gridTemplateColumns: "14px auto 10px",
  padding: "$2",

  "&:not(:last-child)": {
    borderBottom: "1px solid $mainBorder",
  },
});

export const ProjectEventRow = ({ event, profile, projectId }: Props) => {
  const readProjectEventsAt = getReadProjectEventsAt({
    map: profile?.projectEventsReadMap,
    projectId,
  });

  const isUnread = readProjectEventsAt
    ? isAfter({
        timestamp: event.lastUpdatedBy.timestamp,
        reference: readProjectEventsAt,
      })
    : true;

  const [timeSince, setTimeSince] = useState<string>(
    howLongSince(event.createdBy.timestamp),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSince(howLongSince(event.createdBy.timestamp));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [event.createdBy.timestamp]);

  const renderContent = () => (
    <>
      <Group css={{ paddingTop: "calc($1/2)", alignSelf: "flex-start" }}>
        <ProjectEventIcon event={event} />
      </Group>

      <Group direction="vertical">
        <Text>{event.shortTitle ?? event.title}</Text>
        <Text weight="thin">{event.derivedTitle ?? event.description}</Text>
        <FadedText>{timeSince}</FadedText>
      </Group>

      <Group
        css={{
          position: "relative",
          justifyContent: "flex-end",
          width: "min-content",
          paddingLeft: "$1",
        }}
      >
        {isUnread && (
          <NotificationBadge
            intent={event.status === "FAILURE" ? "danger" : "info"}
          />
        )}
      </Group>
    </>
  );

  return event.shortUrl ? (
    <NavigateLink
      target="_blank"
      to={event.shortUrl}
      css={{
        color: "inherit",

        "&:hover": {
          backgroundColor: "$mainBorder",
        },

        "&:not(:last-child)": {
          borderBottom: "1px solid $mainBorder",
        },
      }}
    >
      <Container gap="1">{renderContent()}</Container>
    </NavigateLink>
  ) : (
    <Container gap="1">{renderContent()}</Container>
  );
};
