export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

export const distinct = <T>(value: T, index: number, self: T[]): boolean =>
  self.indexOf(value) === index;

export const uniqueByKey = <T>(values: T[], key: keyof T): T[] => [
  ...new Map(values.map((it) => [it[key], it])).values(),
];

export const randomChoice = <T>(values: T[]): T =>
  values[Math.floor(Math.random() * values.length)];

export const sleep = async (ms: number) => {
  await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
