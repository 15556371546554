import { User } from "firebase/auth";
import { useState } from "react";
import { ModalDialog } from "../../components/ModalDialog";
import { ShareViewForm } from "../../components/ShareViewForm/ShareViewForm";
import { useAppIsPublished } from "../../hooks/useApp";
import { App } from "../../types/persisted";
import { Button } from "../../ui/Button";

interface Props {
  projectId: string;
  app: App;
  user: User;
}

export const ShareViewModalButton = ({ app, projectId, user }: Props) => {
  const [isShareViewModalOpen, setIsShareViewModalOpen] = useState(false);

  const { isPublished } = useAppIsPublished({
    projectId,
    appId: app.id,
    shouldFetch: isShareViewModalOpen,
  });

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          setIsShareViewModalOpen(true);
        }}
      >
        Share
      </Button>

      <ModalDialog
        isOpen={isShareViewModalOpen}
        title={`Share ${app.name}`}
        onClose={() => {
          setIsShareViewModalOpen(false);
        }}
        content={
          <ShareViewForm
            projectId={projectId}
            user={user}
            app={app}
            isPublished={isPublished}
          />
        }
      />
    </>
  );
};
