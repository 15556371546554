import { ComponentProps } from "react";
import { styled } from "../../../stitches.config";

/**
 * @deprecated Use from TypographyV2
 */
export const H1 = styled("h1", {
  fontWeight: "$light",
  fontSize: "$4",

  variants: {
    color: {
      dark: { color: "$primaryDark" },
      light: { color: "$white" },
      inherit: { color: "inherit" },
    },
    margin: {
      none: {
        margin: 0,
      },
    },
  },

  defaultVariants: {
    color: "dark",
  },
});

/**
 * @deprecated Use from TypographyV2
 */
export const H2 = styled("h2", {
  fontWeight: "$semiBold",
  fontSize: "$3",

  variants: {
    color: {
      dark: { color: "$primaryDark" },
      light: { color: "$white" },
      disabled: {
        color: "$gray4",
      },
      inherit: { color: "inherit" },
    },
    margin: {
      none: {
        margin: 0,
      },
    },
  },

  defaultVariants: {
    color: "dark",
  },
});

/**
 * @deprecated Use from TypographyV2
 */
export const H3 = styled("h3", {
  fontWeight: "600",
  fontSize: "$2",
});

/**
 * @deprecated Use from TypographyV2
 */
export const Bold = styled("strong", {
  fontSize: "inherit",
  color: "inherit",
  fontWeight: "$semiBold",
});

const StyledA = styled("a", {
  textDecoration: "none",
  fontSize: "inherit",
  fontWeight: "inherit",
  borderRadius: "4px",

  variants: {
    color: {
      white: {
        color: "$white",

        "&:hover": {
          color: "$primaryPurple",
          transition: "color 0.1s ease",
        },
      },
      blue: {
        color: "$linkBlue",
      },
      purple: {
        color: "$primaryPurple",
      },
    },
    weight: {
      normal: {
        fontWeight: "$normal",
      },
      semiBold: {
        fontWeight: "$semiBold",
      },
    },
  },
});

/**
 * @deprecated Use from TypographyV2
 */
// Set rel="noopener" to all links
export const A = ({ children, ...rest }: ComponentProps<typeof StyledA>) => (
  <StyledA {...rest} rel="noopener">
    {children}
  </StyledA>
);

/**
 * @deprecated Use from TypographyV2
 */
export const FadedText = styled("p", {
  margin: 0,
  color: "$gray4",
});
