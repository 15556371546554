import { collection, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Profile } from "../types/persisted";
import { profileConverter } from "../utils/collections/profiles";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

/**
 * @deprecated Migrate to useProfile that takes user id as input
 */
export const useProfile = (email?: string | null) => {
  const [values, loading] = useCollectionData<Profile>(
    query(
      collection(firestore, CollectionName.PROFILES),
      where("email", "==", email ?? "NO-EMAIL"),
    ).withConverter(profileConverter),
  );

  const profile = useMemo(() => {
    if (values) {
      if (values.length === 1) {
        return values[0];
      } else if (values.length === 2) {
        const profileToUse = values.find((it) => it.migratedFromProfileRef);
        return profileToUse ?? null;
      }
    }

    return null;
  }, [values]);

  return {
    profile,
    loading,
  };
};
