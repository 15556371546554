import { Menu } from "@headlessui/react";
import { signOut, User } from "firebase/auth";
import { Fragment } from "react";
import { styled } from "../../../stitches.config";
import { Div } from "../../ui/Layout";
import { auth } from "../../utils/firebase";
import { RoutePath } from "../../utils/route-utils";
import { ProfilePhoto } from "./ProfilePhoto";

interface Props {
  user: User;
}

const MenuWrapper = styled("div", {
  position: "relative",
  display: "flex",
  alignSelf: "center",
});

const MenuContainer = styled("div", {
  $$width: "250px",
  $$navbarHeight: "60px",

  display: "flex",
  flexFlow: "column",
  alignItems: "flex-start",
  position: "absolute",
  top: "calc($$navbarHeight / 2 + 10px)",
  zIndex: 2,
  right: "-15px",
  color: "$white",
  fontSize: "$1",

  width: "$$width",

  "&:focus": {
    outline: "none",
    boxShadow: "none",
  },

  "&::before": {
    $$size: "5px",
    content: "",
    position: "absolute",
    top: "calc($$size * -1)",
    right: "23px",

    width: 0,
    height: 0,
    borderLeft: "$$size solid transparent",
    borderRight: "$$size solid transparent",
    borderBottom: "$$size solid $primaryDark",
  },
});

const MenuItemButton = styled("button", {
  $$borderRadius: "4px",

  border: "none",
  backgroundColor: "$primaryDark",
  color: "inherit",
  fontSize: "inherit",
  padding: "$2",
  textAlign: "left",
  cursor: "pointer",
  width: "100%",

  "&:first-of-type": {
    borderTopLeftRadius: "$$borderRadius",
    borderTopRightRadius: "$$borderRadius",
  },

  "&:last-of-type": {
    borderBottomLeftRadius: "$$borderRadius",
    borderBottomRightRadius: "$$borderRadius",
  },

  "&:hover": {
    backgroundColor: "$linkBlue",
  },
});

const MenuButton = styled(Div, {
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
});

export const AccountMenu = ({ user }: Props) => (
  <Menu as={MenuWrapper}>
    <Menu.Button as={MenuButton}>
      <ProfilePhoto user={user} />
    </Menu.Button>

    <Menu.Items as={MenuContainer}>
      <Menu.Item as={Fragment}>
        <MenuItemButton
          onClick={() => {
            signOut(auth).then(() => {
              window.location.assign(RoutePath.HOME);
            });
          }}
        >
          Log out ({user.email})
        </MenuItemButton>
      </Menu.Item>
    </Menu.Items>
  </Menu>
);
