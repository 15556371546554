import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import App from "./App";
import { InitWrapper } from "./components/InitWrapper/InitWrapper";
import { VersionPoller } from "./components/VersionPoller/VersionPoller";
import "./index.css";
import "./ui/Font/inter-display.css";

// Variables generated by vite config
declare const __APP_VERBOSE_VERSION__: string;
declare const __APP_BUILD_TIME__: string;
declare const __APP_VERSION__: string;

window.APP_VERBOSE_VERSION = __APP_VERBOSE_VERSION__;
window.APP_BUILD_TIME = __APP_BUILD_TIME__;
window.APP_VERSION = __APP_VERSION__;

Sentry.init({
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
  ],
  enabled: !/localhost:3000/i.test(window.location.origin),
  dsn: "https://500c0dae79fa46d5a846e82d751edfc9@o1000232.ingest.sentry.io/6432635",
  release: __APP_VERSION__,
  environment: /databutton\.com/i.test(window.location.origin)
    ? "production"
    : "development",
  integrations: [new BrowserTracing()],
  initialScope: {
    contexts: {
      app: {
        app_version: __APP_VERBOSE_VERSION__,
      },
    },
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
});

console.log(`App running version ${__APP_VERBOSE_VERSION__}`);

ReactDOM.createRoot(document.querySelector("#root") as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <InitWrapper>
        <App />
      </InitWrapper>
    </BrowserRouter>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      newestOnTop={true}
      closeOnClick={true}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
      draggable={true}
      pauseOnHover={true}
      transition={Zoom}
      theme="dark"
    />
    <VersionPoller />
  </StrictMode>,
);
