import { ComponentProps, RefAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { styled } from "../../../stitches.config";

const StyledLink = styled(Link, {
  variants: {
    fontSize: {
      "1": {
        fontSize: "$1",
      },
      "2": {
        fontSize: "$2",
      },
    },
    color: {
      primaryDark: {
        color: "$primaryDark",
      },
      white: {
        color: "$white",

        "&:hover": {
          color: "$primaryPurple",
          transition: "color 0.1s ease",
        },
      },
    },
  },

  defaultVariants: {
    fontSize: "$2",
  },
});

type Props = LinkProps &
  RefAttributes<HTMLAnchorElement> &
  ComponentProps<typeof StyledLink>;

export const NavigateLink = ({ children, ...rest }: Props) => (
  <StyledLink {...rest} rel="noopener">
    {children}
  </StyledLink>
);
