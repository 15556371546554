import { MouseEventHandler, useCallback, useState } from "react";
import { ModalDialog } from "../../../components/ModalDialog";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { SharePagesForm } from "../../../components/SharePagesForm/SharePagesForm";
import { useIsPublishedMultipageApp } from "../../../hooks/useIsPublishedMultipageApp";
import { useMultipageApp } from "../../../store/slices/project-slice";
import { Button } from "../../../ui/Button";

export const SharePagesModalButton = () => {
  const { project } = useProjectPageContext();
  const multipageApp = useMultipageApp();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isPublished } = useIsPublishedMultipageApp({
    multipageAppRef: multipageApp?.refPath,
  });

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOpen = useCallback((e) => {
    e.preventDefault();

    setIsModalOpen(true);
  }, []) satisfies MouseEventHandler<HTMLButtonElement>;

  return multipageApp ? (
    <>
      <Button intent="pink" onClick={handleOpen}>
        Share
      </Button>

      <ModalDialog
        isOpen={isModalOpen}
        title="Share your pages"
        onClose={handleClose}
        content={
          <SharePagesForm projectId={project.id} isPublished={isPublished} />
        }
      />
    </>
  ) : null;
};
