import { customAlphabet } from "nanoid";

export enum DatabuttonIdPrefix {
  MULTIPAGE_APP_PAGE = "mp",
  STREAMLIT_APP = "st",
  APP_DEPLOY = "ad",
  MODULE = "mo",
  SHARING_KEY = "sk",
  JOB = "jb",
  JOB_DEPLOY = "jd",
  JOB_RUN = "jr",
  SCHEDULE = "sc",
  CODE_BLOCK = "cb",
  CODE_BLOCK_VERSION = "cbv",
  PROJECT_EVENT = "pe",
  PROJECT_REQUIREMENTS = "pr",
  ANONYMOUS_USER = "an",
}

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

// ~981 years years needed, in order to have a 1% probability of at least one collision when generating 1000 per second.
export const createDatabuttonId = (prefix: DatabuttonIdPrefix): string => {
  const nanoid = customAlphabet(alphabet);
  return `${prefix}-${nanoid(16)}`;
};

export const createShortUrl = (): string => {
  const nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz");
  return nanoid(8);
};
