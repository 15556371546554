import { styled } from "../../../../stitches.config";
import { ScheduleForm } from "../../../components/ScheduleForm/ScheduleForm";
import { ScheduleFormType } from "../../../components/ScheduleForm/types";
import { Schedule } from "../../../types/persisted";
import { Button } from "../../../ui/Button";
import { ClockIcon } from "../../../ui/Icon";
import { Flex } from "../../../ui/Layout/Flex";
import { Popover } from "../../../ui/Popover";

interface Props {
  onSubmit: (schedule: string, scheduleForm: ScheduleFormType) => void;
  disabled?: boolean;
  existingSchedule: Schedule | null;
  size?: "large" | "normal";
}

const PopoverButtonLarge = styled(Button, {
  defaultVariants: {
    intent: "pink",
    size: "large",
  },
});

const PopoverButtonNormal = styled(Button, {
  defaultVariants: {
    intent: "pink",
    size: "normal",
  },
});

export const SimpleScheduleButton = ({
  onSubmit,
  disabled = false,
  existingSchedule,
  size = "normal",
}: Props) => (
  <Popover
    buttonAs={size === "large" ? PopoverButtonLarge : PopoverButtonNormal}
    buttonContent={
      <>
        <ClockIcon />
        {existingSchedule ? "Change Schedule" : "Schedule This Job"}
      </>
    }
    panelContent={
      <Flex
        direction="vertical"
        css={{
          width: "450px",
          padding: "$2",
        }}
      >
        <ScheduleForm
          onSubmit={onSubmit}
          disabled={disabled}
          existingSchedule={existingSchedule}
        />
      </Flex>
    }
  />
);
