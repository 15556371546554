import { useModulesSubscription } from "./useModules";
import { useMultipageAppSubscription } from "./useMultipageApp";
import { usePagesSubscription } from "./usePages";
import { useAppsSubscription } from "./useProjectApps";
import { useJobsSubscription } from "./useProjectJobs";

export const useComponentsSubscription = ({
  projectId,
}: {
  projectId: string;
}): void => {
  useMultipageAppSubscription({
    projectId,
  });

  usePagesSubscription({ projectId });

  useAppsSubscription({
    projectId,
    shouldFetch: true,
  });

  useModulesSubscription({
    projectId,
    shouldFetch: true,
  });

  useJobsSubscription({
    projectId,
    shouldFetch: true,
  });
};
