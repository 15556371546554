import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { useStore } from "../../store/store";
import { Button } from "../../ui/Button";
import { PlayIcon, StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { Tooltip } from "../../ui/Tooltip";
import { useWorkspaceContext } from "../BuildComponentWrapper/BuildComponentWrapper";
import { useEffect } from "react";

export const PlayButton = () => {
  const identifier = useWorkspaceContext();
  const inControl = useStore(
    (state) => state.codeBlocks[identifier.codeBlockId]?.inControl ?? false,
  );
  const isDevxReady = useStore((state) => state.project.devxHealth.isDevxReady);
  const isRunning = useStore(
    (state) => state.codeBlocks[identifier.codeBlockId]?.isRunningCode ?? false,
  );
  const updatePreviewTriggered = useStore(
    (state) => state.updatePreviewTriggered,
  );

  useEffect(() => {
    if (isDevxReady) {
      updatePreviewTriggered({ identifier });
    }
  }, [isDevxReady, identifier]);

  return (
    <Tooltip text="Run code (shift + enter)" placement="bottom">
      <Button
        css={{
          $$buttonWidth: "13px",
        }}
        // Hardcoded to ensure button has same width regardless of icon size
        disabled={!(inControl && isDevxReady)}
        intent={isRunning ? "primary" : "pink"}
        content="icon"
        onClick={() => {
          updatePreviewTriggered({ identifier });
        }}
      >
        {isRunning ? (
          <StyledFontAwesomeIcon
            css={{ width: "$$buttonWidth" }}
            icon={faSpinnerThird}
            spin={true}
          />
        ) : (
          <Flex css={{ width: "$$buttonWidth", justifyContent: "center" }}>
            <PlayIcon />
          </Flex>
        )}
      </Button>
    </Tooltip>
  );
};
