import { useEffect, useMemo } from "react";
import { styled } from "../../../../stitches.config";
import { ConvertViewsButton } from "../../../components/ConvertViewsButton/ConvertViewsButton";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import {
  createPresence,
  useUpdateMyPresence,
} from "../../../context/liveblocks-context";
import { NewComponentButton } from "../../../features/component-starter/components/NewComponentButton";
import { useIsPublishedMultipageApp } from "../../../hooks/useIsPublishedMultipageApp";
import { useStore } from "../../../store/store";
import { EditableContent } from "../../../ui/EditableContent/EditableContent";
import { Flex } from "../../../ui/Layout/Flex";
import { H1, H2, Txt } from "../../../ui/TypographyV2/TypographyV2";
import { updateProject } from "../../../utils/collections/projects";
import { CenteredLayout } from "../../UiPage/components/Layout";
import { DataStorageTable } from "./components/DataStorageTable";
import { NewJobCard } from "./components/NewJobCard";
import { NewModuleCard } from "./components/NewModuleCard";
import { NewPageCard } from "./components/NewPageCard";
import { ModuleCard } from "./ModuleCard";
import { PageCard } from "./PageCard";
import { JobCard } from "./JobCard";
import { DataCardProps } from "./types";
import { convertDatafileToDataCard, convertDataframeToDataCard } from "./utils";
import { ViewCard } from "./ViewCard";
import { useDataStorageGroups } from "../../../hooks/useDataStorageGroups";
import {
  useApps,
  useJobs,
  useModules,
  useMultipageApp,
  usePages,
} from "../../../store/slices/project-slice";
import { VisitorSparkline } from "../../../components/VisitorSparkline/VisitorSparkline";

const CardGroupContent = styled(Flex, {
  flexWrap: "wrap",

  defaultVariants: {
    gap: "2",
  },
});

export const CardGroupHeading = styled(H2, {
  fontWeight: "500",
  fontSize: "20px",
  color: "#716385",
  marginBottom: "$2",
});

export const LandingView = () => {
  const { project } = useProjectPageContext();
  const pages = usePages();
  const modules = useModules();
  const jobs = useJobs();
  const apps = useApps();
  const multipageApp = useMultipageApp();
  const dataStorageGroups = useDataStorageGroups({ projectId: project.id });
  const updateMyPresence = useUpdateMyPresence();
  const newComponentToggled = useStore((state) => state.newComponentToggled);
  const { user } = useUserGuardContext();

  const isProjectNavigatorClosed = useStore(
    (state) => state.appState.isProjectNavigatorClosed,
  );
  const projectNavigatorToggled = useStore(
    (state) => state.projectNavigatorToggled,
  );

  useEffect(() => {
    projectNavigatorToggled({ open: false });
  }, []);

  useEffect(() => {
    updateMyPresence(
      createPresence({
        user,
      }),
    );
  }, []);

  const dataComponents = useMemo(
    () =>
      [
        dataStorageGroups.dataframes.map<DataCardProps>((it) =>
          convertDataframeToDataCard(project.id, it),
        ),
        dataStorageGroups.allDatafiles.map<DataCardProps>((it) =>
          convertDatafileToDataCard(project.id, it),
        ),
      ].flat(),
    [dataStorageGroups, project.id],
  );

  const { isPublished: isPublishedMultipageApp } = useIsPublishedMultipageApp({
    multipageAppRef: multipageApp?.refPath,
  });

  const renderViewsSection = () => {
    if (apps.length === 0) {
      return null;
    }

    return (
      <Flex direction="vertical" gap="2">
        <CardGroupHeading>Views</CardGroupHeading>

        <ConvertViewsButton />

        <CardGroupContent>
          {apps.map((it) => (
            <ViewCard projectId={project.id} key={it.id} app={it} />
          ))}
        </CardGroupContent>
      </Flex>
    );
  };

  return (
    <CenteredLayout
      css={{
        paddingTop: "$6",
        paddingBottom: "200px",
      }}
      paddingX={isProjectNavigatorClosed ? "default" : "small"}
    >
      <Flex
        direction="vertical"
        gap="4"
        css={{
          marginBottom: "$4",
          paddingBottom: "$4",
        }}
      >
        <H1
          css={{
            fontWeight: "600",
            fontSize: "30px",
            width: "fit-content",
          }}
        >
          <Flex gap="1">
            <EditableContent
              initialValue={project.name}
              isClickable={true}
              onSubmit={async (value) => {
                await updateProject({
                  refPath: project.refPath,
                  payload: {
                    name: value,
                  },
                });
              }}
              validateFn={(value) => (value ? null : "This field is required")}
              onCancel={() => {}}
            />
          </Flex>
        </H1>

        <VisitorSparkline />
      </Flex>

      <Flex direction="vertical" gap="8">
        <Flex gap="3">
          <Txt>New:</Txt>
          <Flex css={{ gap: "2px" }}>
            <NewComponentButton
              text="Page"
              onClick={() => {
                newComponentToggled({ open: true, tags: ["Page"] });
              }}
            />
            <NewComponentButton
              text="Job"
              onClick={() => {
                newComponentToggled({ open: true, tags: ["Job"] });
              }}
            />
            <NewComponentButton
              text="Library"
              onClick={() => {
                newComponentToggled({ open: true, tags: ["Library"] });
              }}
            />
            <NewComponentButton
              text="Data"
              onClick={() => {
                newComponentToggled({
                  open: true,
                  tags: ["Data"],
                  autoselect: true,
                });
              }}
            />
          </Flex>
        </Flex>

        <Flex direction="vertical">
          <CardGroupHeading>Pages</CardGroupHeading>

          <CardGroupContent>
            <NewPageCard />

            {pages.map((it) => (
              <PageCard
                projectId={project.id}
                key={it.id}
                page={it}
                isPublished={isPublishedMultipageApp}
              />
            ))}
          </CardGroupContent>
        </Flex>

        {renderViewsSection()}

        <Flex direction="vertical">
          <CardGroupHeading>Jobs</CardGroupHeading>
          <CardGroupContent>
            <NewJobCard />

            {jobs.map((it) => (
              <JobCard key={it.id} projectId={project.id} job={it} />
            ))}
          </CardGroupContent>
        </Flex>

        <Flex direction="vertical">
          <CardGroupHeading>Libraries</CardGroupHeading>

          <CardGroupContent>
            <NewModuleCard />

            {modules.map((it) => (
              <ModuleCard projectId={project.id} key={it.id} module={it} />
            ))}
          </CardGroupContent>
        </Flex>

        <DataStorageTable title="Data storage" items={dataComponents} />
      </Flex>
    </CenteredLayout>
  );
};
