import { MultipageAppDeploymentRequest } from "@databutton/firebase-types";
import { addDoc, collection } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { styled } from "../../../../stitches.config";
import { useCodeInProd } from "../../../components/DatabuttonEditor/hooks/useUnpublishedChangesMessage";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import { ZonedTimestamp } from "../../../components/ZonedTimestamp/ZonedTimestamp";
import { useMultipageAppDeployments } from "../../../hooks/useDeployments";
import { useMultipageApp } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import {
  ArrowUpRightIcon,
  CompareIcon,
  CopyIcon,
  LiveIcon,
} from "../../../ui/Icon";
import { Flex } from "../../../ui/Layout/Flex";
import {
  LinkButton,
  OutboundLinkButton,
} from "../../../ui/LinkButton/LinkButton";
import { Popover } from "../../../ui/Popover";
import { H2, Txt } from "../../../ui/TypographyV3/TypographyV3";
import { DEFAULT_MULTIPAGE_APP_KEY } from "../../../utils/collections/multipage-apps";
import { fetchProject } from "../../../utils/collections/projects";
import {
  CollectionName,
  createCollectionRefPath,
} from "../../../utils/collections/shared";
import { firestore } from "../../../utils/firebase";
import { isWorkspaceGenerated } from "../../../utils/project-utils";
import { sleep } from "../../../utils/ts-utils";
import {
  copyTextToClipboard,
  createPerformedByObj,
} from "../../../utils/user-utils";

interface Props {
  pageId: string;
  codeBlockId: string;
}

const publishPageAndWait = async (params: {
  projectRefPath: string;
  payload: MultipageAppDeploymentRequest;
}) => {
  await Promise.all([
    addDoc(
      collection(
        firestore,
        params.projectRefPath,
        CollectionName.MULTIPAGE_APPS,
        DEFAULT_MULTIPAGE_APP_KEY,
        CollectionName.DEPLOYMENT_REQUESTS,
      ),
      params.payload,
    ),
    sleep(1_000),
  ]);
};

const publishPage = async (params: {
  projectRefPath: string;
  payload: MultipageAppDeploymentRequest;
}) => {
  const project = await fetchProject({ refPath: params.projectRefPath });

  if (isWorkspaceGenerated(project)) {
    await toast.promise(publishPageAndWait(params), {
      pending: "Publishing pages",
      success: "Publishing pages",
      error: "Could not publish page",
    });
  } else {
    toast.error("Please wait for workspace to be generated.");
  }
};

const PublishButton = styled(Button, {
  defaultVariants: {
    intent: "primary",
  },
});

const PublishedButton = styled(Button, {
  defaultVariants: {
    intent: "success",
  },
});

export const PublishPagesButton = ({ pageId, codeBlockId }: Props) => {
  const { project } = useProjectPageContext();
  const multipageApp = useMultipageApp();
  const { user } = useUserGuardContext();
  const [isPublishing, setIsPublishing] = useState(false);
  const { latestDeployment } = useMultipageAppDeployments({
    deploymentCollection: multipageApp
      ? createCollectionRefPath([
          multipageApp.refPath,
          CollectionName.DEPLOYMENTS,
        ])
      : null,
  });

  const publishPageFn = useCallback(async () => {
    setIsPublishing(true);

    const payload = {
      createdBy: createPerformedByObj({ user }),
      deploymentRef: null,
    } satisfies MultipageAppDeploymentRequest;

    await publishPage({
      projectRefPath: project.refPath,
      payload,
    });

    setIsPublishing(false);
  }, [project.refPath, user]);

  const appUrl = useMemo(
    () => `${window.location.origin}${multipageApp?.shortUrl ?? ""}`,
    [multipageApp?.shortUrl],
  );

  const draftHash = useStore((state) => state.codeBlocks[codeBlockId]?.hash);
  const codeInProd = useCodeInProd({
    componentId: pageId,
    componentType: "page",
  });

  const compareVersionsPathSegment = useMemo(
    () =>
      codeInProd
        ? `/code-blocks/${codeBlockId}/diff/${codeInProd.codeBlockVersionId}---latest?restorable=true`
        : null,
    [codeInProd],
  );

  const isPublished = useMemo(() => !!codeInProd, [codeInProd]);

  const containsUnsavedChanges = useMemo(
    () => codeInProd === undefined || codeInProd.hash !== draftHash,
    [codeInProd, draftHash],
  );

  const isPublishedAndNoChanges = useMemo(
    () => isPublished && !containsUnsavedChanges,
    [isPublished, containsUnsavedChanges],
  );

  const buttonText = useMemo(() => {
    // If code hash in prod and draft is different, show "Publish changes"
    if (isPublished && containsUnsavedChanges) {
      return "Publish changes";
    }

    // If code hash in prod and draft is the same, show "Published"
    if (isPublished && !containsUnsavedChanges) {
      return "Published";
    }

    // If not code in prod and fallback
    return "Publish this App";
  }, [isPublished, containsUnsavedChanges]);

  return (
    <Popover
      buttonAs={isPublishedAndNoChanges ? PublishedButton : PublishButton}
      panelContent={
        <Flex
          direction="vertical"
          gap="3"
          css={{ width: "320px", padding: "$2" }}
        >
          <Flex direction="vertical" gap="2">
            <H2>Publish App</H2>

            {latestDeployment && isPublished && (
              <Txt color="secondary">
                Last published:{" "}
                <ZonedTimestamp
                  timestamp={latestDeployment.createdBy.timestamp}
                  showAsRelative={true}
                />
              </Txt>
            )}

            {!isPublished && (
              <Txt color="secondary">This app is not published.</Txt>
            )}

            {!isPublished && (
              <Txt color="secondary">Publish this app to enable sharing.</Txt>
            )}
          </Flex>

          {multipageApp?.shortUrl && isPublished && (
            <Flex
              gap="1"
              css={{
                backgroundColor: "$white",
                padding: "$1",
                borderBottom: "1px solid #DFDFDF",
                justifyContent: "space-between",
              }}
            >
              <Txt color="secondary">{appUrl}</Txt>
              <OutboundLinkButton
                intent="pink"
                content="icon"
                target="_blank"
                rel="noopener"
                href={appUrl}
              >
                <ArrowUpRightIcon />
              </OutboundLinkButton>
            </Flex>
          )}

          {containsUnsavedChanges && (
            <Flex css={{ justifyContent: "space-between" }} gap="1">
              <Txt color="secondary">You have unpublished changes</Txt>

              <LinkButton
                to={`/projects/${project.id}${compareVersionsPathSegment}`}
                intent="pink"
              >
                <CompareIcon />
                Compare
              </LinkButton>
            </Flex>
          )}

          <Flex
            css={{
              justifyContent: "space-between",
            }}
          >
            <Button
              intent="pink"
              css={{
                visibility: isPublished ? "visible" : "hidden",
              }}
              onClick={async () => {
                await copyTextToClipboard(appUrl);
                toast.success("Copied to clipboard");
              }}
            >
              <CopyIcon />
              Copy URL
            </Button>

            <Button
              intent={isPublishedAndNoChanges ? "success" : "primary"}
              disabled={isPublishing}
              onClick={publishPageFn}
              css={{
                gap: "$1",
              }}
            >
              {buttonText}
              {isPublishedAndNoChanges && <LiveIcon />}
            </Button>
          </Flex>
        </Flex>
      }
      buttonContent={
        <>
          {buttonText}
          {isPublishedAndNoChanges && <LiveIcon />}
        </>
      }
    />
  );
};
