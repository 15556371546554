import { styled } from "../../../stitches.config";

export const Message = styled("div", {
  borderRadius: "4px",
  padding: "$1 $2",
  width: "fit-content",
  border: "2px solid transparent",

  variants: {
    intent: {
      info: {
        background: "$gray2",
        color: "$white",
      },
      warning: {
        background: "$lightGray",
        color: "$primaryDark",
      },
      danger: {
        background: "#831D1D",
        color: "$white",
      },
      success: {
        background: "$cardCircleGreen",
        color: "$white",
      },
    },
  },

  defaultVariants: {
    intent: "info",
  },
});
