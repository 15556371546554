import { useMemo } from "react";
import { useProjectSummary } from "../../../store/slices/home-slice";
import { TruncatedText } from "../../../ui/TruncatedText/TruncatedText";
import { useProjectStats } from "../hooks";
import { generateComponentsText } from "../utils";

interface Props {
  projectId: string;
}

export const ProjectCardMeta = ({ projectId }: Props) => {
  useProjectStats(projectId);
  const projectSummary = useProjectSummary(projectId);

  const meta = useMemo(
    () =>
      projectSummary
        ? generateComponentsText({
            projectSummary,
          })
        : null,
    [projectSummary],
  );

  return (
    <TruncatedText
      css={{
        color: "$gray4",
        fontSize: "12px",
        fontWeight: 600,
      }}
    >
      {meta}
    </TruncatedText>
  );
};
