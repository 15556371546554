import { ReactNode } from "react";
import { styled } from "../../../stitches.config";
import { Icon } from "../../ui/Icon";
import { Text } from "../../ui/Text";
import { A, H1 } from "../../ui/Typography";

interface Props {
  title?: string;
  content: ReactNode;
}

const Container = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  gap: "86px",
  width: "80%",
  maxWidth: "calc(1440px - (2 * 150px))",
  margin: "300px auto",
});

const ErrorPage = ({ content, title = "Oh no!" }: Props) => (
  <Container>
    <Icon src="/sad-face.svg" alt="Sad face" />
    <div>
      <H1 css={{ fontSize: "120px", marginTop: "$1", marginBottom: "$1" }}>
        {title}
      </H1>
      {content}
    </div>
  </Container>
);

export const NotFoundPage = () => (
  <ErrorPage
    content={
      <div>
        <Text>It seems we could not find what you are looking for.</Text>
        <Text>
          Head <A href="/">back home</A>
        </Text>
        .
      </div>
    }
  />
);
