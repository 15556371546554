import queryString from "query-string";

export enum RoutePath {
  HOME = "/",
  LOGIN = "/login",
  LOGIN_LINK_SENT = "/login/link-sent",
  SIGN_UP = "/sign-up",
  SIGN_UP_LINK_SENT = "/sign-up/link-sent",
  COMPLETE_PROFILE = "/complete-profile",
  COMPLETE_SIGN_IN = "/complete-sign-in",
}

interface RouteBaseParams {
  // to: RoutePath | string;
  query?: Record<string, string | null | undefined | boolean>;
  search?: URLSearchParams;
}

interface AppRouteParams extends RouteBaseParams {
  to: "app";
  params: {
    projectId: string;
    appSlug: string;
    mode: "build" | "monitor";
  };
}

interface ModuleRouteParams extends RouteBaseParams {
  to: "module";
  params: {
    projectId: string;
    moduleIdOrSlug: string;
  };
}

export const createPath = ({
  to,
  query,
  search,
}: {
  to: RoutePath | string;
  query?: Record<string, string | null | undefined | boolean>;
  search?: URLSearchParams;
}): string => {
  if (query) {
    return `${to}?${queryString.stringify(query, {
      skipNull: true,
      skipEmptyString: true,
      encode: false,
    })}`;
  }

  if (search) {
    return `${to}?${search.toString()}`;
  }

  return to;
};

const getPath = (
  route:
    | AppRouteParams
    | ModuleRouteParams
    | JobRouteParams
    | DataframeRouteParams
    | ProjectRouteParams
    | PackageLogsRouteParams
    | ProjectConfigurationRouteParams
    | DatafileRouteParams
    | PageRouteParams,
): string => {
  switch (route.to) {
    case "app":
      return `/projects/${route.params.projectId}/apps/${route.params.appSlug}/${route.params.mode}`;
    case "module":
      return `/projects/${route.params.projectId}/libraries/${route.params.moduleIdOrSlug}/build`;
    case "configuration":
      return `/projects/${route.params.projectId}/configure`;
    case "datafile":
      switch (route.params.contentType) {
        case "application/json":
          return `/projects/${route.params.projectId}/datafiles/${route.params.slug}/json`;
        case "text/plain":
          return `/projects/${route.params.projectId}/datafiles/${route.params.slug}/text`;
        case "application/octet-stream":
          return `/projects/${route.params.projectId}/datafiles/${route.params.slug}/binary`;
        default:
          return `/projects/${route.params.projectId}/datafiles/${route.params.slug}`;
      }
    case "dataframe":
      return `/projects/${route.params.projectId}/dataframes/${route.params.slug}`;
    case "job":
      return `/projects/${route.params.projectId}/jobs/${route.params.jobSlug}/${route.params.mode}`;
    case "package-logs":
      return `/projects/${route.params.projectId}/install-logs/${route.params.requirementsId}`;
    case "project":
      return `/projects/${route.params.projectId}`;
    case "page":
      return `/projects/${route.params.projectId}/pages/${route.params.pageIdOrSlug}/build`;
  }
};

export const routeUtils = {
  path: (
    route:
      | AppRouteParams
      | ModuleRouteParams
      | JobRouteParams
      | DataframeRouteParams
      | ProjectRouteParams
      | PackageLogsRouteParams
      | ProjectConfigurationRouteParams
      | DatafileRouteParams
      | PageRouteParams,
  ): string => {
    const to = getPath(route);

    return createPath({
      ...route,
      to,
    });
  },
};

/** @deprecated Use routeUtils.path() */
export const createPathToApp = ({
  projectId,
  appSlug,
  mode = "build",
}: {
  projectId: string;
  appSlug: string;
  mode?: "build" | "monitor";
}): string => `/projects/${projectId}/apps/${appSlug}/${mode}`;

/** @deprecated Use routeUtils.path() */
export const createPathToModule = (params: {
  projectId: string;
  moduleIdOrSlug: string;
}): string =>
  `/projects/${params.projectId}/libraries/${params.moduleIdOrSlug}/build`;

interface PageRouteParams extends RouteBaseParams {
  to: "page";
  params: {
    projectId: string;
    pageIdOrSlug: string;
  };
}

interface JobRouteParams extends RouteBaseParams {
  to: "job";
  params: {
    projectId: string;
    jobSlug: string;
    mode: "build" | "monitor";
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToJob = (params: {
  projectId: string;
  jobSlug: string;
  mode: "build" | "monitor";
}): string =>
  `/projects/${params.projectId}/jobs/${params.jobSlug}/${params.mode}`;

interface DataframeRouteParams extends RouteBaseParams {
  to: "dataframe";
  params: {
    projectId: string;
    slug: string;
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToDataframe = (params: {
  projectId: string;
  slug: string;
}): string => `/projects/${params.projectId}/dataframes/${params.slug}`;

interface ProjectRouteParams extends RouteBaseParams {
  to: "project";
  params: {
    projectId: string;
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToProject = (params: { projectId: string }): string =>
  `/projects/${params.projectId}`;

interface PackageLogsRouteParams extends RouteBaseParams {
  to: "package-logs";
  params: {
    projectId: string;
    requirementsId: string;
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToPackageLogs = (params: {
  projectId: string;
  requirementsId: string;
}): string =>
  `/projects/${params.projectId}/install-logs/${params.requirementsId}`;

interface ProjectConfigurationRouteParams extends RouteBaseParams {
  to: "configuration";
  params: {
    projectId: string;
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToProjectConfiguration = (params: {
  projectId: string;
}): string => `/projects/${params.projectId}/configure`;

interface DatafileRouteParams extends RouteBaseParams {
  to: "datafile";
  params: {
    projectId: string;
    contentType: string;
    slug: string;
  };
}

/** @deprecated Use routeUtils.path() */
export const createPathToDatafile = (params: {
  projectId: string;
  contentType: string;
  slug: string;
}): string => {
  if (params.contentType === "application/json") {
    return `/projects/${params.projectId}/datafiles/${params.slug}/json`;
  }

  if (params.contentType === "text/plain") {
    return `/projects/${params.projectId}/datafiles/${params.slug}/text`;
  }

  if (params.contentType === "application/octet-stream") {
    return `/projects/${params.projectId}/datafiles/${params.slug}/binary`;
  }

  return `/projects/${params.projectId}/datafiles/${params.slug}`;
};
