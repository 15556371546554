import * as Sentry from "@sentry/react";
import { Datafile } from "../types/persisted";

export const getCodeSnippetForDatafile = (datafile: Datafile): string => {
  if (datafile.contentType === "application/json") {
    return `data = db.storage.json.get(key="${datafile.id}")`;
  }

  if (datafile.contentType === "text/plain") {
    return `text = db.storage.text.get(key="${datafile.id}")`;
  }

  if (datafile.contentType === "application/octet-stream") {
    return `data = db.storage.binary.get(key="${datafile.id}")`;
  }

  throw new Error(
    `Could not generate snippet for datafile of content type: ${datafile.contentType}`,
  );
};

export const getFileType = (
  contentType: string,
): "json" | "txt" | "bin" | null => {
  if (contentType.toLowerCase() === "application/json") {
    return "json";
  }

  if (contentType.toLowerCase() === "text/plain") {
    return "txt";
  }

  if (contentType.toLowerCase() === "application/octet-stream") {
    return "bin";
  }

  Sentry.captureMessage("Found unsupported content type", {
    level: "warning",
    extra: {
      contentType,
    },
  });

  return null;
};
