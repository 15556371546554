import { ReactNode } from "react";
import { styled } from "../../../stitches.config";

interface Props {
  height?: string;
  children: ReactNode;
}

const Wrapper = styled("div", {
  height: "calc(100% - 50px)",
  overflow: "auto",
  position: "relative",
});

export const SidecarContent = ({ children, height }: Props) => (
  <Wrapper css={height ? { height } : undefined}>{children}</Wrapper>
);
