import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { SetOptional } from "type-fest";
import { CodeBlock, CodeBlockVersion } from "../types/persisted";
import { dbtnApi } from "./dbtn-api";
import { logService } from "./log-service";

interface DevxCodeBlockRef {
  ctype: "module" | "job" | "view" | "page";
  componentId: string;
  codeBlockId: string;
  version: string;
  name: string;

  // Defaults to false in devx
  dirty?: boolean;
}

export interface DevxCodeBlock extends DevxCodeBlockRef {
  code: string;
}

type CreateOrUpdateCodeBlockRequest = {
  codeBlock: DevxCodeBlock;
};

type RenameCodeBlockRequest = {
  oldCodeBlock: DevxCodeBlockRef;
  newCodeBlock: DevxCodeBlockRef;
};

type DeleteCodeBlockRequest = {
  codeBlock: DevxCodeBlockRef;
};

type RefreshCodeBlocksRequest = {
  timestamp: string;
};

/**
 * Convert code block and code block version to devx code block ref
 */
const toDevxCodeBlockRef = ({
  codeBlock,
  codeBlockVersion,
  dirty,
}: {
  codeBlock: SetOptional<CodeBlock, "refPath">;
  codeBlockVersion: SetOptional<CodeBlockVersion, "refPath">;
  dirty?: boolean;
}): DevxCodeBlockRef => {
  if (!codeBlockVersion.name) {
    Sentry.captureMessage(
      `Found code block version without name: ${codeBlockVersion.name}. Using component id as fallback`,
    );
  }

  const devxCodeBlockRef = {
    ctype: codeBlock.type === "app" ? "view" : codeBlock.type,
    codeBlockId: codeBlock.id,
    componentId: codeBlock.componentId,
    name: codeBlockVersion.name ?? codeBlock.componentId,
    version: codeBlockVersion.createdAtUtc.toDate().toISOString(),
    dirty,
  } satisfies DevxCodeBlockRef;

  return devxCodeBlockRef;
};

type CreateOrUpdateCodeBlockParams =
  | {
      projectId: string;
      user: User;
      devxCodeBlock: DevxCodeBlock;
      isDevxReady: boolean;
      dirty?: boolean;
    }
  | {
      projectId: string;
      user: User;
      codeBlock: SetOptional<CodeBlock, "refPath">;
      codeBlockVersion: SetOptional<CodeBlockVersion, "refPath">;
      isDevxReady: boolean;
      dirty?: boolean;
    };

const convertToRequest = (
  params: CreateOrUpdateCodeBlockParams,
): { id: string; request: CreateOrUpdateCodeBlockRequest } => {
  if ("devxCodeBlock" in params) {
    const request = {
      codeBlock: params.devxCodeBlock,
    } satisfies CreateOrUpdateCodeBlockRequest;

    return {
      id: params.devxCodeBlock.codeBlockId,
      request,
    };
  } else {
    const devxCodeBlockRef = toDevxCodeBlockRef({
      codeBlock: params.codeBlock,
      codeBlockVersion: params.codeBlockVersion,
      dirty: params.dirty,
    });

    const request = {
      codeBlock: {
        ...devxCodeBlockRef,
        code: params.codeBlockVersion.code,
      },
    } satisfies CreateOrUpdateCodeBlockRequest;

    return {
      id: params.codeBlock.id,
      request,
    };
  }
};

const createOrUpdateCodeBlock = async (
  params: CreateOrUpdateCodeBlockParams,
): Promise<void> => {
  if (params.isDevxReady) {
    const { id: codeBlockId, request } = convertToRequest(params);

    await dbtnApi.put({
      projectId: params.projectId,
      user: params.user,
      route: `/dbtn/devx/codeblocks/${codeBlockId}`,
      json: request,
    });
  } else {
    logService.info(
      "Created or update code block before devx was ready. Skipping code block sync",
    );
  }
};

const renameCodeBlock = async (params: {
  projectId: string;
  user: User;
  codeBlock: CodeBlock;
  oldVersion: CodeBlockVersion;
  newVersion: CodeBlockVersion;
  isDevxReady: boolean;
}): Promise<void> => {
  if (params.isDevxReady) {
    const payload = {
      oldCodeBlock: toDevxCodeBlockRef({
        codeBlock: params.codeBlock,
        codeBlockVersion: params.oldVersion,
      }),
      newCodeBlock: toDevxCodeBlockRef({
        codeBlock: params.codeBlock,
        codeBlockVersion: params.newVersion,
      }),
    } satisfies RenameCodeBlockRequest;

    const { id: codeBlockId } = params.codeBlock;

    await dbtnApi.post({
      projectId: params.projectId,
      user: params.user,
      route: `/dbtn/devx/codeblocks/${codeBlockId}/rename`,
      json: payload,
    });
  } else {
    logService.info(
      "Renamed code block before devx was ready. Skipping code block sync",
    );
  }
};

const deleteCodeBlock = async (params: {
  projectId: string;
  user: User;
  codeBlock: CodeBlock;
  codeBlockVersion: CodeBlockVersion;
  isDevxReady: boolean;
}): Promise<void> => {
  if (params.isDevxReady) {
    const payload = {
      codeBlock: toDevxCodeBlockRef({
        codeBlock: params.codeBlock,
        codeBlockVersion: params.codeBlockVersion,
      }),
    } satisfies DeleteCodeBlockRequest;

    const { id: codeBlockId } = params.codeBlock;

    await dbtnApi.delete({
      projectId: params.projectId,
      user: params.user,
      route: `/dbtn/devx/codeblocks/${codeBlockId}`,
      json: payload,
    });
  } else {
    logService.info(
      "Deleted code block before devx was ready. Skipping code block sync",
    );
  }
};

const refreshCodeBlocks = async (params: {
  projectId: string;
  user: User;
  timestamp: Timestamp;
}): Promise<void> => {
  const timestampAsString = params.timestamp.toDate().toISOString();
  logService.debug(
    `Telling devx to refresh code blocks up to: ${timestampAsString}`,
  );

  const payload = {
    timestamp: timestampAsString,
  } satisfies RefreshCodeBlocksRequest;

  await dbtnApi.post({
    projectId: params.projectId,
    user: params.user,
    route: "/dbtn/devx/codeblocks/api/refresh",
    json: payload,
  });
};

export const codeBlockSync = {
  createOrUpdate: createOrUpdateCodeBlock,
  rename: renameCodeBlock,
  delete: deleteCodeBlock,
  refresh: refreshCodeBlocks,
};
