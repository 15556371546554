import { faArrowLeft, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { memo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { styled } from "../../../stitches.config";
import { useUser } from "../../hooks/useUser";
import { useProject } from "../../store/slices/project-slice";
import { TopBarButton } from "../../ui/Button";
import { Icon, StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { LinkButton } from "../../ui/LinkButton/LinkButton";
import { TopBarButtonLink } from "../../ui/TopBarButtonLink";
import {
  isOnOffFeatureEnabled,
  OnOffFeatureFlag,
} from "../../utils/feature-flag-utils";
import {
  canSeeProjectEvents,
  canShareProject,
} from "../../utils/permission-utils";
import { RoutePath } from "../../utils/route-utils";
import { AccountMenu } from "../AccountMenu/AccountMenu";
import { InviteCollaboratorsModal } from "../InviteCollaboratorsModal/InviteCollaboratorsModal";
import { ShareFromShortUrlButton } from "../ShareFromShortUrlButton/ShareFromShortUrlButton";
import { TopBarContainer } from "../TopBarContainer/TopBarContainer";
import { ProjectEvents } from "./ProjectEvents";

const LogoLink = styled(Link, {
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",

  "&:focus:active": {
    outline: "none",
    boxShadow: "none",
  },
});

interface Props {
  shouldShowDocs?: boolean;
  showProjectEvents?: boolean;
  showTabs?: boolean;
}

export const TopBar = memo(
  ({ shouldShowDocs = false, showProjectEvents = false }: Props) => {
    const { user } = useUser();
    const params = useParams<{ projectId: string; shortUrlId: string }>();
    const project = useProject();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    return (
      <TopBarContainer>
        <Flex gap="2">
          <LogoLink to={RoutePath.HOME}>
            <Icon src="/logo.png" height="24px" width="24px" alt="Databutton" />
          </LogoLink>

          {project && (
            <LinkButton to='/' intent="pink">
              <StyledFontAwesomeIcon icon={faArrowLeft} />
              All Apps
            </LinkButton>
          )}
        </Flex>

        <Flex gap="1">
          {showProjectEvents &&
            isOnOffFeatureEnabled(OnOffFeatureFlag.SHOW_PROJECT_EVENTS) &&
            project &&
            canSeeProjectEvents({ project, user }) && (
              <ProjectEvents projectId={project.id} />
            )}

          {user && params.shortUrlId && (
            <ShareFromShortUrlButton
              shortUrlId={params.shortUrlId}
              user={user}
            />
          )}

          {project && user && canShareProject({ project, user }) && (
            <>
              <TopBarButton
                content="icon"
                intent="secondary"
                onClick={() => {
                  setIsShareModalOpen(true);
                }}
              >
                <StyledFontAwesomeIcon icon={faUserPlus} />
                Invite collaborators
              </TopBarButton>

              <InviteCollaboratorsModal
                isOpen={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
                project={project}
              />
            </>
          )}

          {shouldShowDocs && (
            <TopBarButtonLink
              href="https://docs.databutton.com"
              target="_blank"
              rel="noreferrer"
              intent="secondary"
            >
              Docs
            </TopBarButtonLink>
          )}

          {user && <AccountMenu user={user} />}
        </Flex>
      </TopBarContainer>
    );
  },
);
