import { User } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { logService } from "../../services/log-service";
import { MultipageApp } from "../../types/persisted";
import { firestore } from "../firebase";
import { CollectionName, createConverter, createDocRefPath } from "./shared";

export const DEFAULT_MULTIPAGE_APP_KEY = "main";

export const multipageAppConverter = createConverter<MultipageApp>();

export const getMultipageAppKey = (params: { projectId: string }): string =>
  createDocRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.MULTIPAGE_APPS,
    "main",
  ]);

export const fetchMultipageApp = async (params: {
  refPath: string;
}): Promise<MultipageApp | null> => {
  logService.debug(`Fetching multipage app: ${params.refPath}`);
  const result = await getDoc(
    doc(firestore, params.refPath).withConverter(multipageAppConverter),
  );

  return result.data() ?? null;
};

const updateMultipageApp = async (params: {
  refPath: string;
  payload: Partial<MultipageApp>;
}): Promise<void> => {
  await updateDoc(doc(firestore, params.refPath), params.payload);
};

const togglePubliclyAccessibleByLink = async (params: {
  user: User;
  projectId: string;
  multipageApp: MultipageApp;
}) => {
  const nextState = !params.multipageApp.isPublic;
  const payload = {
    isPublic: nextState,
  } satisfies Pick<MultipageApp, "isPublic">;

  await updateMultipageApp({
    refPath: params.multipageApp.refPath,
    payload,
  });
};

export const multipageAppDoc = {
  togglePubliclyAccessibleByLink,
};
