import { styled } from "../../../stitches.config";

export const LayoutWithActionBar = styled("div", {
  $$actionBarHeight: "50px",
  height: "calc(100vh - $$topBarHeight)",
  width: "100%",

  display: "grid",
  gridTemplateRows: "$$actionBarHeight auto",
});

export const ActionBar = styled("div", {
  borderBottom: "1px solid $mainBorder",
  display: "grid",
  gridTemplateColumns: "1fr max-content 1fr",
  alignItems: "center",
  paddingX: "$1",
  width: "100%",
  gap: "$1",

  variants: {
    justifyContent: {
      "space-between": {
        justifyContent: "space-between",
      },
    },
  },
});
