import React, { useEffect } from "react";
import { useStore } from "../../store/store";
import { LoadingView } from "./LoadingView";

interface Props {
  children: React.ReactNode;
}

export const InitWrapper = ({ children }: Props) => {
  const isInitialized = useStore((state) => state.auth.isInitialized);
  const fetchUser = useStore((state) => state.fetchUser);

  useEffect(() => {
    fetchUser();
  }, []);

  if (isInitialized) {
    return <>{children}</>;
  }

  return <LoadingView />;
};
