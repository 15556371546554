import { useMemo } from "react";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useCodeInProd } from "../../../components/DatabuttonEditor/hooks/useUnpublishedChangesMessage";
import { ScheduleFormType } from "../../../components/ScheduleForm/types";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import { useSchedule } from "../../../hooks/useSchedule";
import { useStore } from "../../../store/store";
import { Job } from "../../../types/persisted";
import { Button } from "../../../ui/Button";
import { ClockIcon } from "../../../ui/Icon";
import { Tooltip } from "../../../ui/Tooltip";
import { scheduleJob } from "../../../utils/job-utils";
import { SimpleScheduleButton } from "../../ProjectPage/EditJobForm/SimpleScheduleButton";

interface Props {
  job: Job;
}

export const ScheduleButton = ({ job }: Props) => {
  const identifier = useWorkspaceContext();
  const { user } = useUserGuardContext();
  const { schedule, loading: isScheduleLoading } = useSchedule(
    identifier.projectId,
    identifier.componentId,
  );
  const codeInProd = useCodeInProd({
    componentId: identifier.componentId,
    componentType: "job",
  });
  const draftHash = useStore(
    (state) => state.codeBlocks[identifier.codeBlockId]?.hash,
  );

  const containsUnsavedChanges = useMemo(
    () => codeInProd === undefined || codeInProd.hash !== draftHash,
    [codeInProd, draftHash],
  );

  if (isScheduleLoading) {
    return null;
  }

  return schedule?.scheduleForm && containsUnsavedChanges ? (
    <Tooltip text="Apply changes to your running schedule">
      <Button
        intent="pink"
        onClick={async () => {
          await scheduleJob({
            projectId: identifier.projectId,
            job,
            user,
            scheduleForm: schedule.scheduleForm as ScheduleFormType,
            cronExpression: schedule.cronExpression,
          });
        }}
      >
        <ClockIcon />
        Apply changes
      </Button>
    </Tooltip>
  ) : (
    <SimpleScheduleButton
      existingSchedule={schedule}
      disabled={false}
      onSubmit={async (cronExpression, scheduleForm) => {
        await scheduleJob({
          projectId: identifier.projectId,
          job,
          cronExpression,
          user,
          scheduleForm,
        });
      }}
    />
  );
};
