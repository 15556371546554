import { User } from "firebase/auth";
import { DevxCodeBlock } from "../../services/code-block-sync";
import { dbtnApi } from "../../services/dbtn-api";
import { CodeBlock, CodeBlockVersion } from "../../types/persisted";
import { getReloadPreviewUrl } from "../../utils/page-utils";

export const updatePreview = async (params: {
  projectId: string;
  pageId: string;
  draftHash: number | null;
  latestCodeBlockVersion: CodeBlockVersion;
  codeBlock: CodeBlock;
  isDevxReady: boolean;
  code: string;
  user: User;
  onInstanceTagReceived?: (params: { instanceTag: string }) => void;
}): Promise<void> => {
  const devxCodeBlock = {
    ctype: "page",
    componentId: params.pageId,
    codeBlockId: params.codeBlock.id,
    version: params.latestCodeBlockVersion.createdAtUtc.toDate().toISOString(),
    name: params.latestCodeBlockVersion.name ?? params.pageId,
    // Set update as dirty if the code sent hasn't been saved yet
    dirty: params.draftHash !== params.latestCodeBlockVersion.hash,
    code: params.code,
  } satisfies DevxCodeBlock;

  const request = { codeBlock: devxCodeBlock } satisfies {
    codeBlock: DevxCodeBlock;
  };

  const response = await dbtnApi.post({
    projectId: params.projectId,
    user: params.user,
    route: "/dbtn/devx/streamlit/multipage/preview",
    json: request,
  });

  const responseJson = await response.json<ReloadPagesResponse>();

  if (responseJson.instanceTag) {
    params.onInstanceTagReceived?.({ instanceTag: responseJson.instanceTag });
  }
};

interface ReloadPagesResponse {
  instanceTag: string;
  ready: boolean;
}

/**
 * Tell devx to reload pages and update the code to the latest code synced to devx
 */
export const reloadPages = async (params: {
  projectId: string;
  user: User;
  onInstanceTagReceived?: (params: { instanceTag: string }) => void;
}): Promise<ReloadPagesResponse> => {
  const response = await dbtnApi.post({
    user: params.user,
    url: getReloadPreviewUrl({ projectId: params.projectId }),
  });

  const responseBody = await response.json<ReloadPagesResponse>();

  params.onInstanceTagReceived?.({ instanceTag: responseBody.instanceTag });

  return responseBody;
};
