import { faArrowDown, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ZonedTimestamp } from "../../../../components/ZonedTimestamp/ZonedTimestamp";
import { Button } from "../../../../ui/Button";
import { StyledFontAwesomeIcon } from "../../../../ui/Icon";
import { Div } from "../../../../ui/Layout";
import { Flex } from "../../../../ui/Layout/Flex";
import {
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "../../../../ui/Table/Table";
import { Text } from "../../../../ui/Text";
import { CardGroupHeading } from "../LandingView";
import { DataCardProps } from "../types";
import { formatBytes } from "../utils";
import { BlurredText } from "./BlurredText";
import { DatafileIcon } from "./DatafileIcon";

interface Props {
  title: string;
  items: DataCardProps[];
}

const columnHelper = createColumnHelper<DataCardProps>();

const columns = [
  columnHelper.accessor("type", {
    header: "Type",
    cell: (info) => <DatafileIcon item={info.row.original} />,
  }),
  columnHelper.accessor("lastUpdatedAt", {
    header: "Last updated",
    cell: (info) => (
      <Text weight="thin" size="regular">
        <ZonedTimestamp timestamp={info.getValue()} showAsRelative={true} />
      </Text>
    ),
  }),
  columnHelper.accessor("displayName", {
    header: "Name",
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor("content", {
    header: "Content",
    enableSorting: false,
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor("size", {
    header: "Size",
    cell: (info) => <Text>{formatBytes(info.getValue())}</Text>,
  }),
];

export const DataStorageTable = ({ title, items }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "lastUpdatedAt",
      desc: true,
    },
  ]);
  const navigate = useNavigate();
  const table = useReactTable<DataCardProps>({
    data: items,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Flex direction="vertical" gap="1">
      <Flex
        css={{
          justifyContent: "space-between",
        }}
      >
        <CardGroupHeading>{title}</CardGroupHeading>

        {table.getPageCount() > 1 && (
          <Div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "$1",
            }}
          >
            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Text weight="thin">
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </Text>
            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
          </Div>
        )}
      </Flex>
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <Flex
                      onClick={header.column.getToggleSortingHandler()}
                      gap="1"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                      {{
                        desc: <StyledFontAwesomeIcon icon={faArrowUp} />,
                        asc: <StyledFontAwesomeIcon icon={faArrowDown} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {items.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <Tr
                onClick={() => {
                  navigate(row.original.to);
                }}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr key="placeholder-row">
              {columns.map((_it, index) => (
                <Td key={`placeholder-${index}`}>
                  <BlurredText css={{ width: "90px" }} />
                </Td>
              ))}
            </Tr>
          )}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
    </Flex>
  );
};
