import { PerformedBy, PerformedByAnonymous } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { AppVisit, Visit, WithoutId } from "../../types/persisted";
import { createDatabuttonId, DatabuttonIdPrefix } from "../databutton-id-utils";
import { firestore } from "../firebase";
import { localStorageUtils } from "../local-storage-utils";
import { createPerformedByObj } from "../user-utils";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
} from "./shared";

export const appVisitConverter = createConverter<AppVisit>();

export const getAppVisitCollectionKey = (params: {
  projectId: string;
  appId: string;
}): string =>
  `${CollectionName.PROJECTS}/${params.projectId}/${CollectionName.APPS}/${params.appId}/${CollectionName.VISITS}`;

const getMultipageAppVisitCollectionKey = (params: {
  projectId: string;
  multipageAppId: string;
}): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.MULTIPAGE_APPS,
    params.multipageAppId,
    CollectionName.VISITS,
  ]);

const createPerformedByForVisit = (params: {
  user: User | null;
}): PerformedBy => {
  if (params.user) {
    return createPerformedByObj({ user: params.user });
  }

  const anonymousUserFromLocalStorage = localStorageUtils.getValue<string>(
    "dbtn-anonymous-user",
    createDatabuttonId(DatabuttonIdPrefix.ANONYMOUS_USER),
  );

  localStorageUtils.setValue(
    "dbtn-anonymous-user",
    anonymousUserFromLocalStorage,
  );

  return {
    type: "anonymous",
    timestamp: Timestamp.now(),
    id: anonymousUserFromLocalStorage,
  } satisfies PerformedByAnonymous;
};

export const createMultipageAppVisit = async (params: {
  user: User | null;
  projectId: string;
  multipageAppId: string;
}) => {
  const performedBy = createPerformedByForVisit({ user: params.user });

  const multipageAppVisit = {
    visitedBy: performedBy,
  } satisfies WithoutId<Visit>;

  await addDoc(
    collection(
      firestore,
      getMultipageAppVisitCollectionKey({
        projectId: params.projectId,
        multipageAppId: params.multipageAppId,
      }),
    ),
    multipageAppVisit,
  );
};

export const createAppVisit = async (params: {
  user: User | null;
  projectId: string;
  appId: string;
}) => {
  const performedBy = createPerformedByForVisit({ user: params.user });

  const appVisit = {
    visitedBy: performedBy,
  } satisfies WithoutId<AppVisit>;

  await addDoc(
    collection(
      firestore,
      getAppVisitCollectionKey({
        projectId: params.projectId,
        appId: params.appId,
      }),
    ),
    appVisit,
  );
};
