import { Placement } from "@popperjs/core";
import Tippy from "@tippyjs/react";
import { JSXElementConstructor, ReactElement } from "react";
import { styled } from "../../../stitches.config";
import "tippy.js/dist/tippy.css";

interface Props {
  text: string;
  placement?: Placement;
  visible?: boolean;
  // rome-ignore lint/suspicious/noExplicitAny: <explanation>
  children: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
}

const StyledTooltip = styled(Tippy, {
  backgroundColor: "#fdfdfd !important",
  color: "$primaryDark",
  paddingX: "$1",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid $mainBorder",
});

export const Tooltip = ({
  text,
  placement = "auto",
  visible,
  children,
}: Props) => (
  <StyledTooltip
    arrow={false}
    visible={visible}
    content={text}
    placement={placement}
    theme="light"
    duration={[200, 400]}
    animation="fade"
    delay={200}
  >
    {children}
  </StyledTooltip>
);

/**
 * Overlay used to enable tooltip over disabled buttons. Without this, the tooltip won't be displayed
 */
export const TooltipOverlay = styled("div");

export const FieldErrorTooltip = ({
  text,
  children,
}: {
  text?: string | null | undefined;
  children: Props["children"];
}) => (
  <Tooltip text={text ?? ""} visible={!!text} placement="bottom">
    {children}
  </Tooltip>
);
