import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { MutableRefObject, ReactNode, useMemo } from "react";
import { styled } from "../../../stitches.config";
import { Button } from "../../ui/Button";
import { H1 } from "../../ui/Typography";

interface Props {
  title: string;
  content: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<HTMLElement | null> | undefined;
  modalSize?: "normal" | "wide" | "super-wide";
}

const Backdrop = styled("div", {
  position: "fixed",
  inset: 0,
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.3)",
});

const PanelWrapper = styled("div", {
  position: "fixed",
  inset: 0,
  margin: "auto",
  display: "flex",
  alignItems: "center",
  zIndex: 2,
  justifyContent: "center",
  variants: {
    width: {
      full: { width: "95%" },
      "one-fourth": { width: "25%" },
      "one-third": { width: "33%" },
      "one-half": { width: "50%" },
      "two-thirds": { width: "66.67%" },
    },
  },
});

const Panel = styled("div", {
  width: "100%",
  backgroundColor: "$backgroundLight",
  borderRadius: "4px",
});

const ModalHeader = styled("div", {
  borderBottom: "2px solid $lightGray",
  padding: "$2 $2 $1",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ModalTitle = styled(H1, {
  margin: 0,
});

const ModalContent = styled("div", {
  padding: "$2",
});

export const ModalDialog = ({
  title,
  content,
  isOpen,
  onClose,
  initialFocus,
  modalSize = "normal",
}: Props) => {
  const width = useMemo(() => {
    if (modalSize === "wide") {
      return { "@initial": "full", "@bp2": "two-thirds", "@bp3": "one-half" };
    } else if (modalSize === "super-wide") {
      return { "@initial": "full", "@bp2": "full", "@bp3": "two-thirds" };
    } else {
      return { "@initial": "full", "@bp2": "one-half", "@bp3": "one-third" };
    }
  }, [modalSize]);
  return (
    <Dialog open={isOpen} onClose={onClose} initialFocus={initialFocus}>
      <Backdrop aria-hidden="true" />

      {/* rome-ignore lint/suspicious/noExplicitAny: <explanation> */}
      <PanelWrapper width={width as any}>
        <Dialog.Panel as={Panel}>
          <ModalHeader>
            <Dialog.Title as={ModalTitle}>{title}</Dialog.Title>
            <Button
              content="icon"
              onClick={onClose}
              intent="plain"
              type="button"
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </ModalHeader>

          <ModalContent>{content}</ModalContent>
        </Dialog.Panel>
      </PanelWrapper>
    </Dialog>
  );
};
