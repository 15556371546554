import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { Deployment } from "../types/persisted";
import {
  deploymentConverter,
  getJobDeploymentsCollectionKey,
} from "../utils/collections/deployments";
import { firestore } from "../utils/firebase";

export const useJobDeployments = ({
  projectId,
  jobId,
}: {
  projectId: string;
  jobId?: string;
}) => {
  const [deployments, setDeployments] = useState<Deployment[]>([]);

  const currentDeployment = useMemo(
    () => (deployments.length > 0 ? deployments[0] : null),
    [deployments],
  );

  useEffect(() => {
    const unsub = jobId
      ? onSnapshot(
          query(
            collection(
              firestore,
              getJobDeploymentsCollectionKey({
                projectId,
                jobId,
              }),
            ),
            orderBy("createdAt", "desc"),
          ).withConverter(deploymentConverter),
          (snapshot) => {
            setDeployments(snapshot.docs.map((it) => it.data()));
          },
        )
      : undefined;

    return () => {
      unsub?.();
    };
  }, [projectId, jobId]);

  return { deployments, currentDeployment };
};
