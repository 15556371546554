import {
  CreateProjectRequest,
  CreateProjectResponse,
} from "@databutton/firebase-types";
import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { functions } from "../../utils/firebase";
import { sleep } from "../../utils/ts-utils";

const createProjectCallable = httpsCallable<
  CreateProjectRequest,
  CreateProjectResponse
>(functions, "createProject");

const createProjectAndWait = async (params: {
  name: string;
  projectTemplateId: string;
}): Promise<CreateProjectResponse> => {
  const [result] = await Promise.all([
    createProjectCallable({
      name: params.name,
      projectTemplateId: params.projectTemplateId,
      requestSentAt: Timestamp.now(),
    }),
    // Add 1 second sleep to avoid flickering UI
    sleep(1_000),
  ]);

  return result.data;
};

export const createProject = async (params: {
  name: string;
  projectTemplateId: string;
}): Promise<CreateProjectResponse & { projectTemplateId: string }> => {
  const result = await toast.promise(createProjectAndWait(params), {
    pending: "Creating data app",
    success: "Data app created",
    error: "Could not create data app",
  });

  return { ...result, projectTemplateId: params.projectTemplateId };
};
