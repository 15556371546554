import { useContext } from "react";
import { ProjectEventRow } from "../../../components/ProjectEventRow/ProjectEventRow";
import { useProjectPageContext } from "../../../components/ProjectWrapper/ProjectWrapper";
import { AllReadButton } from "../../../components/TopBar/AllReadButton";
import { UserGuardContext } from "../../../components/UserGuard/UserGuard";
import {
  getReadProjectEventsAt,
  useProjectEvents,
} from "../../../hooks/useProjectEvents";
import { Div, Group } from "../../../ui/Layout";
import { Text } from "../../../ui/Text";
import { H1 } from "../../../ui/Typography";

const NUMBER_OF_EVENTS = 25;

export const ProjectEventsView = () => {
  const { project } = useProjectPageContext();
  const { profile, user } = useContext(UserGuardContext);
  const { events: projectEvents } = useProjectEvents({
    projectId: project.id,
    readProjectEventsAt: getReadProjectEventsAt({
      projectId: project.id,
      map: profile?.projectEventsReadMap,
    }),
    numberOfEvents: NUMBER_OF_EVENTS,
  });

  if (!projectEvents) {
    return <Text>Loading project events...</Text>;
  }

  return (
    <Div
      css={{
        padding: "$2",
        maxWidth: "500px",
        overflowY: "auto",
        height: "calc(100vh - $$topBarHeight)",
      }}
    >
      <Group css={{ justifyContent: "space-between" }}>
        <H1 margin="none">Project events (last {NUMBER_OF_EVENTS})</H1>

        <AllReadButton user={user} projectId={project.id} />
      </Group>

      {projectEvents.map((it) => (
        <ProjectEventRow
          event={it}
          key={it.id}
          profile={profile}
          projectId={project.id}
        />
      ))}
    </Div>
  );
};
