import { styled } from "../../../stitches.config";
import { Box } from "../Box";

export const Spacer = styled(Box, {
  variants: {
    height: {
      "1": {
        height: "$1",
      },
      "2": {
        height: "$2",
      },
      small: {
        height: "$2",
      },
      normal: {
        height: "$4",
      },
      "6x": {
        height: "$6",
      },
      "8x": {
        height: "$8",
      },
    },
  },
  defaultVariants: {
    height: "normal",
  },
});
