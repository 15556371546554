import { ProjectDeployment } from "@databutton/firebase-types";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { CollectionName, createConverter } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";
import { useProjectPageContext } from "../components/ProjectWrapper/ProjectWrapper";

const projectDeploymentConverter = createConverter<ProjectDeployment>();

export const useProjectDeployment = () => {
  const { project } = useProjectPageContext();

  const [projectDeployment, _loading, error] =
    useDocumentData<ProjectDeployment>(
      project?.currentDeploymentId
        ? doc(
            firestore,
            project.refPath,
            CollectionName.DEPLOYMENTS,
            project?.currentDeploymentId,
          ).withConverter(projectDeploymentConverter)
        : null,
    );

  if (error) {
    logService.warn(`Could not fetch project deployment: ${error.message}`);
  }

  return { projectDeployment: projectDeployment ?? null };
};
