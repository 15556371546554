import {
  faBinary,
  faBracketsCurly,
  faTable,
  faText,
} from "@fortawesome/pro-regular-svg-icons";
import { StyledFontAwesomeIcon } from "../../../../ui/Icon";
import { getFileType } from "../../../../utils/datafile-utils";
import { DataCardProps } from "../types";

interface Props {
  item: DataCardProps;
}

export const DatafileIcon = ({ item }: Props) => {
  if (item.type === "datafile") {
    const fileType = getFileType(item.contentType);
    if (fileType === "json") {
      return <StyledFontAwesomeIcon icon={faBracketsCurly} />;
    }

    if (fileType === "bin") {
      return <StyledFontAwesomeIcon icon={faBinary} />;
    }

    if (fileType === "txt") {
      return <StyledFontAwesomeIcon icon={faText} />;
    }

    if (fileType === null) {
      // Fallback to binary
      return <StyledFontAwesomeIcon icon={faBinary} />;
    }
  }

  if (item.type === "dataframe") {
    return <StyledFontAwesomeIcon icon={faTable} />;
  }

  throw new Error(
    `Found unsupported data card type: ${
      // rome-ignore lint/suspicious/noExplicitAny: <explanation>
      (item as any).type
    }`,
  );
};
