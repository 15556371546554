import { User } from "firebase/auth";
import React, { createContext, useContext } from "react";
import { Navigate } from "react-router-dom";
import { useProfile } from "../../hooks/useProfile";
import { useUser } from "../../hooks/useUser";
import { Profile } from "../../types/persisted";

interface Props {
  children: React.ReactNode;
}

const NO_EARLY_ACCESS_REDIRECT_TARGET = "/waitlist";

/**
 * Temp workaround for ensuring user and profile is always set and typed
 *
 * This Context must not be used without checking that user and profile is actually present
 */
export const UserGuardContext = createContext<{
  user: User;
  profile: Profile;
  segments: string[];
  enabledFeatures: string[];
}>(
  {} as {
    user: User;
    profile: Profile;
    segments: string[];
    enabledFeatures: string[];
  },
);

export const useUserGuardContext = () => useContext(UserGuardContext);

const shouldSkipEarlyAccessCheck = (path: string): boolean =>
  /^\/v\/(.*)/i.test(path);

export const UserGuard = ({ children }: Props) => {
  const { user, isInitialized, segments, enabledFeatures } = useUser();
  const { profile } = useProfile(user?.email);

  if (isInitialized && !user?.email) {
    return (
      <Navigate replace={true} to={`/login?next=${window.location.pathname}`} />
    );
  }

  if (
    profile &&
    profile?.earlyAccess !== true &&
    !shouldSkipEarlyAccessCheck(window.location.pathname)
  ) {
    return <Navigate replace={true} to={NO_EARLY_ACCESS_REDIRECT_TARGET} />;
  }

  if (user && profile) {
    return (
      <UserGuardContext.Provider
        value={{ user, profile, enabledFeatures, segments }}
      >
        {children}
      </UserGuardContext.Provider>
    );
  }

  return null;
};
