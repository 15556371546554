import { styled } from "../../../stitches.config";
import { Box } from "../../ui/Box";

export const TopBarContainer = styled(Box, {
  display: "flex",
  paddingX: "$3",
  height: "$$topBarHeight",
  backgroundColor: "$white",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "0.2s ease-in",
  borderBottom: "1px solid $mainBorder",
  width: "100%",
});
