import { styled } from "../../../stitches.config";

export const BaseCard = styled("div", {
  display: "flex",
  border: "1px solid $mainBorder",
  borderRadius: "4px",

  "&:hover": {
    border: "1px solid $gray4",
  },
});
