import { styled } from "../../../stitches.config";

// Buttons are not tabbable in Safari due to: https://stackoverflow.com/a/1914496
/**
 * @deprecated Use Button
 */
export const ButtonDeprecated = styled("button", {
  $$borderWidth: "1px",

  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "$1",
  fontSize: "$1",
  fontFamily: "$sans",
  fontWeight: "600",
  padding: "$1 $2",
  borderRadius: "4px",

  "&:focus": {
    outline: "$$borderWidth solid $focusOutline",
  },

  "&:focus:active": {
    outline: "none",
    boxShadow: "none",
  },

  variants: {
    intent: {
      primary: {
        backgroundSize: "100%",
        position: "relative",
        zIndex: "1",

        color: "$white",
        background: "$darkPurpleSolid",
        border: "0",

        "&:before": {
          borderRadius: "inherit",
          content: "",
          display: "block",
          height: "100%",
          left: "0",
          opacity: "0",
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: "-1",

          background: "$primaryPurple",
        },

        // Hover color - color need to be set at &:before to transition back smoothly
        "&:hover:before": {
          opacity: "1",
        },

        // Active color
        "&:active:before": {
          opacity: "1",
          background: "$linearDeeper",
        },

        // Focus color
        "&:focus": {
          background: "$linearDeep",
        },

        // Disabled color
        "&:disabled": {
          cursor: "not-allowed",
          color: "$gray5",
          background: "$gray6",
        },

        "&:disabled:before": {
          opacity: "0",
        },
      },

      secondary: {
        backgroundSize: "100%",
        position: "relative",
        zIndex: "1",

        color: "$gray2",
        background: `
            linear-gradient($backgroundLight, $backgroundLight) padding-box,
            $darkPurpleGradient border-box
          `,
        border: "$$borderWidth solid  $gray2",

        "&:before": {
          borderRadius: "inherit",
          content: "",
          display: "block",
          height: "100%",
          left: "0",
          opacity: "0",
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: "-1",

          background: `
            linear-gradient($darkPurpleSolid, $darkPurpleSolid) padding-box,
            $darkPurpleGradient border-box
          `,
        },

        "&:hover:not(:disabled):before": {
          opacity: "1",
        },

        "&:hover:not(:disabled)": {
          color: "$white",
          border: "none",
          padding: "calc($1 + $$borderWidth) calc($2 + $$borderWidth)",

          background: `
            linear-gradient($darkPurpleSolid, $darkPurpleSolid) padding-box,
            $darkPurpleGradient border-box
          `,
        },

        "&:active:before": {
          opacity: "1",
          background: `
            linear-gradient($backgroundLight, $backgroundLight) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:focus": {
          background: `
            linear-gradient($white, $white) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:disabled": {
          cursor: "not-allowed",
          color: "$gray5",
          background: "$backgroundLight",
          border: "$$borderWidth solid $gray5",
        },

        "&:disabled:before": {
          opacity: "0",
        },
      },
      "secondary-dark": {
        backgroundSize: "100%",
        position: "relative",
        zIndex: "1",

        color: "$white",
        background: "none",
        border: "$$borderWidth solid $white",

        "&:before": {
          content: "",
          display: "block",
          height: "100%",
          left: "0",
          opacity: "0",
          position: "absolute",
          borderRadius: "4px",
          top: "0",
          width: "100%",
          zIndex: "-1",

          background: `
            linear-gradient($darkPurpleSolid, $darkPurpleSolid) padding-box,
            $darkPurpleSolid border-box
          `,

          color: "$white",
          border: "none",
          padding: "calc($1 + $$borderWidth) calc($2 + $$borderWidth)",
        },

        "&:hover:not(:disabled):before": {
          opacity: "1",
        },

        "&:hover:not(:disabled)": {
          border: "none",
          padding: "calc($1 + $$borderWidth) calc($2 + $$borderWidth)",
        },

        "&:active:before": {
          background: `
            linear-gradient($gray1, $gray1) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:focus": {
          background: `
            linear-gradient($gray1, $gray1) padding-box,
            $linearDeep border-box
          `,
        },

        "&:disabled": {
          border: "$$borderWidth solid $gray5",
          cursor: "not-allowed",
          color: "$gray5",
        },

        "&:disabled:before": {
          opacity: "0",
        },
      },
      plain: {
        background: "transparent",
        border: "$$borderWidth solid transparent",
        color: "$gray2",

        "&:hover:not(:focus):enabled": {
          color: "$primaryPurple",
        },

        "&:active:enabled": {
          color: "$primaryPurple",
        },

        "&:focus:not(:active)": {
          background: `
            linear-gradient($solidHoverPink, $solidHoverPink) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:disabled": {
          cursor: "not-allowed",
          border: 0,
          color: "$gray5",
        },
      },
      "plain-bordered": {
        background: "transparent",
        border: "$$borderWidth solid $primaryDark",
        color: "$gray2",

        "&:hover:not(:focus):enabled": {
          color: "$primaryPurple",
        },

        "&:active:enabled": {
          color: "$primaryPurple",
        },

        "&:focus:not(:active)": {
          background: `
            linear-gradient($solidHoverPink, $solidHoverPink) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:disabled": {
          cursor: "not-allowed",
          border: 0,
          color: "$gray5",
        },
      },
      "plain-on-dark": {
        color: "$white",
        background: "transparent",
        border: "$$borderWidth solid transparent",

        "&:hover:not(:focus):enabled": {
          color: "$primaryPurple",
        },

        "&:active:enabled": {
          color: "$primaryPurple",
        },

        "&:focus:not(:active)": {
          background: `
            linear-gradient($solidHoverPink, $solidHoverPink) padding-box,
            $linearDeeper border-box
          `,
        },

        "&:disabled": {
          cursor: "not-allowed",
          border: 0,
          color: "$gray5",
        },
      },
      danger: {
        backgroundSize: "100%",
        position: "relative",
        zIndex: "1",

        background: "$redSolid",
        border: 0,
        color: "$white",

        "&:before": {
          borderRadius: "inherit",
          content: "",
          display: "block",
          height: "100%",
          left: "0",
          opacity: "0",
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: "-1",

          background: "$redHover",
        },

        "&:hover:before": {
          opacity: "1",
        },

        "&:active:before": {
          background: "$redHover",
        },

        "&:focus": {
          background: "redGradient",
        },

        "&:disabled": {
          cursor: "not-allowed",
          border: 0,
          color: "$gray5",
          background: "$gray6",
        },

        "&:disabled:before": {
          opacity: "0",
        },
      },
    },
  },

  defaultVariants: {
    intent: "primary",
  },
});
