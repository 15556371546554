import {
  collection,
  DocumentData,
  limit,
  orderBy,
  Query,
  query,
  where,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { RunsTableFilter } from "../components/JobRunsTable/JobRunsTable";
import { logService } from "../services/log-service";
import { JobRun } from "../types/persisted";
import {
  getRunsCollectionKey,
  jobRunConverter,
} from "../utils/collections/runs";
import { firestore } from "../utils/firebase";

const NUMBER_OF_RUNS_TO_FETCH = 100;

const constructQuery = (
  projectId: string,
  jobId: string,
  filter: RunsTableFilter["filter"],
): Query<DocumentData> => {
  const collectionKey = getRunsCollectionKey({ projectId });

  if (filter === "success") {
    return query(
      collection(firestore, collectionKey),
      where("jobId", "==", jobId),
      where("success", "==", true),
      orderBy("startTime", "desc"),
      limit(NUMBER_OF_RUNS_TO_FETCH),
    );
  } else if (filter === "failed") {
    return query(
      collection(firestore, collectionKey),
      where("jobId", "==", jobId),
      where("success", "==", false),
      orderBy("startTime", "desc"),
      limit(NUMBER_OF_RUNS_TO_FETCH),
    );
  }

  if (filter !== "all") {
    logService.warn(
      `Received unsupported filter for fetching job runs: ${filter}`,
    );
  }

  // When filter === 'all' and as a fallback
  return query(
    collection(firestore, collectionKey),
    where("jobId", "==", jobId),
    orderBy("startTime", "desc"),
    limit(NUMBER_OF_RUNS_TO_FETCH),
  );
};

export const useRuns = (
  projectId: string,
  jobId: string | undefined,
  { filter }: RunsTableFilter,
): { runs: JobRun[] } => {
  const [values] = useCollectionData(
    jobId
      ? constructQuery(projectId, jobId, filter).withConverter(jobRunConverter)
      : null,
  );

  return { runs: values ?? [] };
};
