import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { useCallback } from "react";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { toast } from "react-toastify";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Div } from "../../ui/Layout";
import { Tooltip } from "../../ui/Tooltip";
import { copyTextToClipboard } from "../../utils/user-utils";
import { CopyCodeButton } from "../Assistant/components/CopyCodeButton";

interface Props {
  logs: string;
  copyEnabled?: boolean;
  width: string | null;
  maxHeight: string | null;
}

export const LogOutput = ({
  maxHeight,
  width,
  logs,
  copyEnabled = true,
}: Props) => {
  const handleCopyClick = useCallback(async () => {
    await copyTextToClipboard(logs);
    toast.success("Copied to clipboard");
  }, [logs]);

  return (
    <Div css={{ position: "relative" }}>
      {copyEnabled && (
        <Div css={{ position: "absolute", top: "$1", right: "$2" }}>
          <Tooltip text="Copy logs to clipboard">
            <CopyCodeButton onClick={handleCopyClick}>
              <StyledFontAwesomeIcon icon={faCopy} />
              Copy
            </CopyCodeButton>
          </Tooltip>
        </Div>
      )}

      <SyntaxHighlighter
        language="plaintext"
        style={vs}
        customStyle={{
          backgroundColor: "transparent",
          width: width ?? "100%",
          maxHeight: maxHeight ?? "100%",
          overflow: "auto",

          margin: 0,
        }}
      >
        {logs}
      </SyntaxHighlighter>
    </Div>
  );
};
