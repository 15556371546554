import { orderBy, query } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import {
  appConverter,
  createAppCollectionRef,
} from "../utils/collections/apps";

export const useAppsSubscription = ({
  projectId,
  shouldFetch,
}: {
  projectId?: string;
  shouldFetch: boolean;
}) => {
  const appsReceived = useStore((state) => state.appsReceived);

  const [apps, _loading, error] = useCollectionData(
    shouldFetch && projectId
      ? query(
          createAppCollectionRef({ projectId }),
          orderBy("createdAt", "asc"),
        ).withConverter(appConverter)
      : null,
  );

  const filteredApps = useMemo(() => {
    return (
      apps?.filter(
        (it) => !(it.markedForDeletionAt || it.markedForDeletionBy),
      ) ?? []
    );
  }, [apps]);

  useEffect(() => {
    appsReceived({
      items: filteredApps,
    });
  }, [filteredApps]);

  if (error) {
    logService.warn(error.message);
  }
};
