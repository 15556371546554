import { faEyeSlash, faHome } from "@fortawesome/pro-light-svg-icons";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import { useMemo } from "react";
import { styled } from "../../../../stitches.config";
import { useScreenshot } from "../../../hooks/useAppScreenshot";
import { useLatestCodeBlockVersion } from "../../../hooks/useLatestCodeBlockVersion";
import { useLatestMultipageAppDeployment } from "../../../hooks/useMultipageApp";
import { MultipageAppPage } from "../../../types/persisted";
import { BaseCard } from "../../../ui/Card/BaseCard";
import { StyledFontAwesomeIcon } from "../../../ui/Icon";
import { Flex } from "../../../ui/Layout/Flex";
import { Grid } from "../../../ui/Layout/Grid";
import { NavigateLink } from "../../../ui/NavigateLink";
import { Tooltip } from "../../../ui/Tooltip";
import { TruncatedText } from "../../../ui/TruncatedText/TruncatedText";
import { convertToImageSrc } from "../../../utils/collections/screenshots";
import {
  CollectionName,
  createCollectionRefPath,
} from "../../../utils/collections/shared";
import { isHomePage } from "../../../utils/page-utils";
import { routeUtils } from "../../../utils/route-utils";
import { ComponentName } from "./ComponentName";

interface Props {
  projectId: string;
  page: MultipageAppPage;
  isPublished: boolean;
}

export const PAGE_CARD_HEIGHT = "230px";
export const PAGE_CARD_WIDTH = "170px";

const Card = styled(BaseCard, {
  height: PAGE_CARD_HEIGHT,
  width: PAGE_CARD_WIDTH,
  color: "$primaryDark",
});

const Image = styled("img", {
  transform: "scale(0.46)",
});

export const PageCard = ({ projectId, page, isPublished }: Props) => {
  const { latestMultipageAppDeployment } = useLatestMultipageAppDeployment({
    projectId,
  });
  const { latestCodeBlockVersion } = useLatestCodeBlockVersion({
    codeBlockRef: page.codeBlockRef,
  });

  const { screenshot } = useScreenshot({
    screenshotCollectionRef: createCollectionRefPath([
      page.refPath,
      CollectionName.SCREENSHOTS,
    ]),
    isPublished,
  });

  // Latest code block is not the same as latest deployed
  const hasUnpublishedChanges = useMemo(() => {
    const latestDeployForPage = latestMultipageAppDeployment?.pages.find(
      (it) => it.pageRef === page.refPath,
    );

    return (
      latestDeployForPage === null ||
      (!!latestCodeBlockVersion &&
        latestDeployForPage?.lastCodeBlockVersionRef !==
          latestCodeBlockVersion.refPath)
    );
  }, [latestMultipageAppDeployment, latestCodeBlockVersion]);

  return (
    <NavigateLink
      to={routeUtils.path({
        to: "page",
        params: {
          projectId,
          pageIdOrSlug: page.slug,
        },
      })}
    >
      <Card>
        <Grid
          css={{
            gridTemplateRows: "auto max-content",
            width: "100%",
          }}
        >
          <Flex
            css={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",

              overflow: "hidden",
              borderBottom: "1px solid $mainBorder",
            }}
          >
            {screenshot && (
              <Image
                alt={`Preview of ${page.name}`}
                src={convertToImageSrc(screenshot.base64Image)}
              />
            )}
          </Flex>

          <Flex
            direction="vertical"
            css={{
              padding: "$1",
            }}
          >
            <ComponentName>
              <Grid
                css={{
                  gridTemplateColumns: "auto max-content",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Grid
                  css={
                    isHomePage(page) || page.hidden
                      ? {
                          gridTemplateColumns: "max-content auto",
                          alignItems: "center",
                          columnGap: "$1",
                        }
                      : {
                          gridTemplateColumns: "auto",
                          alignItems: "center",
                          columnGap: "$1",
                        }
                  }
                >
                  {isHomePage(page) && (
                    <Tooltip text="The landing page">
                      <StyledFontAwesomeIcon icon={faHome} />
                    </Tooltip>
                  )}

                  {page.hidden && (
                    <Tooltip text="This page is only visible to collaborators">
                      <StyledFontAwesomeIcon icon={faEyeSlash} />
                    </Tooltip>
                  )}

                  <TruncatedText>{page.name}</TruncatedText>
                </Grid>

                {hasUnpublishedChanges && (
                  <Tooltip text="Contains unpublished changes">
                    <StyledFontAwesomeIcon
                      icon={faCircleSmall}
                      css={{ color: "$gray5" }}
                    />
                  </Tooltip>
                )}
              </Grid>
            </ComponentName>
          </Flex>
        </Grid>
      </Card>
    </NavigateLink>
  );
};
