import { faPlay, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Assistant } from "../../components/Assistant/Assistant";
import { useWorkspaceContext } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import {
  BuildLayout,
  OutputAreaContent,
} from "../../components/BuildLayout/BuildLayout";
import { NotInControlWarning } from "../../components/BuildLayout/NotInControlWarning";
import { DatabuttonEditorLazy } from "../../components/DatabuttonEditor/DatabuttonEditorLazy";
import { IFrameWrapper } from "../../components/IFrameWrapper/IFrameWrapper";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useUserGuardContext } from "../../components/UserGuard/UserGuard";
import { useDeployments } from "../../hooks/useDeployments";
import { useQuery } from "../../hooks/useQuery";
import {
  createCodeSelector,
  createInControlSelector,
} from "../../store/slices/code-slice";
import { useStore } from "../../store/store";
import { App, CodeBlock, ProjectSummary } from "../../types/persisted";
import { Button } from "../../ui/Button";
import { StyledFontAwesomeIcon } from "../../ui/Icon";
import { Flex } from "../../ui/Layout/Flex";
import { Tooltip } from "../../ui/Tooltip";
import { getAppDeploymentsCollectionKey } from "../../utils/collections/deployments";
import { getProjectPath } from "../../utils/project-utils";
import { randomAppTip } from "../../utils/user-utils";
import { HotReloadingButton } from "../BuildPagePage/components/HotReloadingButton";
import { GoToPublishedViewButton } from "./GoToPublishedViewButton";
import { PublishViewButton } from "./PublishViewButton";
import { ShareViewModalButton } from "./ShareViewModalButton";

interface Props {
  app: App;
  codeBlock: CodeBlock;
  isDevxReady: boolean;
  project: ProjectSummary;
}

export const BuildAppForm = ({
  app,
  project,
  codeBlock,
  isDevxReady,
}: Props) => {
  const projectId = project.id;
  const componentId = app.id;
  const componentType = "app";
  const identifier = useWorkspaceContext();

  const { user } = useUserGuardContext();
  const isRunning = useStore(
    (state) => state.codeBlocks[identifier.codeBlockId]?.isRunningCode ?? false,
  );

  const [isStreamlitServerStarted, setIsStreamlitServerStarted] =
    useState(false);

  const code = useStore(createCodeSelector(codeBlock.id));
  const inControl = useStore(createInControlSelector(codeBlock.id));

  const appSlugOrCodeBlockId = useMemo(
    () => app.slug ?? codeBlock.id,
    [app, codeBlock],
  );

  const previewUrl = useMemo(
    () =>
      getProjectPath({
        projectId,
        route: `/dbtn/devx/streamlit/${appSlugOrCodeBlockId}`,
      }),
    [projectId, appSlugOrCodeBlockId],
  );

  const { search } = useQuery();

  const iframeSrc = useMemo(
    () =>
      `${getProjectPath({
        projectId,
        route: `/dbtn/devx/views/${appSlugOrCodeBlockId}`,
      })}${search ?? ""}`,
    [projectId, search, appSlugOrCodeBlockId],
  );

  const { deployments } = useDeployments({
    deploymentCollection: getAppDeploymentsCollectionKey({
      projectId,
      appId: app.id,
    }),
  });

  const isPublished = useMemo(() => deployments.length > 0, [deployments]);

  const instanceTag = useStore((state) => state.project.devxHealth.instanceTag);
  const updateLegacyPreviewTriggered = useStore(
    (state) => state.updateLegacyPreviewTriggered,
  );

  const updatePreviewFn = useCallback(async () => {
    updateLegacyPreviewTriggered({ identifier, previewUrl });
  }, [identifier, previewUrl]);

  /**
   * Update preview once devx is ready and mark streamlit server as started
   */
  useEffect(() => {
    if (isDevxReady && !isStreamlitServerStarted && code) {
      updatePreviewFn().then(() => {
        setIsStreamlitServerStarted(true);
      });
    }
  }, [isDevxReady, isStreamlitServerStarted, code]);

  const loadingTip = useMemo(() => randomAppTip(), []);

  return (
    <BuildLayout
      codeBlockId={codeBlock.id}
      componentType={componentType}
      iframeSrc={iframeSrc}
      outputAreaActions={
        <Flex gap="1">
          {isPublished && <GoToPublishedViewButton app={app} />}

          <ShareViewModalButton user={user} app={app} projectId={projectId} />
        </Flex>
      }
      outputArea={
        <OutputAreaContent>
          {isDevxReady && isStreamlitServerStarted && instanceTag ? (
            <IFrameWrapper
              instanceTag={instanceTag}
              title="View preview"
              src={iframeSrc}
              loadingMessage="Fetching view preview..."
            />
          ) : (
            <LoadingScreen animation="random" message={loadingTip} />
          )}
        </OutputAreaContent>
      }
      buildAreaActions={
        <>
          <Tooltip text="Run code (shift + enter)" placement="bottom">
            <Button
              css={{ width: "38px" }}
              disabled={!(inControl && isDevxReady)}
              intent="primary"
              content="icon"
              onClick={updatePreviewFn}
            >
              {isRunning ? (
                <StyledFontAwesomeIcon icon={faSpinner} pulse={true} />
              ) : (
                <StyledFontAwesomeIcon icon={faPlay} />
              )}
            </Button>
          </Tooltip>

          <PublishViewButton projectId={projectId} app={app} user={user} />

          <Flex gap="1">
            <HotReloadingButton />
          </Flex>
        </>
      }
      buildArea={
        <>
          <DatabuttonEditorLazy
            projectId={projectId}
            componentId={componentId}
            codeBlockId={codeBlock.id}
            isDevxReady={isDevxReady}
            runFn={updatePreviewFn}
          />

          {!inControl && codeBlock.controlTakenBy && <NotInControlWarning />}
        </>
      }
      statusBarContent={<Assistant />}
    />
  );
};
