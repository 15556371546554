import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "../../../../stitches.config";
import { runHealthCheck } from "../../../components/DevxStatusIndicator/utils";
import { createProject } from "../../../components/NewProjectForm/utils";
import { useUserGuardContext } from "../../../components/UserGuard/UserGuard";
import { useStore } from "../../../store/store";
import { Button } from "../../../ui/Button";
import { Image } from "../../../ui/Image/Image";
import { Flex } from "../../../ui/Layout/Flex";
import { Grid } from "../../../ui/Layout/Grid";
import { H1, H3, Txt } from "../../../ui/TypographyV2/TypographyV2";
import {
  SELECTED_START_CREATING,
  SELECTED_TAKE_THE_TOUR,
} from "../../../utils/analytics-constants";
import {
  A_TOUR_OF_DATABUTTON_TEMPLATE_ID,
  A_TOUR_OF_DATABUTTON_TEMPLATE_NAME,
} from "../../../utils/constants";
import { routeUtils } from "../../../utils/route-utils";
import { Centered, Span } from "../../UiPage/components/Layout";
import { NewDataAppButton } from "./NewDataAppButton";

interface Props {
  aTourOfDatabuttonProjectId?: string;
  isModalOpen: boolean;
}

export const CTACard = styled(Flex, {
  backgroundColor: "$gray8BoardBackground",
  borderRadius: "4px",
  padding: "$2",
});

export const OnboardingGuide = ({
  aTourOfDatabuttonProjectId,
  isModalOpen,
}: Props) => {
  const { user } = useUserGuardContext();
  const navigate = useNavigate();
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  useEffect(() => {
    if (aTourOfDatabuttonProjectId) {
      // Wake up a tour of databutton to decrease load time
      runHealthCheck({
        user,
        projectId: aTourOfDatabuttonProjectId,
      });
    }
  }, [aTourOfDatabuttonProjectId]);

  return (
    <Centered>
      <Flex direction="vertical" gap="8">
        <Flex direction="vertical" gap="3">
          <Flex direction="vertical" gap="1">
            <H3>
              Welcome to{" "}
              <Span css={{ color: "$primaryPurple" }}>Databutton</Span>
            </H3>
            <H1>Are you ready to create kick-ass data apps?</H1>
          </Flex>

          <Grid
            css={{
              gridTemplateColumns: "1fr 1fr",
              columnGap: "$3",
            }}
          >
            <CTACard direction='vertical' gap="3">
              <Txt>
                Take a quick product tour to get familiar with Databutton. Don't
                worry, we'll guide you through it!
              </Txt>

              <Flex css={{ justifyContent: "center" }}>
                {aTourOfDatabuttonProjectId ? (
                  <Button
                    onClick={async () => {
                      await pulseTriggered({
                        user,
                        eventName: SELECTED_TAKE_THE_TOUR,
                      });

                      navigate(
                        routeUtils.path({
                          to: "page",
                          params: {
                            projectId: aTourOfDatabuttonProjectId,
                            pageIdOrSlug: "home",
                          },
                        }),
                      );
                    }}
                  >
                    Tour before takeoff!
                  </Button>
                ) : (
                  <Button
                    onClick={async () => {
                      const [response] = await Promise.all([
                        createProject({
                          name: A_TOUR_OF_DATABUTTON_TEMPLATE_NAME,
                          projectTemplateId: A_TOUR_OF_DATABUTTON_TEMPLATE_ID,
                        }),
                        pulseTriggered({
                          user,
                          eventName: SELECTED_TAKE_THE_TOUR,
                        }),
                      ]);

                      navigate(
                        routeUtils.path({
                          to: "page",
                          params: {
                            projectId: response.projectId,
                            pageIdOrSlug: "home",
                          },
                        }),
                      );
                    }}
                  >
                    Take the tour
                  </Button>
                )}
              </Flex>
            </CTACard>

            <CTACard direction="vertical" gap="3">
              <Txt>
                Create your first app without a product tour. We're here to
                support you along the way.
              </Txt>
              <Flex css={{ justifyContent: "center" }}>
                <NewDataAppButton
                  isModalOpen={isModalOpen}
                  buttonText="Create new data app"
                  onOpenModalClick={() => {
                    pulseTriggered({
                      user,
                      eventName: SELECTED_START_CREATING,
                    });
                  }}
                />
              </Flex>
            </CTACard>
          </Grid>
        </Flex>

        <Flex direction="vertical">
          <Image
            css={{ width: "130%", marginLeft: "-15%" }}
            src="/workspace-screenshot.png"
          />
          <Txt
            css={{
              color: "$placeholderGray",
              marginTop: "-30px",
              marginLeft: "-10%",
            }}
          >
            Screenshot from the Databutton workspace
          </Txt>
        </Flex>
      </Flex>
    </Centered>
  );
};
