import { DeleteDatafile } from "@databutton/firebase-types";
import { User } from "firebase/auth";
import {
  doc,
  DocumentReference,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { SetNonNullable } from "type-fest";
import urlSlug from "url-slug";
import { Datafile } from "../../types/persisted";
import { firestore } from "../firebase";
import { createPerformedByObj } from "../user-utils";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
  createDocRefPath,
} from "./shared";

export const datafileConverter = createConverter<Datafile>({
  generateSlugFn: (id) => urlSlug(id),
});

export const getDatafilesCollectionKey = (params: {
  projectId: string;
}): string =>
  createCollectionRefPath([
    CollectionName.PROJECTS,
    params.projectId,
    CollectionName.DATAFILES,
  ]);

const getDatafileDocKey = (params: {
  projectId: string;
  datafileId: string;
}): string =>
  createDocRefPath([
    getDatafilesCollectionKey({ projectId: params.projectId }),
    params.datafileId,
  ]);

const createDatafileDocRef = (params: {
  refPath: string;
}): DocumentReference<Datafile> =>
  doc(firestore, params.refPath).withConverter(datafileConverter);

const updateDatafile = async (params: {
  refPath: string;
  payload: Partial<Datafile>;
}) =>
  updateDoc(createDatafileDocRef({ refPath: params.refPath }), params.payload);

export const deleteDatafile = async (params: {
  datafile: Datafile;
  user: User;
}) => {
  const payload: DeleteDatafile = {
    markedForDeletionAt: Timestamp.now(),
    markedForDeletionBy: createPerformedByObj({ user: params.user }),
  };

  await updateDatafile({
    refPath: params.datafile.refPath,
    payload,
  });
};

export const renameDatafile = async (params: {
  projectId: string;
  datafileId: string;
  user: User;
  name: {
    from: string;
    to: string;
  };
}): Promise<{ newSlug: string }> => {
  const newSlug = urlSlug(params.name.to);

  const payload: SetNonNullable<Required<Pick<Datafile, "name" | "slug">>> = {
    name: params.name.to,
    slug: newSlug,
  };

  await updateDatafile({
    refPath: getDatafileDocKey({
      projectId: params.projectId,
      datafileId: params.datafileId,
    }),
    payload,
  });

  await updateDoc(
    doc(
      firestore,
      getDatafilesCollectionKey({ projectId: params.projectId }),
      params.datafileId,
    ),
    payload,
  );

  return { newSlug };
};
