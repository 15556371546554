import { ComponentProps, lazy, Suspense } from "react";

const DatabuttonDiffEditor = lazy(() => import("./DatabuttonDiffEditor"));

type Props = ComponentProps<typeof DatabuttonDiffEditor>;

export const DatabuttonDiffEditorLazy = (props: Props) => (
  <Suspense fallback={<>...</>}>
    <DatabuttonDiffEditor {...props} />
  </Suspense>
);
