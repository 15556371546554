const GRID_REGEX =
  /^\[[a-z-]*\] (?<buildArea>\d*\.\d*|\d*)px \[[a-z-]*\] (?<dragbar>\d*\.\d*|\d*)px \[[a-z-]*\] (?<outputArea>\d*.\d*|\d*)px$/;

const getSizeOfCustomLayout = (
  customGrid: string,
): {
  primaryTileSizeInPx: number;
  dragbarSizeInPx: number;
  secondaryTileSizeInPx: number;
  totalSizeInPx: number;
} => {
  const match = GRID_REGEX.exec(customGrid);
  if (match) {
    const primaryTileSizeInPx = parseFloat(match[1]);
    const dragbarSizeInPx = parseFloat(match[2]);
    const secondaryTileSizeInPx = parseFloat(match[3]);

    return {
      primaryTileSizeInPx,
      dragbarSizeInPx,
      secondaryTileSizeInPx,
      totalSizeInPx:
        primaryTileSizeInPx + dragbarSizeInPx + secondaryTileSizeInPx,
    };
  }

  throw new Error(`Tried to find size of invalid grid: ${customGrid}`);
};

/**
 * Checks if the saved custom grid will fit in the current window or if it needs to be shrunk or expanded
 * to match the window width. Also takes into account if the project navigator is collapsed or expanded.
 */
export const convertToRelativeGridTemplate = (params: {
  template: string;
}): string => {
  const sizeOfCustomGrid = getSizeOfCustomLayout(params.template);

  const newSizeOfBuildCol =
    (sizeOfCustomGrid.primaryTileSizeInPx / sizeOfCustomGrid.totalSizeInPx) *
    100;

  return `[primary] ${newSizeOfBuildCol}% [dragbar] ${sizeOfCustomGrid.dragbarSizeInPx}px [secondary] auto`;
};

export enum SplitPaneLayout {
  HORIZONTAL_50_50 = "horizontal-50-50",
  HORIZONTAL_CUSTOM = "horizontal-custom",
  VERTICAL_0_100 = "vertical-0-100",
  VERTICAL_100_0 = "vertical-100-0",
  VERTICAL_50_50 = "vertical-50-50",
  VERTICAL_CUSTOM = "vertical-custom",
}

export enum SplitPaneOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export const isSplitLayout = (layout: SplitPaneLayout): boolean =>
  layout !== SplitPaneLayout.VERTICAL_0_100 &&
  layout !== SplitPaneLayout.VERTICAL_100_0;

export const isCustomLayout = (layout: SplitPaneLayout): boolean =>
  layout === SplitPaneLayout.VERTICAL_CUSTOM ||
  layout === SplitPaneLayout.HORIZONTAL_CUSTOM;

export const determineOrientation = (
  layout: SplitPaneLayout,
): SplitPaneOrientation =>
  layout === SplitPaneLayout.HORIZONTAL_50_50 ||
  layout === SplitPaneLayout.HORIZONTAL_CUSTOM
    ? SplitPaneOrientation.HORIZONTAL
    : SplitPaneOrientation.VERTICAL;
