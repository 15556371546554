import { memo, useCallback, useState } from "react";
import { useWorkspaceContext } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import {
  ActionBar,
  LayoutWithActionBar,
} from "../../components/BuildLayout/LayoutWithActionbar";
import { SaveStatus } from "../../components/BuildLayout/SaveStatus";
import { useStore } from "../../store/store";
import { Job } from "../../types/persisted";
import { Flex } from "../../ui/Layout/Flex";
import { LinkButton } from "../../ui/LinkButton/LinkButton";
import { SplitPaneButtons } from "../../ui/SplitPane/components/SplitPaneButtons";
import { SplitPane } from "../../ui/SplitPane/SplitPane";
import { SplitPaneLayout } from "../../ui/SplitPane/utils";
import { ADJUSTED_WORKSPACE } from "../../utils/analytics-constants";
import { MemoizedJobEditor } from "./components/JobEditor";
import { JobRuns } from "./components/JobRuns";
import { RunJobButton } from "./components/RunJobButton";
import { ScheduleButton } from "./components/ScheduleButton";

interface Props {
  job: Job;
  codeBlockId: string;
}

const BuildJobForm = ({ job }: Props) => {
  const identifier = useWorkspaceContext();
  const pulseTriggered = useStore((state) => state.pulseTriggered);

  const [layout, setLayout] = useState<SplitPaneLayout>(
    SplitPaneLayout.VERTICAL_50_50,
  );

  const handleCustomLayoutChange = useCallback(
    (params: {
      layout: SplitPaneLayout;
      customGrid: string;
    }) => {
      pulseTriggered({
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          layout: params.layout,
          customGrid: params.customGrid,
          workspaceSetting: "layout",
        },
      });
    },
    [identifier],
  );

  const handleLayoutChanged = useCallback((layout: SplitPaneLayout) => {
    setLayout(layout);
  }, []);

  return (
    <LayoutWithActionBar>
      <ActionBar justifyContent="space-between">
        <RunJobButton />

        <SplitPaneButtons
          layout={layout}
          onLayoutClicked={handleLayoutChanged}
        />

        <Flex justifyContent="flex-end" gap="1">
          <ScheduleButton job={job} />

          <LinkButton
            intent="secondary"
            to={`/projects/${identifier.projectId}/jobs/${job.slug}/monitor`}
          >
            View job
          </LinkButton>

          <SaveStatus />
        </Flex>
      </ActionBar>

      <SplitPane
        layout={layout}
        onLayoutChange={handleLayoutChanged}
        onCustomLayoutChange={handleCustomLayoutChange}
        primaryTile={<MemoizedJobEditor layout={layout} />}
        secondaryTile={<JobRuns />}
        storageKey={identifier.componentId}
      />
    </LayoutWithActionBar>
  );
};

export const MemoizedBuildJobForm = memo(
  BuildJobForm,
  (prev, next) => prev.codeBlockId === next.codeBlockId,
);
