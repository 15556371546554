const setValue = <T>(key: string, value: T) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

const getValue = <T>(key: string, initialValue: T): T => {
  if (typeof window === "undefined") {
    return initialValue;
  }

  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : initialValue;
};

export const localStorageUtils = { getValue, setValue };
