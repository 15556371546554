import { User } from "@firebase/auth";
import { dbtnApi } from "../services/dbtn-api";
import { logService } from "../services/log-service";
import { MultipageAppPage } from "../types/persisted";
import { getProjectPath } from "./project-utils";

export const isHomePage = (page: MultipageAppPage): boolean =>
  /^home$/i.test(page.name);

export const sortBySequence = (
  a: MultipageAppPage,
  b: MultipageAppPage,
): number => (a.sequence > b.sequence ? 1 : -1);

interface UpdatePageMetadataPayload {
  pageId: string;
  codeBlockId: string;
  title?: string;
  hidden?: boolean;
  sequence?: number;
}

export interface UpdatePageMetadataRequest {
  updates: UpdatePageMetadataPayload[];
}

export const updatePageMetadata = async (params: {
  user: User;
  projectId: string;
  payload: UpdatePageMetadataRequest;
}): Promise<void> => {
  logService.info("Updating pages");

  await dbtnApi.put({
    projectId: params.projectId,
    route: "/dbtn/devx/streamlit/multipage/update",
    user: params.user,
    json: params.payload,
  });
};
export const getReloadPreviewUrl = (params: { projectId: string }): string =>
  getProjectPath({
    projectId: params.projectId,
    route: "/dbtn/devx/streamlit/multipage/reload",
  });
