import { User } from "firebase/auth";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { logService } from "../../services/log-service";
import { App } from "../../types/persisted";
import { firestore } from "../firebase";
import {
  CollectionName,
  createCollectionRefPath,
  createConverter,
  createDocRefPath,
} from "./shared";

export type AppRef =
  | {
      refPath: string;
    }
  | {
      projectId: string;
      appId: string;
    };

export const appConverter = createConverter<App>();

export const createAppCollectionRef = (params: {
  projectId: string;
}): CollectionReference<App> =>
  collection(
    firestore,
    createCollectionRefPath([
      CollectionName.PROJECTS,
      params.projectId,
      CollectionName.APPS,
    ]),
  ).withConverter(appConverter);

const createAppDocumentRef = (params: AppRef): DocumentReference<App> =>
  "refPath" in params
    ? doc(firestore, params.refPath).withConverter(appConverter)
    : doc(
        firestore,
        createDocRefPath([
          CollectionName.PROJECTS,
          params.projectId,
          CollectionName.APPS,
          params.appId,
        ]),
      ).withConverter(appConverter);

// -------------------------

export const fetchApp = async (params: AppRef): Promise<App | null> => {
  const docRef = createAppDocumentRef(params);
  logService.debug(`Fetching app: ${docRef.path}`);

  const result = await getDoc(docRef);

  if (result.exists()) {
    return result.data().markedForDeletionAt === null ? result.data() : null;
  }

  return null;
};

export const updateApp = async (params: {
  refPath: string;
  payload: Partial<App>;
}): Promise<void> => {
  logService.debug(`Updating app: ${params.refPath}`);
  await updateDoc(
    createAppDocumentRef({ refPath: params.refPath }),
    params.payload,
  );
};

export const togglePubliclyAccessibleByLink = async (params: {
  projectId: string;
  app: App;
  user: User;
}) => {
  const nextState = !params.app.isPublic;
  const payload: Pick<App, "isPublic"> = {
    isPublic: nextState,
  };

  await updateApp({
    refPath: params.app.refPath,
    payload,
  });
};
