import { memo, useCallback } from "react";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { DatabuttonEditorLazy } from "../../../components/DatabuttonEditor/DatabuttonEditorLazy";
import { EditorWrapper } from "../../../components/DatabuttonEditor/EditorWrapper";
import { useIsDevxReady } from "../../../store/slices/project-slice";
import { useStore } from "../../../store/store";
import { SplitPaneLayout } from "../../../ui/SplitPane/utils";

interface Props {
  layout: SplitPaneLayout;
}

const JobEditor = ({ layout }: Props) => {
  const isDevxReady = useIsDevxReady();
  const identifier = useWorkspaceContext();
  const runJobTriggered = useStore((state) => state.runJobTriggered);

  const runCodeFn = useCallback(() => {
    runJobTriggered(identifier);
  }, [identifier]);

  return (
    <EditorWrapper layout={layout} codeBlockId={identifier.codeBlockId}>
      <DatabuttonEditorLazy
        isDevxReady={isDevxReady}
        projectId={identifier.projectId}
        componentId={identifier.componentId}
        codeBlockId={identifier.codeBlockId}
        runFn={runCodeFn}
      />
    </EditorWrapper>
  );
};

export const MemoizedJobEditor = memo(JobEditor);
