import { Project } from "../../types/persisted";
import { ModalDialog } from "../ModalDialog";
import { InvitaCollaboratorsForm } from "./InviteCollaboratorsForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  project: Project;
}

export const InviteCollaboratorsModal = ({
  isOpen,
  onClose,
  project,
}: Props) => (
  <ModalDialog
    isOpen={isOpen}
    onClose={() => {
      onClose();
    }}
    title="Invite collaborators"
    content={<InvitaCollaboratorsForm project={project} />}
  />
);
