import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { CSS } from "@stitches/react";
import {
  createRef,
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import { StyledFontAwesomeIcon } from "../Icon";
import { Div } from "../Layout";
import { Flex } from "../Layout/Flex";
import { FieldErrorTooltip } from "../Tooltip";

interface Props {
  initialValue: string;
  validateFn: (value: string) => string | null;
  isEditing?: boolean;
  isClickable?: boolean;
  css?: CSS;
  onSubmit: (value: string) => Promise<void>;
  onCancel: () => void;
}

const isEnter = (key: string): boolean => /^enter$/i.test(key);

export const EditableContent = ({
  initialValue,
  validateFn,
  isClickable = false,
  isEditing,
  css,
  onSubmit,
  onCancel,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const ref = createRef<HTMLDivElement>();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (isEditing === true) {
      ref.current?.focus();
    } else if (isEditing === false) {
      setErrorMessage(null);
    }
  }, [isEditing]);

  // Submit or cancel based on change in value or not
  const submitOrCancel = () => {
    const value = ref.current?.textContent ?? "";
    const isChanged = value !== initialValue;
    const isValid = errorMessage === null;

    if (isValid && isChanged) {
      onSubmit(value.trim()).then(() => {
        setCounter((prev) => prev + 1);
      });
    } else if (isValid && !isChanged) {
      onCancel();
    }
  };

  // Validate trimmed value on change
  const handleChange: FormEventHandler<HTMLDivElement> = (e) => {
    const newValue = e.currentTarget.textContent ?? "";
    setErrorMessage(validateFn(newValue.trim()));
  };

  // Submit on blur
  const handleBlur: FocusEventHandler<HTMLDivElement> = () => {
    submitOrCancel();
  };

  // Attempt submit on "Enter"
  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (isEnter(e.key)) {
      submitOrCancel();
    }
  };

  // Disable Enter key (disallow newlines)
  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (isEnter(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <FieldErrorTooltip text={errorMessage}>
      <Flex
        gap="1"
        css={{
          "&:hover > .editable": {
            visibility: "visible",
          },
        }}
      >
        <Div
          // Work-around for dealing with blur()-bug on contentEditable elements
          key={counter}
          css={{
            ...css,
            "&:focus-visible": {
              backgroundColor: "$white",
              paddingLeft: "$1",
              paddingRight: "$1",
              overflow: "unset",
              whiteSpace: "unset",
              textOverflow: "unset",
            },
          }}
          ref={ref}
          contentEditable={isEditing || isClickable ? "true" : "false"}
          suppressContentEditableWarning={true}
          role={isClickable ? "button" : undefined}
          tabIndex={isClickable ? 0 : undefined}
          onKeyPress={handleKeyPress}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onInput={handleChange}
          onClick={(e) => {
            if (isEditing) {
              e.preventDefault();
            }
          }}
        >
          {initialValue}
        </Div>

        {isClickable && (
          <StyledFontAwesomeIcon
            className="editable"
            icon={faPencil}
            css={{
              visibility: "hidden",
              color: "$placeholderGray",
            }}
          />
        )}
      </Flex>
    </FieldErrorTooltip>
  );
};
