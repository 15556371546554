import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { styled } from "../../../../stitches.config";
import { useLatestCodeBlockVersion } from "../../../hooks/useLatestCodeBlockVersion";
import { Module } from "../../../types/persisted";
import { BaseCard } from "../../../ui/Card/BaseCard";
import { Flex } from "../../../ui/Layout/Flex";
import { Grid } from "../../../ui/Layout/Grid";
import { NavigateLink } from "../../../ui/NavigateLink";
import { TruncatedText } from "../../../ui/TruncatedText/TruncatedText";
import { routeUtils } from "../../../utils/route-utils";
import { ComponentName } from "./ComponentName";

interface Props {
  projectId: string;
  module: Module;
}

export const MODULE_CARD_HEIGHT = "230px";
export const MODULE_CARD_WIDTH = "170px";

const Card = styled(BaseCard, {
  height: MODULE_CARD_HEIGHT,
  width: MODULE_CARD_WIDTH,
  color: "$primaryDark",
});

export const ModuleCard = ({ projectId, module }: Props) => {
  const { latestCodeBlockVersion } = useLatestCodeBlockVersion({
    codeBlockRef: module.codeBlockRef,
  });

  return (
    <NavigateLink
      to={routeUtils.path({
        to: "module",
        params: {
          projectId,
          moduleIdOrSlug: module.slug,
        },
      })}
    >
      <Card>
        <Grid
          css={{
            width: "100%",
            gridTemplateRows: "auto max-content",
          }}
        >
          <Flex
            css={{
              height: "100%",
              width: "100%",

              overflow: "hidden",
              position: "relative",
              borderBottom: "1px solid $mainBorder",
            }}
          >
            {latestCodeBlockVersion && (
              <SyntaxHighlighter
                customStyle={{
                  overflow: "hidden",
                  fontSize: "8px",
                  position: "absolute",
                  paddingTop: 0,
                  top: 0,
                  left: 0,
                  margin: 0,
                  border: "none",
                  height: "100%",
                  width: "100%",
                }}
                language="python"
                style={vs}
              >
                {latestCodeBlockVersion.code}
              </SyntaxHighlighter>
            )}
          </Flex>

          <Flex
            direction="vertical"
            css={{
              padding: "$1",
            }}
          >
            <ComponentName>
              <Grid
                css={{
                  gridTemplateColumns: "auto max-content",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <TruncatedText>{module.name}</TruncatedText>

                {/* {hasUnpublishedChanges && (
                  <Tooltip text="Contains unpublished changes">
                    <StyledFontAwesomeIcon
                      icon={faCircleSmall}
                      css={{ color: "$gray5" }}
                    />
                  </Tooltip>
                )} */}
              </Grid>
            </ComponentName>
          </Flex>
        </Grid>
      </Card>
    </NavigateLink>
  );
};
