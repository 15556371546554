import { AppConfigSnapshot } from "../../types/persisted";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

interface AppConfigState {
  appConfig: AppConfigSnapshot | null;
}

export interface AppConfigSlice {
  appConfigState: AppConfigState;
  appConfigReceived: (params: { appConfig: AppConfigSnapshot }) => void;
}

const actionType = createActionTypeLogger("app-config");

export const appConfigStore: StoreSlice<AppConfigSlice> = (set, _get) => ({
  appConfigState: {
    appConfig: null,
  },
  appConfigReceived: async (params) => {
    set(
      (draft) => {
        draft.appConfigState.appConfig = params.appConfig;
      },
      false,
      actionType("app-config-received"),
    );
  },
});
