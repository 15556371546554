import { User } from "firebase/auth";
import { doc, getDoc, runTransaction, Timestamp } from "firebase/firestore";
import urlSlug from "url-slug";
import { ComponentCategory } from "../pages/ProjectPage/ProjectComponents/utils";
import {
  CodeBlock,
  CodeBlockVersion,
  Job,
  Project,
  WithoutId,
} from "../types/persisted";
import {
  codeBlockVersionConverter,
  createCodeBlockVersionDocument,
  getCodeBlockVersionCollectionKey,
} from "./collections/code-block-versions";
import {
  createCodeBlockDocument,
  getCodeBlockCollectionKey,
} from "./collections/code-blocks";
import { createJobsCollectionRefPath } from "./collections/jobs";
import { createDatabuttonId, DatabuttonIdPrefix } from "./databutton-id-utils";
import { firestore } from "./firebase";

export const getProjectPath = (params: {
  projectId: string;
  route: string;
}): string =>
  params.route.endsWith("/") || params.route.includes(".json")
    ? `https://cdn.databutton.com/_projects/${params.projectId}${params.route}`
    : `https://cdn.databutton.com/_projects/${params.projectId}${params.route}/`;

export const getProjectWssPath = (params: {
  projectId: string;
  route: string;
}): string =>
  `wss://cdn.databutton.com/_projects/${params.projectId}${params.route}`;

const getJobCodeTemplate = (category: ComponentCategory | null) => {
  if (category === ComponentCategory.INTEGRATIONS) {
    return `import databutton as db
import requests
import json

# Gives you a funny (and safe!) joke!
url = "https://v2.jokeapi.dev/joke/Any?safe-mode"
data = requests.get(url).json()

print(json.dumps(data, indent=2))
`;
  }

  return `from datetime import datetime

now = datetime.now()

print(f"Current time is {now} (UTC)")`;
};

type CreateJobParams = WithoutId<Job>;

const createNewJobRequest = (params: {
  name: string;
  createdById: string;
}): { id: string; payload: CreateJobParams } => {
  const id = createDatabuttonId(DatabuttonIdPrefix.JOB);

  return {
    id,
    payload: {
      category: null,
      name: params.name,
      slug: urlSlug(params.name),
      createdBy: params.createdById,
      createdAt: Timestamp.now(),
      markedForDeletionAt: null,
    },
  };
};

/**
 * Creates a job, a code block and a code block version and saves it to Firebase
 *
 * Also syncs code to devx
 */
export const createJobInFirebase = async (params: {
  name: string;
  projectId: string;
  user: User;
  code?: string;
}): Promise<{
  job: Job;
  codeBlock: CodeBlock;
  codeBlockVersion: CodeBlockVersion;
}> => {
  const { id: newJobId, payload: newJob } = createNewJobRequest({
    name: params.name,
    createdById: params.user.uid,
  });

  const { id: newCodeBlockId, doc: newCodeBlock } = createCodeBlockDocument({
    componentId: newJobId,
    type: "job",
  });

  const { id: newCodeBlockVersionId, doc: newCodeBlockVersion } =
    createCodeBlockVersionDocument({
      user: params.user,
      name: newJob.slug,
      code:
        typeof params.code === "string"
          ? params.code
          : getJobCodeTemplate(null),
    });

  const jobDocRef = doc(
    firestore,
    createJobsCollectionRefPath({ projectId: params.projectId }),
    newJobId,
  );

  const codeBlockRef = doc(
    firestore,
    getCodeBlockCollectionKey(params.projectId),
    newCodeBlockId,
  );

  const codeBlockVersionRef = doc(
    firestore,
    getCodeBlockVersionCollectionKey({
      projectId: params.projectId,
      codeBlockId: newCodeBlockId,
    }),
    newCodeBlockVersionId,
  );

  await Promise.all([
    runTransaction(firestore, async (transaction) => {
      transaction
        .set(jobDocRef, newJob)
        .set(codeBlockRef, newCodeBlock)
        .set(codeBlockVersionRef, newCodeBlockVersion);
    }),
  ]);

  const persistedCodeBlockVersionDoc = await getDoc(
    codeBlockVersionRef.withConverter(codeBlockVersionConverter),
  );

  const persistedCodeBlockVersion = persistedCodeBlockVersionDoc.data();

  if (!persistedCodeBlockVersion) {
    throw new Error(
      `Unable to fetch newely created code block version: ${codeBlockVersionRef.path}`,
    );
  }

  return {
    job: {
      ...newJob,
      id: jobDocRef.id,
      refPath: jobDocRef.path,
    },
    codeBlock: {
      ...newCodeBlock,
      id: codeBlockRef.id,
      refPath: codeBlockRef.path,
    },
    codeBlockVersion: persistedCodeBlockVersion,
  };
};

export const isWorkspaceGenerated = (project: Project): boolean =>
  !!project.devxUrl;
