import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { screenshotConverter } from "../utils/collections/screenshots";
import { CollectionName } from "../utils/collections/shared";
import { firestore } from "../utils/firebase";

export const useScreenshot = (params: {
  screenshotCollectionRef: string;
  isPublished: boolean;
}) => {
  const screenshotCollection = collection(
    firestore,
    params.screenshotCollectionRef,
  );

  const [values, _loading, error] = useCollectionData(
    params.isPublished
      ? query(
          screenshotCollection,
          where("deploymentId", "!=", null),
          orderBy("deploymentId", "desc"),
          orderBy("capturedBy.timestamp", "desc"),
          limit(1),
        ).withConverter(screenshotConverter)
      : query(
          screenshotCollection,
          where("deploymentId", "==", null),
          orderBy("capturedBy.timestamp", "desc"),
          limit(1),
        ).withConverter(screenshotConverter),
  );

  if (error) {
    logService.warn(error.message);
  }

  return { screenshot: values && values.length > 0 ? values[0] : null };
};

export const useAppScreenshotByRef = ({
  appRef,
  shouldFetch = true,
}: {
  appRef: string;
  shouldFetch?: boolean;
}) => {
  const collectionPath = useMemo(() => {
    if (/\/multipage-apps\//i.test(appRef)) {
      return `${appRef}/${CollectionName.PAGES}/home/${CollectionName.SCREENSHOTS}`;
    }

    return `${appRef}/${CollectionName.SCREENSHOTS}`;
  }, [appRef]);

  const [values] = useCollectionDataOnce(
    shouldFetch
      ? query(
          collection(firestore, collectionPath),
          orderBy("capturedBy.timestamp", "desc"),
          limit(1),
        ).withConverter(screenshotConverter)
      : null,
  );

  return { appScreenshot: values && values.length > 0 ? values[0] : null };
};
