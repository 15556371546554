import { styled } from "../../../stitches.config";
import { Div } from "../../ui/Layout";

export const NotificationBadge = styled(Div, {
  $$size: "10px",

  zIndex: 2,
  position: "absolute",
  width: "$$size",
  height: "$$size",
  borderRadius: "calc($$size / 2)",
  top: "calc(calc($$size / 3) * -1)",
  right: "calc(calc($$size / 3) * -1)",

  variants: {
    intent: {
      info: {
        backgroundColor: "$linkBlue",
      },
      danger: {
        backgroundColor: "$redSolid",
      },
    },
  },

  defaultVariants: {
    intent: "info",
  },
});
