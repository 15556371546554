import { Timestamp } from "firebase/firestore";
import { createContext, useContext, useEffect } from "react";
import { useComponentsSubscription } from "../../hooks/useProjectComponents";
import { useQuery } from "../../hooks/useQuery";
import { reloadPages } from "../../pages/BuildPagePage/utils";
import { codeBlockSync } from "../../services/code-block-sync";
import { useIsDevxReady } from "../../store/slices/project-slice";
import { useStore } from "../../store/store";
import { ProjectSummary } from "../../types/persisted";
import { ProjectGuardContext } from "../ProjectGuard/ProjectGuard";
import { UserGuardContext } from "../UserGuard/UserGuard";

interface Props {
  children: React.ReactNode;
}

interface ProjectPageContext {
  project: ProjectSummary;
}

const ProjectPageWrapperContext = createContext<ProjectPageContext>(
  {} as ProjectPageContext,
);

export const useProjectPageContext = () =>
  useContext(ProjectPageWrapperContext);

export const ProjectWrapper = ({ children }: Props) => {
  const project = useContext(ProjectGuardContext);
  const { user, profile } = useContext(UserGuardContext);

  useComponentsSubscription({
    projectId: project.id,
  });

  const { queryParams } = useQuery();
  const newComponentToggled = useStore((state) => state.newComponentToggled);
  const instanceTagReceived = useStore((state) => state.instanceTagReceived);
  const isDevxReady = useIsDevxReady();
  const hasMadeChangesWhileDevxNotReady = useStore(
    (state) => state.project.devxHealth.hasMadeChangesWhileDevxNotReady,
  );
  const devxRefreshed = useStore((state) => state.devxRefreshed);

  useEffect(() => {
    if (isDevxReady && hasMadeChangesWhileDevxNotReady) {
      codeBlockSync
        .refresh({
          projectId: project.id,
          user,
          timestamp: Timestamp.now(),
        })
        .then(() => {
          // Tell Streamlit to update all pages based on the latest code refresh
          // to make sure that all pages are ready when the user goes into build mode
          reloadPages({
            projectId: project.id,
            user,
            onInstanceTagReceived: instanceTagReceived,
          });
          devxRefreshed();
        });
    }
  }, [project.id, isDevxReady, user, profile, hasMadeChangesWhileDevxNotReady]);

  useEffect(() => {
    const newComponentParam = queryParams.get("new");
    if (newComponentParam === "true") {
      newComponentToggled({ open: true });
    }
  }, []);

  return (
    <ProjectPageWrapperContext.Provider value={{ project }}>
      {children}
    </ProjectPageWrapperContext.Provider>
  );
};
