import { Datafile, Dataframe } from "../../../types/persisted";
import {
  createPathToDatafile,
  createPathToDataframe,
} from "../../../utils/route-utils";
import { DataCardProps } from "./types";
import * as Sentry from "@sentry/react";
import { Timestamp } from "firebase/firestore";

export const convertDataframeToDataCard = (
  projectId: string,
  dataframe: Dataframe,
): DataCardProps => ({
  type: "dataframe",
  displayName: dataframe.name ?? dataframe.id,
  to: createPathToDataframe({
    projectId,
    slug: dataframe.slug ?? dataframe.generatedSlug,
  }),
  lastUpdatedAt:
    dataframe.lastUpdatedBy?.timestamp ??
    Timestamp.fromMillis(dataframe.lastUpdatedAtMillis),
  // The size of dataframes and files is stored as a string in firestore.
  // Size should be converted to a number here so we can sort on the size
  size: +dataframe.size,
  content: `${dataframe.numberOfRows} rows & ${dataframe.numberOfProperties} properties`,
  id: dataframe.id,
});

export const convertDatafileToDataCard = (
  projectId: string,
  datafile: Datafile,
): DataCardProps => ({
  type: "datafile",
  displayName: datafile.name ?? datafile.id,
  contentType: datafile.contentType,
  to: createPathToDatafile({
    projectId,
    slug: datafile.slug ?? datafile.generatedSlug,
    contentType: datafile.contentType,
  }),
  lastUpdatedAt: datafile.lastUpdatedBy.timestamp,
  content: "",
  size: +datafile.size,
  id: datafile.id,
});

const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

// This is taken from https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
export const formatBytes = (bytes: number | string, decimals: number = 2) => {
  try {
    if (typeof bytes === "string") {
      bytes = Number(bytes);
    }
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
      SIZES[i]
    }`;
  } catch (e) {
    Sentry.captureException(e, {
      contexts: {
        locals: { bytes, decimals },
      },
    });
    return "-";
  }
};
