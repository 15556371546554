import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MemoizedBuildComponentWrapper } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useProjectPageContext } from "../../components/ProjectWrapper/ProjectWrapper";
import { useCodeBlockSubscription } from "../../hooks/useCodeBlock";
import { useTitle } from "../../hooks/useTitle";
import { usePages } from "../../store/slices/project-slice";
import { WorkspaceIdentifier } from "../../store/slices/workspace-slice";
import { MemoizedBuildPageForm } from "./BuildPageForm";

export const BuildPagePage = () => {
  const { pageIdOrSlug } = useParams() as { pageIdOrSlug: string };
  const { project } = useProjectPageContext();
  const pages = usePages();

  const page = useMemo(
    () =>
      pages.find(
        (it) =>
          it.slug.toLowerCase() === pageIdOrSlug.toLowerCase() ||
          it.id === pageIdOrSlug,
      ),
    [pages, pageIdOrSlug],
  );

  useTitle(page?.name);

  const { codeBlock } = useCodeBlockSubscription(project.id, page?.id);

  const identifier = useMemo(
    (): WorkspaceIdentifier | null =>
      page && codeBlock
        ? {
            projectId: project.id,
            componentId: page.id,
            componentType: "page",
            codeBlockId: codeBlock.id,
          }
        : null,
    [project.id, page?.id, codeBlock?.id],
  );

  if (page && codeBlock && identifier) {
    return (
      <MemoizedBuildComponentWrapper
        component={page}
        createdFromProjectTemplateId={project.createdFromProjectTemplateId}
        identifier={identifier}
        codeBlockId={codeBlock.id}
      >
        <MemoizedBuildPageForm page={page} codeBlockId={codeBlock.id} />
      </MemoizedBuildComponentWrapper>
    );
  }

  return null;
};
