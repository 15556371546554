import { styled } from "../../../stitches.config";

export const SidecarContainer = styled("div", {
  width: "100%",
  height: "100%",

  variants: {
    padding: {
      none: {
        padding: 0,
      },
      normal: {
        padding: "$2",
      },
    },
  },

  defaultVariants: {
    padding: "normal",
  },
});
