import { styled } from "../../../stitches.config";

export const Table = styled("table", {
  width: "100%",
  borderCollapse: "collapse",
});

export const Thead = styled("thead");

export const Tbody = styled("tbody");

export const Tfoot = styled("tfoot");

export const Th = styled("th", {
  textAlign: "left",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: "10px",
  color: "#656565",
  padding: "$1",
  paddingBottom: "$2",
});

export const Td = styled("td", {
  padding: "$1",
});

export const Tr = styled("tr", {
  borderBottom: "1px solid $gray6BoardBackground",

  "&:hover": {
    backgroundColor: "$gray7CardBackground",
    cursor: "pointer",
  },

  "&:last-child": {
    borderBottom: "none",
  },
});
