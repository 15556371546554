import { memo, useCallback, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMultipageApp } from "../../store/slices/project-slice";
import { useStore } from "../../store/store";
import { Button } from "../../ui/Button";
import { Divider } from "../../ui/Divider/Divider";
import { Form } from "../../ui/Form";
import { Icon } from "../../ui/Icon";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import { Flex } from "../../ui/Layout/Flex";
import { Grid } from "../../ui/Layout/Grid";
import { Spacer } from "../../ui/Spacer";
import { Text } from "../../ui/Text";
import { FieldErrorTooltip } from "../../ui/Tooltip";
import { MADE_MULTIPAGE_APP_ACCESSIBLE_BY_LINK } from "../../utils/analytics-constants";
import { multipageAppDoc } from "../../utils/collections/multipage-apps";
import { copyTextToClipboard } from "../../utils/user-utils";
import { isValidEmailAddress } from "../../utils/validation-utils";
import { IconButton } from "../IconButton";
import { useUserGuardContext } from "../UserGuard/UserGuard";
import { ListViewers } from "./ListViewers";
import { addViewerToMultipageApp } from "./utils";

interface Props {
  projectId: string;
  isPublished: boolean;
}

interface FormProps {
  recipient: string;
}

export const SharePagesForm = memo(({ projectId, isPublished }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const pulseTriggered = useStore((state) => state.pulseTriggered);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();
  const { user } = useUserGuardContext();
  const multipageApp = useMultipageApp();

  const sharingLink = useMemo(
    () => `${window.location.origin}${multipageApp?.shortUrl}`,
    [multipageApp],
  );

  const handleCopyLink = useCallback(async () => {
    await copyTextToClipboard(sharingLink);
    toast("Link copied to clipboard");
  }, [sharingLink]);

  const onSubmit: SubmitHandler<FormProps> = useCallback(
    async (data) => {
      if (multipageApp) {
        setIsLoading(true);
        await addViewerToMultipageApp({
          projectId,
          multipageAppRef: multipageApp.refPath,
          recipient: data.recipient,
          user,
        });

        toast("Invitation sent");

        setIsLoading(false);
      } else {
        throw new Error("Multipage app not found when adding viewer");
      }
    },
    [multipageApp, projectId, user],
  );

  const toggleSharingLink = useCallback(async () => {
    if (multipageApp) {
      await multipageAppDoc.togglePubliclyAccessibleByLink({
        user,
        projectId,
        multipageApp,
      });

      pulseTriggered({
        user,
        eventName: MADE_MULTIPAGE_APP_ACCESSIBLE_BY_LINK,
        properties: {
          projectId,
          multipageAppId: multipageApp.id,
        },
      });
    } else {
      throw new Error("Multipage app not found when toggling sharing link");
    }
  }, [user, projectId, multipageApp]);

  return (
    <>
      <Flex direction="vertical">
        <Text weight="thin">Show them what you made!</Text>
        {!isPublished && (
          <Text weight="thin">
            In order to share your pages, you have to publish them.
          </Text>
        )}
      </Flex>

      <Spacer height="small" />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        css={{ display: "flex", gap: "$1", flexFlow: "column" }}
      >
        <Grid
          css={{ gridTemplateColumns: "auto max-content", columnGap: "$1" }}
        >
          <Label>
            <Text size="2">Share via email</Text>
            <FieldErrorTooltip text={errors.recipient?.message}>
              <Input
                type="email"
                disabled={isLoading}
                {...register("recipient", {
                  required: "This field is required",
                  validate: {
                    isValidEmailCheck: (value) => {
                      const valid = isValidEmailAddress(value);
                      return valid || "Invalid email address";
                    },
                  },
                })}
              />
            </FieldErrorTooltip>
          </Label>

          <Button
            type="submit"
            disabled={isLoading}
            css={{ alignSelf: "flex-end" }}
          >
            {isPublished ? "Send invitation" : "Publish and send invitation"}
          </Button>
        </Grid>
      </Form>

      <Spacer height="small" />

      <ListViewers />

      <Divider marginY="4" />

      <Flex direction="vertical" gap="2">
        <Text size="2">Share with open link</Text>

        <Flex direction="vertical">
          <Text weight="thin">
            Anyone who has access to this link can see your pages.
          </Text>

          <Text weight="thin">
            You can always deactivate the link using the toggle below.
          </Text>
        </Flex>

        <Grid
          css={{
            gridTemplateColumns: "auto max-content",
            columnGap: "$1",
          }}
        >
          <Input
            id="sharing-link"
            disabled={true}
            type="text"
            value={sharingLink}
          />

          <Button
            css={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
            onClick={handleCopyLink}
            disabled={isLoading || !multipageApp?.isPublic}
          >
            {isPublished ? "Copy link" : "Publish and copy link"}
          </Button>
        </Grid>

        <Flex css={{ marginBottom: "$1" }} gap="1">
          <Flex gap="1">
            <Text weight="thin">Enable open link</Text>
            <IconButton onClick={toggleSharingLink}>
              <Icon
                src={
                  multipageApp?.isPublic
                    ? "/toggle-active.svg"
                    : "/toggle-inactive.svg"
                }
                alt="Button to toggle open link"
                height="10px"
              />
            </IconButton>
          </Flex>

          <Text weight="thin">
            {multipageApp?.isPublic
              ? "Anyone with access to this link can see the pages."
              : "Your link is currently deactivated and can not be opened."}
          </Text>
        </Flex>
      </Flex>
    </>
  );
});
