import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { Profile } from "../../types/persisted";
import { firestore } from "../firebase";
import { CollectionName, createConverter, createDocRefPath } from "./shared";

export const profileConverter = createConverter<Profile>();

const createProfileDocRef = (params: {
  refPath: string;
}): DocumentReference<Profile> =>
  doc(firestore, params.refPath).withConverter(profileConverter);

const fetchProfileByUserId = async (params: {
  userId: string;
}): Promise<Profile | null> => {
  const result = await getDoc(
    createProfileDocRef({
      refPath: createDocRefPath([CollectionName.PROFILES, params.userId]),
    }),
  );

  return result.exists() ? result.data() : null;
};

const fetchProfileByEmail = async (params: {
  email: string;
}): Promise<Profile | null> => {
  const result = await getDocs(
    query(
      collection(firestore, CollectionName.PROFILES),
      where("email", "==", params.email),
      limit(1),
    ).withConverter(profileConverter),
  );

  return result.size > 0 ? result.docs[0].data() : null;
};

export const fetchProfile = async (params: {
  user: User;
}): Promise<Profile | null> => {
  const profileByUserId = await fetchProfileByUserId({
    userId: params.user.uid,
  });

  if (profileByUserId) {
    return profileByUserId;
  }

  if (!params.user.email) {
    Sentry.captureMessage(
      `User obj does not have an email: ${params.user.uid}`,
      "error",
    );
    return null;
  }

  const profileByEmail = await fetchProfileByEmail({
    email: params.user.email,
  });

  if (profileByEmail) {
    return profileByEmail;
  }

  Sentry.captureMessage(
    `User logged in without a profile: ${params.user.uid}`,
    "error",
  );
  return null;
};
