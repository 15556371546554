import { faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Listbox } from "@headlessui/react";
import { styled } from "../../../stitches.config";
import { StyledFontAwesomeIcon } from "../Icon";
import { Div } from "../Layout";

interface Props<T> {
  getKey: (value: T) => string;
  getDisplayValue: (value: T) => string;
  value: T;
  options: T[];
  onChange: (value: T) => void;
}

const SelectButton = styled("button", {
  $$selectWidth: "100px",

  width: "$$selectWidth",
  border: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$1",
  background: "$white",
  padding: "$1",
  borderRadius: "4px",
  fontSize: "$2",
  fontFamily: "$sans",
  position: "relative",
});

const Options = styled("div", {
  $$selectWidth: "100px",

  zIndex: 1,
  width: "$$selectWidth",
  backgroundColor: "$white",
  borderRadius: "4px",
  fontSize: "$2",
  position: "absolute",
  boxShadow: "$mediumElevation",
});

const Option = styled("div", {
  padding: "$1",
  cursor: "pointer",

  "&:hover": {
    background: "$gray7CardBackground",
  },

  "&:not(:last-child)": {
    borderBottom: "1px solid $gray7CardBackground",
  },
});

export function Select<T>({
  value,
  options,
  getDisplayValue,
  onChange,
  getKey,
}: Props<T>) {
  return (
    <Listbox value={value} onChange={onChange}>
      <Listbox.Button as={SelectButton}>
        <Div>{getDisplayValue(value)}</Div>
        <StyledFontAwesomeIcon
          icon={faTriangle}
          transform={{ flipY: true }}
          css={{ fontSize: "10px" }}
        />
      </Listbox.Button>

      <Listbox.Options as={Options}>
        {options.map((it) => (
          <Listbox.Option as={Option} key={getKey(it)} value={it}>
            {getDisplayValue(it)}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
