import { User } from "firebase/auth";
import { useMemo, useState } from "react";
import { useAppOrMultipageAppByRef } from "../../hooks/useAppOrMultipageAppByRef";
import { useShortUrlOnce } from "../../hooks/useShortUrl";
import { useProject } from "../../store/slices/project-slice";
import { Button } from "../../ui/Button";
import { extractProjectIdFromAppRefPath } from "../../utils/firebase-utils";
import { canShareProject } from "../../utils/permission-utils";
import { isApp } from "../AppGuard/AppGuard";
import { ModalDialog } from "../ModalDialog";
import { SharePagesForm } from "../SharePagesForm/SharePagesForm";
import { ShareViewForm } from "../ShareViewForm/ShareViewForm";

interface Props {
  shortUrlId: string;
  user: User;
}

export const ShareFromShortUrlButton = ({ shortUrlId, user }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { shortUrl } = useShortUrlOnce(shortUrlId);
  const componentRef = useMemo(
    () => (shortUrl?.type === "ref" ? shortUrl.componentRef : undefined),
    [shortUrl],
  );
  const { app } = useAppOrMultipageAppByRef(componentRef);

  const idFromRefPath = componentRef
    ? extractProjectIdFromAppRefPath(componentRef)
    : null;

  const project = useProject();

  const modalTitle = useMemo(() => {
    if (app && isApp(app)) {
      return `Share ${app.name}`;
    } else if (app) {
      return "Share your pages";
    }

    return "Share";
  }, [app]);

  return project && canShareProject({ user, project }) ? (
    <>
      <Button
        disabled={!app}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Share
      </Button>

      {app && idFromRefPath && (
        <ModalDialog
          isOpen={isModalOpen}
          title={modalTitle}
          onClose={() => {
            setIsModalOpen(false);
          }}
          content={
            isApp(app) ? (
              <ShareViewForm
                projectId={idFromRefPath.projectId}
                user={user}
                isPublished={true}
                app={app}
              />
            ) : (
              <SharePagesForm
                projectId={idFromRefPath.projectId}
                isPublished={true}
              />
            )
          }
        />
      )}
    </>
  ) : null;
};
