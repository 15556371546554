import { styled } from "../../../stitches.config";

export const Flex = styled("div", {
  display: "flex",

  variants: {
    direction: {
      horizontal: {
        alignItems: "center",
      },
      vertical: {
        flexFlow: "column",
      },
    },
    width: {
      full: {
        width: "100%",
      },
    },
    gap: {
      "1/2": {
        gap: "calc($1/2)",
      },
      "1": {
        gap: "$1",
      },
      "2": {
        gap: "$2",
      },
      "3": {
        gap: "$3",
      },
      "4": {
        gap: "$4",
      },
      "5": {
        gap: "$5",
      },
      "6": {
        gap: "$6",
      },
      "7": {
        gap: "$7",
      },
      "8": {
        gap: "$8",
      },
      "9": {
        gap: "$9",
      },
      "10": {
        gap: "$10",
      },
      "11": {
        gap: "$11",
      },
      "12": {
        gap: "$12",
      },
    },
    justifyContent: {
      "space-between": {
        justifyContent: "space-between",
      },
      "flex-end": {
        justifyContent: "flex-end",
      },
    },
  },

  defaultVariants: {
    direction: "horizontal",
  },
});
