import { doc, DocumentReference, getDoc, updateDoc } from "firebase/firestore";
import { Project, WithoutId } from "../../types/persisted";
import { firestore } from "../firebase";
import { CollectionName, createConverter } from "./shared";

export const projectConverter = createConverter<Project>();

type ProjectRef = { refPath: string } | { projectId: string };

const createProjectDocumentRef = (
  params: ProjectRef,
): DocumentReference<Project> => {
  if ("refPath" in params) {
    return doc(firestore, params.refPath).withConverter(projectConverter);
  }

  if ("projectId" in params) {
    return doc(
      firestore,
      CollectionName.PROJECTS,
      params.projectId,
    ).withConverter(projectConverter);
  }

  throw new Error("Could not create project document ref");
};

export const updateProject = async (params: {
  refPath: string;
  payload: Partial<WithoutId<Project>>;
}) => {
  await updateDoc(
    createProjectDocumentRef({
      refPath: params.refPath,
    }),
    params.payload,
  );
};

export const fetchProject = async (params: {
  refPath: string;
}): Promise<Project> => {
  const projectDoc = await getDoc(
    doc(firestore, params.refPath).withConverter(projectConverter),
  );

  if (projectDoc.exists()) {
    return projectDoc.data();
  }

  throw new Error(`Project not found: ${params.refPath}`);
};
