import * as Sentry from "@sentry/react";
const isLocalhost = /localhost:\d{4}/i.test(window.location.origin);

const LOG_SETTINGS: {
  editor: boolean;
  "new-project": boolean;
  lsp: boolean;
} = {
  editor: false,
  "new-project": true,
  lsp: false,
};

type LogSetting = keyof typeof LOG_SETTINGS;

// rome-ignore lint/suspicious/noExplicitAny: <explanation>
type LogExtra = { key?: LogSetting } & Record<string, any>;

const shouldLog = (extra?: LogExtra): boolean => {
  const key = extra?.key;

  if (key) {
    return isLocalhost && LOG_SETTINGS[key];
  }

  return isLocalhost;
};

const debug = (message: string, extra?: LogExtra): void => {
  if (shouldLog(extra)) {
    const { key: _key, ...extraWithoutKey } = extra ?? {};
    console.debug(message, extraWithoutKey);
  }
};

const info = (message: string, category?: string): void => {
  if (isLocalhost) {
    console.log(category ? `${category}: ${message}` : message);
  } else {
    Sentry.addBreadcrumb({
      category,
      message,
      level: "info",
    });
  }
};

const warn = (message: string, category?: string): void => {
  if (isLocalhost) {
    console.warn(category ? `${category}: ${message}` : message);
  } else {
    Sentry.addBreadcrumb({
      category,
      message,
      level: "warning",
    });
  }
};

export const logService = { debug, info, warn };
