import { ReactNode, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useShortUrlOnce } from "../../hooks/useShortUrl";
import { useUser } from "../../hooks/useUser";
import {
  AppContext,
  AppContextType,
} from "../../pages/PublishedViewPage/context";
import {
  App,
  MultipageApp,
  ShortUrl,
  ShortUrlRef,
} from "../../types/persisted";
import { FullSizePage, Page } from "../../ui/Page";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingScreen } from "../LoadingScreen";
import { LoadingAnimationType } from "../LoadingScreen/LoadingAnimation";
import { useAppAndClaimInvite } from "./utils";

interface Props {
  children: ReactNode;
}

const isShortUrlRef = (value: ShortUrl | undefined): value is ShortUrlRef =>
  value !== undefined && value.type === "ref";

export type AppOrMultipageApp = App | MultipageApp;

export const isApp = (value: AppOrMultipageApp): value is App =>
  "variant" in value;

const createContext = (params: {
  projectId: string;
  app: AppOrMultipageApp;
}): AppContextType =>
  isApp(params.app)
    ? { type: "view", app: params.app, projectId: params.projectId }
    : { type: "multipage-app", app: params.app, projectId: params.projectId };

export const AppGuard = ({ children }: Props) => {
  const params = useParams() as { shortUrlId: string };
  const { user } = useUser();

  const { shortUrl, loading: loadingShortUrl } = useShortUrlOnce(
    params.shortUrlId,
  );

  const { app, loading: loadingApp } = useAppAndClaimInvite({
    ref: shortUrl?.type === "ref" ? shortUrl.componentRef : undefined,
    user,
  });

  const context = useMemo(() => {
    if (app && isShortUrlRef(shortUrl)) {
      return createContext({
        app,
        projectId: shortUrl.projectId,
      });
    }

    return null;
  }, [app, shortUrl]);

  const loading = loadingApp || loadingShortUrl;

  if (loading) {
    return (
      <Page width="full" height="full" padding="none">
        <LoadingScreen
          message="Loading..."
          animation={LoadingAnimationType.LIGHT_SPEED}
        />
      </Page>
    );
  }

  if (app?.isPublic === true || (app && user)) {
    return (
      <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
  }

  if (user) {
    return (
      <FullSizePage>
        <ErrorView message="Page not found. You might have the wrong link, or you do not have access to this view" />
      </FullSizePage>
    );
  }

  return (
    <Navigate replace={true} to={`/login?next=${window.location.pathname}`} />
  );
};
