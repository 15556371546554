import { User } from "firebase/auth";
import { deleteDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import { Viewer, WithoutId } from "../../types/persisted";
import { getViewersCollectionKey } from "../../utils/collections/viewers";
import { firestore } from "../../utils/firebase";

export const addViewerToView = async (params: {
  projectId: string;
  appId: string;
  recipient: string;
  user: User;
}): Promise<Viewer> => {
  // Using recipients email address as ID as we don't know if the email belongs to a user or not yet
  const viewerId = params.recipient.toLowerCase();

  const newViewer: WithoutId<Viewer> = {
    userId: null,
    recipient: params.recipient,
    revoked: false,
    createdByUserId: params.user.uid,
    createdAtUtc: Timestamp.now(),
  };

  const docRef = `${getViewersCollectionKey({
    projectId: params.projectId,
    appId: params.appId,
  })}/${viewerId}`;

  await setDoc(doc(firestore, docRef), newViewer);

  return {
    ...newViewer,
    id: viewerId,
    refPath: docRef,
  };
};

export const revokeAccessForViewer = async (params: {
  projectId: string;
  appId: string;
  viewerId: string;
}): Promise<boolean> => {
  // TODO: Reintroduce the usage of the revoke flag

  // const payload: Pick<Viewer, "revoked"> = {
  //   revoked: true,
  // };

  // await updateDoc(
  //   doc(
  //     firestore,
  //     getViewersCollectionKey({
  //       projectId: params.projectId,
  //       appId: params.appId,
  //     }),
  //     params.viewerId
  //   ),
  //   payload
  // );

  await deleteDoc(
    doc(
      firestore,
      getViewersCollectionKey({
        projectId: params.projectId,
        appId: params.appId,
      }),
      params.viewerId,
    ),
  );

  return true;
};
