import { collection, limit, query, where } from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import {
  codeBlockConverter,
  getCodeBlockCollectionKey,
} from "../utils/collections/code-blocks";
import { firestore } from "../utils/firebase";

export const useCodeBlockSubscription = (
  projectId: string,
  componentId?: string,
) => {
  const codeBlockChanged = useStore((state) => state.codeBlockChanged);

  const [codeBlocks, _loading, error] = useCollectionData(
    componentId
      ? query(
          collection(firestore, getCodeBlockCollectionKey(projectId)),
          where("componentId", "==", componentId),
          limit(1),
        ).withConverter(codeBlockConverter)
      : null,
  );

  const codeBlock = useMemo(() => {
    if (codeBlocks && codeBlocks?.length === 1) {
      return codeBlocks[0];
    }

    if (codeBlocks && codeBlocks?.length > 1) {
      throw new Error("Got more than one code block. Expected 1 or 0.");
    }

    return null;
  }, [codeBlocks]);

  useEffect(() => {
    if (codeBlock) {
      codeBlockChanged(codeBlock);
    }
  }, [codeBlock]);

  if (error) {
    logService.warn(error.message);
  }

  return { codeBlock };
};
