import { ProjectGroup } from "../../../components/ProjectGroup/ProjectGroup";
import { useMemberProjects } from "../../../store/slices/home-slice";
import { useStore } from "../../../store/store";
import { Flex } from "../../../ui/Layout/Flex";
import { H2 } from "../../../ui/TypographyV2/TypographyV2";
import { ViewCardGroup } from "./ViewCardGroup";

export const SharedWithMe = () => {
  const memberProjects = useMemberProjects();
  const appsSharedWithUser = useStore(
    (state) => state.homeState.appsSharedWithUser,
  );

  const shouldRenderSharedWithMeSection =
    memberProjects.length > 0 || appsSharedWithUser.length > 0;

  return shouldRenderSharedWithMeSection ? (
    <Flex direction="vertical" gap="3">
      <H2>Shared with me</H2>

      {memberProjects.length > 0 && (
        <Flex direction="vertical" gap="2">
          <H2>Data apps</H2>
          <ProjectGroup projects={memberProjects} />
        </Flex>
      )}

      {appsSharedWithUser.length > 0 && (
        <Flex direction="vertical" gap="2">
          <H2>Views</H2>
          <ViewCardGroup apps={appsSharedWithUser} />
        </Flex>
      )}
    </Flex>
  ) : null;
};
