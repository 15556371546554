import { styled } from "../../../../stitches.config";

export const DRAGBAR_SIZE = "3px";

export const Dragbar = styled("div", {
  gridArea: "dragbar",
  height: "100%",
  width: "100%",
  backgroundColor: "$mainBorder",
  display: "block",

  variants: {
    orientation: {
      vertical: {
        cursor: "ew-resize",
      },
      horizontal: {
        cursor: "ns-resize",
      },
    },
    visible: {
      no: { display: "none" },
      yes: {},
    },
  },
});
