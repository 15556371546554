import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useProjectTemplates } from "../../hooks/useProjectTemplates";
import { useStore } from "../../store/store";
import { ProjectSummary } from "../../types/persisted";
import { ButtonDeprecated } from "../../ui/Button";
import { Input } from "../../ui/Input";
import { Label } from "../../ui/Label";
import { Row } from "../../ui/Layout";
import { Message } from "../../ui/Message";
import { Spacer } from "../../ui/Spacer";
import { Text } from "../../ui/Text";
import { ModalDialog } from "../ModalDialog";
import { useUserGuardContext } from "../UserGuard/UserGuard";
import { deleteProject } from "./utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  project: ProjectSummary;
}

const REQUIRED_CONFIRM_TEXT = "delete my data app";

export const DeleteProjectModal = ({
  isOpen,
  onClose,
  project,
  onSuccess,
}: Props) => {
  const { projectTemplates } = useProjectTemplates({ filter: "all" });
  const [confirmationInputText, setConfirmationInputText] =
    useState<string>("");
  const initialFocusRef = useRef(null);
  const { user } = useUserGuardContext();
  const projectDeleted = useStore(
    (state) => state.projectActions.projectDeleted,
  );

  const handleDeleteConfirmClick = async (
    value: ProjectSummary,
  ): Promise<void> => {
    await deleteProject({
      user,
      projectId: value.id,
    });

    projectDeleted({ projectId: value.id });

    toast(`"${project.name}" has been deleted`);
    onSuccess?.();
  };

  const isDisabled = confirmationInputText !== REQUIRED_CONFIRM_TEXT;

  const projectTemplateFromProject = projectTemplates.find(
    (it) => it.basedOnProjectId === project.id,
  );

  return (
    <ModalDialog
      isOpen={isOpen}
      initialFocus={initialFocusRef}
      onClose={onClose}
      title={`Delete ${project.name}`}
      content={
        <>
          <Text>
            This will delete your data app from databutton.com and is not
            reversible.
          </Text>

          {projectTemplateFromProject && (
            <>
              <Spacer />
              <Message intent="danger">
                This project is the base for the template "
                {projectTemplateFromProject.name}". The template might stop
                working if you delete this data app.
              </Message>
            </>
          )}

          <Spacer />

          <Label htmlFor="confirmation-text">
            Type &quot;{REQUIRED_CONFIRM_TEXT}&quot; here to confirm
            <Input
              ref={initialFocusRef}
              id="confirmation-text"
              value={confirmationInputText}
              onChange={(e) => setConfirmationInputText(e.target.value)}
              onKeyUp={(e) => {
                if (/^enter$/i.test(e.key) && !isDisabled) {
                  handleDeleteConfirmClick(project);
                }
              }}
            />
          </Label>

          <Spacer />
          <Row css={{ alignItems: "center", justifyContent: "flex-end" }}>
            <ButtonDeprecated
              intent="plain"
              type="button"
              onClick={() => {
                setConfirmationInputText("");
                onClose();
              }}
            >
              No, take me back to safe grounds
            </ButtonDeprecated>
            <ButtonDeprecated
              intent="danger"
              type="button"
              onClick={() => {
                handleDeleteConfirmClick(project);
              }}
              disabled={isDisabled || !!projectTemplateFromProject}
            >
              Delete data app
            </ButtonDeprecated>
          </Row>
        </>
      }
    />
  );
};
