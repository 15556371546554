import { collection, getCountFromServer, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { getAppDeploymentsCollectionKey } from "../utils/collections/deployments";
import { firestore } from "../utils/firebase";

export const useIsPublishedApp = ({
  projectId,
  appId,
}: {
  projectId: string;
  appId: string;
}) => {
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    getCountFromServer(
      query(
        collection(
          firestore,
          getAppDeploymentsCollectionKey({
            projectId,
            appId,
          }),
        ),
      ),
    ).then((it) => {
      setIsPublished(it.data().count > 0);
    });
  }, []);

  return { isPublished };
};
