import { useMemo } from "react";
import { useWorkspaceContext } from "../../../components/BuildComponentWrapper/BuildComponentWrapper";
import { JobDevRuns } from "../../../components/JobDevRuns/JobDevRuns";
import { LoadingScreen } from "../../../components/LoadingScreen";
import { useIsDevxReady } from "../../../store/slices/project-slice";
import { randomJobTip } from "../../../utils/user-utils";

export const JobRuns = () => {
  const identifier = useWorkspaceContext();
  const isDevxReady = useIsDevxReady();
  const loadingTip = useMemo(() => randomJobTip(), []);

  return isDevxReady ? (
    <JobDevRuns
      projectId={identifier.projectId}
      jobId={identifier.componentId}
      codeBlockId={identifier.codeBlockId}
    />
  ) : (
    <LoadingScreen message={loadingTip} />
  );
};
