import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { logService } from "../services/log-service";
import { useStore } from "../store/store";
import {
  getPageCollectionKey,
  pageConverter,
} from "../utils/collections/pages";
import { firestore } from "../utils/firebase";

export const usePagesSubscription = ({ projectId }: { projectId: string }) => {
  const pagesReceived = useStore((state) => state.pagesReceived);

  const [pages, _loading, error] = useCollectionData(
    query(
      collection(firestore, getPageCollectionKey({ projectId })),
      where("markedForDeletionBy", "==", null),
    ).withConverter(pageConverter),
  );

  useEffect(() => {
    pagesReceived({ items: pages ?? [] });
  }, [pages]);

  if (error) {
    logService.info(`Failed fetching pages: ${error.message}`);
  }
};
