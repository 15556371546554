import { styled } from "../../../../stitches.config";
import { H3 } from "../../../ui/Typography";

export const ComponentName = styled(H3, {
  fontWeight: 600,
  fontSize: "16px",
  color: "$fontDark",
  lineHeight: "16px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: 0,
});
