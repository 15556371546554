import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { MemoizedBuildComponentWrapper } from "../../components/BuildComponentWrapper/BuildComponentWrapper";
import { useProjectPageContext } from "../../components/ProjectWrapper/ProjectWrapper";
import { useCodeBlockSubscription } from "../../hooks/useCodeBlock";
import { useTitle } from "../../hooks/useTitle";
import { useApps, useIsDevxReady } from "../../store/slices/project-slice";
import { WorkspaceIdentifier } from "../../store/slices/workspace-slice";
import { BuildAppForm } from "./BuildAppForm";

const BuildAppFormMemoized = memo(
  BuildAppForm,
  (prev, next) =>
    prev.codeBlock.id === next.codeBlock.id &&
    prev.codeBlock.controlTakenBy?.id === next.codeBlock.controlTakenBy?.id &&
    prev.isDevxReady === next.isDevxReady &&
    prev.app.isPublic === next.app.isPublic,
);

export const BuildAppPage = () => {
  const { appIdOrSlug } = useParams() as { appIdOrSlug: string };
  const { project } = useProjectPageContext();
  const apps = useApps();
  const isDevxReady = useIsDevxReady();

  const app = useMemo(
    () => apps.find((it) => it.slug === appIdOrSlug || it.id === appIdOrSlug),
    [apps, appIdOrSlug],
  );

  useTitle(app?.name);

  const { codeBlock } = useCodeBlockSubscription(project.id, app?.id);

  const identifier = useMemo(
    (): WorkspaceIdentifier | null =>
      app && codeBlock
        ? {
            projectId: project.id,
            componentId: app.id,
            componentType: "app",
            codeBlockId: codeBlock.id,
          }
        : null,
    [project.id, app?.id, codeBlock?.id],
  );

  if (!(app && codeBlock && identifier)) {
    return null;
  }

  return (
    <MemoizedBuildComponentWrapper
      component={app}
      identifier={identifier}
      codeBlockId={codeBlock.id}
      createdFromProjectTemplateId={project.createdFromProjectTemplateId}
    >
      <BuildAppFormMemoized
        app={app}
        codeBlock={codeBlock}
        isDevxReady={isDevxReady}
        project={project}
      />
    </MemoizedBuildComponentWrapper>
  );
};
