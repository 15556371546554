import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAdgR9BGfQrV2fzndXZLZYgiRtpydlq8ug",
  authDomain: "databutton.firebaseapp.com",
  projectId: "databutton",
  storageBucket: "databutton.appspot.com",
  messagingSenderId: "89950862312",
  appId: "1:89950862312:web:13c6e744d43563e84d025d",
  measurementId: "G-999G0VN01E",
} satisfies FirebaseOptions;

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1");
export const analytics = getAnalytics(firebaseApp);
