import { ADJUSTED_WORKSPACE, pulse } from "../../utils/analytics-constants";
import { localStorageUtils } from "../../utils/local-storage-utils";
import { StoreSlice } from "../types";
import { createActionTypeLogger } from "../utils";

interface LayoutConfig {
  preset: "horizontal-50-50" | "vertical-50-50" | "vertical-100-0" | "custom";
  direction: "horizontal" | "vertical";
  customGrid: string | null;
}

// Update this to invalidate all saved workspaces for users
const CACHE_KEY = "2022-11-08";

interface WorkspaceState {
  cacheKey: typeof CACHE_KEY;
  darkMode: boolean;
  layout: LayoutConfig;
  hotReloading: boolean;
  multiplayerCursor: boolean;
}

export interface WorkspaceIdentifier {
  projectId: string;
  componentId: string;
  componentType: "app" | "job" | "module" | "page";
  codeBlockId: string;
}

export interface WorkspaceSlice {
  workspace: WorkspaceState;
  darkModeToggled: (identifier: WorkspaceIdentifier) => void;
  hotReloadingToggled: (identifier: WorkspaceIdentifier) => void;
  multiplayerCursorToggled: (identifier: WorkspaceIdentifier) => void;
  workspaceLoaded: (params: {
    identifier: WorkspaceIdentifier;
    isOnboardingTemplate?: boolean;
  }) => void;
  layoutDragCompleted: (
    identifier: WorkspaceIdentifier,
    params: Pick<LayoutConfig, "customGrid">,
  ) => void;
  layoutAdjusted: (
    identifier: WorkspaceIdentifier,
    params: Pick<LayoutConfig, "direction" | "preset">,
  ) => void;
}

const getWorkspaceStorageKey = (identifier: WorkspaceIdentifier): string =>
  `${identifier.projectId}-${identifier.componentId}-workspace`;

const saveWorkspaceSettings = (
  identifier: WorkspaceIdentifier,
  state: WorkspaceState,
): void => {
  const storageKey = getWorkspaceStorageKey(identifier);

  localStorageUtils.setValue<WorkspaceState>(storageKey, state);
};

const loadWorkspaceSettings = (
  identifier: WorkspaceIdentifier,
): WorkspaceState | null => {
  const storageKey = getWorkspaceStorageKey(identifier);

  const settings = localStorageUtils.getValue<WorkspaceState | null>(
    storageKey,
    null,
  );

  if (settings) {
    return settings.cacheKey === CACHE_KEY ? settings : null;
  }

  return settings;
};

const initialState: WorkspaceState = {
  cacheKey: CACHE_KEY,
  darkMode: false,
  layout: {
    preset: "vertical-50-50",
    direction: "vertical",
    customGrid: null,
  },
  hotReloading: false,
  multiplayerCursor: false,
};

const actionType = createActionTypeLogger("workspace");

export const workspaceStore: StoreSlice<WorkspaceSlice> = (set, get) => ({
  workspace: initialState,
  workspaceLoaded: ({ identifier, isOnboardingTemplate }) => {
    const storedSettings = loadWorkspaceSettings(identifier);

    if (storedSettings) {
      set(
        (draft) => {
          draft.workspace = storedSettings;
        },
        false,
        actionType("workspace-loaded"),
      );
    } else {
      set(
        (draft) => {
          if (identifier.componentType === "module") {
            draft.workspace = {
              ...initialState,
              layout: {
                preset: "vertical-100-0",
                direction: "vertical",
                customGrid: null,
              },
            };
          } else {
            draft.workspace = {
              ...initialState,
              hotReloading:
                (identifier.componentType === "page" && isOnboardingTemplate) ??
                false,
            };
          }
        },
        false,
        actionType("workspace-loaded"),
      );
    }
  },
  multiplayerCursorToggled: async (identifier) => {
    set(
      (draft) => {
        draft.workspace.multiplayerCursor = !get().workspace.multiplayerCursor;
      },
      false,
      actionType("multiplayer-cursor-toggled"),
    );

    const user = get().auth.user;
    if (user) {
      await pulse({
        user,
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          workspaceSetting: "multiplayer-cursor",
        },
        text: "Toggled multiplayer cursor",
      });
    }

    saveWorkspaceSettings(identifier, get().workspace);
  },
  darkModeToggled: async (identifier) => {
    set(
      (draft) => {
        draft.workspace.darkMode = !get().workspace.darkMode;
      },
      false,
      actionType("dark-mode-toggled"),
    );

    const user = get().auth.user;
    if (user) {
      await pulse({
        user,
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          workspaceSetting: "dark-mode",
        },
        text: "Toggled theme",
      });
    }

    saveWorkspaceSettings(identifier, get().workspace);
  },
  hotReloadingToggled: async (identifier) => {
    set(
      (draft) => {
        draft.workspace.hotReloading = !get().workspace.hotReloading;
      },
      false,
      actionType("hot-reloading-toggled"),
    );

    const user = get().auth.user;
    if (user) {
      await pulse({
        user,
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          workspaceSetting: "hot-reloading",
        },
        text: "Toggled hot-reloading",
      });
    }

    saveWorkspaceSettings(identifier, get().workspace);
  },
  layoutAdjusted: async (identifier, params) => {
    set(
      (draft) => {
        draft.workspace.layout.direction = params.direction;
        draft.workspace.layout.preset = params.preset;
      },
      false,
      actionType("layout-adjusted"),
    );

    const user = get().auth.user;
    if (user) {
      await pulse({
        user,
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          workspaceSetting: "layout",
          layout: params.direction,
        },
        text: "Adjusted layout",
      });
    }

    saveWorkspaceSettings(identifier, get().workspace);
  },
  layoutDragCompleted: async (identifier, params) => {
    set(
      (draft) => {
        draft.workspace.layout.preset = "custom";
        draft.workspace.layout.customGrid = params.customGrid;
      },
      false,
      actionType("layout-drag-completed"),
    );

    const user = get().auth.user;
    if (user) {
      await pulse({
        user,
        eventName: ADJUSTED_WORKSPACE,
        properties: {
          ...identifier,
          workspaceSetting: "layout",
          editorSize: "custom",
        },
        text: "Adjusted layout",
      });
    }

    saveWorkspaceSettings(identifier, get().workspace);
  },
});
