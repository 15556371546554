import type * as Stitches from "@stitches/react";
import { ComponentProps } from "react";
import { styled } from "../../../../stitches.config";

const roundButtonStyle = {
  $$primaryColor: "#3A21D1",
  $$secondaryColor: "#fff",
  $$transition: "all 0.6s cubic-bezier(0.33, 1, 0.68, 1)",

  color: "inherit",
  width: "fit-content",
  whiteSpace: "nowrap",
  backgroundColor: "transparent",
  cursor: "pointer",
  fontSize: "inherit",
  fontFamily: "inherit",

  border: "2px solid $$primaryColor",
  borderRadius: "40px",
  padding: "13px 26px 13px",

  position: "relative",
  overflow: "hidden",

  transition: "$$transition",

  "&:hover .background": {
    width: "100%",
    backgroundColor: "$$primaryColor",
  },

  "&:hover .content": {
    color: "$$secondaryColor",
  },
} satisfies Stitches.CSS;

const LinkWrapper = styled("a", roundButtonStyle);

const ButtonWrapper = styled("button", roundButtonStyle);

const Background = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: 0,
  right: "auto",
  height: "100%",
  backgroundColor: "transparent",
  transition: "$$transition",
  zIndex: 0,
});

const Content = styled("div", {
  color: "$$primaryColor",
  transition: "$$transition",
  zIndex: 1,
  position: "relative",
});

export const RoundButtonLink = ({
  children,
  ...rest
}: ComponentProps<typeof LinkWrapper>) => (
  <LinkWrapper rel="noopener" {...rest}>
    <Background className="background" />
    <Content className="content">{children}</Content>
  </LinkWrapper>
);

export const RoundButton = ({
  children,
  ...rest
}: ComponentProps<typeof ButtonWrapper>) => (
  <ButtonWrapper {...rest}>
    <Background className="background" />
    <Content className="content">{children}</Content>
  </ButtonWrapper>
);
