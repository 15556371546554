import dayjs from "dayjs";
import { getVisitorSparklineData } from "../../components/VisitorSparkline/utils";
import { logService } from "../../services/log-service";
import {
  App,
  Job,
  Module,
  MultipageApp,
  MultipageAppPage,
  Project,
} from "../../types/persisted";
import { ENTERED_A_PROJECT, pulse } from "../../utils/analytics-constants";
import { localStorageUtils } from "../../utils/local-storage-utils";
import { sortBySequence } from "../../utils/page-utils";
import { notEmpty } from "../../utils/ts-utils";
import { useStore } from "../store";
import { StoreSlice, StoreState } from "../types";
import { createActionTypeLogger } from "../utils";

export enum DevxStatus {
  ALL_OK = "ALL_OK",
  UNSTABLE = "UNSTABLE",
  NO_CONNECTION = "NO_CONNECTION",
  OFFLINE = "OFFLINE",
}

export interface SparklineChartProps {
  items: { timestamp: string; count: number }[];

  // Used to memoize the chart to avoid expensive re-renders
  key: string;
}

export interface SparklineData {
  newVisits: number;
  activeVisitors: number;
  calculatedAt: string;
  sparklineChart: SparklineChartProps;
}

const actionType = createActionTypeLogger("project");

export type HealthCheckResponse =
  | {
      online: true;
      ok: boolean;
      statusCode: number;
      latency: number;
      instanceTag?: string;
    }
  | { online: false };

interface ProjectState {
  projectId: string | null;
  project?: Project;
  newComponentState:
    | {
        open: true;
        tags?: string[];
        autoselect?: boolean;
      }
    | { open: false };
  devxHealth: {
    state: DevxStatus | null;
    isDevxReady: boolean;
    hasMadeChangesWhileDevxNotReady: boolean;
    meanLatency: number | null;
    healthChecks: HealthCheckResponse[];
    instanceTag: string | null;
  };
  components: {
    multipageApp?: MultipageApp;
    apps: App[];
    jobs: Job[];
    modules: Module[];
    pages: MultipageAppPage[];
  };
  sparkline: SparklineData | null;
  loadingSparkline: boolean;
}

export interface ProjectSlice {
  project: ProjectState;
  newComponentToggled: (params: ProjectState["newComponentState"]) => void;
  projectLoaded: (project: Project) => void;
  projectUnloaded: () => void;
  healthCheckFinished: (params: HealthCheckResponse) => void;
  instanceTagReceived: (params: { instanceTag: string }) => void;
  multipageAppReceived: (params: {
    multipageApp?: MultipageApp;
  }) => void;
  appsReceived: (params: { items: App[] }) => void;
  jobsReceived: (params: { items: Job[] }) => void;
  modulesReceived: (params: { items: Module[] }) => void;
  pagesReceived: (params: { items: MultipageAppPage[] }) => void;
  sparklineMounted: () => void;
  devxRefreshed: () => void;
}

const addToLimitedList = (
  healthCheckResult: HealthCheckResponse,
  history: HealthCheckResponse[],
  limit = 3,
) => [healthCheckResult, ...history.slice(0, limit - 1)];

const isUnstableConnection = (history: HealthCheckResponse[]): boolean =>
  history.some(
    (it) => (it.online && !it.ok) || (it.online && it.latency > 600),
  );

const meanLatency = (history: HealthCheckResponse[]): number => {
  const latencies = history
    .map((it) => (it.online ? it.latency : null))
    .filter(notEmpty);

  if (latencies.length === 0) {
    return 0;
  }

  const sum = latencies.reduce((acc, curr) => acc + curr, 0);

  return Math.round(sum / latencies.length);
};

const initialProjectState = {
  projectId: null,
  newComponentState: { open: false },
  devxHealth: {
    isDevxReady: false,
    state: null,
    hasMadeChangesWhileDevxNotReady: false,
    meanLatency: null,
    healthChecks: [],
    instanceTag: null,
  },
  project: undefined,
  components: {
    multipageApp: undefined,
    modules: [],
    pages: [],
    jobs: [],
    apps: [],
  },
  sparkline: null,
  loadingSparkline: false,
} satisfies ProjectState;

export const devxHealthSelector =
  (projectId: string) => (state: StoreState): ProjectState["devxHealth"] =>
    state.project.projectId === projectId
      ? state.project.devxHealth
      : initialProjectState.devxHealth;

export const useIsDevxReady = (): boolean =>
  useStore((state) => state.project.devxHealth.isDevxReady);

export const useMultipageApp = (): MultipageApp | undefined =>
  useStore((state) => state.project.components.multipageApp);

export const useProject = (): Project | null =>
  useStore((state) => state.project.project ?? null);

export const useModules = (): Module[] =>
  useStore((state) => state.project.components.modules);

export const usePages = (): MultipageAppPage[] =>
  useStore((state) => state.project.components.pages);

export const useJobs = (): Job[] =>
  useStore((state) => state.project.components.jobs);

export const useApps = (): App[] =>
  useStore((state) => state.project.components.apps);

export const projectStore: StoreSlice<ProjectSlice> = (set, get) => ({
  project: initialProjectState,
  projectUnloaded: () => {
    set(
      (draft) => {
        draft.project = initialProjectState;
      },
      false,
      actionType("project-unloaded"),
    );
  },
  multipageAppReceived: (params) => {
    set(
      (draft) => {
        draft.project.components.multipageApp = params.multipageApp;
      },
      false,
      actionType("multipage-app-received"),
    );
  },
  newComponentToggled: (params) => {
    set(
      (draft) => {
        draft.project.newComponentState = params;
      },
      false,
      actionType("new-component-toggled"),
    );
  },
  instanceTagReceived: (params) => {
    set(
      (draft) => {
        draft.project.devxHealth.instanceTag = params.instanceTag;
      },
      false,
      actionType("instance-tag-received", params),
    );
  },
  projectLoaded: async (project?: Project) => {
    if (project) {
      localStorageUtils.setValue<{ projectId: string }>(
        "last-project-visited",
        {
          projectId: project.id,
        },
      );

      set(
        (draft) => {
          if (project.id !== get().project.projectId) {
            draft.project = {
              ...initialProjectState,
              projectId: project.id,
              project,
            };
          }
        },
        false,
        actionType("project-loaded", { projectId: project.id }),
      );

      const user = get().auth.user;

      if (user) {
        await pulse({
          user,
          eventName: ENTERED_A_PROJECT,
          properties: {
            projectId: project.id,
          },
        });
      }
    } else {
      logService.warn("Received undefined for project");
      set(
        (draft) => {
          draft.project = initialProjectState;
        },
        false,
        actionType("project-loaded", { projectId: null }),
      );
    }
  },
  healthCheckFinished: (params) => {
    set(
      (draft) => {
        if (params.online) {
          const { ok } = params;
          const updatedList = addToLimitedList(
            params,
            get().project.devxHealth.healthChecks,
          );

          if (params.instanceTag) {
            draft.project.devxHealth.instanceTag = params.instanceTag;
          }

          draft.project.devxHealth.healthChecks = updatedList;
          draft.project.devxHealth.meanLatency = meanLatency(updatedList);

          if (ok) {
            draft.project.devxHealth.isDevxReady = true;
          }

          if (ok && isUnstableConnection(updatedList)) {
            draft.project.devxHealth.state = DevxStatus.UNSTABLE;
          } else if (ok) {
            draft.project.devxHealth.state = DevxStatus.ALL_OK;
          } else if (!ok) {
            draft.project.devxHealth.state = DevxStatus.NO_CONNECTION;
          } else {
            draft.project.devxHealth.state = null;
          }
        } else {
          draft.project.devxHealth.state = DevxStatus.OFFLINE;
        }
      },
      false,
      actionType("health-check-finished", params),
    );
  },
  appsReceived: (params) => {
    set(
      (draft) => {
        draft.project.components.apps = params.items.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
          }),
        );
      },
      false,
      actionType("apps-received"),
    );
  },
  jobsReceived: (params) => {
    set(
      (draft) => {
        draft.project.components.jobs = params.items.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
          }),
        );
      },
      false,
      actionType("jobs-received"),
    );
  },
  modulesReceived: (params) => {
    set(
      (draft) => {
        draft.project.components.modules = params.items.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
          }),
        );
      },
      false,
      actionType("modules-received"),
    );
  },
  pagesReceived: (params) => {
    set(
      (draft) => {
        draft.project.components.pages = params.items.sort(sortBySequence);
      },
      false,
      actionType("pages-received"),
    );
  },
  devxRefreshed: () => {
    set(
      (draft) => {
        draft.project.devxHealth.hasMadeChangesWhileDevxNotReady = false;
      },
      false,
      actionType("devx-refreshed"),
    );
  },
  sparklineMounted: async () => {
    const {
      projectId,
      sparkline: previousSparkline,
      loadingSparkline,
    } = get().project;

    // Should update sparkline if
    // - Is not already generating sparkline
    // - Has no sparkline or sparkline is older than 10 minutes
    const shouldUpdateSparkline =
      loadingSparkline === false &&
      (previousSparkline === null ||
        dayjs().diff(dayjs(previousSparkline.calculatedAt), "minutes") > 10);

    if (projectId && shouldUpdateSparkline) {
      set(
        (draft) => {
          draft.project.loadingSparkline = true;
        },
        false,
        actionType("sparkline-loading"),
      );

      const sparkline = await getVisitorSparklineData({ projectId });

      set(
        (draft) => {
          draft.project.sparkline = sparkline;
          draft.project.loadingSparkline = false;
        },
        false,
        actionType("sparkline-updated"),
      );
    }
  },
});
