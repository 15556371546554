import * as Sentry from "@sentry/react";
import { FirebaseError } from "firebase/app";
import { User } from "firebase/auth";
import { doc, getDoc, runTransaction } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fetchApp } from "../../utils/collections/apps";
import { fetchMultipageApp } from "../../utils/collections/multipage-apps";
import { CollectionName } from "../../utils/collections/shared";
import { viewerConverter } from "../../utils/collections/viewers";
import { firestore } from "../../utils/firebase";
import { AppOrMultipageApp } from "./AppGuard";

const claimPendingInvite = async (params: {
  refPath: string;
  user: User;
}): Promise<void> => {
  if (params.user.email) {
    const pendingInviteDocRef = doc(
      firestore,
      `${params.refPath}/${CollectionName.VIEWERS}`,
      params.user.email,
    ).withConverter(viewerConverter);

    const pendingInviteDoc = await getDoc(pendingInviteDocRef);

    // Check if there exists a pending invite for logged in users email address
    if (pendingInviteDoc.exists()) {
      const {
        id: _id,
        refPath: _refPath,
        ...pendingInvite
      } = pendingInviteDoc.data();

      // Check that the invite has not been revoked
      if (!pendingInvite.revoked) {
        const viewerDocRef = doc(
          firestore,
          `${params.refPath}/${CollectionName.VIEWERS}`,
          params.user.uid,
        );

        // Create a new viewer using the logged in user id and delete the pending invite
        await runTransaction(firestore, async (transaction) => {
          transaction
            .set(viewerDocRef, {
              ...pendingInvite,
              userId: params.user.uid,
            })
            .delete(pendingInviteDocRef);
        });
      }
    }
  }
};

export const fetchAppOrMultipageApp = async (params: {
  refPath: string;
}): Promise<AppOrMultipageApp | null> => {
  if (/\/multipage-apps\//i.test(params.refPath)) {
    return fetchMultipageApp({ refPath: params.refPath });
  } else {
    return fetchApp({ refPath: params.refPath });
  }
};

export const useAppAndClaimInvite = ({
  ref,
  user,
}: {
  ref?: string;
  user: User | null;
}): { app: AppOrMultipageApp | null; loading: boolean } => {
  const [app, setApp] = useState<AppOrMultipageApp | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ref) {
      setLoading(true);

      fetchAppOrMultipageApp({ refPath: ref })
        .then((it) => {
          if (user) {
            claimPendingInvite({
              refPath: ref,
              user,
            }).then(() => {
              setApp(it);
              setLoading(false);
            });
          } else {
            setApp(it);
            setLoading(false);
          }
        })
        .catch((error: FirebaseError) => {
          if (/permission-denied/i.test(error.code)) {
            setLoading(false);
          } else {
            Sentry.captureException(error);
            setLoading(false);
          }
        });
    }
  }, [ref]);

  return { app, loading };
};
